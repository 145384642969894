import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axiosInstance from '../../axiosinstance/axiosApi';

import Confirmnws from './confirmnws';
import Spinner from 'react-bootstrap/Spinner';

import { Grid, InputBase } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
// import Confirmdstv from "./confirmdstv";

export class NWS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			account_number: '',
			amount: '',
			phone_number: '',
			show: false,
			loading: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlenwsShow = this.handlenwsShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handlenwsShow() {
		// console.log(this.state);
		this.setState({ show: true });
		this.setState({ loading: false });
		this.setState({ account_number: '' });
		this.setState({ amount: '' });
		this.setState({ phone_number: '' });
    this.setState({ loading: false });

	}

	handleClose() {
		this.setState({ show: false });
	}
	gotoDetails() {
		this.setState({ show: false });
		this.onLoginClick();
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit() {
		this.setState({ loading: false });

		const amount = Number(this.state.amount);

		const data = {
			amount,
			account_number: this.state.account_number,
			phone_number: this.state.phone_number,
		};

		console.log('send data reference number to server', data);

		try {
			const response = await axiosInstance.post(
				'/savanacomwater/meter/number/',
				data
			);
			console.log(
				'savana customer details server response',
				response.data
			);

			this.setState(response.data);

			Cookies.set(`nwsdetails`, {
				amount: this.state.amount,
				acount_number: this.state.acount_number,
				confirmation_number: response.data.confirmation_number,
				customer_name: response.data.customer_name,
			});
			if (response.data.success) {
				this.setState({ loading: false }, () =>
					console.log('changed: ', this.state)
				);
				return this.gotoDetails();
			}

			toast.error('failed to pay', {
				position: 'top-center',
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			this.setState({ loading: false }, () =>
				console.log('changed: ', this.state)
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	// code for confirm modal
	confirmModalRef = ({ handleConfirmnwsShow }) => {
		this.showModal = handleConfirmnwsShow;
	};

	gottodetails = () => {
		this.showModal();
	};
	onLoginClick() {
		this.setState({ show: false });
		this.gottodetails();
	}
	render() {
		const { values, handleChange } = this.props;
		const { loading } = this.state;

		return (
			<div>
				<Confirmnws ref={this.confirmModalRef}></Confirmnws>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Bill Payment
						</h4>
					</div>
					<Modal.Body>
						{/* <Fragment> */}
						<div style={{ fontSize: '9', textAlign: 'center' }}>
							Enter Nkana Water Details to make payment
						</div>

						<br />
						<div
							style={{
								// display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<InputBase
								placeholder='Enter Account Number'
								name='account_number'
								className='form-control'
								type='number'
								value={this.state.account_number}
								onChange={this.handleChange}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							<br />
							<br />
							<InputBase
								placeholder='No. Register To Acc'
								name='phone_number'
								className='form-control'
								type='number'
								value={this.state.phone_number}
								onChange={this.handleChange}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							<br />
							<br />
							<InputBase
								placeholder='Enter Amount'
								name='amount'
								className='form-control'
								type='number'
								value={this.state.amount}
								onChange={this.handleChange}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							<br />

							<br />
							<br />
							<div
								style={{
									// backgroundColor: theme.palette.background.paper,
									// border: "2px solid #C8C8C8",
									// boxShadow: theme.shadows[5],
									// padding: theme.spacing(2, 4, 4),
									// textAlign: "center",
									background: [
										//['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
											'no-repeat',
											'top 230% left 100%',
										],
										//['white']
									],
								}}>
								{loading && (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<Spinner
											as='span'
											animation='grow'
											size='lg'
											role='status'
											aria-hidden='true'
											style={{ color: '#29BD00' }}
										/>
										<span style={{ color: '#29BD00' }}>
											Loading...
										</span>
									</div>
								)}

								{!loading && (
									<div>
										<input
											onClick={this.handleSubmit}
											type='submit'
											value='Submit'
											style={{
												color: 'white',
												variant: 'contained',
												size: 'small',
												backgroundColor: '#29BD00',
												margin: '0px 2px 0px 55px ',
												fontSize: 15,
												height: '40px',
												fontWeight: '',
												textTransform: 'none',
												textAlign: 'center',
												border: '1px solid #29BD00',
												borderRadius:
													'100px 100px 100px 100px',
												padding: '5px 3px ',
												width: '60%',
												boxShadow:
													'0px 3px 3px #E2E2E2',
												'&:hover': {
													backgroundColor: '#29BD00',
												},
											}}
										/>
									</div>
								)}
							</div>
						</div>

						<br />
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default NWS;
