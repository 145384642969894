import React, { Component, Fragment } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
// import './account.css';
import TextField from '@material-ui/core/TextField';

// import "./PageStyles.css";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axiosInstance from '../axiosinstance/axiosApi';
const styles = (theme) => ({
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'yellow !important',
		color: 'red',
	},
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#29BD00', //your color
		},
	},
});

class NotRegisteredBusiness extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nrc_file: null,

			business_name: '',
			merchant_code: '000',
			bank_account_number: '',
			email_address: '',
			mobile_money_account_number: '01',
			physical_address: '',
			contact_phone_number: '',
			nrc_number: '',

			business_name_error: null,
			bank_account_number_error: null,
			email_address_error: null,
			physical_address_error: null,
			contact_phone_number_error: null,
			nrc_number_error: null,
			nrc_file_error: null,
			// business_name: "",
			// merchant_code: "000",
			// bank_account_number: "",
			// email_address: "",
			// mobile_money_account_number: "",
			// physical_address: "",
			// contact_phone_number: "",
			// nrc_number: "",
			// nrc_file: null,

			show: false,
			accessshow: false,
			timeoutshow: false,
			loading: false,
			access_error: '',
			error: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlelogout = this.handlelogout.bind(this);

		this.sendData = this.sendData.bind(this);
	}

	nrc_file = null;

	handlenrcUpload = (event) => {
		event.preventDefault();
		this.nrc_file = event.target.files[0];
	};

	// save the picked file in this

	validateNumber = (evt) => {
		var theEvent = evt || window.event;

		// Handle paste
		if (theEvent.type === 'paste') {
			key = theEvent.clipboardData.getData('text/plain');
		} else {
			// Handle key press
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSubmit = () => {
		alert('Hello World!');
		this.setState({ loading: true });

		this.sendData();
	};

	async sendData() {
		const { form } = this.state;
		const timer = null;
		if (this.state.business_name == 0) {
			this.setState({
				business_name_error: 'Business Name is Required',
			});
		} else if (this.state.bank_account_number == 0) {
			this.setState({
				bank_account_number_error: 'Bank Account No. is Required',
			});
			this.setState({
				business_name_error: '',
			});
		} else if (this.state.contact_phone_number == 0) {
			this.setState({
				contact_phone_number_error: 'Phone No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
			});
		} else if (this.state.email_address == 0) {
			this.setState({
				email_address_error: 'Email Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				contact_phone_number_error: '',
			});
		} else if (this.state.nrc_number == 0) {
			this.setState({
				nrc_number_error: 'NRC Number is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
			});
		} else if (this.state.physical_address == 0) {
			this.setState({
				physical_address_error: 'Physical Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				contact_phone_number_error: '',
				nrc_number_error: '',
				email_address_error: '',
			});
		} else {
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				contact_phone_number_error: '',
				nrc_number_error: '',
				email_address_error: '',
				physical_address_error: '',
			});
			this.setState({ loading: true });
			let data = new FormData();

			data.append('business_name', this.state.business_name);
			data.append('bank_account_number', this.state.bank_account_number);
			data.append('email_address', this.state.email_address);
			data.append(
				'contact_phone_number',
				this.state.contact_phone_number
			);
			data.append(
				'mobile_money_account_number',
				this.state.mobile_money_account_number
			);

			data.append('physical_address', this.state.physical_address);
			data.append('nrc_number', this.state.nrc_number);
			data.append('nrc_file', this.nrc_file);
			data.append('account_type', 'unregistered');
			console.log('check if the data is all in', data);
			try {
				const response = await axiosInstance.post(
					'/account/unregistered/business/',
					data
				);
				console.log('getting response ', response);

				if (response.data.success === 'ok') {
					this.onEkamoClick();
					this.setState({ loading: false, timeoutshow: false });
					console.log('account has been created');
				}

				if (
					response.data.error ===
					'You can not access this area, you are not a sales agent'
				) {
					this.setState({
						access_error: response.data.error,
						timeoutshow: false,
					});

					this.accesserrorclick();
				}

				if (!response.data.error == 0) {
					console.log('check the if error is present');

					this.setState({
						loading: false,
						timeoutshow: false,

						business_name_error: response.data.error.business_name,
						nrc_file_error: response.data.error.nrc_file,
						bank_account_number_error:
							response.data.error.bank_account_number,
						contact_phone_number_error:
							response.data.error.contact_phone_number,
						nrc_number_error: response.data.error.nrc_number,
						email_address_error: response.data.error.email_address,
						physical_address_error:
							response.data.error.physical_address,
					});
				}

				if (response.data.error === 'ok') {
					this.setState({
						loading: false,
						timeoutshow: false,

						error: response.data.message,
					});
					this.notify();
				}
			} catch (error) {
				this.setState({
					errors: error,
					timeoutshow: false,
				});
			}
			setTimeout(() => {
				this.setState({
					loading: false,
					timeoutshow: true,
				});
			}, 60000);
		}

		// // event.preventDefault();
	}

	async handlelogout(event) {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	}

	accesserrorclick = () => {
		this.setState({ accessshow: true });
	};

	onEkamoClick = () => {
		this.setState({ show: true });
	};

	notify = () => {
		toast.error(this.state.error, {
			position: 'top-center',
			autoClose: 400000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	handletimeClose = () => {
		this.setState({ timeoutshow: false });
	};
	render() {
		const { loading } = this.state;
		// const { classes } = this.props;
		return (
			<Fragment>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Modal
					scrollable={true}
					show={this.state.timeoutshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<br />
						<h5
							style={{
								fontWeight: 'bold',
								color: 'red',
								textAlign: 'center',
							}}>
							Response taking too long
						</h5>
						<br />
						<ul>
							<li>
								Go through the form and check for error
								messages.
							</li>
							<li>
								Go to accounts created page to see if account
								has been created.
							</li>
							<li>Ensure you have Strong internet Connection.</li>
							<li>
								Make sure you have logged in and are able to
								view your name.
							</li>
							<li>
								Ensure the number you used to login is
								activaterd as Sales Agent.
							</li>
							<li>If issue persists, contact us.</li>
						</ul>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Button
								onClick={this.handletimeClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.accessshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							{this.state.access_error}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />

							<Button
								onClick={this.handleClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							You Have Succesfully Created an Unregistered
							Personal Business account called
							{this.state.business_name}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />
							<Link
								to='/'
								color='inherit'
								style={{ fontSize: '12px', color: 'white' }}>
								<Button
									style={{
										color: 'white',
										variant: 'contained',
										size: 'small',
										backgroundColor: '#29BD00',
										margin: '0px 1px 0px 0px ',
										fontSize: 15,
										height: '40px',
										fontWeight: '',
										textTransform: 'none',
										border: '1px solid #29BD00',
										borderRadius: '100px 100px 100px 100px',
										padding: '5px 3px ',
										width: '70%',
										boxShadow: '0px 3px 3px #E2E2E2',
										'&:hover': {
											backgroundColor: '#29BD00',
										},
									}}>
									Close
								</Button>
							</Link>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Grid item container>
					<Hidden mdDown>
						<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
					</Hidden>
					<br /> <br />
					<br /> <br />
					<br /> <br />
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<div
							style={{
								margin: 0,
								minHeight: 40,
								textAlign: 'left',
								marginLeft: 20,
								marginRight: 20,
								marginTop: '10%',
								borderRadius: '10px 10px 0px 0px',
								background: [
									[
										'url(https://liftandshifttrucking.co.zm/email_signature/reg_title_icon.svg)',
										'no-repeat',
										'top 56% left 440px',
									],

									['#29BD00'],
								],

								color: 'white',
							}}>
							<Typography
								style={{
									padding: 10,
									paddingLeft: 20,
								}}>
								Business Not Registered With Pacra
							</Typography>
						</div>
						<Card
							style={{
								minHeight: 0,
								border: '1px solid #E8E8E8',
								textAlign: 'left',
								borderRadius: 0,
								marginLeft: 20,
								marginRight: 20,
								boxShadow: 'none',
							}}>
							<CardContent>
								<ThemeProvider theme={theme}>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}>
										{/* <form onSubmit={this.handleSubmit}> */}
										<Card
											style={{
												background: [
													[
														'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
														'no-repeat',
														'top 10% left 15%',
													],
												],

												padding: 20,
											}}>
											<br />
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}>
												<div>Business Information</div>
											</Grid>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='business_name'
														label='Business Name'
														value={
															this.state
																.business_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.business_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.business_name_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='bank_account_number'
														value={
															this.state
																.bank_account_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Bank Account Number'
														variant='outlined'
													/>
													{this.state
														.bank_account_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.bank_account_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='contact_phone_number'
														value={
															this.state
																.contact_phone_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Phone No'
														variant='outlined'
													/>
													{this.state
														.contact_phone_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.contact_phone_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='email_address'
														type='email'
														value={
															this.state
																.email_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Email Address'
														variant='outlined'
													/>
													{this.state
														.email_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.email_address_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='nrc_number'
														value={
															this.state
																.nrc_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Enter NRC No.'
														variant='outlined'
													/>
													{this.state
														.nrc_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.nrc_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='physical_address'
														value={
															this.state
																.physical_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Physical Address'
													/>
													{this.state
														.physical_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.physical_address_error
															}
														</span>
													)}
												</Grid>
											</Grid>
											<br />
											<TextField
												variant='outlined'
												margin='normal'
												required
												fullWidth
												label='Upload NRC'
												type='file'
												name='nrc_file'
												value={this.state.nrc_file}
												onChange={this.handlenrcUpload}
												onBlur={this.handlenrcUpload}
												autoFocus
												id='filled-number'
												InputLabelProps={{
													shrink: true,
												}}
											/>
											{/* <label htmlFor="conPhysical Addresstained-button-file">
                        <Button
                          type="file"
                          style={{
                            textTransform: "none",
                            border: "1px solid #979797",

                            height: "40px",
                            padding: "0px 0px 0px 10px ",
                            background: "none",
                            marginTop: 20,
                            color: "#C0C0C0",

                            fontSize: 13,
                            fontWeight: "normal",
                            display: "flex",
                            justifyContent: "flex-start",
                            width: "100%",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "#d4f2cc",
                              border: "1px solid #d4f2cc",
                              boxShadow: "none",
                            },
                          }}
                          startIcon={
                            <CloudUploadIcon
                              style={{ fontSize: 18, color: "#C0C0C0" }}
                            />
                          }
                          variant="contained"
                          component="span"
                        >
                          Upload NRC
                        </Button>
                      </label>
                      <InputBase
                        accept="image/*"
                        style={{
                          fontSize: 10,

                          textTransform: "none",
                          paddingRight: 20,
                          opacity: 0.3,
                        }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="nrc_file"
                        value={this.state.nrc_file}
                        onChange={this.handlenrcUpload}
                        onBlur={this.handlenrcUpload}
                      /> */}
											{this.state.nrc_file_error && (
												<span
													style={{
														fontSize: 'small',
														color: 'red',
													}}>
													{this.state.nrc_file_error}
												</span>
											)}
											<br /> <br />
										</Card>
										<span style={{ fontSize: '10px' }}>
											Note: All fields are required
										</span>
										<br />
										<Hidden smDown>
											<br />
										</Hidden>
										<Grid container spacing={0}>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
											<Grid
												item
												xs={12}
												sm={4}
												md={4}
												lg={4}
												xl={4}>
												<Button
													onClick={this.sendData}
													type='submit'
													value='Submit'
													style={{
														color: 'white',
														variant: 'contained',
														size: 'small',
														backgroundColor:
															'#29BD00',
														margin: '0px 1px 0px 0px ',
														fontSize: 15,
														height: '40px',
														fontWeight: '',
														textTransform: 'none',
														textAlign: 'center',
														border: '1px solid #29BD00',
														borderRadius:
															'100px 100px 100px 100px',
														padding: '5px 3px ',
														width: '100%',
														boxShadow:
															'0px 3px 3px #E2E2E2',
														'&:hover': {
															backgroundColor:
																'#29BD00',
														},
													}}>
													{loading && (
														<>
															<Spinner
																as='span'
																animation='grow'
																size='lg'
																role='status'
																aria-hidden='true'
																style={{
																	color: '#fff',
																}}
															/>
														</>
													)}
													{!loading && (
														<span>Submit</span>
													)}
												</Button>
											</Grid>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
										</Grid>
										<br />
										{/* </form> */}
									</Grid>
								</ThemeProvider>
							</CardContent>
						</Card>
					</Grid>
					<Hidden mdDown>
						<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
					</Hidden>
				</Grid>
				<br /> <br />
			</Fragment>
		);
	}
}
NotRegisteredBusiness.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NotRegisteredBusiness);
