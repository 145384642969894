import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../axiosinstance/axiosApi";

import Confirmmtn from "./confirm";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";

export class MTNDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_number: "",
      balance: "",
      phone_number_error: "",
      balance_error: "",
      show: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlemtnShow = this.handlemtnShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlemtnShow() {
    // console.log(this.state);
    this.setState({ show: true });

    this.setState({
      loading: false,
      phone_number: "",
      balance: "",
      phone_number_error: "",
      balance_error: "",
    });
  }

  handleClose() {
    this.setState({ show: false });
  }
  gotoDetails() {
    this.setState({ show: false });
    this.onLoginClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    if (this.state.phone_number == 0) {
      this.setState({
        phone_number_error: "Phone Number is Required",
      });
    } else if (this.state.balance == 0) {
      this.setState({
        balance_error: "Amount is Required",
        phone_number_error: "",
      });
    } else {
      this.setState({
        loading: true,
        phone_number_error: "",
        balance_error: "",
      });

      Cookies.set(`mtnmomo`, {
        phone_number: this.state.phone_number,
        amount: this.state.balance,
      });
      // console.log("form data", this.state);

      this.gotoDetails();
    }
  }

  // code for confirm modal
  confirmModalRef = ({ handleConfirmShow }) => {
    this.showConfirmModal = handleConfirmShow;
  };

  onLoginClick = () => {
    this.showConfirmModal();
  };

  render() {
    const { values, handleChange } = this.props;
    const { form, formErrors, loading } = this.state;

    return (
      <div>
        <Confirmmtn ref={this.confirmModalRef}></Confirmmtn>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              LoadFunds
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}

            <div style={{ fontSize: "9", textAlign: "center" }}>
              Loading Funds Into Your Ekamo Wallet From MTN Money
            </div>
            <br />
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
              autocomplete="off"
                placeholder="Enter Phone Number"
                name="phone_number"
                className="form-control"
                type="number"
                value={this.state.phone_number}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              {this.state.phone_number_error && (
                <>
                  <span
                    style={{
                      fontSize: "small",
                      color: "red",
                      textAlign: "center",
                      marginLeft: "12%",
                    }}
                  >
                    {this.state.phone_number_error}
                  </span>
                  <br />
                </>
              )}
              <br />
              <InputBase
              autocomplete="off"
                placeholder="Enter Amount"
                name="balance"
                className="form-control"
                type="number"
                value={this.state.balance}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />
              {this.state.balance_error && (
                <>
                  <span
                    style={{
                      fontSize: "small",
                      color: "red",
                      textAlign: "center",
                      marginLeft: "12%",
                    }}
                  >
                    {this.state.balance_error}
                  </span>
                  <br />
                </>
              )}
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MTNDetails;
