import React, { Component } from 'react';
import axiosInstance from '../axiosinstance/axiosApi';
import { Grid, InputBase, Button } from '@material-ui/core';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Hidden from '@material-ui/core/Hidden';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
const styles = (theme) => ({
	paper: {
		position: 'relative',
		width: '',
		// borderRadius: "10px 10px 10px 10px ",
		backgroundColor: theme.palette.background.paper,
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 4),
		text: ['center'],
		background: [
			[
				'url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)',
				'no-repeat',
				'top -30% left 1%',
			],
			//['url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)', 'no-repeat', 'top 100% left 100%'],
			//['white']
		],
	},

	//define style for country code on mobile screens

	//define style for username on mobile screens
	InputUsernameMobile: {
		border: '1px solid #979797',
		borderRadius: theme.shape.borderRadius,
		height: '40px',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'none',
		margin: '0px 2px 0px 0px ',
		fontSize: 14,
		width: '100%',
	},

	//define style for passworde on mobile screens
	InputPasswordMobile: {
		border: '1px solid #979797',
		// borderRadius : theme.shape.borderRadius,
		height: '40px',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'none',
		margin: '0px 4px 0px 0px ',
		fontSize: 14,
		width: '100%',
	},

	//define style for header buttons on mobile screens
	HeaderButtonMobile: {
		color: 'inherit',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#25A203',
		margin: '0px 1px 0px 0px ',
		fontSize: 13,
		fontWeight: '',
		textTransform: 'none',
		border: '1px solid #25A203',
		// borderRadius : theme.shape.borderRadius,
		padding: '5px 3px ',
		'&:hover': {
			backgroundColor: '#25A203',
		},
	},

	//define style for header buttons on mobile screens
	LoginButtonMobile: {
		color: 'white',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#29BD00',
		margin: '0px 1px 0px 0px ',
		fontSize: 15,
		height: '40px',
		fontWeight: '',
		textTransform: 'none',
		border: '1px solid #229BD00',
		borderRadius: '100px 100px 100px 100px',
		padding: '5px 3px ',
		width: '100%',
		boxShadow: '0px 3px 3px #E2E2E2',

		'&:hover': {
			backgroundColor: '#29BD00',
		},
	},

	clickHere: {
		fontSize: 12,
		color: '#25A203',
	},

	forgotpassword: {
		textAlign: 'right',
		marginTop: '10px',
		marginBottom: '10px',
		fontSize: 12,
		color: '#25A203',
		padding: '3px',
	},

	dontHaveAccount: {
		textAlign: 'center',
		fontSize: 11,
		color: '#646464',

		//boxShadow: "0px 40px 40px #E2E2E2",
	},
});

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			business_name: '',
			loading: false,
			loadingerror: '',
			username: '',
			password: '',
			errormessage: '',
			hidden: true,
			error: '',
			// form: {
			//   username: "",
			//   password: "",
			// },
			formErrors: {
				username: null,
				password: null,
			},
		};
		// this.state = { merchant_code: "", amount: "", business_name: "" };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		// this.login = this.login.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);

	
		this.toggleShow = this.toggleShow.bind(this);
		this.handleClose = this.handleClose.bind(this); //   this closes the modal
	}


	handleShow() {
		// console.log("check the login state",this.state.form.username);
		this.setState({
			show: true,
			username: '',
			password: '',
		});

		if (this.props.password) {
			this.setState({ password: this.props.password });
		  }
	}


	toggleShow() {
		this.setState({ hidden: !this.state.hidden });
	  }
	

	handleClose() {
		this.setState({ show: false });
	}

	gotoDetails() {
		this.setState({ show: false });
		this.onSuccessClick();
	}
	// the modal code ends here

	forgotpassword = () => {
		window.location.href = '/passwordrecovery/';
	};
	validateNumber = (evt) => {
		var theEvent = evt || window.event;

		// Handle paste
		if (theEvent.type === 'paste') {
			key = theEvent.clipboardData.getData('text/plain');
		} else {
			// Handle key press
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handlePasswordChange(e) {
		this.setState({ password: e.target.value });
	  }
	
	async handleSubmit() {
		this.setState({ loading: true });
		// const { form } = this.state;

		setTimeout(() => {
			this.setState({
				loading: false,
				loadingerror: ['Something with the Network, Please Try Again'],
			});
		}, 20000);

		try {
			const response = await axiosInstance.post('/token/obtain/', {
				username: this.state.username,
				password: this.state.password,
			});
			// console.log("check response", response.data);
			axiosInstance.defaults.headers['Authorization'] =
				'JWT ' + response.data.access;
			localStorage.setItem('access_token', response.data.access);

			console.log('check login response', response);
			this.handleClose();
			if (response.statusText == 'OK') {
				window.location.href = '/';
			}
			// return response;
		} catch (error) {
			// throw error;
			if (
				error.response.data.non_field_errors[0] ==
				'No active account found with the given credentials'
			) {
				console.log('check if the user in logged in');
				this.setState({
					loading: false,
					errormessage: 'Account Not Found',
				});
			} else {
				this.setState({
					loading: false,
					errormessage: error.response.data,
				});
			}
		}
	}


	onSuccessClick = () => {
		this.showModal();
	};

	render() {
		const { form, formErrors, loading } = this.state;
		const { classes } = this.props;
		return (
			<div>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Body className={classes.paper}>
						{/* <Fragment> */}
						<Modal.Header
							closeButton
							style={{
								borderBottom: 'none',
								background: 'transparent',
								padding: '0rem',
							}}
						/>
						<Grid>
							<Grid item xs={1} sm={1} md={4} lg={8} xl={8} />

							<Grid item xs={10} sm={10} md={8} lg={10} xl={10}>
								<div
									style={{
										marginLeft: '20%',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<h5
										style={{
											textAlign: 'center',
											fontWeight: 'bold ',
										}}>
										Login
									</h5>
									<form autoComplete='off'>
										<InputBase
											autocomplete='false'
											name='username'
											className='form-control'
											type='number'
											autoComplete='off'
											value={this.state.username}
											onChange={this.handleChange}
											onBlur={this.handleChange}
											id='username'
											placeholder='Enter Mobile Number'
											variant='outlined'
											className={
												classes.InputUsernameMobile
											}
										/>
										<br />

										{/* {formErrors.username && (
                    <>
                      <span style={{ fontSize: "small", color: "red" }}>
                        {formErrors.username}
                      </span>
                      <br />
                    </>
                  )} */}
										<br />
										<div>
        {/* <input
          type={this.state.hidden ? "password" : "text"}
          value={this.state.password}
          onChange={this.handlePasswordChange}
        /> */}
			<InputBase
											autocomplete='false'
											placeholder='Enter Password'
											className='form-control'
											type='password'
											name='password'
											value={this.state.password}
											onChange={this.handlePasswordChange}
											onBlur={this.handlePasswordChange}
											className={
												classes.InputPasswordMobile
											}
										/>
        {/* <button onClick={this.setState({ hidden: !this.state.hidden })}>Show / Hide</button> */}
      </div>
									
										<br />
									</form>
									{/* {formErrors.password && (
                    <>
                      <span
                        style={{
                          fontSize: "small",
                          color: "red",
                          marginLeft: "20%",
                        }}
                      >
                        {formErrors.password}
                      </span>
                      <br />
                    </>
                  )} */}

									<span
										className={classes.forgotpassword}
										onClick={this.forgotpassword}>
										Forgot Password?
									</span>

									<br />
									{!loading && (
										<Button
											onClick={this.handleSubmit}
											disabled={loading}
											className={
												classes.LoginButtonMobile
											}>
											Login
										</Button>
									)}

									{loading && (
										<div
											style={{
												marginLeft: '20%',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											<Spinner
												as='span'
												animation='grow'
												size='lg'
												role='status'
												aria-hidden='true'
												style={{ color: '#29BD00' }}
											/>
											<span style={{ color: '#29BD00' }}>
												Loading...
											</span>
										</div>
									)}
									<br />

									<h5
										style={{
											color: 'red',
											fontStyle: 'italic',
											textAlign: 'center',
										}}>
										{this.state.errormessage}
									</h5>

									<br />

								
								</div>
							</Grid>

							<Grid item xs={1} sm={1} md={4} lg={4} xl={4} />
						</Grid>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
Login.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
