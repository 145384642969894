import axiosInstance from '../axiosinstance/axiosApi';

export class APIService {
	static getUserProfile = async () => {
		try {
			const response = await axiosInstance.get('/user/details/');

			return response.data;
		} catch (error) {
			return error;
		}
	};

	static sendOtp = async (phoneNumber) => {
		try {
			const response = await axiosInstance.post(
				'/otp/create/',
				phoneNumber
			);
			return response.data;
		} catch (error) {
			return error;
		}
	};

	static updatePin = async (pinInfo) => {
		try {
			const response = await axiosInstance.put(
				'/user/pin/update/',
				pinInfo
			);
			return response.data;
		} catch (error) {
			return error;
		}
	};

	static createPin = async (pinInfo) => {
		try {
			const response = await axiosInstance.post(
				'/user/pin/create/',
				pinInfo
			);
			return response.data;
		} catch (error) {
			return error;
		}
	};

	static updateKyc = async (files) => {
		try {
			let data = new FormData();
			// // setState({ loading: true });
			data.append('passport_photo', files.passport_photo);
			data.append('nrc_photo_frontface', files.nrc_photo_frontface);
			data.append('nrc_photo_backface', files.nrc_photo_backface);

			const response = await axiosInstance.put(
				'/user/profile/update/',
				data
			);
		} catch (error) {
			return error;
		}
	};

	static changePassword = async (details) => {
		try {
			const response = await axiosInstance.patch(
				'/user/password/change',
				details
			);

			return response.data;
		} catch (error) {
			return error;
		}
	};

	static fetchTransferHistory = async (page = 1) => {
		try {
			if (page <= 0) {
				page = 1;
			}
			const response = await axiosInstance.get(
				`/personal_transaction_history?trans_type=personal&page=${page}`
			);
			return response.data;
		} catch (err) {
			return err;
		}
	};

	static filterTransactions = async (start, end) => {
		try {
			const response = await axiosInstance.get(
				`/personal_transaction_history?trans_type=personal&start_date=${start}&end_date=${end}`
			);
			return response.data;
		} catch (err) {
			return err;
		}
	};

	static getCharges = async (method, amount) => {
		try {
			const response = await axiosInstance.get(
				`/transaction_charges?method=${method}&amount=${amount}`
			);
			return response.data;
		} catch (err) {
			return err;
		}
	};

	static createBranch = async (payload) => {
		try {
			const response = await axiosInstance.post(
				`/account/branch/businesses`,
				payload
			);
			return response.data;
		} catch (err) {
			return err;
		}
	};
}

export class MoneyTransfers {
	static business2Business = async (payload) => {
		try {
			const response = await axiosInstance.post(
				`/money/business-business-transfer`,
				payload
			);
			return response.data;
		} catch (err) {
			if (err && err.response) {
				const axiosError = err;
				return axiosError.response.data;
			}
			throw err;
		}
	};

	static fetchRecepientDetails = async (payload) => {
		try {
			const response = await axiosInstance.get(
				`/business/details/${payload.phone_number}`,
				payload
			);

			return response.data;
		} catch (error) {
			return error;
		}
	};

	static checkExistingUser = async (payload) => {
		try {
			const response = await axiosInstance.get(
				`/money/receivers/${payload.phone_number}`,
				payload
			);

			return response.data;
		} catch (error) {
			return error;
		}
	};

	static business2Person = async (payload) => {
		try {
			const response = await axiosInstance.post(
				'/money/business-person-transfer',
				payload
			);
			return response.data;
		} catch (error) {
			return error;
		}
	};
}
