import React, { Component, Fragment } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Verifyotp from "./verifyotp";
import Card from "@material-ui/core/Card";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import { Grid, InputBase, Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import axiosInstance from "../../axiosinstance/axiosApi";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Navbar, NavItem, Nav } from "react-bootstrap";

const styles = (theme) => ({
  paper: {
    position: "relative",
    width: "",
    // borderRadius: "10px 10px 10px 10px ",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    text: ["center"],
    background: [
      [
        "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
        "no-repeat",
        "top -30% left 1%",
      ],
      //['url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)', 'no-repeat', 'top 100% left 100%'],
      //['white']
    ],
  },

  //define style for username on mobile screens
  InputUsernameMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 2px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for passworde on mobile screens
  InputPasswordMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 4px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for header buttons on desktop screens
  HeaderButtonWeb: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "3px 3px 2px 0px ",
    fontSize: 11,
    textTransform: "none",
    fontWeight: "bold",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },
  //define style for header buttons on mobile screens
  HeaderButtonMobile: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "0px 1px 0px 0px ",
    fontSize: 13,
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #25A203",
    borderRadius: theme.shape.borderRadius,
    padding: "5px 3px ",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },

  //define style for header buttons on mobile screens
  LoginButtonMobile: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29BD00",
    margin: "0px 1px 0px 0px ",
    fontSize: 15,
    height: "40px",
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #29BD00",
    borderRadius: "100px 100px 100px 100px",
    padding: "5px 3px ",
    width: "100%",
    boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29BD00",
    },
  },

  clickHere: {
    fontSize: 12,
    color: "#25A203",
  },

  forgotpassword: {
    textAlign: "right",
    fontSize: 12,
    color: "#25A203",
    padding: "3px",
  },

  dontHaveAccount: {
    textAlign: "center",
    fontSize: 11,
    color: "#646464",

    //boxShadow: "0px 40px 40px #E2E2E2",
  },
});

class Sendotp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { phone_number: "",phone_number_error:"", loading: false };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.Close = this.Close.bind(this);

    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });


    if (event.target.name === 'phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
			
						phone_number_error: 'phone number must be 10 digits',
				  })
				: this.setState({ phone_number_error: '', });
		}

  }

  async onSubmitForm() {
    // this.onVerifyOTP();

    this.setState({ loading: true });
    let ExpireIn30Minutes = 1 / 48;
    Cookies.set("username", this.state.phone_number, {
      expires: ExpireIn30Minutes,
    });
    try {
      const response = await axiosInstance.post("/otp/create/", {
        phone_number: this.state.phone_number,
      });

    

      if (response.data.otp_code === "success") {
       
        this.onVerifyOTP();

        this.handleClose();
        this.setState({ loading: false });
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  handleShow() {

    this.setState({ show: true, loading: false,phone_number:"" });
  }
  handleClose() {
    this.setState({ show: false });
    // this.onVerifyOTP();
  }

  Close() {
    this.setState({ show: false });
  }

  // this code is for opening verify otp

  verifyotpModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onVerifyOTP = () => {
    this.showModal();
  };

  render() {
    const { items, loading } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Verifyotp ref={this.verifyotpModalRef}></Verifyotp>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Body className={classes.paper}>
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "transparent",
                padding: "0rem",
              }}
            />
            <Grid>
              <Grid>
                <Grid item xs={1} sm={1} md={4} lg={8} xl={8} />

                <Grid item xs={10} sm={10} md={8} lg={10} xl={10}>
                  <div
                    style={{
                      marginLeft: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center ",
                      }}
                    >
                      Open New Account
                    </h4>

                    <InputBase
                    autocomplete="off"
                      placeholder="Enter Mobile Number"
                      name="phone_number"
                      type="number"
                      value={this.state.phone_number}
                      onChange={this.onInputchange}
                      className={classes.InputUsernameMobile}
                    />
                    <br/>
                    <span
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      {this.state.phone_number_error}
                    </span>
                    <br />
                    <br />
                    {!loading && (
                      <Button
                        onClick={this.onSubmitForm}
                        disabled={loading}
                        className={classes.LoginButtonMobile}
                      >
                        Next
                      </Button>
                    )}

                    {loading && (
                      <div
                        style={{
                          marginLeft: "20%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                          style={{ color: "#29BD00" }}
                        />
                        <span style={{ color: "#29BD00" }}>Loading...</span>
                      </div>
                    )}
                    <br />

                    <h5
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      {this.state.error}
                    </h5>
                    <br />

                    <br />
                     {/* <div className={classes.dontHaveAccount}>
                       Already Have an Account? Click{" "}
                       <a className={classes.clickHere} href="www">
                         Here
                       </a>{" "}
                       to Login
                     </div> */}
                  </div>
                </Grid>
                <Grid item xs={1} sm={2} md={4} lg={4} xl={4} />
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Sendotp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Sendotp);
