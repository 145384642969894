import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
export default class Nextofkin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      first_name2: "",
      last_name2: "",
      phone_number2: "",
      relation: "",
     
      // },
      // formErrors: {
      //   first_name2: null,
      //   last_name2: null,
      //   phone_number2: null,
      //   relation: null,
      // },
    };
    this.selfticketShow = this.selfticketShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // this.state = { merchant_code: "", amount: "", business_name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  selfticketShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({
      loading: false,
      first_name2: "",
      last_name2: "",
      phone_number2: "",
      relation: "",
    });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit() {
    this.setState({ loading: true });

    this.setcookie();
  }
  async setcookie() {
    let ExpireIn30Minutes = 1 / 48;
    Cookies.set("nextofkin", this.state, {
      expires: ExpireIn30Minutes,
    });
    window.location.href = "/ConfirmBuyForSelf";
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <br />
        <br />
        <br />
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Enter next of kin details
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}

            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            <form autoComplete='off'>

              <InputBase
              autocomplete="off"
                placeholder="Enter First Name"
                name="first_name2"
                className="form-control"
                type="text"
                value={this.state.first_name2}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              {/* {formErrors.merchant_code && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.merchant_code}
                </span>
              )} */}

              <br />
              <br />

              <InputBase
              autocomplete="off"
                placeholder="Enter Last Name"
                name="last_name2"
                className="form-control"
                type="text"
                value={this.state.last_name2}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              {/* {formErrors.amount && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.amount}
                </span>
              )} */}
              <br />
              <br />
              <InputBase
              autocomplete="off"
                placeholder="Enter Phone Number"
                name="phone_number2"
                className="form-control"
                type="text"
                value={this.state.phone_number2}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              <br />

              <InputBase
              autocomplete="off"
                placeholder="Enter  Relation"
                name="relation"
                className="form-control"
                type="text"
                value={this.state.relation}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              </form>
              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
