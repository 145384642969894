import React, { Component } from "react";
import axiosInstance from "../axiosinstance/axiosApi";

import Agentprofile from "./agentprofile";
import Pin from "./pin";
import KYC from "./kyc";
import "./profile.css";
import Box from '@material-ui/core/Box';
import Businessnotregisteredprofile from "./businessnotregisteredprofile";
import Registeredbusinessprofile from "./registeredbusinessprofile.js";
import Myticket from "./myticket";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Container,
  Modal,
  Card,
  ListGroup,
  Tab,
  Tabs,
  Nav,
} from "react-bootstrap";
import Limitedcompanyprofile from "./limitedcompanyprofile";
import Churchprofile from "./churchprofile";
import Spinner from "react-bootstrap/Spinner";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      last_name: "",
      first_name: "",
      nrc_number: "",
      balance: "",
      phone_number: "",
      password: "",
      isLoading: true,
      logged_in: localStorage.getItem("access_token") ? true : false,
    };
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    const refreshToken = localStorage.getItem("refresh_token");
    this.getUser();
    // const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
  }

  async getUser() {
    try {
      let response = await axiosInstance.get("/user/details/");
      this.setState({
        last_name: response.data.last_name,
        first_name: response.data.first_name,
        nrc_number: response.data.nrc_number,
        phone_number: response.data.phone_number,
        date_of_birth: response.data.date_of_birth,
        balance: response.data.balance,

        isLoading: false,
      });
      console.log("profile details", response);
      return response;
    } catch (error) {
      // console.log("Error error: ", JSON.stringify(error, null, 4));
      // throw error; todo
    }
  }

  pinModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onPinClick = () => {
    this.showModal();
  };



  kycModalRef = ({ handlekyc }) => {
    this.showkycModal = handlekyc;
  };

  onKYClick = () => {
    this.showkycModal();
  };





  limitedclick = () => {
    window.location.href = "/limitedcompanyprofile";
  };

  churchclick = () => {
    window.location.href = "/church";
  };

  registeredbusinessprofile = () => {
    window.location.href = "/registeredbusinessprofile";
  };

  notregisteredclick = () => {
    window.location.href = "/businessnotregisteredprofile";
  };

  agentclick = () => {
    window.location.href = "/agentprofile";
  };
  render() {
    const { isLoading } = this.state;
    const error = "/images/error.jpg";
    // if (this.state.logged_in) {
      return (
        <div>
          <Pin ref={this.pinModalRef}></Pin>
          <KYC ref={this.kycModalRef}></KYC>
          <br/>
          <br/>
          <br/>
          <div
     
        >
              <Box
              style={{ width: "349px" }}
        boxShadow={3}
        bgcolor="background.paper"
        m={1}
        p={1}
        
      >
      <h5 style={{ color: "black", fontWeight: "bolder" }}>
                    Personal Details
                  </h5>
                  <br/>
                  <span style={{fontSize:"14px"}}>First Name:<span style={{fontWeight:"bold",fontSize:"17px"}}> {this.state.first_name}</span></span>
                  <br />
                  <span style={{fontSize:"14px"}}>Last Name: <span style={{fontWeight:"bold",fontSize:"17px"}}>{this.state.last_name}</span></span>
                  <br />

                  <span style={{fontSize:"14px"}}>Phone Number: <span style={{fontWeight:"bold",fontSize:"17px"}}>{this.state.phone_number}</span></span>
                  <br/>
                  <span style={{fontSize:"14px"}}>My Balance: <span style={{fontWeight:"bold",fontSize:"17px"}}>{this.state.balance}</span></span>

                  {/* <h4>My Balance:K{this.state.balance}</h4> */}
                  <Box
              style={{ width: "150px",background:"#29BD00" }}
        boxShadow={3}
        bgcolor="background.paper"
        m={1}
        p={1}
        onClick={this.onPinClick}
        >
    <span style={{ color: "white" }}>Create Pin</span>
        </Box>
      
      </Box>
      <Box
              style={{ width: "150px",background:"#29BD00" }}
        boxShadow={3}
        bgcolor="background.paper"
        m={1}
        p={1}
        onClick={this.onKYClick}
        >
    <span style={{ color: "white" }}>Update Account Document</span>
        </Box>
      </div>
      {/* <div style={{background:"red"}}>
<h1>testing</h1>
      </div> */}

    
              {/* <Card style={{ width: "329px" }}>
                <Card.Header >
                  
                </Card.Header>
                <Card.Body>
                
                </Card.Body>
                <Card.Body>
                </Card.Body>

                <Card.Body>
                 
                </Card.Body>
                <Button
                  onClick={this.onPinClick}
                  style={{ background: "#29BD00" }}
                >
              
                </Button>

            
              </Card> */}
{/* 
              <Agentprofile />

     
          <Churchprofile/>
<span>business not registered</span>

          <Businessnotregisteredprofile />
<span>business registered</span>

          <Registeredbusinessprofile />

<span>limited</span>
          
          <Limitedcompanyprofile/> */}
  
          {/* <h1>My tickets</h1>
          <Myticket />  */}
              {/*   <br />
          <br />
          {/* <Link to={"/limitedcompanyprofile/"} style={{ color: "#fff" }}>
            <Button>View Limited Company Profile</Button>
          </Link> */}
            </div>
     
      );
    }
    // return (
    //   <div>
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <h1 style={{ textAlign: "center" }}>Login to View Your Profile </h1>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <img
    //         alt="not logged in"
    //         src={error}
    //         style={{
    //           width: "335px",
    //           height: "335px",
    //         }}
    //       />
    //     </div>
    //   </div>
    // );
  // }
}

export default Profile;
