import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";

class Pinsuccess extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSuccessShow = this.handleSuccessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleSuccessShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
        >
          <Modal.Header
            style={{
              backgroundColor: "#32c709",
              color: "white",
              textAlign: "center",
            }}
            closeButton
          >
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h1>Proccess Successfully Complete</h1>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Pinsuccess;
