import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axiosInstance from '../../axiosinstance/axiosApi';

// import Confirmairtel from "./confirm";
import Spinner from 'react-bootstrap/Spinner';

import { Grid, InputBase } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import moment from 'moment';
import { message } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
	paper: {
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],

		background: [
			// ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
			[
				// "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
				'no-repeat',
				'top 100% left 100%',
			],
			//['white']
		],
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#29BD00 !important',
	},
	cssLabel: {
		color: 'black !important',
	},

	//define style for country code on mobile screens
});

export class Airteldetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phone_number: '',
			balance: '',
			show: false,
			loading: false,
			business_name: '',
			bank_name: '',
			merchant_code: '',
			amount: '',
			deposit_slip: null,

			refrerence_number: '',
			deposit_date: '',
			initialamount: '',
		};
		this.handleChange = this.handleChange.bind(this);

		// this.onInputchange = this.onInputchange.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);

		this.handleairtelmoneyShow = this.handleairtelmoneyShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleairtelmoneyShow() {
		// console.log(this.state);
		this.setState({ show: true });
		this.setState({ loading: false });
	}

	handleClose() {
		this.setState({ show: false });
	}
	gotoDetails() {
		this.setState({ show: false });
		this.setState({ loading: true });
		this.onLoginClick();
	}
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	deposit_slip = null;

	handleDepositcUpload = (event) => {
		event.preventDefault();
		this.deposit_slip = event.target.files[0];
	};

	async onSubmitForm() {
		// this.handleClose();
		let data = new FormData();

		let initialamount = this.state.initialamount;
		const amount = Number(initialamount);

		// this.setState({ loading: true });

		data.append('deposit_slip', this.deposit_slip);
		data.append('bank_name', this.state.bank_name);
		data.append('merchant_code', this.state.merchant_code);
		data.append(
			'deposit_date',
			moment(this.state.deposit_date).format('YYYY-MM-DD')
		);
		data.append('amount', amount);
		data.append('business_name', this.state.business_name);
		data.append('refrerence_number', this.refrerence_number);

		try {
			const response = await axiosInstance.post(
				'/agents/upload-float',
				data
			);

			console.log('check for server errors', response);

			if (response.data.amount) {
				message.success({
					content: 'Float Successfuly Added',
					style: {
						marginTop: '20vh',
					},
				});
				this.setState({ isOpen: false });
			}
		} catch (error) {
			console.log('check for errors', error.response);
		}
	}

	handlekyc() {
		// console.log(this.state);
		this.setState({ show: true });
	}

	// code for confirm modal
	confirmModalRef = ({ handleConfirmShow }) => {
		this.showConfirmModal = handleConfirmShow;
	};

	onLoginClick = () => {
		this.showConfirmModal();
	};

	render() {
		const { classes } = this.props;
		const { items, loading } = this.state;

		return (
			<div>
				{/* <Confirmairtel ref={this.confirmModalRef}></Confirmairtel> */}
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							LoadFunds
						</h4>
					</div>
					<Modal.Body>
						{/* <Fragment> */}

						<div style={{ fontSize: '9', textAlign: 'center' }}>
							To Load money to your Ekamo Wallet from airtel mobile money,follow the following steps:
						</div>

					
						<br />
						<div
							style={{
								// display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<ul>
								<li>Dial *778#</li>
								<li>Select 4 - Make Payments</li>
								<li>Select 5 - Goods and Services</li>
								<li>Enter Merchant ID = Ekamo</li>
								<li>Enter Amount (NO COMMAS) eg 100</li>
								<li>Enter Reference : ekamo</li>
								<li>Enter Pin</li>
								<li>
									You Will Receive an SMS to confirm
									transaction{' '}
								</li>
							</ul>
						</div>

						<br />
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

Airteldetails.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Airteldetails);
