import React, { Component, Fragment } from 'react';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Verifyotp from './verifyotp';
import Card from '@material-ui/core/Card';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import { SpinnerRoundFilled } from 'spinners-react';
import { Navbar, NavItem, Nav } from 'react-bootstrap';
import axiosInstance from '../../axiosinstance/axiosApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Accountsuccess from './success';
class Confirmdetails extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			error: '',
			password_error: '',
			gender_error: '',
			first_name_error: '',
			last_name_error: '',
			email_error: '',
			date_of_birth_error: '',
			error_message: '',
		};
		// this.handleShow = this.handleShow.bind(this);
		// this.handleClose = this.handleClose.bind(this);
		// this.Close = this.Close.bind(this);

		this.onSubmitForm = this.onSubmitForm.bind(this);
		this.handledetailsShow = this.handledetailsShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.Close = this.Close.bind(this);
	}

	getdetails() {
		const personaldetails = JSON.parse(Cookies.get('personaldetails'));
		const username = Cookies.get('username');
		const secondpersonaldetails = JSON.parse(
			Cookies.get('secondpersonaldetails')
		);
		this.setState({
			first_name: personaldetails.first_name,
			last_name: personaldetails.last_name,
			nrc_number: personaldetails.nrc_number,
			date_of_birth: personaldetails.date_of_birth,
			email: secondpersonaldetails.email,
			gender: secondpersonaldetails.gender,
			// gender: username.gender,
		});
		console.log('check personsal details', username);
	}

	async onSubmitForm() {
		// this.handleClose();
		this.setState({ loading: true });

		const personaldetails = JSON.parse(Cookies.get('personaldetails'));
		const username = Cookies.get('username');
		const secondpersonaldetails = JSON.parse(
			Cookies.get('secondpersonaldetails')
		);

		const data = { ...personaldetails, ...secondpersonaldetails, username };
		console.log('this is heading to the server', data);

		const { form, formErrors } = this.state;
		// this.gotosuccess();
		try {
			const response = await axiosInstance.post('/user/create/', data);

			if (response.data.error) {
				this.setState({ error_message: response.data.error });

				toast.error(this.state.error_message, {
					position: 'top-center',
					autoClose: 50000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}

			if (!response.data.success) {
				if (response.data.error.password) {
					let final_error = `Password: ${response.data.error.password[0]}`;
					this.setState({ password_error: final_error });

					return toast.error(this.state.password_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				if (response.data.error.gender) {
					let final_error = `Gender: ${response.data.error.gender[0]}`;
					this.setState({ gender_error: final_error });

					return toast.error(this.state.gender_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				if (response.data.error.first_name) {
					let final_error = `First Name: ${response.data.error.first_name[0]}`;
					this.setState({ first_name_error: final_error });

					return toast.error(this.state.first_name_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				if (response.data.error.first_name) {
					let final_error = `Last Name: ${response.data.error.last_name[0]}`;
					this.setState({ last_name_error: final_error });

					toast.error(this.state.last_name_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				if (response.data.error.first_name) {
					let final_error = `Email: ${response.data.error.email[0]}`;
					this.setState({ email_error: final_error });

					toast.error(this.state.email_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				if (response.data.error.date_of_birth) {
					let final_error = `Date Of Birth: ${response.data.error.date_of_birth[0]}`;
					this.setState({ date_of_birth_error: final_error });

					toast.error(this.state.date_of_birth_error, {
						position: 'top-center',
						autoClose: 50000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			}

			if (response.data.success) {
				this.gotosuccess();
				return this.setState({ loading: false });
			}
			this.setState({ loading: true });
			setTimeout(() => {
				this.setState({ loading: false });
			}, 2000);

			// console.log("checking error response", response.data.error);
			return response;
		} catch (error) {
			throw error;
		}
	}

	successModalRef = ({ handlesuccessShow }) => {
		this.showModal = handlesuccessShow;
	};

	gotosuccess() {
		this.setState({ show: false });
		this.onSuccess();
	}

	onSuccess = () => {
		this.showModal();
	};

	handledetailsShow() {
		// console.log(this.state);
		this.setState({ show: true });
		this.getdetails();
	}
	handleClose() {
		this.setState({ show: false });
	}
	Close() {
		this.setState({ show: false });
	}

	render() {
		const { items, loading } = this.state;

		return (
			<div>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Accountsuccess ref={this.successModalRef}></Accountsuccess>
				<Modal
					show={this.state.show}
					centered
					// backdrop="static"
					onHide={this.Close}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{!loading && (
							<div>
								<h4 style={{ color: '#6d6969' }}>
									First Name: {this.state.first_name}
								</h4>
								<h4 style={{ color: '#6d6969' }}>
									Last Name: {this.state.last_name}
								</h4>
								<h4 style={{ color: '#6d6969' }}>
									NRC #: {this.state.nrc_number}
								</h4>
								<h4 style={{ color: '#6d6969' }}>
									D.O.B : {this.state.date_of_birth}
								</h4>
								<h4 style={{ color: '#6d6969' }}>
									Gender : {this.state.gender}
								</h4>
								<h4 style={{ color: '#6d6969' }}>
									Email : {this.state.email}
								</h4>

								{/* <h5 style={{ color: "red", textAlign: "center" }}>
                  {this.state.error}
                </h5> */}

								<br />

								<Button
									onClick={this.onSubmitForm}
									disabled={loading}
									style={{
										color: 'white',
										width: '100%',
										border: '1px solid #29BD00',
										height: '40px',
										margin: '0px 1px 0px 0px',
										padding: '5px 3px',
										variant: 'contained',
										fontSize: '15px',
										// boxshadow: "0px 3px 3px #E2E2E2",

										borderRadius: '100px 100px 100px 100px',
										display: 'block',
										marginRight: 'auto',
										marginLeft: 'auto',
										background: '#29BD00',
										width: '70%',
									}}>
									Proceed
								</Button>
							</div>
						)}

						{loading && (
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<span
										style={{
											textAlign: 'center',
											fontWeight: 'bolder',
											color: '#000',
										}}>
										Creating Account, Please Wait...
									</span>
								</div>
								<br />

								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<SpinnerRoundFilled
										size={90}
										thickness={200}
										speed={67}
										color='#29BD00'
									/>
								</div>
							</div>
						)}

						<div
							style={{
								background: [
									// add background image
									[
										'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
										'no-repeat',
										'top 10% left 15%',
									],
								],

								padding: 20,
							}}></div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Confirmdetails;
