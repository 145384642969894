// import React, { Component } from "react";
import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../../axiosinstance/axiosApi";
import { Grid, InputBase } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TransferPin from "./TransferPin.js";
import Spinner from "react-bootstrap/Spinner";

class TransferBusinessList extends React.Component {
  constructor(props, context) {
    super(props, context);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.verifypin = this.verifypin.bind(this);

    this.selectBusiness = this.selectBusiness.bind(this);
    this.handlePinBusinessShow = this.handlePinBusinessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.state = {
      businesses: [],
      resultserror: "",
      show: false,
      loading: true,
    };
  }

  async getMerchant() {
    const transfertobusiness = JSON.parse(Cookies.get("transfertobusiness"));
    let response = transfertobusiness;
    const phone_number = response.phone_number;

    console.log("get business using this phone number", phone_number);

    try {
      const response = await axiosInstance.get(
        `/business/details/${phone_number}`
      );

      if (response.data.results == 0) {
        this.setState({
          resultserror: "No Business found matching that number",
          loading: false,
        });
      } else {
        this.setState({
          businesses: response.data.results,
          loading: false,
        });
      }
      if (response.data.success === "ok") {
        this.setState({ loading: false });
      }

      console.log("business list now", response.data.results);
    } catch (error) {
      console.log("catch the error",error.response);
    }
  }

  async selectBusiness(value) {
    this.setState({ loading: true });
    console.log("check the value", value);
    Cookies.set("transferbusiness", value);
    // event.preventDefault();
    // const pay = JSON.parse(Cookies.get("pay"));
    // this.setState({ merchant_code: pay.merchant_code });
    this.gottopin();
  }

  handlePinBusinessShow() {
    // console.log("show this", this.state);
    this.setState({ show: true, resultserror: "", businesses: [] });

    this.setState({ loading: true });
    this.getMerchant();
  }

  gottopin() {
    this.setState({ show: false });
    this.showSuccessModal();
  }

  handleClose() {
    this.setState({ show: false });
  }

  // gotoDetails() {
  //   this.setState({ show: false });
  //   this.onLoginClick();
  // }

  // code for success
  successModalRef = ({ handlePinShow }) => {
    this.showModal = handlePinShow;
  };

  showSuccessModal = () => {
    this.showModal();
  };

  // onLoginClick = () => {
  //   this.showModal();
  // };

  render() {
    const { form, formErrors, loading } = this.state;

    return (
      <div>
        <TransferPin ref={this.successModalRef}></TransferPin>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Select Business
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                marginTop: "-14px",
              }}
            >
              Select the business you want to pay
            </div>

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#29BD00" }}
                />
                <span style={{ color: "#29BD00" }}>Fetching Data</span>
              </div>
            )}

            {!loading && (
              <div>
                <br />

                {this.state.businesses.map((business) => (
                  <div>
                    <Paper
                      elevation={3}
                      //   key={i}
                      ref="itemValue"
                      value={business}
                      onClick={this.selectBusiness.bind(this, business)}
                      // onClick={this.gotomtn}
                      style={{
                        borderRadius: "12px",
                        cursor: "pointer",

                        display: "flex",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ fontWeight: "bolder", marginLeft: "25px" }}
                        >
                          {business.business_name}
                        </span>
                      </div>
                    </Paper>
                  </div>
                ))}

                {this.state.resultserror && (
                  <>
                    <span
                      style={{
                        fontSize: "18px",
                        color: "red",
                        textAlign: "center",
                        // marginLeft: "12%",
                      }}
                    >
                      {this.state.resultserror}
                    </span>
                    <br />
                  </>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default TransferBusinessList;
