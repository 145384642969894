import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../axiosinstance/axiosApi";

// import Confirmdstv from "./confirmdstv";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Confirmtopster from "./confirmtopster";

export class Topster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icu_number: "",
      data_amount: "",
      show: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handledtopsterShow = this.handledtopsterShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handledtopsterShow() {
    this.setState({ show: true });
    this.setState({ loading: false });
    this.setState({ icu_number: "" });
    this.setState({ data_amount: "" });
    this.setState({ loading: false });

  }

  handleClose() {
    this.setState({ show: false });
  }
  gotoDetails() {
    this.setState({ show: false });
    this.onLoginClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    this.setState({ loading: true });
 
    // Cookies.set(`dstvdetails`, this.state);
    // console.log("form data", this.state);

    // this.gotoDetails();

    const data = {
      icu_number: this.state.icu_number,
    };
    console.log("pass icu_number as first step", data);
    try {
      const response = await axiosInstance.post("/topstar/icu/number/", data);
      console.log("this is the icu response", response);

      this.setState({
        data_reference_number: response.data.data_reference_number,
      });
      // this.setState(response.data);

      // Cookies.set(`zescocookie`, {
      //   confirmation_number: response.data.confirmation_number,
      //   customer_address: response.data.customer_address,
      //   customer_name: response.data.customer_name,
      //   amount: this.state.amount,
      //   meter_number: this.state.meter_number,
      // });

      if (response.data.success === "ok") {
        this.getcustomerdetails();
      }

      return response;
    } catch (error) {
      throw error;
    }
    // this.getcustomerdetails();
  }

  async getcustomerdetails() {
    const data = {
      data_amount: this.state.data_amount,
      data_reference_number: this.state.data_reference_number,
    };
    console.log("send data reference number to server", data);

    // try {
    //   const response = await axiosInstance.post(
    //     "/topstar/customer/details/",
    //     data
    //   );
    //   console.log("customer details server response", response);

  

    //   Cookies.set(`topstercookie`, {
    //     confirmation_number: response.data.confirmation_number,
    //     customer_surname: response.data.customer_surname,
    //     customer_name: response.data.customer_name,
    //     data_amount: this.state.data_amount,
    //     icu_number: this.state.icu_number,
    //   });

    //   if (response.data.success === "ok") {
    //     // this.onLoginClick();
    //     console.log("go to success");
    //   }

    //   return response;
    // } catch (error) {
    //   throw error;
    // }
  }

  // code for confirm modal
  confirmModalRef = ({ handleConfirmShow }) => {
    this.showModal = handleConfirmShow;
  };

  gottodetails = () => {
    this.showModal();
  };
  onLoginClick() {
    this.setState({ show: false });
    this.gottodetails();
  }
  render() {
    const { values, handleChange } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <Confirmtopster ref={this.confirmModalRef}></Confirmtopster>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Bill Payment
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}
            <div style={{ fontSize: "9", textAlign: "center" }}>
              Enter Topster Smart card number
            </div>
            <br />
            <br />
            <InputBase
              placeholder="Enter Smartcard Number"
              name="icu_number"
              className="form-control"
              type="number"
              value={this.state.icu_number}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            <br />
            <br />
            <InputBase
              placeholder="Enter Amount"
              name="data_amount"
              className="form-control"
              type="number"
              value={this.state.data_amount}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            <br />
            <br />
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Topster;
