import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../axiosinstance/axiosApi";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Confirmzamtel from "./confirmzamtel";

export class Zamtel extends Component {
  constructor(props) {
    super(props);
    this.state = { phone_number: "", balance: "", show: false, loading: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlezamtelShow = this.handlezamtelShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlezamtelShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({ loading: false });
    this.setState({ phone_number: "" });
    this.setState({ balance: "" });
  }

  handleClose() {
    this.setState({ show: false });
  }
  gotoDetails() {
    this.setState({ show: false });
    this.onLoginClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    Cookies.set(`mtndetails`, this.state);
    // console.log("form data", this.state);
    this.setState({ loading: true });

    this.gotoDetails();
  }

  // code for confirm modal
  confirmModalRef = ({ handleConfirmzamtelShow }) => {
    this.showModal = handleConfirmzamtelShow;
  };

  gottodetails = () => {
    this.showModal();
  };
  onLoginClick() {
    this.setState({ show: false });
    this.gottodetails();
  }
  render() {
    const { values, handleChange } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <Confirmzamtel ref={this.confirmModalRef}></Confirmzamtel>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Bill Payment
            </h4>
          </div>
          <Modal.Body>
            <div style={{ fontSize: "9", textAlign: "center" }}>
              Enter Zamtel Phone Number
            </div>

            <br />
            <br />
            <form autoComplete='off'>

            <InputBase
              placeholder="Enter Phone Number"
              name="phone_number"
              className="form-control"
              type="number"
              value={this.state.phone_number}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            <br />
            <br />
            <select
            autocomplete='false'
              name="balance"
              onBlur={this.handleChange}
              onChange={this.handleChange || null}
              value={this.state.balance}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
                background: "white",
              }}
            >
              <option value="">Select Amount</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            </form>
            <br />
            <br />
            <br />
            <br />
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
              <br />
            </div>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Zamtel;
