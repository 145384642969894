import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Moment from "moment";
import axiosInstance from "../axiosinstance/axiosApi";

// import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import {
  Popover,
  Tooltip,
  Modal,
  Row,
  Col,
  Container,
  Button,
  Collapse,
} from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, InputBase } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Cookies from "js-cookie";
// import Success from "../loadfunds/success";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles((theme) => ({
  //SignUp Modal Paper Style On Mobile Devices
  CashoutAmountModalMobile: {
    position: "relative",
    width: "",
    borderRadius: "10px 10px 10px 10px ",
    marginTop: "10%",
    // backgroundColor: theme.palette.background.paper,
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 4),
    textAlign: "center",
  },

  //SignUp Modal Paper Style On Desktop Devices
  CashoutAmountModalWeb: {
    position: "relative",
    width: "",
    borderRadius: "10px 10px 10px 10px ",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 12, 8),
    textAlign: "center",
    marginTop: "5%",
    background: [
      //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
      [
        "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
        "no-repeat",
        "top 230% left 100%",
      ],
      //['white']
    ],
  },

  TicketTitleCard: {
    margin: 0,
    minHeight: 40,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: "15px 15px 0px 0px",
    background: [["#29BD00"]],

    color: "white",
  },

  TicketFooterCard: {
    margin: 0,
    minHeight: 20,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: "0px 0px 15px 15px",
    background: [["#29BD00"]],

    color: "white",
  },

  smallBus: {
    width: "18%",

    //On Mobile
    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
  },

  TicketTitleText: {
    fontWeight: "bolder",
    paddingLeft: 25,
    paddingTop: 14,
    fontSize: 15,
    // opacity: 0.8,
    textAlign: "left",
  },

  TicketFooterText: {
    paddingLeft: 25,
    paddingTop: 2,
    fontSize: 9,
    opacity: 0.8,
    textAlign: "left",
  },

  TicketTitleIcon: {
    paddingRight: 15,
    paddingTop: 5,
    fontSize: 11,
    //opacity: 0.8,
    textAlign: "right",
  },

  cardIconContainer: {
    paddingTop: 25,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },

  TicketDetailsCard: {
    minHeight: 0,
    border: "1px solid #E8E8E8",
    textAlign: "left",
    borderRadius: 0,
    marginLeft: 20,
    marginRight: 20,
    boxShadow: "none",
  },

  //define style for username on mobile screens
  InputStyle: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 2px 0px 0px ",
    fontSize: 14,
    width: "80%",
  },

  //define style for header buttons on mobile screens
  CashoutButton: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29BD00",
    margin: "0px 1px 0px 0px ",
    fontSize: 15,
    height: "40px",
    fontWeight: "bolder",
    textTransform: "none",
    border: "1px solid #29BD00",
    borderRadius: "100px 100px 100px 100px",
    padding: "5px 3px ",
    width: "100%",
    boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29BD00",
    },
  },

  busfaresection: {
    paddingLeft: 10,
  },

  datesection: {
    paddingLeft: 10,
  },

  TicketContentTitle: {
    fontSize: 11,
    fontWeight: "bolder",
  },

  TicketContentTitleInner: {
    fontSize: 11,
    fontWeight: "bolder",
    paddingTop: 10,
  },

  TicketContentFare: {
    fontSize: 15,
    fontWeight: "bolder",
  },

  TicketContentNo: {
    fontSize: 25,
    fontWeight: "bolder",
  },

  TicketQrcode: {
    paddingTop: 20,
    width: "100%",
    height: "100%",
  },

  TicketContent: {
    fontSize: 13,
  },

  infoText: {
    fontSize: 12,
  },

  momoText: {
    fontSize: 12,
    fontWeight: "bolder",
    padding: 10,
  },
}));

//===========================
//Set  Modal Paper Top Margin
//===========================
function getModalStyleMobile() {
  return {
    margin: "55px 0px 0px 0px ",
  };
}

function getModalStyleWeb() {
  return {
    margin: "70px 0px 0px 0px ",
  };
}

const Details = (props) => {
  const initialResponseState = {
    wallet_msisdn: "",
    supplier_transaction_id: "",
  };
  const [resopnse, setResponse] = useState(initialResponseState);
  // const isMobile = useMediaQuery('(max-width:699px)'); //CHECK IF SCREEN SIZE IS 648PX OR BELOW
  const classes = useStyles();

  //Fetch Icons

  //=================================
  //Start Modal Style For Mobile/Web
  //=================================
  const isMobile = useMediaQuery("(max-width:648px)"); //CHECK IF SCREEN SIZE IS 648PX OR BELOW
  const NotMobile = useMediaQuery("(min-width:700px)"); //CHECK IF SCREEN SIZE IS 649PX OR ABOVE

  let modelPaperStyle;

  //Check screen size and change Model Paper Style
  if (isMobile) {
    modelPaperStyle = classes.CashoutAmountModalMobile;
  }

  if (NotMobile) {
    modelPaperStyle = classes.CashoutAmountModalWeb;
  }

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyleMobile] = React.useState(getModalStyleMobile);
  const [modalStyleWeb] = React.useState(getModalStyleWeb);
  let modalStyle;

  const [show, setShow] = useState(false);
  const [shownetwork, setShowNetwork] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [airtelLoading, setairtelLoading] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowNetwork = () => setShowNetwork(true);
  const [error, setError] = useState(false);
  const [pin, setPin] = useState();
  const [airtel_number, setairtel_number] = useState();
  const [mtn_number, setmtn_number] = useState();

  // success modal logic begins here
  const [showsuccess, setShowsuccess] = useState(false);

  // const handlesuccessClose = () => setShowsuccess(false);
  const handlesuccessShow = () => setShowsuccess(true);

  // success modal logic ends here

  // pay via ekamo logic begins here
  const [showekamopay, setekamopayShow] = useState(false);

  const handleClose = () => setekamopayShow(false);
  const handleekamoShow = () => setekamopayShow(true);
  // pay via ekamo logic ends here

  // pay via airtel logic begins here
  const [showairtelpay, setairtelpayShow] = useState(false);

  const handleairtelClose = () => setairtelpayShow(false);
  const handleeairtelShow = () => setairtelpayShow(true);
  const getAirtelNumber = async (event) => {
    setairtelpayShow(false);

    console.log("check the airtel number", airtel_number);
  };
  // pay via airtel logic ends here

  // pay via mtn logic begins here
  const [showmtnpay, setmtnpayShow] = useState(false);

  const handlemtnClose = () => setmtnpayShow(false);
  const handleemtnShow = () => setmtnpayShow(true);




  const handlesuccessClose = async (event) => {
    window.location.href = "/MyTickets";



  }


  const getMtnNumber = async (event) => {
    // setmtnpayShow(false);

    setLoading(true);
    setError(false);

    const bus = JSON.parse(
      Cookies.get("bus", {
        expires: ExpireIn30Minutes,
      })
    );

    const date = JSON.parse(Cookies.get("date"));
    const nextofkin = JSON.parse(Cookies.get("nextofkin"));
    // 0966468776
    const data = { ...bus, ...date, ...nextofkin, mtn_number };
    console.log("send mtn ticket to server", data);
    try {
      const response = await axiosInstance.post(
        "/bus/ticket/mtn/payment/",
        data
      );
      console.log("mtn ticket server response", response.data);
      if(response.data.error){
        setLoading(false);
        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        }
    
      setLoading(false);
      if (response.data.success === "ok") {
        console.log("ticket bought");
        handlesuccessShow(true);
        // handleShow();
        setLoading(false);
        // clearTimeout(timeout1);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      // clearTimeout(timeout1);
    }
  };
  // pay via mtn logic ends here

  const mtn = "/images/mtn_momo.svg";
  const ekamo = "/images/logo.svg";
  const zamtel = "/images/zamtel_momo.svg";
  const airtel = "/images/airtel_momo.svg";
  const busicon = "/images/bus_icon_white.svg";
  const qrcode = "/images/qrcode.png";
  const phone = "/images/phone.jpg";

  const postPin = async (event) => {
    setPin("");
    setLoading(true);

    event.preventDefault();

    console.log("verify pin", pin);
    try {
      const response = await axiosInstance
        .get(`pin/verification/${pin}`)

        .catch((err) => {
          setLoading(false);
        });



      console.log("check errors", response.data);
      if (response.data.pin === "success") {
        setLoading(false);
        // setekamopayShow(false);
        postTicket();
        console.log("Trigger Ticket");
      }

      if(response.data.error){
        setLoading(false);
        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 400000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
   
      setLoading(false);
    }
  };

  const ExpireIn30Minutes = 1 / 48;
  // bus/ticket/mtn/payment/
  const postTicket = async (event, state) => {
    setLoading(true);
    setError(false);

    const bus = JSON.parse(
      Cookies.get("bus", {
        expires: ExpireIn30Minutes,
      })
    );

    const date = JSON.parse(Cookies.get("date"));
    const nextofkin = JSON.parse(Cookies.get("nextofkin"));

    const data = { ...bus, ...date, ...nextofkin };
    console.log("send ticket to server", data);
    try {
      const response = await axiosInstance.post(
        "/bus/ticket/ekamo/payment/",
        data
      );
		  if(response.data.error){

        console.log("ekamo ticket server error response", response.data);
        setLoading(false);

        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });


      }
      if (response.data.success === "ok") {
        console.log("ticket bought");
      setLoading(false);

        handlesuccessShow();
        setekamopayShow(false);
        // handleShow();
        setLoading(false);
        // clearTimeout(timeout1);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      // clearTimeout(timeout1);
    }
  };

  const bus = JSON.parse(Cookies.get("bus"));
  const userdetails = JSON.parse(Cookies.get("userdetails"));
  const date = JSON.parse(Cookies.get("date"));
  // console.log(date);

  const momo = require("moment");

  return (
    <React.Fragment>
      		<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
      <div>
        {/* success modal begins here */}
        <Modal show={showsuccess} centered backdrop="static" size="sm">
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  color: "#4fe422",
                }}
              >
                Transaction Complete
              </span>
            </div>
            <br />

            <Button
              style={{
                background: "#29BD00",
                border: "1px solid #29BD00",
              }}
              onClick={handlesuccessClose}
            >
              Finish
            </Button>
          </Modal.Body>
        </Modal>
        {/* success modal ends here */}

        {/* pay using mtn begins here */}

        <Modal
          show={showmtnpay}
          onHide={handlemtnClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              E-Bus Ticket
            </h4>
          </div>
          <Modal.Body>
            <ul>
              <li style={{ fontSize: 12 }}>
                Pay for Bus Ticket worth
                <span
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "2px",
                    marginRight: "2px",
                  }}
                >
                  ZMW{bus.price}
                </span>
                Through MTN Money
              </li>
              <li style={{ fontSize: 12 }}>
                You will receive an SMS that contains your Bus Ticket No.
              </li>
              <li style={{ fontSize: 12 }}>
                You will receive a promp to enter a Pin
              </li>
              <li style={{ fontSize: 12, fontWeight: "bold" }}>
                Enter MTN Money Phone Number
              </li>
            </ul>

            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                placeholder="Enter MTN Number"
                name="mtn_number"
                className="form-control"
                type="number"
                onChange={(e) => setmtn_number(e.target.value)}
                value={mtn_number}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              <br />
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!isLoading && (
                <div>
                  <input
                    onClick={getMtnNumber}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />

            <br />
          </Modal.Body>
        </Modal>
        {/* pay using mtn ends here */}

        {/* pay using airtel begins here */}

        <Modal
          show={showairtelpay}
          onHide={handleairtelClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              E-Bus Ticket
            </h4>
          </div>
          <Modal.Body>
            <ul>
              <li style={{ fontSize: 12 }}>
                Pay for Bus Ticket worth
                <span
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "2px",
                    marginRight: "2px",
                  }}
                >
                  ZMW{bus.price}
                </span>
                Through Airtel Money
              </li>
              <li style={{ fontSize: 12 }}>
                You will receive an SMS that contains your Bus Ticket No.
              </li>
              <li style={{ fontSize: 12 }}>
                You will receive a promp to enter a Pin
              </li>
              <li style={{ fontSize: 12, fontWeight: "bold" }}>
                Enter Airtel Money Phone Number
              </li>
            </ul>

            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                placeholder="Enter Airtel Number"
                name="airtel_number"
                className="form-control"
                type="number"
                onChange={(e) => setairtel_number(e.target.value)}
                value={airtel_number}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              <br />
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!isLoading && (
                <div>
                  <input
                    onClick={getAirtelNumber}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
            {/* <h5>{this.state.error}</h5> */}
            <br />
          </Modal.Body>
        </Modal>
        {/* pay using airtel ends here */}

        {/* pay using ekamo begins here */}
        <Modal
          show={showekamopay}
          onHide={handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              E-Bus Ticket
            </h4>
          </div>
          <Modal.Body>
            <ul>
              <li style={{ fontSize: 12 }}>
                Pay for Bus Ticket worth
                <span
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "2px",
                    marginRight: "2px",
                  }}
                >
                  ZMW{bus.price}
                </span>
                Through Ekamo
              </li>
              <li style={{ fontSize: 12 }}>
                You will receive an SMS that contains your Bus Ticket No.
              </li>

              <li style={{ fontSize: 12, fontWeight: "bold" }}>
                Enter Ekamo pin to complete transaction
              </li>
            </ul>
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
              autocomplete="off"
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                onChange={(e) => setPin(e.target.value)}
                value={pin}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              <br />
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!isLoading && (
                <div>
                  <input
                    onClick={postPin}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
            {/* <h5>{this.state.error}</h5> */}
            <br />
          </Modal.Body>
        </Modal>
        {/* payusing ekamo ends here */}
        <div style={{ marginTop: "8px" }}>
          {NotMobile ? (
            <Grid container direction="column">
              <Grid item container>
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
                <Grid
                  item
                  xs={10}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  style={modalStyle}
                  className={modelPaperStyle}
                >
                  Please check ticket details  green
                  <div className={classes.TicketTitleCard}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketTitleText}
                      >
                        {bus.bus_name}
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketTitleIcon}
                      >
                        <img
                          alt="Bus Icon"
                          src={busicon}
                          className={classes.smallBus}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Card className={classes.TicketDetailsCard}>
                    <CardContent>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        spacing={1}
                      >
                        <Grid item container xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={classes.TicketContentTitle}>
                              Name Of Passenger
                            </Typography>
                            <Typography className={classes.TicketContent}>
                              <span className={classes.TicketContent}>
                                {userdetails.first_name}
                                <span
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {userdetails.last_name}
                                </span>
                              </span>
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            container
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                          >
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classes.TicketContentTitleInner}
                              >
                                From
                              </Typography>
                              <Typography className={classes.TicketContent}>
                                {bus.from_location}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classes.TicketContentTitleInner}
                              >
                                Reference ID
                              </Typography>
                              <Typography className={classes.TicketContent}>
                                PTC100236658
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className={classes.datesection}>
                                <Typography
                                  className={classes.TicketContentTitleInner}
                                >
                                  Date
                                </Typography>
                                <Typography className={classes.TicketContent}>
                                  {date.departure_date}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classes.TicketContentTitle}
                              >
                                To
                              </Typography>
                              <Typography className={classes.TicketContent}>
                                {bus.to_location}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                className={classes.TicketContentTitle}
                              >
                                Boarding Time
                              </Typography>
                              <Typography className={classes.TicketContent}>
                                {bus.boarding_time}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className={classes.datesection}>
                                <Typography
                                  className={classes.TicketContentTitle}
                                >
                                  Departure Time
                                </Typography>
                                <Typography className={classes.TicketContent}>
                                  {bus.departure_time}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.datesection}>
                              <img
                                alt="qrcode"
                                src={qrcode}
                                className={classes.TicketQrcode}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          {/* <Typography className={classes.TicketContentTitle}>
                            Ticket Line...
                          </Typography> */}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className={classes.busfaresection}>
                            <Typography className={classes.TicketContentTitle}>
                              Bus Terminus
                            </Typography>
                            <Typography className={classes.TicketContent}>
                              {bus.bus_terminal}
                            </Typography>
                          </div>
                          <div className={classes.busfaresection}>
                            <Typography
                              className={classes.TicketContentTitleInner}
                            >
                              Bus Fare
                            </Typography>
                            <Typography className={classes.TicketContentFare}>
                              ZMW{bus.price}.00
                            </Typography>
                          </div>
                          <div className={classes.busfaresection}>
                            <Typography
                              className={classes.TicketContentTitleInner}
                            >
                              Ticket
                            </Typography>
                            <Typography className={classes.TicketContentNo}>
                              No.
                            </Typography>
                          </div>
                        </Grid>
                        {/* <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <span
                            style={{
                              marginTop: "20px",
                              fontWeight: "bolder",
                              fontSize: "12px",
                            }}
                          >
                            Select Payment Method:
                          </span>
                          <br />
                        </Grid> */}
                        {/* <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <h1>show options on large screen</h1>
                        </Grid> */}
                        <br />
                      </Grid>
                      <br />
                      {error && (
                        <div style={{ color: `red`, textAlign: "center" }}>
                          Error! You Need To be Loggged In To Proceed
                        </div>
                      )}
                    </CardContent>
                  </Card>
                  <div className={classes.TicketFooterCard}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketFooterText}
                      >
                        For Enquiries Call Power Tools on 260968014011
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bolder",
                        fontSize: "12px",
                        marginTop: "12px",
                      }}
                    >
                      Select Payment Method:
                    </span>
                  </div>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          background: "#fff",

                          borderWidth: "0px",
                        }}
                        onClick={handleekamoShow}
                      >
                        <img
                          alt="ekamo logo"
                          src={ekamo}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                      </button>
{/* 
                      <button
                        style={{
                          background: "#fff",

                          borderWidth: "0px",
                        }}
                        onClick={handleeairtelShow}
                      >
                        <img
                          alt="Cashout"
                          src={airtel}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                      </button>

                      <button
                        style={{
                          background: "#fff",

                          borderWidth: "0px",
                        }}
                        onClick={handleemtnShow}
                      >
                        <img
                          alt="Cashout"
                          src={mtn}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                      </button> */}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {isMobile ? (
            <Grid container direction="column">
              <Grid item>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={modalStyle}
                  className={modelPaperStyle}
                >
                  <br />
                  <br />
                  Please check ticket details green
                  <br />
                  <div className={classes.TicketTitleCard}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketTitleText}
                      >
                        {bus.bus_name}
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketTitleIcon}
                      >
                        <img
                          alt="Bus Icon"
                          src={busicon}
                          className={classes.smallBus}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Card className={classes.TicketDetailsCard}>
                    <CardContent>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        spacing={1}
                      >
                        <Grid item container xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              style={{
                                fontSize: "8px",
                                fontWeight: "bolder",
                                paddingTop: "10px",
                              }}
                            >
                              Name Of Passenger
                            </Typography>
                            <Typography className={classes.TicketContent}>
                              <span>
                                {userdetails.first_name}
                                <span
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {userdetails.last_name}
                                </span>
                              </span>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                          >
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                style={{
                                  fontSize: "8px",
                                  fontWeight: "bolder",
                                  paddingTop: "10px",
                                }}
                              >
                                From
                              </Typography>
                              <Typography style={{ fontSize: "8px" }}>
                                {bus.from_location}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                style={{
                                  fontSize: "8px",
                                  fontWeight: "bolder",
                                  paddingTop: "10px",
                                }}
                              >
                                Reference ID
                              </Typography>
                              <Typography style={{ fontSize: "8px" }}>
                                _ _ _ _ _ _
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className={classes.datesection}>
                                <Typography
                                  style={{
                                    fontSize: "8px",
                                    fontWeight: "bolder",
                                    paddingTop: "10px",
                                  }}
                                >
                                  Date
                                </Typography>
                                <Typography style={{ fontSize: "8px" }}>
                                  {date.departure_date}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                style={{
                                  fontSize: "8px",
                                  fontWeight: "bolder",
                                  paddingTop: "10px",
                                }}
                              >
                                To
                              </Typography>
                              <Typography style={{ fontSize: "8px" }}>
                                {bus.to_location}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Typography
                                style={{
                                  fontSize: "8px",
                                  fontWeight: "bolder",
                                  paddingTop: "10px",
                                }}
                              >
                                Boarding Time
                              </Typography>
                              <Typography style={{ fontSize: "8px" }}>
                                {bus.boarding_time}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className={classes.datesection}>
                                <Typography
                                  style={{
                                    fontSize: "8px",
                                    fontWeight: "bolder",
                                    paddingTop: "10px",
                                  }}
                                >
                                  Departure Time
                                </Typography>
                                <Typography style={{ fontSize: "8px" }}>
                                  {bus.departure_time}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.datesection}>
                              <img
                                alt="qrcode"
                                src={qrcode}
                                style={{ width: "55px", height: "55px" }}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          {/* <Typography className={classes.TicketContentTitle}>
                          Ticket Line...
                        </Typography> */}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className={classes.busfaresection}>
                            <Typography
                              style={{
                                fontSize: "8px",
                                fontWeight: "bolder",
                                paddingTop: "10px",
                              }}
                            >
                              Bus Terminus
                            </Typography>
                            <Typography style={{ fontSize: "8px" }}>
                              {bus.bus_terminal}
                            </Typography>
                          </div>
                          <div className={classes.busfaresection}>
                            <Typography
                              style={{
                                fontSize: "8px",
                                fontWeight: "bolder",
                                paddingTop: "10px",
                              }}
                            >
                              Bus Fare
                            </Typography>
                            <Typography
                              style={{ fontSize: 11, fontWeight: "bolder" }}
                            >
                              K{bus.price}.00
                            </Typography>
                          </div>
                        </Grid>
                        <br />
                        <br />
                        <br />
                      </Grid>
                      <br />
                    </CardContent>
                  </Card>
                  <div className={classes.TicketFooterCard}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className={classes.TicketFooterText}
                      >
                        For Enquiries Call Power Tools on 260968014011
                      </Grid>
                    </Grid>
                  </div>
                  <br />
                </Grid>

                {error && (
                  <div style={{ color: `red`, textAlign: "center" }}>
                    Something went wrong
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: "12px",
                    }}
                  >
                    Select Payment Method:
                  </span>
                </div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        background: "#fff",

                        borderWidth: "0px",
                      }}
                      onClick={handleekamoShow}
                    >
                      <img
                        alt="ekamo logo"
                        src={ekamo}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </button>
                    {/* <button
                      style={{
                        background: "#fff",

                        borderWidth: "0px",
                      }}
                      onClick={handleeairtelShow}
                    >
                      <img
                        alt="Cashout"
                        src={airtel}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </button>

                    <button
                      style={{
                        background: "#fff",

                        borderWidth: "0px",
                      }}
                      onClick={handleemtnShow}
                    >
                      <img
                        alt="Cashout"
                        src={mtn}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </button> */}
                  </div>
                </Grid>
            
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2} />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
