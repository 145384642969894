import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
// import Atmcashout from "./Atmcashout";
// import { Grid } from "@material-ui/core";
// import Ekamocashout from "./Ekamocashout";
import { IconButton, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import MTNDetails from './mtnmoney/mtndetails';
import Zanacodetails from './zanaco/zanaco';
import Airteldetails from './airtelmoney/airteldetails';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Popover, Tooltip, Modal } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Mobilemoney extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleloadShow = this.handleloadShow.bind(this);

		this.handleClose = this.handleClose.bind(this);
		this.gotomtn = this.gotomtn.bind(this);
		this.gotozanaco = this.gotozanaco.bind(this);
		this.onairtelClick = this.onairtelClick.bind(this);

		this.state = {
			show: false,
		};
	}

	handleloadShow() {
		// console.log(this.state);
		this.setState({ show: true });
	}

	handleClose() {
		this.setState({ show: false });
	}

	//mtn mobile Money
	mtnModalRef = ({ handlemtnShow }) => {
		this.showmtnModal = handlemtnShow;
	};

	onmtnClick() {
		this.showmtnModal();
	}
	gotomtn() {
		this.setState({ show: false });
		this.onmtnClick();
	}

	// airtel mobile money
	airtelModalRef = ({ handleairtelmoneyShow }) => {
		this.showairtelModal = handleairtelmoneyShow;
	};

	onairtelClick() {
		this.showairtelModal();
		this.setState({ show: false });

	}

	gotoairtel() {
	
		// this.setState({ show: false });
		// this.onairtelClick();
	}

	// zanaco money
	// airtel mobile money
	zanacoModalRef = ({ handlezanacoShow }) => {
		this.showzanacoModal = handlezanacoShow;
	};

	onzanacoClick() {
		this.showzanacoModal();
	}

	gotozanaco() {
		// toast('Load money using Zanaco is coming soon', {
		// 	position: 'top-center',
		// 	autoClose: 40000,
		// 	hideProgressBar: true,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// });
		this.setState({ show: false });
		this.onzanacoClick();
	}

	render() {
		const airtel = '/images/airtel_momo.svg'; //get  Icon
		const mtn = '/images/mtn_momo.svg'; //get  Icon
		const kazang = '/images/kazang_momo.svg'; //get  Icon
		const konse = '/images/konse_momo.svg'; //get  Icon
		const zanaco = '/images/zanaco.png'; //get  Icon
		const tenga = '/images/tenga_momo.svg'; //get  Icon
		return (
			<div>
						<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<MTNDetails ref={this.mtnModalRef}></MTNDetails>
				<Airteldetails ref={this.airtelModalRef}></Airteldetails>
				<Zanacodetails ref={this.zanacoModalRef}></Zanacodetails>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Load Funds
						</h4>
					</div>
					<Modal.Body>
						<div
							style={{
								fontSize: '9',
								textAlign: 'center',
								paddingBottom: '15px',
							}}>
							Visit Ekamo agent to load funds into your wallet.
						</div>
						<div>
		

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
				  onClick={this.gotomtn}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      MTN Money
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onairtelClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Airtel Money 
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  // onClick={this.gotomomo}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Zanaco Xpress (Coming Soon)
                    </span>
                  </div>
                </Paper>
              </div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default Mobilemoney;
