import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Mtndeposit from "./Mtndeposit";
import Airtelmoney from "./airtelmoney";
import Depositfunds from "./Depositfunds";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid } from "@material-ui/core";
// import Ekamocashout from "./Ekamocashout";
import { Link } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import Paper from "@material-ui/core/Paper";

class Depositoptions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handledepositShow = this.handledepositShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.gotoEkamo = this.gotoEkamo.bind(this);
    this.gotomomo = this.gotomomo.bind(this);
    this.state = {
      show: false,
    };
  }

  handledepositShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // add modal for mtn deposit
  mtnModalRef = ({ handlemtnShow }) => {
    this.showmtnModal = handlemtnShow;
  };

  onmtnClick = () => {
    this.showmtnModal();
  };

  gotomomo() {
    // toast('Sending Money to Mtn MOMO is coming soon', {
		// 	position: 'top-center',
		// 	autoClose: 400000,
		// 	hideProgressBar: true,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// });
    this.onmtnClick();
    this.setState({ show: false });
  }

  // add modal for airtel deposit
  airtelModalRef = ({ handleairtelShow }) => {
    this.showairtelModal = handleairtelShow;
  };

  onairtelClick = () => {
    this.showairtelModal();
  };

  gotoairtel() {
    this.onairtelClick();
    this.setState({ show: false });
  }

  gotoairtel() {
    this.showairtelModal();
    this.setState({ show: false });
  }

  // modal for ekamo account deposit
  ekamoModalRef = ({ handleEkamoShow }) => {
    this.showekamoModal = handleEkamoShow;
  };

  onEkamoClick = () => {
    this.showekamoModal();
  };

  gotoEkamo() {
    this.setState({ show: false });
    this.onEkamoClick();
  }

  render() {
    return (
      <div>
        			<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Depositfunds ref={this.ekamoModalRef}></Depositfunds>
        <Mtndeposit ref={this.mtnModalRef}></Mtndeposit>
        <Airtelmoney ref={this.airtelModalRef}></Airtelmoney>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              E-Deposit
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Select where you want to deposit the money
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.gotoEkamo}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Ekamo Account
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.gotomomo}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      MTN Money
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  // onClick={this.gotoairtel}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Airtel Money (Coming Soon)
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  // onClick={this.gotomomo}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Zanaco Xpress (Coming Soon)
                    </span>
                  </div>
                </Paper>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Depositoptions;
