import React, { Component } from 'react';

import { Grid, InputBase } from '@material-ui/core';
import TransferBusinessList from './TransferBusinessList.js';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Popover, Tooltip, Modal } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
	paper: {
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],

		background: [
			// ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
			[
				// "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
				'no-repeat',
				'top 100% left 100%',
			],
			//['white']
		],
	},

	//define style for country code on mobile screens
});

class Deposittobusiness extends Component {
	constructor(props) {
		super(props);
		this.state = {
			business_name: '',
			loading: false,
			phone_number: '',
			phone_number_error: '',
			amount_error: '',
			amount: '',
			charge: null,
			// form: {
			//   phone_number: "",
			//   amount: "",
			// },
			// formErrors: {
			//   phone_number: null,
			//   amount: null,
			// },
		};
		// this.state = { phone_number: "", amount: "", business_name: "" };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		// this.setcookie = this.setcookie.bind(this);
		this.handleEkamoShow = this.handleEkamoShow.bind(this);
		// this.cashoutforotherShow = this.cashoutforotherShow.bind(this);

		this.handleClose = this.handleClose.bind(this); //   this closes the modal
	}

	// this code is for the modal
	handleEkamoShow() {
	
		this.setState({ show: true });
		this.setState({
			loading: false,

			phone_number: '',
			phone_number_error: '',
			amount_error: '',
			amount: '',
		});

	}

	handleClose() {
		this.setState({ show: false });
	}

	gotoDetails() {
		this.setState({ show: false });
		this.setState({ loading: true });

		this.onSuccessClick();
	}
	// the modal code ends here

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit() {
		    
	

			
			this.setState({
				loading: true,
				phone_number_error: '',
				amount_error: '',
			});
	

			
				Cookies.set(`transfertobusiness`, {
					phone_number: this.state.phone_number,
					amount: this.state.amount,
				
				});
		
			this.gotoDetails();
		
	}

	businesslistModalRef = ({ handlePinBusinessShow }) => {
		this.showModal = handlePinBusinessShow;
	};

	onSuccessClick = () => {
		this.showModal();
	};

	render() {
		const { form, formErrors, loading } = this.state;
		const { classes } = this.props;

		return (
			<div>
						<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<TransferBusinessList
					ref={this.businesslistModalRef}></TransferBusinessList>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Transfer Funds
						</h4>
					</div>
					<Modal.Body>
						{/* <Fragment> */}
						<div style={{ fontSize: '9', textAlign: 'center' }}>
							Enter Business Phone Number to transfer funds
						</div>

						<br />
						<br />
						<div
							style={{
								// display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
							}}>
									<form autoComplete='off'>
							<InputBase
							autocomplete="off"
								placeholder='Enter Merchant Phone No.'
								name='phone_number'
								className='form-control'
								type='number'
								value={this.state.phone_number}
								onChange={this.handleChange}
								onBlur={this.handleChange}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							<br />

							{this.state.phone_number_error && (
								<>
									<span
										style={{
											fontSize: 'small',
											color: 'red',
											textAlign: 'center',
											marginLeft: '12%',
										}}>
										{this.state.phone_number_error}
									</span>
									<br />
								</>
							)}

							<br />
							<InputBase
							autocomplete="off"
								placeholder='Enter Amount'
								name='amount'
								className='form-control'
								type='number'
								value={this.state.amount}
								onChange={this.handleChange}
								onBlur={this.handleChange}
								style={{
									border: '1px solid #29BD00',
									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							</form>
							<br />
							{this.state.amount_error && (
								<>
									<span
										style={{
											fontSize: 'small',
											color: 'red',
											textAlign: 'center',
											marginLeft: '12%',
										}}>
										{this.state.amount_error}
									</span>
									<br />
								</>
							)}
						
							<br />
							{loading && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<Spinner
										as='span'
										animation='grow'
										size='lg'
										role='status'
										aria-hidden='true'
										style={{ color: '#29BD00' }}
									/>
									<span style={{ color: '#29BD00' }}>
										Loading...
									</span>
								</div>
							)}

							{!loading && (
								<div>
									<input
										onClick={this.handleSubmit}
										type='submit'
										value='Submit'
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 2px 0px 55px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											textAlign: 'center',
											border: '1px solid #29BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '60%',
											boxShadow: '0px 3px 3px #E2E2E2',
											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}
									/>
								</div>
							)}
						</div>

						<br />
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
Deposittobusiness.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Deposittobusiness);
