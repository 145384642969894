import React, { Component } from "react";
import axiosInstance from "../axiosinstance/axiosApi";
import { Grid, InputBase } from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import Selfcashout from "./Selfcashout.js";
import "./cashout.css";
import Spinner from "react-bootstrap/Spinner";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
class Ekamoselfcashout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: "",
      balance: "",
      phone_number_error: "",
      account_not_found: "",
      balance_error: "",
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cashoutforselfShow = this.cashoutforselfShow.bind(this);

    this.handleClose = this.handleClose.bind(this); //   this closes the modal
  }

  // this code is for the modal
  cashoutforselfShow() {
    // console.log(this.state);
    this.setState({ show: true });

    this.setState({ loading: false });
    this.setState({
      phone_number: "",
      phone_number_error: "",
      balance_error: "",
      balance: "",
      account_not_found: "",
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  gotoSuccess() {
    this.setState({ show: false });
    this.onSuccessClick();
  }
  // the modal code ends here

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    if (this.state.phone_number == 0) {
      this.setState({
        phone_number_error: "Phone Number is Required",
      });
    } else if (this.state.balance == 0) {
      this.setState({
        balance_error: "Amount is Required",
        phone_number_error: "",
      });
    } else {
      this.setState({
        loading: true,
        phone_number_error: "",
        balance_error: "",
      });

      try {
        this.setState({ loading: true, account_not_found: "" });
        const response = await axiosInstance.get(
          `/money/withdraw/${this.state.phone_number}`
        );
        console.log("this is the cashout resonse", response.data);
        this.setState(response.data);
        // console.log("form data", this.state.balance);

        if (response.data.success === "ok") {
          Cookies.set(`cashoutself`, {
            phone_number: this.state.phone_number,
            balance: this.state.balance,
            business_name: response.data.agent_details.business_name,
            merchant_code: response.data.agent_details.merchant_code,
          });

          this.gotoSuccess();
          this.setState({ loading: false });
        }

        if (response.data.error ) {
          this.setState({
            loading: false,
         
          });
          toast.error(response.data.error, {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

        // return response;
      } catch (error) {
        this.setState({ loading: false });
        throw error;
      }
    }
  }

  // code for success modal
  successotherModalRef = ({ handlesuccessShow }) => {
    this.showsuccessModal = handlesuccessShow;
  };

  onSuccessClick = () => {
    this.showsuccessModal();
  };

  render() {
    const { items, loading } = this.state;

    return (
      <div>
            		<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Selfcashout ref={this.successotherModalRef}></Selfcashout>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Cash Out
            </h4>
          </div>
          <Modal.Body>
            <div style={{ fontSize: "9", textAlign: "center" }}>
              Cashout For Yourself From Your Ekamo Wallet Through
              <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                Ekamo Agent
              </span>
            </div>

            <br />
            <form autoComplete='off'>
            <InputBase
            autocomplete="off"
              placeholder="Enter Agent Code "
              name="phone_number"
              className="form-control"
              type="number"
              value={this.state.phone_number}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            <br />
            {this.state.phone_number_error && (
              <>
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "12%",
                  }}
                >
                  {this.state.phone_number_error}
                </span>
                <br />
              </>
            )}
            <br />

            <InputBase
            autocomplete="off"
              placeholder="Enter Cashout Amount"
              name="balance"
              className="form-control"
              type="number"
              value={this.state.balance}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
</form>
            <br />
            {this.state.balance_error && (
              <>
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "12%",
                  }}
                >
                  {this.state.balance_error}
                </span>
              </>
            )}
            <br />
            {this.state.account_not_found && (
              <>
                <br />
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "12%",
                  }}
                >
                  {this.state.account_not_found}
                </span>
              </>
            )}
            <br />
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#29BD00" }}
                />
                <span style={{ color: "#29BD00" }}>Loading...</span>
                {/* <span style={{ color: "#29BD00" }}>Loading...</span> */}
              </div>
            )}

            {!loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  onClick={this.handleSubmit}
                  type="submit"
                  value="Submit"
                  style={{
                    color: "white",
                    variant: "contained",
                    size: "small",
                    backgroundColor: "#29BD00",
                    margin: "0px 2px 0px 0% ",
                    fontSize: 15,
                    height: "40px",
                    fontWeight: "",
                    textTransform: "none",
                    textAlign: "center",
                    border: "1px solid #29BD00",
                    borderRadius: "100px 100px 100px 100px",
                    padding: "5px 3px ",
                    width: "50%",
                    boxShadow: "0px 3px 3px #E2E2E2",
                    "&:hover": {
                      backgroundColor: "#29BD00",
                    },
                  }}
                />
              </div>
            )}

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Ekamoselfcashout;
