import React, { Component } from 'react';
// import ReactSelect from "react-select";
import Cookies from 'js-cookie';
import axiosInstance from '../../axiosinstance/axiosApi';
import { Grid, InputBase, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Col, Row, Container, Form, Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Secondpersonaldetails from './secondpersonaldetails';

const styles = (theme) => ({
	paper: {
		position: 'relative',
		width: '',
		// borderRadius: "10px 10px 10px 10px ",
		backgroundColor: theme.palette.background.paper,
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 4),
		text: ['center'],
		background: [
			[
				'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
				'no-repeat',
				'top -30% left 1%',
			],
			//['url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)', 'no-repeat', 'top 100% left 100%'],
			//['white']
		],
	},

	//define style for username on mobile screens
	InputUsernameMobile: {
		border: '1px solid #979797',
		borderRadius: theme.shape.borderRadius,
		height: '40px',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'none',
		margin: '0px 2px 0px 0px ',
		fontSize: 14,
		width: '100%',
	},

	//define style for passworde on mobile screens
	InputPasswordMobile: {
		border: '1px solid #979797',
		borderRadius: theme.shape.borderRadius,
		height: '40px',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'none',
		margin: '0px 4px 0px 0px ',
		fontSize: 14,
		width: '100%',
	},

	//define style for header buttons on desktop screens
	HeaderButtonWeb: {
		color: 'inherit',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#25A203',
		margin: '3px 3px 2px 0px ',
		fontSize: 11,
		textTransform: 'none',
		fontWeight: 'bold',
		padding: '5px 0px',
		'&:hover': {
			backgroundColor: '#25A203',
		},
	},
	//define style for header buttons on mobile screens
	HeaderButtonMobile: {
		color: 'inherit',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#25A203',
		margin: '0px 1px 0px 0px ',
		fontSize: 13,
		fontWeight: '',
		textTransform: 'none',
		border: '1px solid #25A203',
		borderRadius: theme.shape.borderRadius,
		padding: '5px 3px ',
		'&:hover': {
			backgroundColor: '#25A203',
		},
	},

	//define style for header buttons on mobile screens
	LoginButtonMobile: {
		color: 'white',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#29BD00',
		margin: '0px 1px 0px 0px ',
		fontSize: 15,
		height: '40px',
		fontWeight: '',
		textTransform: 'none',
		border: '1px solid #29BD00',
		borderRadius: '100px 100px 100px 100px',
		padding: '5px 3px ',
		width: '100%',
		boxShadow: '0px 3px 3px #E2E2E2',
		'&:hover': {
			backgroundColor: '#29BD00',
		},
	},

	clickHere: {
		fontSize: 12,
		color: '#25A203',
	},

	forgotpassword: {
		textAlign: 'right',
		fontSize: 12,
		color: '#25A203',
		padding: '3px',
	},

	dontHaveAccount: {
		textAlign: 'center',
		fontSize: 11,
		color: '#646464',

		//boxShadow: "0px 40px 40px #E2E2E2",
	},
});

class Personaldetails extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.handlepersonalShow = this.handlepersonalShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.Proceed = this.Proceed.bind(this);
		this.state = {
			form: {
				first_name: '',
				last_name: '',

				nrc_number: '',

				date_of_birth: '',
			},
			formErrors: {
				first_name: null,
				last_name: null,

				nrc_number: null,

				date_of_birth: null,
			},
			loading: false,
			show: false,

			//validation
			nrcHelperText: '',
			nrcError: false,
		};
	}

	handlepersonalShow() {
		this.setState({ show: true });
	}
	handleClose() {
		this.setState({ show: false });
	}
	Proceed() {
		this.setState({ show: false });
		this.nextPage();
	}

	SecondpersonaldetailsRef = ({ handlesecondpersonaldetailsShow }) => {
		this.showModal = handlesecondpersonaldetailsShow;
	};

	nextPage = () => {
		this.showModal();
	};

	// code below cptures data

	validateNumber = (evt) => {
		var theEvent = evt || window.event;

		// Handle paste
		if (theEvent.type === 'paste') {
			key = theEvent.clipboardData.getData('text/plain');
		} else {
			// Handle key press
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	};

	handleChange = (e) => {
		const { name, value, checked } = e.target;
		const { form, formErrors } = this.state;

		// if (e.target.name === 'nrc_number') {
		// 	!e.target.value.match(/^\d{6}\/\d{2}\/\d{1}$/)
		// 		? this.setState({
		// 				formErrors: {
		// 					...this.state.formErrors,
		// 					nrc_number:
		// 						'Please enter a valid nrc number with slashes',
		// 				},
		// 		  })
		// 		: this.setState({
		// 				formErrors: {
		// 					...this.state.formErrors,
		// 					nrc_number: '',
		// 				},
		// 		  });
		// }
		let formObj = {};
		if (name === 'language') {
			// handle the change event of language field
			if (checked) {
				// push selected value in list
				formObj = { ...form };
				formObj[name].push(value);
			} else {
				// remove unchecked value from the list
				formObj = {
					...form,
					[name]: form[name].filter((x) => x !== value),
				};
			}
		} else {
			// handle change event except language field
			formObj = {
				...form,
				[name]: value,
			};
		}
		this.setState({ form: formObj }, () => {
			if (!Object.keys(formErrors).includes(name)) return;
			let formErrorsObj = {};
			if (name === 'password' || name === 'confirmPassword') {
				let refValue =
					this.state.form[
						name === 'password' ? 'confirmPassword' : 'password'
					];
				const errorMsg = this.validateField(name, value, refValue);
				formErrorsObj = { ...formErrors, [name]: errorMsg };
				if (!errorMsg && refValue) {
					formErrorsObj.confirmPassword = null;
					formErrorsObj.password = null;
				}
			} else {
				const errorMsg = this.validateField(
					name,
					name === 'language' ? this.state.form['language'] : value
				);
				formErrorsObj = { ...formErrors, [name]: errorMsg };
			}
			this.setState({ formErrors: formErrorsObj });
		});
	};

	validateField = (name, value, refValue) => {
		let errorMsg = null;
		switch (name) {
			case 'first_name':
				if (!value) errorMsg = 'Please enter First Name.';
				break;
			case 'last_name':
				if (!value) errorMsg = 'Please enter Last Name.';
				break;

			case 'nrc_number':
				if (!value) errorMsg = 'Please enter  NRC Number.';
				break;
			case 'date_of_birth':
				if (!value) errorMsg = 'Please select Date of Birth.';
				break;

			default:
				break;
		}
		return errorMsg;
	};

	validateForm = (form, formErrors, validateFunc) => {
		const errorObj = {};
		Object.keys(formErrors).map((x) => {
			let refValue = null;
			if (x === 'password' || x === 'confirmPassword') {
				refValue =
					form[x === 'password' ? 'confirmPassword' : 'password'];
			}
			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});
		return errorObj;
	};

	handleSubmit = () => {
		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(
			form,
			formErrors,
			this.validateField
		);
		if (Object.keys(errorObj).length !== 0) {
			this.setState({ formErrors: { ...formErrors, ...errorObj } });
			return false;
		}

		this.sendData();
	};

	async sendData() {
		const { form } = this.state;
		let ExpireIn30Minutes = 1 / 48;
		Cookies.set('personaldetails', form, {
			expires: ExpireIn30Minutes,
		});
		this.Proceed();
	}

	render() {
		const { form, formErrors, loading } = this.state;

		const { classes } = this.props;
		return (
			<div>
				<Secondpersonaldetails
					ref={this.SecondpersonaldetailsRef}></Secondpersonaldetails>

				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Body>
						<Modal.Header
							closeButton
							style={{
								borderBottom: 'none',
								background: 'transparent',
								padding: '0rem',
							}}
						/>
						<Grid>
							<Grid>
								<Grid item xs={1} sm={1} md={4} lg={8} xl={8} />

								<Grid
									item
									xs={10}
									sm={10}
									md={8}
									lg={10}
									xl={10}>
									<div
										style={{
											marginLeft: '20%',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<h4
											style={{
												fontSize: '16px',
												fontWeight: 'bold',
												textAlign: 'center ',
												marginBottom: '25px',
											}}>
											Enter Details
										</h4>

										<input
											style={{
												border: '1px solid #29BD00',
												height: '40px',
												margin: '0px 2px 0px 0px',
												padding: '0px 0px 0px 10px',
												fontSize: '14px',
												borderRadius: '4px',
												// display: "block",
												// marginRight: "auto",
												// marginLeft: "auto",
											}}
											type='text'
											autocomplete="off"
											placeholder='Enter First Name '
											name='first_name'
											value={form.first_name}
											onChange={this.handleChange}
											onBlur={this.handleChange}
										/>
										<br />
										{formErrors.first_name && (
											<span
												style={{
													color: 'red',
													textAlign: 'center',
												}}>
												{formErrors.first_name}
											</span>
										)}

										<br />
										<div>
											<input
											autocomplete="off"
												style={{
													border: '1px solid #29BD00',
													height: '40px',
													margin: '0px 2px 0px 0px',
													padding: '0px 0px 0px 10px',
													fontSize: '14px',
													borderRadius: '4px',
													// display: "block",
													// marginRight: "auto",
													// marginLeft: "auto",
												}}
												placeholder='Enter Last Name '
												type='text'
												name='last_name'
												value={form.last_name}
												onChange={this.handleChange}
												onBlur={this.handleChange}
											/>
											<br />

											{formErrors.last_name && (
												<span
													style={{
														color: 'red',
														textAlign: 'center',
													}}>
													{formErrors.last_name}
												</span>
											)}
										</div>

										<br />
										<div>
											<input
											autocomplete="off"
												style={{
													border: '1px solid #29BD00',
													height: '40px',
													margin: '0px 2px 0px 0px',
													padding: '0px 0px 0px 10px',
													fontSize: '14px',
													borderRadius: '4px',
													// display: "block",
													// marginRight: "auto",
													// marginLeft: "auto",
												}}
												type='text'
												placeholder='Date of Birth eg 26/04/1900'
												name='date_of_birth'
												value={form.date_of_birth}
												onChange={this.handleChange}
												onBlur={this.handleChange}
											/>
											<br />

											{formErrors.date_of_birth && (
												<span
													style={{
														color: 'red',
														textAlign: 'center',
													}}>
													{formErrors.date_of_birth}
												</span>
											)}
										</div>
										<br />
										<div>
											<input
											autocomplete="off"
												style={{
													border: '1px solid #29BD00',
													height: '40px',
													margin: '0px 2px 0px 0px',
													padding: '0px 0px 0px 10px',
													fontSize: '14px',
													borderRadius: '4px',
													// display: "block",
													// marginRight: "auto",
													// marginLeft: "auto",
												}}
												type='text'
												placeholder='NRC # eg 123456/78/9 '
												name='nrc_number'
												value={form.nrc_number}
												onChange={this.handleChange}
												onBlur={this.handleChange}
								
											
											/>
											<br />
											{formErrors.nrc_number && (
												<span
													style={{
														color: 'red',
														textAlign: 'center',
													}}>
													{formErrors.nrc_number}
												</span>
											)}

											<br />

											<br />
											<br />
											{!loading && (
												<Button
													onClick={this.handleSubmit}
													disabled={loading}
													className={
														classes.LoginButtonMobile
													}>
													Next
												</Button>
											)}

											{loading && (
												<div
													style={{
														marginLeft: '20%',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<Spinner
														as='span'
														animation='grow'
														size='lg'
														role='status'
														aria-hidden='true'
														style={{
															color: '#29BD00',
														}}
													/>
													<span
														style={{
															color: '#29BD00',
														}}>
														Loading...
													</span>
												</div>
											)}
											<br />

											<h5
												style={{
													color: 'red',
													fontStyle: 'italic',
													textAlign: 'center',
												}}>
												{this.state.error}
											</h5>
											<br />

											<br />
										</div>
									</div>
								</Grid>
								<Grid item xs={1} sm={2} md={4} lg={4} xl={4} />
							</Grid>
						</Grid>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
// export default Personaldetails;

Personaldetails.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Personaldetails);
