import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";

class Autherror extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSuccessShow = this.handleSuccessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleSuccessShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  color: "#4fe422",
                }}
              >
                Please login to proceed
              </span>
            </div>
            <br />
            <Button
              style={{
                background: "#29BD00",
                border: "1px solid #29BD00",
                borderRadius: "100px 100px 100px 100px",
              }}
              onClick={this.handleClose}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Autherror;
