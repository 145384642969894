import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import { IconButton, Avatar, InputBase } from '@material-ui/core';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import axiosInstance from '../../axiosinstance/axiosApi';

import Successmodal from '../success';
import { notify } from '../../utils';
class Confirmnwws extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.handleConfirmnwwsShow = this.handleConfirmnwwsShow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		// this.senddata = this.senddata.bind(this);
		// this.laststep = this.laststep.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
			loading: false,
		};
	}
	getmtnLoadfunds() {
		const zamteldetails = JSON.parse(Cookies.get('nwwsdetails'));
		let response = zamteldetails;
		this.setState({
			phone_number: zamteldetails.phone_number,
			amount: zamteldetails.amount,
			customer_name: zamteldetails.customer_name,
		});

		console.log('zamtel confirm details', zamteldetails);
	}
	handleConfirmnwwsShow() {
    this.setState({ loading: false });

		console.log(this.state);
		this.setState({ show: true });
		this.getmtnLoadfunds();
	}
	handleClose() {
		this.setState({ show: false });
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit() {
		this.setState({ loading: true });

		try {
			const response = await axiosInstance.get(
				`pin/verification/${this.state.pin}`
			);

			if (response.data.error) {
				this.setState({ loading: false });
				return notify('Wrong pin', 'success');
			}

			console.log(response.data);
			// this.setState(response.data);

			if (response.data.pin === 'success') {
				// console.log("pin success");

				// window.location.href = "./cashoutSuccess";
				this.senddata();
				// this.handleClose();
			}
			return response;
		} catch (error) {
			this.setState({ loading: false });
			throw error;
		}
	}

	async senddata() {
		const mwsdetails = JSON.parse(Cookies.get('nwwsdetails'));
		const confirmation_number = mwsdetails.confirmation_number;

		const amount = Number(mwsdetails.amount);

		const data = { confirmation_number, amount };
		console.log('sending data', data);
		try {
			const response = await axiosInstance.post(
				'/savanacomwater/payment/confirm/',
				data
			);

			console.log('this is the response', response);
			// this.setState(response.data);
			if (response.data.success) {
				this.gotosucess();
			}
			return response;
		} catch (error) {
			throw error;
		}
	}

	gotosucess = () => {
		this.onLoginClick();
		this.setState({ show: false });
	};

	loginModalRef = ({ handleShow }) => {
		this.showModal = handleShow;
	};

	onLoginClick = () => {
		this.showModal();
	};

	render() {
		const airtel = '/images/airtel_momo.svg'; //get  Icon
		const mtn = '/images/mtn_momo.svg'; //get  Icon
		const zamtel = '/images/zamtel_momo.svg'; //get  Icon
		const { items, loading } = this.state;
		return (
			<div>
				<Successmodal ref={this.loginModalRef}></Successmodal>
				<Modal
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Bill Payment
						</h4>
					</div>
					<Modal.Body>
						<div style={{ textAlign: 'center' }}>
							Pay Mulonga Water
							<span
								style={{
									fontWeight: 'bold',
									marginLeft: '2px',
								}}>
								ZMW{this.state.amount}
							</span>
							<br />
							Account No.
							<span
								style={{
									fontWeight: 'bold',
								}}>
								{this.state.account_number}
							</span>
							<br />
							belonging to{''}
							<span
								style={{
									fontWeight: 'bold',
								}}>
								{this.state.customer_name}
							</span>
							,
						</div>
						{/* <div>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {this.state.customer_name}
              </span>
            </div> */}
						<br />
						<br />
						<div
							style={{
								fontSize: 12,
								textAlign: 'center',
								marginTop: '-14px',
							}}>
							<span style={{ marginBottom: '9px' }}>
								Enter Pin To Confirm Payment
							</span>
						</div>
						<br />

						<div
							style={{
								// display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<InputBase
								placeholder='Enter Pin'
								name='pin'
								className='form-control'
								type='password'
								value={this.state.pin}
								onChange={this.handleChange}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>
							<br />

							<br />
							<br />
							<div
								style={{
									background: [
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
											'no-repeat',
											'top 230% left 100%',
										],
										//['white']
									],
								}}>
								{loading && (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<Spinner
											as='span'
											animation='grow'
											size='lg'
											role='status'
											aria-hidden='true'
											style={{ color: '#29BD00' }}
										/>
										<span style={{ color: '#29BD00' }}>
											Loading...
										</span>
									</div>
								)}

								{!loading && (
									<div>
										<input
											onClick={this.handleSubmit}
											type='submit'
											value='Submit'
											style={{
												color: 'white',
												variant: 'contained',
												size: 'small',
												backgroundColor: '#29BD00',
												margin: '0px 2px 0px 55px ',
												fontSize: 15,
												height: '40px',
												fontWeight: '',
												textTransform: 'none',
												textAlign: 'center',
												border: '1px solid #29BD00',
												borderRadius:
													'100px 100px 100px 100px',
												padding: '5px 3px ',
												width: '60%',
												boxShadow:
													'0px 3px 3px #E2E2E2',
												'&:hover': {
													backgroundColor: '#29BD00',
												},
											}}
										/>
									</div>
								)}
							</div>
						</div>
						<br />
						<h5>{this.state.error}</h5>

						<br />
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Confirmnwws;
