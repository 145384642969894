import React, { Component } from "react";
// import ReactSelect from "react-select";
import Cookies from "js-cookie";
import axiosInstance from "../../axiosinstance/axiosApi";
import { Grid, InputBase, Button } from "@material-ui/core";

import { Link } from "react-router-dom";

import { Modal, Col, Row, Container, Form, Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Confirmdetails from "./confirmdetails";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    position: "relative",
    width: "",
    // borderRadius: "10px 10px 10px 10px ",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    text: ["center"],
    background: [
      [
        "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
        "no-repeat",
        "top -30% left 1%",
      ],
      //['url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)', 'no-repeat', 'top 100% left 100%'],
      //['white']
    ],
  },

  //define style for username on mobile screens
  InputUsernameMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 2px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for passworde on mobile screens
  InputPasswordMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 4px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for header buttons on desktop screens
  HeaderButtonWeb: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "3px 3px 2px 0px ",
    fontSize: 11,
    textTransform: "none",
    fontWeight: "bold",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },
  //define style for header buttons on mobile screens
  HeaderButtonMobile: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "0px 1px 0px 0px ",
    fontSize: 13,
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #25A203",
    borderRadius: theme.shape.borderRadius,
    padding: "5px 3px ",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },

  //define style for header buttons on mobile screens
  LoginButtonMobile: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29BD00",
    margin: "0px 1px 0px 0px ",
    fontSize: 15,
    height: "40px",
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #29BD00",
    borderRadius: "100px 100px 100px 100px",
    padding: "5px 3px ",
    width: "100%",
    boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29BD00",
    },
  },

  clickHere: {
    fontSize: 12,
    color: "#25A203",
  },

  forgotpassword: {
    textAlign: "right",
    fontSize: 12,
    color: "#25A203",
    padding: "3px",
  },

  dontHaveAccount: {
    textAlign: "center",
    fontSize: 11,
    color: "#646464",

    //boxShadow: "0px 40px 40px #E2E2E2",
  },
});

class Secondpersonaldetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlesecondpersonaldetailsShow =
      this.handlesecondpersonaldetailsShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.Proceed = this.Proceed.bind(this);
    this.state = {
      form: {
        email: "",

        password: "",
        confirmPassword: "",

        gender: null,
      },
      formErrors: {
        email: null,

        password: null,
        confirmPassword: null,

        gender: null,
      },
      loading: false,
      show: false,
    };
  }

  handlesecondpersonaldetailsShow() {

    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
    // this.nextClick();
  }

  Proceed() {
    this.setState({ show: false });
    this.nextClick();
  }

  ConfirmdetailsRef = ({ handledetailsShow }) => {
    this.showModal = handledetailsShow;
  };

  nextClick = () => {
    this.showModal();
  };

  //   the code below captures data

  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    if (name === "language") {
      // handle the change event of language field
      if (checked) {
        // push selected value in list
        formObj = { ...form };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...form,
          [name]: form[name].filter((x) => x !== value),
        };
      }
    } else {
      // handle change event except language field
      formObj = {
        ...form,
        [name]: value,
      };
    }
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue =
          this.state.form[name === "password" ? "confirmPassword" : "password"];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(
          name,
          name === "language" ? this.state.form["language"] : value
        );
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter valid Email.";
        break;

      case "gender":
        if (!value) errorMsg = "Please select Gender.";
        break;
      case "password":
        // refValue is the value of Confirm Password field
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password does not match.";
        break;
      case "confirmPassword":
        // refValue is the value of Password field
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password does not match.";
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;
      if (x === "password" || x === "confirmPassword") {
        refValue = form[x === "password" ? "confirmPassword" : "password"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }

    this.sendData();
  };

  async sendData() {
    const { form } = this.state;
    let ExpireIn30Minutes = 1 / 48;
    Cookies.set("secondpersonaldetails", form, {
      expires: ExpireIn30Minutes,
    });
    this.Proceed();
  }
  render() {
    const { form, formErrors, loading } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Confirmdetails ref={this.ConfirmdetailsRef}></Confirmdetails>

        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Body>
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "transparent",
                padding: "0rem",
              }}
            />
            <Grid>
              <Grid>
                <Grid item xs={1} sm={1} md={4} lg={8} xl={8} />

                <Grid item xs={10} sm={10} md={8} lg={10} xl={10}>
                  <div
                    style={{
                      marginLeft: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center ",
                        marginBottom: "25px",
                      }}
                    >
                      Enter Details
                    </h4>

                    <input
                    autocomplete="off"
                      style={{
                        border: "1px solid #29BD00",
                        height: "40px",
                        margin: "0px 2px 0px 0px",
                        padding: "0px 0px 0px 10px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        // display: "block",
                        // marginRight: "auto",
                        // marginLeft: "auto",
                      }}
                      type="text"
                      placeholder="Email eg john@gmail.com"
                      name="email"
                      value={form.email}
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                    />
                    <br />
                    {formErrors.email && (
                      <span style={{ color: "red", textAlign: "center" }}>
                        {formErrors.email}
                      </span>
                    )}

                    <br />
                    <div>
                      <input
                      autocomplete="off"
                        style={{
                          border: "1px solid #29BD00",
                          height: "40px",
                          margin: "0px 2px 0px 0px",
                          padding: "0px 0px 0px 10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          // display: "block",
                          // marginRight: "auto",
                          // marginLeft: "auto",
                        }}
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={form.password}
                        onChange={this.handleChange}
                        onBlur={this.handleChange}
                      />
                      <br />

                      {formErrors.password && (
                        <span style={{ color: "red", textAlign: "center" }}>
                          {formErrors.password}
                        </span>
                      )}
                    </div>

                    <br />
                    <div>
                      <input
                      autocomplete="off"
                        style={{
                          border: "1px solid #29BD00",
                          height: "40px",
                          margin: "0px 2px 0px 0px",
                          padding: "0px 0px 0px 10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          // display: "block",
                          // marginRight: "auto",
                          // marginLeft: "auto",
                        }}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={form.confirmPassword}
                        onChange={this.handleChange}
                        onBlur={this.handleChange}
                      />
                      <br />

                      {formErrors.date_of_birth && (
                        <span style={{ color: "red", textAlign: "center" }}>
                          {formErrors.date_of_birth}
                        </span>
                      )}
                    </div>
                    <br />
                    <div>
                      <div>
                        <label className="mr-3">Gender:</label>
                        <div>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={form.gender === "male"}
                              onChange={this.handleChange}
                            />
                            Male
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={form.gender === "female"}
                              onChange={this.handleChange}
                            />
                            Female
                          </label>
                        </div>

                        {formErrors.gender && (
                          <span style={{ color: "red" }}>
                            {formErrors.gender}
                          </span>
                        )}
                      </div>
                      <br />

                      <br />
                      <br />
                      {!loading && (
                        <Button
                          onClick={this.handleSubmit}
                          disabled={loading}
                          className={classes.LoginButtonMobile}
                        >
                          Next
                        </Button>
                      )}

                      {loading && (
                        <div
                          style={{
                            marginLeft: "20%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            style={{ color: "#29BD00" }}
                          />
                          <span style={{ color: "#29BD00" }}>Loading...</span>
                        </div>
                      )}
                      <br />

                      <h5
                        style={{
                          color: "red",
                          fontStyle: "italic",
                          textAlign: "center",
                        }}
                      >
                        {this.state.error}
                      </h5>
                      <br />

                      <br />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} sm={2} md={4} lg={4} xl={4} />
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>

        {/* <Modal
          show={this.state.show}
          centered
          backdrop="static"
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Details Modal </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                width: "337px",
              }}
            >
              <div>
                <input
                  style={{
                    width: "85%",
                    border: "1px solid #29BD00",
                    height: "40px",
                    margin: "0px 2px 0px 0px",
                    padding: "0px 0px 0px 10px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    // display: "block",
                    // marginRight: "auto",
                    // marginLeft: "auto",
                  }}
                
                />
                <br />

                {formErrors.email && (
                  <span style={{ color: "red" }}>{formErrors.email}</span>
                )}
              </div>
              <br />

              <div>
                <input
                  style={{
                    width: "85%",
                    border: "1px solid #29BD00",
                    height: "40px",
                    margin: "0px 2px 0px 0px",
                    padding: "0px 0px 0px 10px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    // display: "block",
                    // marginRight: "auto",
                    // marginLeft: "auto",
                  }}
                
                />
                <br />

                {formErrors.password && (
                  <span style={{ color: "red" }}>{formErrors.password}</span>
                )}
              </div>
              <br />

              <div>
                <input
                  style={{
                    width: "85%",
                    border: "1px solid #29BD00",
                    height: "40px",
                    margin: "0px 2px 0px 0px",
                    padding: "0px 0px 0px 10px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    // display: "block",
                    // marginRight: "auto",
                    // marginLeft: "auto",
                  }}
                 
                />
                {formErrors.confirmPassword && (
                  <span style={{ color: "red" }}>
                    {formErrors.confirmPassword}
                  </span>
                )}
              </div>

              <br />
              

              <br />
            </div>
            <div>
              <input
                type="button"
                className="btn btn-primary"
                value="Submit"
                onClick={this.handleSubmit}
                style={{
                  color: "white",
                  variant: "contained",
                  // size: "sm                                                                                                                   all",
                  //backgroundColor : '#29BD00',
                  background:
                    "linear-gradient(45deg, #29BD00 30%, #25A203 90%)",
                  margin: "15px 1px 0px 0px ",
                  fontSize: 15,
                  height: "40px",
                  fontWeight: "",
                  textTransform: "none",
                  border: 0,
                  boxShadow: "0 3px 5px 2px rgba(41, 189, 0, .3)",
                  borderRadius: "100px 100px 100px 100px",
                  padding: "5px 3px ",
                  width: "80%",

                  "&:hover": {
                    backgroundColor: "#29BD00",
                  },
                }}
              />
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}
// export default Secondpersonaldetails;

Secondpersonaldetails.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Secondpersonaldetails);
