import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Image, Space, Typography } from 'antd';
import styles from './styles.module.css';

import React from 'react';
import DepositFloat from '../floatdeposit/create';

export const DetailsDisplay = ({ details }) => {
	return (
		<div className={styles.profile_container}>
			<Card id={styles.profile_details_card}>
				<div className={styles.avatar_container}>
					<Avatar
						style={{
							borderColor: 'white',
							borderWidth: '3px',
							borderStyle: 'solid',
						}}
						size={140}
						// src={details.first_name[0]}
					/>
				</div>

				<div className={styles.typography_container}>
					<Typography id={styles.profile_details_typography}>
						{details.first_name} &nbsp; {details.last_name}
					</Typography>
					<Typography id={styles.profile_details_typography}>
						{' '}
						{details.email}
					</Typography>
					<Typography id={styles.profile_details_typography}>
						{' '}
						{details.phone_number}
					</Typography>
					<Typography id={styles.profile_details_typography}>
						{' '}
						{details.nrc_number}
					</Typography>
					{/* <DepositFloat /> */}
				</div>
			</Card>
		</div>
	);
};
