// import React, { Component } from "react";
import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../../axiosinstance/axiosApi";


import { Grid, InputBase } from "@material-ui/core";
import Depositsuccess from "../depositSuccess";
// import Paysuccess from "./paysuccess.js";
import Spinner from "react-bootstrap/Spinner";
// import "./style.css";
// import DepositToPerson from "./deposittoperson";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class DepositToAgentPin extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCashout = this.sendCashout.bind(this);
    this.handleekamodepositShow = this.handleekamodepositShow.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.gotosuccess = this.gotosuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      show: false,
      phone_number: "",
      last_name: "",
      first_name: "",
      error_message:"",
      loading: false,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    // this.gotosuccess();
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );
console.log("check pin response",response)
      if(response.data.error == "Pin not found"){
        this.setState({
          error_message:response.data.error,
          loading:false
        })
      }
      // this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("pin success");
        this.sendCashout();
        // this.handleClose();
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendCashout() {
    
		const transfertoagent = JSON.parse(Cookies.get('transfertoagent'));
        console.log('start the process');
		this.setState({ loading: true });
        const transfertobusiness = JSON.parse(Cookies.get('transfertobusiness'));
	
		const get_merchant_code = JSON.parse(Cookies.get('get_merchant_code'));

			const data = {
            receiver_merchant_code: transfertoagent.merchant_code,
				sender_merchant_code: get_merchant_code.merchant_code,
				amount: Number(transfertoagent.amount)
				
		};


        console.log("send this information to business transfer",data)
		try {
			const response = await axiosInstance.post(
				'/money/business-business-transfer',
				data
			);
			console.log('how is the response', response);
			/* window.location.href = "/"; */
			if (response.data.transfer_successful) {
				this.gotosuccess();
				this.setState({
					loading: false,
				});
			}

			return response;
		} catch (error) {
			// throw error;
			this.setState({
				loading: false,
        pin:""
			});
			console.log('transaction error', error.response.data);
      toast.error(error.response.data.low_balance, {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
		}
  }
  getekamoDeposit() {
    const ekamodeposit = JSON.parse(Cookies.get("transfertoagent"));
    
    let response = ekamodeposit;
    this.setState({
      amount: ekamodeposit.amount,
      
      business_name:ekamodeposit.business_name,
 
    });
    // console.log("Mtn Deposit details", response);
  }
  handlePinShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // code for success
  handleekamodepositShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({ loading: false });
    this.setState({ pin: "" });
    this.getekamoDeposit();
  }
  gotosuccess() {
    this.setState({ show: false });
    this.showSuccessModal();
  }

  showSuccessModal = () => {
    this.showModal();
  };

  successModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  render() {
    const { items, loading } = this.state;
    return (
      <div>
           		<ToastContainer
					position='top-center'
					// autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Depositsuccess ref={this.successModalRef}></Depositsuccess>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Send Money
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              Deposit
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                ZMW{this.state.amount}
              </span>
              to
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {this.state.business_name}  
              
              </span>
              from your Ekamo Wallet Account
              <br />
              <br />
              <br />
            </div>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                value={this.state.pin}
                onChange={this.handleChange}
                value={this.state.pin}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />
              {/* {formErrors.amount && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.amount}
                </span>
              )} */}
              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Send"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
            <h6 style={{color:"red",fontWeight:"bold",textAlign:"center"}}>{this.state.error_message}</h6>


            {/* <Button onClick={this.handleClose}>Pay Complete</Button> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default DepositToAgentPin;
