import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Airtel from "./airtel/airtel";
import MTN from "./mtn/mtn";
import Zamtel from "./zamtel/zamtel";
class TT extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlettShow = this.handlettShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onairtelClick = this.onairtelClick.bind(this);
    this.onmtnclick = this.onmtnclick.bind(this);
    this.onzamtelclick = this.onzamtelclick.bind(this);
    this.state = {
      show: false,
    };
  }

  handlettShow() {
    console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // airtel modal here
  airtelModalRef = ({ handleairtelShow }) => {
    this.showModal = handleairtelShow;
  };

  gotoairtel = () => {
    this.showModal();
  };

  onairtelClick() {
    this.setState({ show: false });
    this.gotoairtel();
  }

  // mtn modal here
  mtnModalRef = ({ handlemtnShow }) => {
    this.showmtnModal = handlemtnShow;
  };

  gotomtn = () => {
    this.showmtnModal();
  };

  onmtnclick() {
    this.setState({ show: false });
    this.gotomtn();
  }

  // zamtel modal here
  zamtelModalRef = ({ handlezamtelShow }) => {
    this.showzamtelModal = handlezamtelShow;
  };

  gotozamtel = () => {
    this.showzamtelModal();
  };

  onzamtelclick() {
    this.setState({ show: false });
    this.gotozamtel();
  }
  render() {
    const airtel = "/images/airtel_momo.svg"; //get  Icon
    const mtn = "/images/mtn_momo.svg"; //get  Icon
    const kazang = "/images/kazang_momo.svg"; //get  Icon
    const konse = "/images/konse_momo.svg"; //get  Icon
    const zamtel = "/images/zamtel_momo.svg"; //get  Icon
    const tenga = "/images/tenga_momo.svg"; //g
    return (
      <div>
        <Airtel ref={this.airtelModalRef}></Airtel>
        <MTN ref={this.mtnModalRef}></MTN>
        <Zamtel ref={this.zamtelModalRef}></Zamtel>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Select Network
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onmtnclick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      MTN
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onairtelClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Airtel
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onzamtelclick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Zamtel
                    </span>
                  </div>
                </Paper>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default TT;
