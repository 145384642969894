import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Layout, message, Typography } from 'antd';
import { Table } from 'ant-table-extensions';
import { Col, Drawer, Pagination, Row, Tag, Statistic, Modal } from 'antd';
import { APIService } from '../api/APIService';
import styles from './styles.module.css';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import axiosInstance from "../axiosinstance/axiosApi";
import Cookies from "js-cookie";

const { Content } = Layout;

export const BusinessTransactionHistory = () => {
    const view_business_history = JSON.parse(Cookies.get('view_business_history'));

	const [viewmore, setViewMore] = useState(false);
	const [txnDetail, setTxnDetail] = useState({});
	//filter by status handler and radio group

	const [state, setState] = useState({
		loading: false,
	});
	const [transactions, setTransactions] = useState([]);
	const [totalTxns, setTotalTxns] = useState(0);
	const [page, setPage] = useState(1);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [modalvisible, setModalvisible] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [current, setCurrent] = useState(1);

	const filterByDate = async () => {
		try {
			const response = await APIService.filterTransactions(
				startDate,
				endDate
			);
			// setTransactions(response);
		} catch (error) {
			message.error({ content: error.message, duration: 2 });
		}
	};

	const onPageChange = async (pageNo) => {
		// try {
		// 	setIsReady(false);
		// 	const response = await APIService.fetchTransferHistory(pageNo);
		// 	setTotalTxns(response.items_on_page);
		// 	setCurrent(response.current);
		// 	setTransactions(response.results);
		// 	setIsReady(true);
		// } catch (error) {
		// 	return error.message;
		// }
	};


    useEffect( () => {
        const view_business_history = JSON.parse(Cookies.get('view_business_history'));

        console.log("history",view_business_history.business_name);
    })


	//on mount
	useEffect(async () => {
        const view_business_history = JSON.parse(Cookies.get('view_business_history'));
     console.log("checking the account id attached  to profile",view_business_history)
	 const business_account_id = view_business_history.businessAccountID
		try {
            const response = await axiosInstance.get(
                `/transactions/business/history?business_account_id=${business_account_id}`
              );

			setTransactions(response.data.Transactions);
            setCurrent(response.current);
			setIsReady(true);
         console.log("View the transaction history",response)
            }
            catch(err){
        // console.log("check history error",err.response)
            }
	}, []);

	const { pagination, loading } = state;

	//columns start
	const columns = [
		{
			title: <Typography style={styles.columnName}>Amount</Typography>,
			ellipsis: true,
			key: 'amount',
			width: 'min-content',
			render: (record) => {
				return (
					<Statistic
						value={record.trans_amount}
						precision={2}
						valueStyle={
							record.status
								? { color: '#3f8600', fontSize: '13px' }
								: { color: 'red', fontSize: '13px' }
						}
						prefix='ZMW'
					/>
				);
			},
		},
		{
			title: <Typography style={styles.columnName}>Charge</Typography>,
			ellipsis: true,
			key: 'charge',
			responsive: ['sm'],
			render: (record) => {
				return (
					<div>
						{record.trans_charge ? (
							<p>{record.trans_charge}</p>
						) : (
							<div
								style={{
									width: '30px',
									height: '5px',
									background: 'gray',
								}}></div>
						)}
					</div>
				);
			},
		},
		{
			title: (
				<Typography style={styles.columnName}>
					Updated Amount
				</Typography>
			),
			responsive: ['sm'],
			key: 'updated',
			render: (record) => {
				return (
					<Statistic
						value={record.update_amount}
						precision={2}
						valueStyle={
							record.status
								? { color: '#3f8600', fontSize: '13px' }
								: { color: 'red', fontSize: '13px' }
						}
						prefix='ZMW'
					/>
				);
			},
		},
		{
			title: <Typography style={styles.columnName}>Status</Typography>,
			key: 'status',
			render: (record) => {
				return (
					<p>
						{record.status ? (
							<Tag color='green'>Successful</Tag>
						) : (
							<Tag color='red'>Failed</Tag>
						)}
					</p>
				);
			},
		},

		{
			title: (
				<Typography style={styles.columnName}>
					Transaction Action
				</Typography>
			),
			dataIndex: 'trans_action',
			key: 'trans_action',
			responsive: ['sm'],
			ellipsis: true,
		},

		{
			title: (
				<Typography style={styles.columnName}>
					Transaction Method
				</Typography>
			),
			dataIndex: 'trans_method',
			responsive: ['sm'],
			key: 'transaction_method',
			ellipsis: true,
		},
		{
			title: <Typography style={styles.columnName}>Ref Id</Typography>,
			dataIndex: 'refference_id',
			key: 'reference_id',
			responsive: ['sm'],
			ellipsis: true,
		},

		{
			title: <Typography style={styles.columnName}>Date</Typography>,
			render: (record) => {
				return (
					<>
						{' '}
						{moment(record.created_on).format(
							'Do-MMM-YYYY HH:mm'
						)}{' '}
						hrs{' '}
					</>
				);
			},
			key: 'date',
			ellipsis: true,
		},
		{
			title: <Typography style={styles.columnName}>Action</Typography>,
			render: (record) => {
				delete record.message;
				delete record.updated_on;

				return (
					<>
						<Button
							style={
								record.status
									? {
											borderColor: '#29bd00',
											color: '#29bd00',
									  }
									: { borderColor: 'red', color: 'red' }
							}
							onClick={() => {
								setModalvisible(true);
								setTxnDetail(record);
							}}>
							View
						</Button>
					</>
				);
			},
			key: 'view',
			ellipsis: true,
		},
	];

	return (
		<Layout style={{ height: '100vh', padding: '20px', paddingTop: '5px' }}>
			<Drawer visible={viewmore}></Drawer>
			<Content className='site-layout' style={{ marginTop: 64 }}>

				<div
					className='site-layout-background'
					style={{
						padding: 20,
						paddingLeft: 0,
						paddingRight: 0,
						minHeight: 380,
						height: '100%',
					}}>
            <h3 style={{fontWeight:"bold",textAlign:"center",color:"#29bd00"}}> {view_business_history.business_name} Transaction History</h3>

					<div className={styles.filters}>
						<DatePicker
							placeholder='Start Date'
							onChange={(values) => {
								const date =
									moment(values).format('YYYY-MM-DD HH:mm');
								setStartDate(date);
							}}
						/>
						<DatePicker
							placeholder='End Date'
							onChange={(values) => {
								const date =
									moment(values).format('YYYY-MM-DD HH:mm');
								setEndDate(date);
								filterByDate();
							}}
							disabled={startDate ? false : true}
						/>
						<Button
							style={{
								borderColor: '#29bd00',
								color: 'white',
								background: '#29bd00',
								width: '100px',
								borderRadius: 10,
							}}
							onClick={filterByDate}>
							Search
						</Button>
					</div>
					<Table
						columns={columns}
						rowKey={(record) => record.id}
						dataSource={transactions}
						
						loading={{
							indicator: (
								<Spinner
									as='span'
									animation='grow'
									size='lg'
									role='status'
									aria-hidden='true'
									style={{
										color: '#29BD00',
										margin: '0px 2px 0px 55px ',
									}}
								/>
							),
							spinning: !isReady,
						}}
						scroll={{ y: 600 }}
						sticky
					/>
					<Pagination
						onChange={onPageChange}
						size='small'
						total={totalTxns}
						responsive={true}
						defaultCurrent={current}
						pageSize={20}
						current={current}
						style={{ float: 'right' }}
					/>
				</div>
				<Modal
					visible={modalvisible}
					onCancel={() => setModalvisible(false)}
                    
					footer={[
						<Button onClick={() => setModalvisible(false)}>
							Close
						</Button>,
					]}>
					{Object.keys(txnDetail).map((txn) => {
						return (
							<>
								<Row span={12} style={{ fontSize: '16px' }}>
									<Col span={12}>{txn.replace('_', ' ')}</Col>
									<Col span={12}>
										{txn === 'created_on'
											? moment(txnDetail[txn]).format(
													'Do MMM YYYY HH:mm'
											  )
											: txnDetail[txn]}
									</Col>
								</Row>
							</>
						);
					})}
				</Modal>
			</Content>
		</Layout>
	);
};
