import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
export default class Ticketother extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      first_name: "",
      last_name: "",
      phone_number: "",
      gender: "",
      payment_for: "other",
      // },
      // formErrors: {
      //   first_name: null,
      //   last_name: null,
      //   phone_number: null,
      //   gender: null,
      // },
    };

    // first_name: "",
    // last_name: "",
    // phone_number: "",
    // gender: "",

    // this.state = { merchant_code: "", amount: "", business_name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.otherticketShow = this.otherticketShow.bind(this);
    this.handleClose = this.handleClose.bind(this); //   this closes the modal
  }

  handleClose() {
    this.setState({ show: false });
  }
  otherticketShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({
      loading: false,
      first_name: "",
      last_name: "",
      phone_number: "",
      gender: "",
    });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit() {
    this.setState({ loading: true });

    this.setcookie();
  }

  async setcookie() {
    // const { form } = this.state;
    let ExpireIn30Minutes = 1 / 48;
    Cookies.set("buyforother", this.state);
    window.location.href = "/ConfirmBuyForOther";
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <br />
        <br />
        <br />
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Enter person's details
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}

            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            <form autoComplete='off'>

              <InputBase
              autocomplete="off"
                placeholder="Enter First Name"
                name="first_name"
                className="form-control"
                type="text"
                value={this.state.first_name}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              {/* {formErrors.merchant_code && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.merchant_code}
                </span>
              )} */}

              <br />
              <br />

              <InputBase
              autocomplete="off"
                placeholder="Enter Last Name"
                name="last_name"
                className="form-control"
                type="text"
                value={this.state.last_name}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              {/* {formErrors.amount && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.amount}
                </span>
              )} */}
              <br />
              <br />

              <InputBase
              autocomplete="off"
                placeholder="Enter Phone Number"
                name="phone_number"
                className="form-control"
                type="text"
                value={this.state.phone_number}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              <br />

              <select
                name="gender"
                value={this.state.gender}
                onBlur={this.handleChange || null}
                onChange={this.handleChange || null}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                  background: "white",
                }}
              >
                <option value="">Gender</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
              </form>
              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Proceed"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
