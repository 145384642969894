import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Cookies from "js-cookie";

const PersonalType = (props) => {
  // const [loginModalShow, setLoginModalShow] = useState(props.showModal);



  const gotoregistered = () => {
    window.location.href = "/PersonalBusinessRegistered";
  }


  const gotounregistered = () => {
    window.location.href = "/NotRegisteredBusiness";
  }


  

  // console.log("PROPS in MODAL", props);
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ textAlign: "center", fontWeight: "bold" }}>
            Is your Business registered with Pacra?
            </span>
          </div>
		<Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
           
            </div>

            <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={gotoregistered}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                    Yes
                    </span>
                  </div>
                </Paper>
              </div>



              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={gotounregistered}
                
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                     No
                    </span>
                  </div>
                </Paper>
              </div>

            <br />
            <br />
          </Modal.Body>
      </Modal>
    </>
  );
};

export default PersonalType;