import React, { Component, Fragment } from 'react';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Verifyotp from './verifyotp';
import Card from '@material-ui/core/Card';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import Pinsuccess from './pinsuccess';
import axiosInstance from '../axiosinstance/axiosApi';
import TextField from '@material-ui/core/TextField';
import { Navbar, NavItem, Nav } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Button as Btn, message } from 'antd';

import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	paper: {
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],

		background: [
			// ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
			[
				// "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
				'no-repeat',
				'top 100% left 100%',
			],
			//['white']
		],
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#29BD00 !important',
	},
	cssLabel: {
		color: 'black !important',
	},

	//define style for country code on mobile screens
});

class KYC extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			phone_number: '',
			loading: false,
			passport_photo: null,
			nrc_photo_frontface: null,
			nrc_photo_backface: null,
		};

		// this.handleClose = this.handleClose.bind(this);

		this.onInputchange = this.onInputchange.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);
		// this.handlekyc = this.handlekyc.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.Close = this.Close.bind(this);

		this.state = {
			show: false,
			error: '',
		};
	}
	onInputchange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	nrc_photo_frontface = null;

	handlenrcUpload = (event) => {
		event.preventDefault();
		console.log(event.target.files[0]);
		this.nrc_photo_frontface = event.target.files[0];
	};

	nrc_photo_backface = null;

	handlenrcbackfaceUpload = (event) => {
		event.preventDefault();
		console.log(event.target.files[0]);
		this.nrc_photo_backface = event.target.files[0];
	};

	passport_photo = null;

	handlepassportUpload = (event) => {
		event.preventDefault();
		console.log(event.target.files[0]);
		this.passport_photo = event.target.files[0];
	};

	async onSubmitForm() {
		// this.handleClose();
		let data = new FormData();
		this.setState({ loading: true });
		data.append('passport_photo', this.passport_photo);
		data.append('nrc_photo_frontface', this.nrc_photo_frontface);
		data.append('nrc_photo_backface', this.nrc_photo_backface);

		try {
			const response = await axiosInstance.put(
				'/user/profile/update/',
				data
			);


			if (response.data.success) {
				this.setState({ loading: false });
				this.handleClose();
				this.notify(response.data.success);
				return this.setState({ loading: false });
			}

			this.setState({ loading: false });
		
		} catch (error) {
			console.log(error.response.data, 'hello world');

			if (error && error.response) {
				Object.keys(error.response.data).map((key) => {
					toast.error(
						`Failed to upload files please make sure ${key.replace(
							'_',
							' '
						)}  is a valid image. Check our FAQ for more information.`,
						{
							position: 'top-center',
							autoClose: 10000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						}
					);
				});
				return this.setState({ loading: false });
			}

			this.setState({ loading: false });
			this.handleClose();
			toast('Failed to upload files please check your images and try again! Check our FAQ', {
				position: 'top-center',
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			// console.log('check for errors', error.response);
		}
	}

	// handlekyc() {
	// 	// console.log(this.state);
	// 	this.setState({ show: true });
	// }

	handleClose() {
		this.setState({ show: false });
		// this.onTokenClick();
	}

	tokenModalRef = ({ handleSuccessShow }) => {
		this.showModal = handleSuccessShow;
	};

	onTokenClick = () => {
		this.showModal();
	};

	Close() {
		this.setState({ show: false });
	}

	errorToast = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 10000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	notify = (message) => {
		toast.success(message, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	render() {
		const { items, loading } = this.state;
		const { classes } = this.props;
		return (
			<div>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Pinsuccess ref={this.tokenModalRef}></Pinsuccess>

				<Btn
					shape='round'
					style={{ color: 'white', background: '#29bd00' }}
					onClick={() => this.setState({ show: true })}>
					Update Account Documents
				</Btn>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.Close}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Update Personal Account Details
						</h5>
					</div>
					<Modal.Body>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Upload Potrait Size Photo'
							type='file'
							name='passport_photo'
							value={this.state.passport_photo}
							onChange={this.handlepassportUpload}
							onBlur={this.handlepassportUpload}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Upload Front Of NRC'
							type='file'
							name='nrc_photo_frontface'
							value={this.state.nrc_photo_frontface}
							onChange={this.handlenrcUpload}
							onBlur={this.handlenrcUpload}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,
								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Upload Back Of NRC'
							type='file'
							name='nrc_photo_backface'
							value={this.state.nrc_photo_backface}
							onChange={this.handlenrcbackfaceUpload}
							onBlur={this.handlenrcbackfaceUpload}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<br />
						<br />
						<span style={{ color: 'red' }}>{this.state.error}</span>
						<br />
						{loading && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Spinner
									as='span'
									animation='grow'
									size='lg'
									role='status'
									aria-hidden='true'
									style={{ color: '#29BD00' }}
								/>
								<span style={{ color: '#29BD00' }}>
									Updating Profile
								</span>
							</div>
						)}

						{!loading && (
							<Button
								onClick={this.onSubmitForm}
								style={{
									color: 'white',
									width: '100%',
									border: '1px solid #29BD00',
									height: '40px',
									margin: '0px 1px 0px 0px',
									padding: '5px 3px',
									variant: 'contained',
									fontSize: '15px',
									// boxshadow: "0px 3px 3px #E2E2E2",

									borderRadius: '100px 100px 100px 100px',
									display: 'block',
									marginRight: 'auto',
									marginLeft: 'auto',
									background: '#29BD00',
									width: '70%',
								}}>
								Proceed
							</Button>
						)}
						<div
							style={{
								background: [
									// add background image
									[
										'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
										'no-repeat',
										'top 10% left 15%',
									],
								],

								padding: 20,
							}}></div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
KYC.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(KYC);
