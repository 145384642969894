import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import { Navbar, NavItem, Nav } from "react-bootstrap";
import axiosInstance from "../../axiosinstance/axiosApi";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Personaldetails from "./personaldetails";
import Spinner from "react-bootstrap/Spinner";
import { Grid, InputBase, Button } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    position: "relative",
    width: "",
    // borderRadius: "10px 10px 10px 10px ",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    text: ["center"],
    background: [
      [
        "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
        "no-repeat",
        "top -30% left 1%",
      ],
      //['url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)', 'no-repeat', 'top 100% left 100%'],
      //['white']
    ],
  },

  //define style for username on mobile screens
  InputUsernameMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 2px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for passworde on mobile screens
  InputPasswordMobile: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    margin: "0px 4px 0px 0px ",
    fontSize: 14,
    width: "100%",
  },

  //define style for header buttons on desktop screens
  HeaderButtonWeb: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "3px 3px 2px 0px ",
    fontSize: 11,
    textTransform: "none",
    fontWeight: "bold",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },
  //define style for header buttons on mobile screens
  HeaderButtonMobile: {
    color: "inherit",
    variant: "contained",
    size: "small",
    backgroundColor: "#25A203",
    margin: "0px 1px 0px 0px ",
    fontSize: 13,
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #25A203",
    borderRadius: theme.shape.borderRadius,
    padding: "5px 3px ",
    "&:hover": {
      backgroundColor: "#25A203",
    },
  },

  //define style for header buttons on mobile screens
  LoginButtonMobile: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29BD00",
    margin: "0px 1px 0px 0px ",
    fontSize: 15,
    height: "40px",
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #29BD00",
    borderRadius: "100px 100px 100px 100px",
    padding: "5px 3px ",
    width: "100%",
    boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29BD00",
    },
  },

  clickHere: {
    fontSize: 12,
    color: "#25A203",
  },

  forgotpassword: {
    textAlign: "right",
    fontSize: 12,
    color: "#25A203",
    padding: "3px",
  },

  dontHaveAccount: {
    textAlign: "center",
    fontSize: 11,
    color: "#646464",

    //boxShadow: "0px 40px 40px #E2E2E2",
  },
});

class Verifyotp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.Close = this.Close.bind(this);
    this.state = {
      loading: false,
      otp_code: "",
      error: "",
      show: false,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  Close() {
    this.setState({ show: false });
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();

    try {
      const response = await axiosInstance.get(
        `/otp/get/${this.state.otp_code}`
      );
   
      this.setState(response.data);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000);
     
      if (response.data.otp === "success") {
     
        this.handleClose();
        this.onNextClick();
        this.setState({ loading: true });
      } else {
        this.setState({ error: response.data.error });
        this.setState({ loading: false });
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  handleShow() {

    this.setState({ show: true, loading: false });
  }

  handleClose() {
    this.setState({ show: false });
    // window.location.href = "/signup";
  }

  // code for opening signup modal

  personsaldetailsModalRef = ({ handlepersonalShow }) => {
    this.showModal = handlepersonalShow;
  };

  onNextClick = () => {
    this.showModal();
  };

  render() {
    const { items, loading } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Personaldetails ref={this.personsaldetailsModalRef}></Personaldetails>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Body>
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "transparent",
                padding: "0rem",
              }}
            />
            <Grid>
              <Grid>
                <Grid item xs={1} sm={1} md={4} lg={8} xl={8} />

                <Grid item xs={10} sm={10} md={8} lg={10} xl={10}>
                  <div
                    style={{
                      marginLeft: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center ",
                      }}
                    >
                      Verification
                    </h4>
                    <input
                    autocomplete="off"
                      placeholder="Enter OTP Code"
                      name="otp_code"
                      className="form-control"
                      type="text"
                      value={this.state.otp_code}
                      onChange={this.handleChange}
                    />
                    {/* <input
                      placeholder="Enter OTP Code"
                      name="otp_code"
                      className="form-control"
                   
                      value={this.state.otp_code}
                    /> */}
                    <br />
                    <br />
                    {!loading && (
                      <Button
                        onClick={this.handleSubmit}
                        disabled={loading}
                        className={classes.LoginButtonMobile}
                      >
                        Next
                      </Button>
                    )}

                    {loading && (
                      <div
                        style={{
                          marginLeft: "20%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                          style={{ color: "#29BD00" }}
                        />
                        <span style={{ color: "#29BD00" }}>Loading...</span>
                      </div>
                    )}
                    <br />

                    <h5
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      {this.state.error}
                    </h5>
                    <br />

                    <br />
                  </div>
                </Grid>
                <Grid item xs={1} sm={2} md={4} lg={4} xl={4} />
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
// export default Verifyotp;

Verifyotp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Verifyotp);
