import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Cookies from "js-cookie";
import { Grid, InputBase } from "@material-ui/core";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../axiosinstance/axiosApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Successmodal from "./success";
class Confirmzesco extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleConfirmShow = this.handleConfirmShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.senddata = this.senddata.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      loading: false,
      pin:""
    };
  }
  getzescodata() {
    const zescodetails = JSON.parse(Cookies.get("zescocookie"));
    let response = zescodetails;
    this.setState({
      confirmation_number: zescodetails.confirmation_number,
      customer_address: zescodetails.customer_address,
      customer_name: zescodetails.customer_name,
      amount: zescodetails.amount,
      meter_number: zescodetails.meter_number,
    });
    console.log("zesco confirm details", zescodetails);
  }
  handleConfirmShow() {
    console.log(this.state);
    this.setState({ show: true });
    this.getzescodata();
    this.setState({ pin: "" });
    this.setState({ loading: false });


  }
  handleClose() {
    this.setState({ show: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    // this.gotosuccess();
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );
console.log("check pin response",response)
      if(response.data.error ){
        this.setState({
    
          loading:false
        })

        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
      }
      // this.setState(response.data);

      if (response.data.pin === "success") {
        this.senddata();
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  async senddata() {
   
    const zescodetails = JSON.parse(Cookies.get("zescocookie"));

    const confirmation_number = zescodetails.confirmation_number;
    const initial_amount = zescodetails.amount;


    const amount = Number(initial_amount) 
 



    const data = { confirmation_number, amount };

    console.log("sending last zesco data", data);
    try {
      const response = await axiosInstance.post("/zesco/buy/confirm/", data);
      console.log("this is the response", response);
      // this.setState(response.data);

      if (response.data.success === "ok") {
        this.gotosucess();
      }

      return response;
    } catch (error) {
    


      if(error.response.status === 500) {
      
        this.setState({ loading: false });
        toast.error('Internal Server Error, try again later', {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
        
        }
  
    }
  }

  gotosucess = () => {
    this.onLoginClick();
    this.setState({ show: false });
  };

  loginModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    const airtel = "/images/airtel_momo.svg"; //get  Icon
    const mtn = "/images/mtn_momo.svg"; //get  Icon
    const zamtel = "/images/zamtel_momo.svg"; //get  Icon
    const { items, loading } = this.state;
    return (
      <div>
           		<ToastContainer
					position='top-center'
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Successmodal ref={this.loginModalRef}></Successmodal>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Bill Payment
            </h4>
          </div>
          <Modal.Body>
            {/* <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            ></div> */}
            	<div
							style={{
								fontSize: 12,
								textAlign: 'center',
								marginTop: '-14px',
							}}>
						 Buy Zesco units for
							<span
								style={{
									fontWeight: 'bolder',
									marginLeft: '5px',
									marginRight: '5px',
								}}>
							ZMW {this.state.amount}
							</span>
              meter No.
							<span
								style={{
									fontWeight: 'bolder',
									marginLeft: '4px',
									marginRight: '4px',
								}}>
							 {this.state.meter_number}
							</span>
					    <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {this.state.customer_name}
              </span>
						
						</div>


            <br />
            <br />
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                value={this.state.pin}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 0% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
            </div>
            <br />
            <div
              style={{
                background: [
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Confirmzesco;
