import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../axiosinstance/axiosApi";

import { InputBase } from "@material-ui/core";
import Spinner from "react-bootstrap/Spinner";
import { Grid } from "@material-ui/core";
import Cashoutsuccess from "./cashoutSuccess";
import { SpinnerRoundFilled } from "spinners-react";
import "./cashout.css";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
class Successother extends Component {
  constructor(props, context) {
    super(props, context);
    this.handlesuccessShow = this.handlesuccessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCashout = this.sendCashout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.gotosuccess = this.gotosuccess.bind(this);

    this.state = {
      show: false,
      loading: false,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getotherDetails() {
    const cashoutother = JSON.parse(Cookies.get("cashoutother"));
    let response = cashoutother;
    this.setState({
      balance: cashoutother.balance,
      phone_number: cashoutother.phone_number,
    });
    // console.log("Receiver details", response);
  }

  async handleSubmit() {
    this.setState({ loading: true });
    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );

      // console.log(response.data.pin);
      this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("cash out for other pin");
        this.sendCashout();
        this.setState({ pin: "" });
      
      }



      if(response.data.error){
        this.setState({ loading: false });
    
            toast.error(response.data.error, {
              position: 'top-center',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }

      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendCashout() {
   
    const cashoutother = Cookies.get("cashoutother");

    // console.log("cookie data", cashoutother);
    // event.preventDefault();
    try {
      const response = await axiosInstance.post(
        "/others/cashout/",
        cashoutother
      );
    console.log("get data cahsout response", response);

      if (response.data.success === "ok") {
        this.gotosuccess();
      }
      if(response.data.error){
        this.setState({ loading: false });
    
            toast.error(response.data.error, {
              position: 'top-center',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }

          if(response.data.low_balance){
            this.setState({ loading: false });
        
                toast.error(response.data.low_balance, {
                  position: 'top-center',
                  autoClose: 4000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              }

      // console.log(response.data);
      /* window.location.href = "/"; */
      return response;
    } catch (error) {
      throw error;
    }
  }

  handlesuccessShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.getotherDetails();

    this.setState({ loading: false });
    this.setState({ pin: "" });
  }

  handleClose() {
    this.setState({ show: false });
    // this.showSuccessModal();
  }
  gotosuccess() {
    this.setState({ show: false });
    this.showSuccessModal();
  }

  showSuccessModal = () => {
    this.showModal();
  };

  successModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  render() {
    const { loading } = this.state;

    return (
      <div>
        		<ToastContainer
					position='top-center'
					autoClose={40000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Cashoutsuccess ref={this.successModalRef}></Cashoutsuccess>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Cash Out
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              You want to cashout for
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                ZMW{this.state.balance}
              </span>
              for
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                {this.state.phone_number}
              </span>
              <br />
              <br />
              <br />
            </div>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Withdraw
              </span>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="pasword"
                value={this.state.pin}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
            </div>
            <br />
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#29BD00" }}
                />
                <span style={{ color: "#29BD00" }}>Loading...</span>
              </div>
            )}

            {!loading && (
              <div>
                <input
                  onClick={this.handleSubmit}
                  type="submit"
                  value="Withdraw"
                  style={{
                    color: "white",
                    variant: "contained",
                    size: "small",
                    backgroundColor: "#29BD00",
                    margin: "0px 2px 0px 55px ",
                    fontSize: 15,
                    height: "40px",
                    fontWeight: "",
                    textTransform: "none",
                    textAlign: "center",
                    border: "1px solid #29BD00",
                    borderRadius: "100px 100px 100px 100px",
                    padding: "5px 3px ",
                    width: "60%",
                    boxShadow: "0px 3px 3px #E2E2E2",
                    "&:hover": {
                      backgroundColor: "#29BD00",
                    },
                  }}
                />
              </div>
            )}
            <br />
            <h5 style={{ textAlign: "center", color: "red" }}>
              {this.state.error}
            </h5>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Successother;
