import logo from './logo.svg';
import './App.css';
import {
	Router,
	Switch,
	Route,
	Link,
	Redirect,
	useHistory,
	useLocation,
} from 'react-router-dom';
import Login from './auth/login';
import Home from './home/home';
import Header from './components/header/header';
import MiniHeader from './components/header/miniheader';
import Spinner from 'react-bootstrap/Spinner';
import Agent from './accounts/agent';
import Agentprofile from './accounts/agentprofile';
import Churchprofile from './accounts/churchprofile';
import Profile from './accounts/profile';
import buslist from './ticket/buslist';
import nextofkin from './ticket/nextofkin';
import Details from './ticket/ticketsuccess';
import Buyforother from './ticket/ticketpin';
import ProfilePage from './profile/Profile';
import BusinessLevyForm from './council/BusinessLevyForm';
import WholeSaleBusiness from './council/WholeSaleBusiness';

import RetailBusinessForm from './council/RetailBusinessForm';

import Selforother from './ticket/selforother';
import Myticket from './accounts/myticket';
import ForgotPasswordPage from './auth/forgotpassword/forgotpasswordpage';
import Forgotpasswordform from './auth/forgotpassword/passwordform';
import Church from './accounts/church';
import NotRegisteredBusiness from './accounts/businessnotregistered';
import PersonalBusinessRegistered from './accounts/personalbusiness';
import Limitedcompany from './accounts/Limitedcompany';
import Limitedcompanyprofile from './accounts/limitedcompanyprofile';
import Businessnotregisteredprofile from './accounts/businessnotregisteredprofile';
import Registeredbusinessprofile from './accounts/registeredbusinessprofile';

// import Churchprofile from "./accounts/churchprofile";

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import axiosInstance from './axiosinstance/axiosApi';

// import axiosInstance from "./components/axiosApi";
import { Grid } from '@material-ui/core';
import { TransactionHistory } from './Transactions/Transactions';
import { BusinessTransactionHistory } from './Transactions/BusinessTransactionHistory';

import { Alert, Button, Modal, Space } from 'antd';
import { CreatePin } from './profile/createPin';
import { UploadKyc } from './profile/uploadKyc';
import { ProtectRoutes } from './ProtectRoutes/ProtectRoutes';

import IdleTimer from 'react-idle-timer';

import { IdleTimeOutModal }  from './components/timoutmodal/IdleTimeOutModal';

import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

class App extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            timeout:1000 * 60 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
			tickets: {},
			phone_number: '',
			updateTicket: this.updateTicket,
		
			balance: 10,
			is_kyc_approved: false,
			my_kyc: false,
			my_pin: true,
			user_role: '',
			loading: false,
			logged_in:localStorage.getItem('access_token') ? true : false

        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }


	componentDidMount() {
		this.getUserDetail();
		
	

	}


	async getUserDetail() {
	
		try {
			this.setState({ loading: true });
			let response = await axiosInstance.get('/user/details/');
			this.setState(
				{
					phone_number: response.data.phone_number,
					is_kyc_approved: response.data.is_kyc_approved,
					my_kyc: response.data.my_kyc,
					my_pin: response.data.my_pin,
					//   isLoading: false,
				},
				// () => console.log('the state is: ', this.state)
			);

			if (response.statusText == 'OK') {
				this.getbusinesslist();
			}
			this.setState({ loading: false });
			return response;
		} catch (error) {
			// console.log("Error error: ", JSON.stringify(error, null, 4));
			// throw error; todo
		}

	}

	async getbusinesslist() {
		try {
			const response = await axiosInstance.get(
				`/business/details/${this.state.phone_number}`
			);
			// console.log("check my accounts now",response)
		} catch (error) {
			// console.log('catch the error');
		}
	}


    _onAction(e) {
    //   console.log('user did something',this.state.logged_in )
	  if(this.state.logged_in){
		this.setState({isTimedOut: false})

	  }
    }
   
    _onActive(e) {
    //   console.log('user is active', this.state.logged_in)
	  if(this.state.logged_in){
		this.setState({isTimedOut: false})

	  }
    }
   
    async _onIdle(e) {
    //   console.log('user is idle', this.state.logged_in)
	  if(this.state.logged_in){
      const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
		try {
			const response = await axiosInstance.post("/blacklist/", {
			  refresh_token: localStorage.getItem(" "),
			});
			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");
			axiosInstance.defaults.headers["Authorization"] = null;
			window.location.href = "/";
			
			return response;
		  } catch (e) {
			// console.log(e);
		  }

      } else {
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.setState({isTimedOut: true})
		console.log("start count down ")
      }
	}
      
    }

    handleClose() {
      this.setState({showModal: false})
    }

   async handleLogout() {
      this.setState({showModal: false})
	  if(this.state.logged_in){
	  try {
		const response = await axiosInstance.post("/blacklist/", {
		  refresh_token: localStorage.getItem(" "),
		});
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		axiosInstance.defaults.headers["Authorization"] = null;
		// window.location.href = "/";
		
		return response;
	  } catch (e) {
		// console.log(e);
	  }
	}
    }

    render(){
      const { match } = this.props
      return(
        <>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />

            <div className="">
            
                <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />

<Grid>
				<Grid item container>
					<Header />
				</Grid>

				<Grid item Container>
					{this.state.loading ? (
						<Spinner />
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}>
							{(this.state.logged_in && !this.state.my_kyc) ||
							(this.state.logged_in && !this.state.my_pin) ? (
								<Alert
									message={
										!this.state.my_kyc
											? 'Complete setting up by uploading kyc documents.'
											: !this.state.my_pin
											? 'Create a pin to be able to pay bills and transfer money.'
											: ''
									}
									type='warning'
									action={
										<Space>
											<Button
												size='small'
												type='ghost'
												style={{
													borderColor: '#29bd00',
													color: 'white',
													background: '#29bd00',
													minWidth: '100px',
													width: 'auto',
												}}>
												<a href='/profile'>
													{' '}
													Complete Setup
												</a>
											</Button>
										</Space>
									}
									style={{
										marginTop: '100px',
										width: '70%',
										textAlign: 'center',
									}}
									closable
								/>
							) : null}
						</div>
					)}
					<Switch>
					<Route exact path={'/'} component={Home} />

						<ProtectRoutes>
							<Route exact path={'/Agent'} component={Agent} />
							<Route
								exact
								path={'/Organisation'}
								component={Church}
							/>
							<Route
								exact
								path={'/Limitedcompany'}
								component={Limitedcompany}
							/>
							<Route
								exact
								path={'/PersonalBusinessRegistered'}
								component={PersonalBusinessRegistered}
							/>
							<Route
								exact
								path={'/LimitedcompanyProfile'}
								component={Limitedcompanyprofile}
							/>
							<Route
								exact
								path={'/BusinessRegistered'}
								component={Registeredbusinessprofile}
							/>
							<Route
								exact
								path={'/PersonalBusinessNotRegistered'}
								component={Businessnotregisteredprofile}
							/>
							<Route
								exact
								path={'/NotRegisteredBusiness'}
								component={NotRegisteredBusiness}
							/>

							{/* password recovery links */}

							<Route
								exact
								path={'/passwordrecovery'}
								component={ForgotPasswordPage}
							/>

							<Route
								exact
								path={'/Profile'}
								component={ProfilePage}
							/>

							<Route
								exact
								path={'/AgentBusinessProfile'}
								component={Agentprofile}
							/>
							<Route
								exact
								path={'/OrganisationBusinessProfile'}
								component={Churchprofile}
							/>
							<Route
								exact
								path={'/Busticket'}
								component={buslist}
							/>
							<Route
								exact
								path={'/nextofkin'}
								component={nextofkin}
							/>
							<Route
								exact
								path={'/Selforother'}
								component={Selforother}
							/>
							<Route
								exact
								path={'/MyTickets'}
								component={Myticket}
							/>
							<Route
								exact
								path={'/ConfirmBuyForSelf'}
								component={Details}
							/>
							<Route
								exact
								path={'/ConfirmBuyForOther'}
								component={Buyforother}
							/>

							<Route
								exact
								path={'/passwordreset'}
								component={Forgotpasswordform}
							/>

							{/* council forms */}

							<Route
								exact
								path={'/BusinessLevyForm'}
								component={BusinessLevyForm}
							/>

							<Route
								exact
								path={'/WholeSaleBusiness'}
								component={WholeSaleBusiness}
							/>
							<Route
								exact
								path={'/RetailBusinessForm'}
								component={RetailBusinessForm}
							/>
							<Route
								exact
								path={'/transfer-history'}
								component={TransactionHistory}
							/>
							<Route
								exact
								path={'/BusinessTransactionHistory'}
								component={BusinessTransactionHistory}
							/>
						</ProtectRoutes>
					</Switch>
				</Grid>
			</Grid>
            </div>
        </>
      )
   }

 }

 App.propTypes = {
     match: PropTypes.any.isRequired,
     history: PropTypes.func.isRequired
 }

export default App