import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { render } from 'react-dom';
import 'antd/dist/antd.css';

import ReactGA from 'react-ga';

ReactGA.initialize('G-35MN1DSTYC');

render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
);
