import React, { Component, Fragment } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
// import './account.css';
import TextField from '@material-ui/core/TextField';

// import "./PageStyles.css";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axiosInstance from '../axiosinstance/axiosApi';

const styles = (theme) => ({
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'yellow !important',
		color: 'red',
	},
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#29BD00', //your color
		},
	},
});
class PersonalBusinessRegistered extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_first_name: '',
			user_last_name: '',
			user_nrc_number: '',
			user_email: '',
			username: '',
			user_gender: '',
			user_date_of_birth: '',

			// profile details
			business_name: '',
			bank_account_number: '',
			email_address: '',
			business_registeration_number: '',
			tpin_number: '',
			mobile_money_account_number: '01',
			company_physical_address: '',
			contact_phone_number: '',
			merchant_code: '000',
			first_name: '',
			last_name: '',
			nrc_number: '',
			phone_number: '',
			physical_address: '',
			email: '',

			user_first_name_error: null,
			user_last_name_error: null,
			user_nrc_number: null,
			user_email_error: null,
			username_error: null,
			user_gender_error: null,
			user_date_of_birth_error: null,
			business_name_error: null,
			bank_account_number_error: null,
			email_address_error: null,
			business_registeration_number_error: null,
			tpin_number_error: null,
			mobile_money_account_number_error: null,
			company_physical_address_error: null,
			contact_phone_number_error: null,
			tpin_file_error: null,
			pacra_document_error: null,
			nrc_file_error: null,
			first_name_error: null,
			last_name_error: null,
			nrc_number_error: null,
			phone_number_error: null,
			physical_address_error: null,
			email_error: null,
			show: false,
			loading: false,
			tpin_file: null,
			pacra_document: null,
			nrc_file: null,
			error: '',
			fielderror: '',
			accessshow: false,
			timeoutshow: false,

			access_error: '',

			emailHelperText: '',
			emailError: false,

			email1HelperText: '',
			email1Error: false,

			phoneHelperText: '',
			phoneError: false,

			phone1HelperText: '',
			phone1Error: false,

			nrcHelperText: '',
			nrcError: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendData = this.sendData.bind(this);
		this.handlelogout = this.handlelogout.bind(this);
	}

	nrc_file = null;
	handlenrcUpload = (event) => {
		event.preventDefault();
		this.nrc_file = event.target.files[0];
	};

	pacra_document = null;
	handlepacraUpload = (event) => {
		event.preventDefault();
		this.pacra_document = event.target.files[0];
	};

	tpin_file = null;
	handleTpinUpload = (event) => {
		event.preventDefault();
		this.tpin_file = event.target.files[0];
	};

	// save the picked file in this

	handleChange(event) {
		//email validation begin
		if (event.target.name === 'email_address') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						emailHelperText: 'Enter valid email',
						emailError: true,
				  })
				: this.setState({ emailHelperText: '', emailError: false });
		}

		if (event.target.name === 'email') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						email1HelperText: 'Enter valid email',
						email1Error: true,
				  })
				: this.setState({ email1HelperText: '', email1Error: false });
		}

		//EMAIL VALIDATION END

		//contact business phonenumber validation
		if (event.target.name === 'contact_phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phoneError: true,
						phoneHelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phoneError: false, phoneHelperText: '' });
		}

		//contact1 validation
		if (event.target.name === 'phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phone1Error: true,
						phone1HelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phone1Error: false, phone1HelperText: '' });
		}

		//contact2 validation
		if (event.target.name === 'phone_number2') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phone2Error: true,
						phone2HelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phone2Error: false, phone2HelperText: '' });
		}

		if (event.target.name === 'nrc_number') {
			!event.target.value.match(/^\d{6}\/\d{2}\/\d{1}$/)
				? this.setState({
						nrcError: true,
						nrcHelperText:
							'Enter valid nrc number with forward slashes',
				  })
				: this.setState({ nrcError: false, nrcHelperText: '' });
		}
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSubmit = () => {
		this.sendData();
	};

	async sendData() {
		// event.preventDefault();
		let data = new FormData();
		// Personal Details
		if (this.state.business_name == 0) {
			this.setState({
				business_name_error: 'Business Name is Required',
			});
		} else if (this.state.bank_account_number == 0) {
			this.setState({
				bank_account_number_error: 'Bank Account No. is Required',
			});
			this.setState({
				business_name_error: '',
			});
		} else if (this.state.bank_account_number == 0) {
			this.setState({
				bank_account_number_error: 'Bank Account No. is Required',
			});
			this.setState({
				business_name_error: '',
			});
		} else if (this.state.company_physical_address == 0) {
			this.setState({
				company_physical_address_error:
					'Company Physical Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
			});
		} else if (this.state.email_address == 0) {
			this.setState({
				email_address_error: 'Email Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
			});
		} else if (this.state.tpin_number == 0) {
			this.setState({
				tpin_number_error: 'TPIN NO is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
			});
		} else if (this.state.contact_phone_number == 0) {
			this.setState({
				contact_phone_number_error: 'Phone No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
			});
		} else if (this.state.business_registeration_number == 0) {
			this.setState({
				business_registeration_number_error:
					'Business Registration No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
			});
		} else if (this.state.first_name == 0) {
			this.setState({
				first_name_error: 'First Name is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
			});
		} else if (this.state.last_name == 0) {
			this.setState({
				last_name_error: 'Last Name is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
			});
		} else if (this.state.nrc_number == 0) {
			this.setState({
				nrc_number_error: 'NRC No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
				last_name_error: '',
			});
		} else if (this.state.phone_number == 0) {
			this.setState({
				phone_number_error: 'Phone No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
			});
		} else if (this.state.email == 0) {
			this.setState({
				email_error: 'Email Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
			});
		} else if (this.state.physical_address == 0) {
			this.setState({
				physical_address_error: 'Physical Address is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',
			});
		} else {
			this.setState({ loading: true });
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				company_physical_address_error: '',
				email_address_error: '',
				tpin_number_error: '',
				contact_phone_number_error: '',
				business_registeration_number_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',
				physical_address_error: '',
			});

			data.append('user_first_name', this.state.user_first_name);
			data.append('user_last_name', this.state.user_last_name);
			data.append('user_nrc_number', this.state.user_nrc_number);
			data.append('user_email', this.state.user_email);
			data.append('username', this.state.username);
			data.append('user_gender', this.state.user_gender);
			data.append('user_date_of_birth', this.state.user_date_of_birth);

			// Profile Details
			data.append('business_name', this.state.business_name);
			data.append('bank_account_number', this.state.bank_account_number);
			data.append(
				'business_registeration_number',
				this.state.business_registeration_number
			);
			data.append('email_address', this.state.email_address);
			data.append(
				'contact_phone_number',
				this.state.contact_phone_number
			);
			data.append('tpin_number', this.state.tpin_number);
			data.append(
				'mobile_money_account_number',
				this.state.mobile_money_account_number
			);
			data.append(
				'company_physical_address',
				this.state.company_physical_address
			);
			data.append('email', this.state.email);
			data.append('first_name', this.state.first_name);
			data.append('last_name', this.state.last_name);
			data.append('nrc_number', this.state.nrc_number);
			data.append('phone_number', this.state.phone_number);
			data.append('physical_address', this.state.physical_address);
			data.append('account_type', 'registerd');
			// upload files

			data.append('nrc_file', this.nrc_file);
			data.append('pacra_document', this.pacra_document);
			data.append('tpin_file', this.tpin_file);

			try {
				const response = await axiosInstance.post(
					'/account/personal/business/',
					data
				);
				// console.log("getting response", response.data.error);
				console.log('getting other response', response.data);

				this.setState({
					fielderror: response.data.error,
				});

				if (response.data.success === 'ok') {
					this.onEkamoClick();
					this.setState({ loading: false, timeoutshow: false });
					console.log('account has been created');
				}

				if (!response.data.error == 0) {
					console.log('check the if error is present');
					this.setState({
						timeoutshow: false,

						loading: false,
						// user_first_name_error: response.data.error.user_first_name,
						// user_last_name_error: response.data.error.user_last_name,
						// user_nrc_number: response.data.error.user_nrc_number,
						// user_email_error: response.data.error.user_email,
						// username_error: response.data.error.username_error,
						// user_gender_error: response.data.error.business_name,
						// user_date_of_birth_error: response.data.error.business_name,
						business_name_error: response.data.error.business_name,
						bank_account_number_error:
							response.data.error.bank_account_number,
						email_address_error: response.data.error.email_address,
						business_registeration_number_error:
							response.data.error.business_registeration_number,
						tpin_number_error: response.data.error.tpin_number,
						mobile_money_account_number_error:
							response.data.error.mobile_money_account_number,
						company_physical_address_error:
							response.data.error.company_physical_address,
						contact_phone_number_error:
							response.data.error.contact_phone_number,
						tpin_file_error: response.data.error.tpin_file,
						pacra_document_error:
							response.data.error.pacra_document,
						nrc_file_error: response.data.error.nrc_file,
						first_name_error: response.data.error.first_name,
						last_name_error: response.data.error.last_name,
						nrc_number_error: response.data.error.nrc_number,
						phone_number_error: response.data.error.phone_number,
						physical_address_error:
							response.data.error.physical_address,
						email_error: response.data.error.email,
					});
				}

				if (
					response.data.error ===
					'You can not access this area, you are not a sales agent'
				) {
					this.setState({
						access_error: response.data.error,
						timeoutshow: false,
					});

					this.accesserrorclick();
				}

				if (response.data.error === 'ok') {
					this.setState({
						loading: false,
						timeoutshow: false,
						error: response.data.message,
					});
					this.notify();
				}
			} catch (error) {
				// console.log(error);

				this.setState({ loading: false });
				this.setState({
					errors: error,
					timeoutshow: false,
				});
			}
			setTimeout(() => {
				this.setState({
					loading: false,
					timeoutshow: true,
				});
			}, 60000);
		}
	}
	// 0975870301

	async handlelogout(event) {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	}

	accesserrorclick = () => {
		this.setState({ accessshow: true });
	};

	onEkamoClick = () => {
		this.setState({ show: true });
	};

	notify = () => {
		toast.error(this.state.error, {
			position: 'top-center',
			autoClose: 400000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	handletimeClose = () => {
		this.setState({ timeoutshow: false });
	};

	render() {
		const { loading } = this.state;
		return (
			<Fragment>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Modal
					scrollable={true}
					show={this.state.timeoutshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<br />
						<h5
							style={{
								fontWeight: 'bold',
								color: 'red',
								textAlign: 'center',
							}}>
							Response taking too long
						</h5>
						<br />
						<ul>
							<li>
								Go through the form and check for error
								messages.
							</li>
							<li>
								Go to accounts created page to see if account
								has been created.
							</li>
							<li>Ensure you have Strong internet Connection.</li>
							<li>
								Make sure you have logged in and are able to
								view your name.
							</li>
							<li>
								Ensure the number you used to login is
								activaterd as Sales Agent.
							</li>
							<li>If issue persists, contact us.</li>
						</ul>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Button
								onClick={this.handletimeClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.accessshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							{this.state.access_error}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />

							<Button
								onClick={this.handlelogout}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							You Have Succesfully Created a Profile account
							called {this.state.business_name}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />
							<Link
								to='/'
								color='inherit'
								style={{ fontSize: '12px', color: 'white' }}>
								<Button
									style={{
										color: 'white',
										variant: 'contained',
										size: 'small',
										backgroundColor: '#29BD00',
										margin: '0px 1px 0px 0px ',
										fontSize: 15,
										height: '40px',
										fontWeight: '',
										textTransform: 'none',
										border: '1px solid #29BD00',
										borderRadius: '100px 100px 100px 100px',
										padding: '5px 3px ',
										width: '70%',
										boxShadow: '0px 3px 3px #E2E2E2',
										'&:hover': {
											backgroundColor: '#29BD00',
										},
									}}>
									Close
								</Button>
							</Link>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<br /> <br />
				<Grid item container>
					<ThemeProvider theme={theme}>
						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>

						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<div
								style={{
									margin: 0,
									minHeight: 40,
									textAlign: 'left',
									marginLeft: 20,
									marginTop: '5%',
									marginRight: 20,
									borderRadius: '10px 10px 0px 0px',
									background: [
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/reg_title_icon.svg)',
											'no-repeat',
											'top 56% left 440px',
										],

										['#29BD00'],
									],

									color: 'white',
								}}>
								<Typography
									style={{
										padding: 10,
										paddingLeft: 20,
									}}>
									Business Registered With Pacra
								</Typography>
							</div>
							<Card
								style={{
									minHeight: 0,
									border: '1px solid #E8E8E8',
									textAlign: 'left',
									borderRadius: 0,
									marginLeft: 20,
									marginRight: 20,
									boxShadow: 'none',
								}}>
								<CardContent>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}>
										{/* <form onSubmit={this.handleSubmit}> */}
										<Card
											style={{
												background: [
													[
														'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
														'no-repeat',
														'top -0% left -50%',
													],
												],

												padding: 20,
											}}>
											<br />
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}>
												<div>Company Information</div>
												<br />
											</Grid>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='business_name'
														value={
															this.state
																.business_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Business Name'
													/>
													{this.state
														.business_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.business_name_error
															}
														</span>
													)}

													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.business_name}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='bank_account_number'
														value={
															this.state
																.bank_account_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Bank Account Number'
													/>

													{this.state
														.bank_account_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.bank_account_number_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.bank_account_number}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='company_physical_address'
														value={
															this.state
																.company_physical_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Company Physical Address'
													/>

													{this.state
														.company_physical_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.company_physical_address_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.company_physical_address}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														type='email'
														name='email_address'
														error={
															this.state
																.emailError
														}
														helperText={
															this.state
																.emailHelperText
														}
														value={
															this.state
																.email_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Company Email Address'
													/>

													{this.state
														.email_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.email_address_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.email_address}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='tpin_number'
														value={
															this.state
																.tpin_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='TPIN NO.'
													/>

													{this.state
														.tpin_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.tpin_number_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.tpin_number}
                          </span> */}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														label='Upload TPIN Document'
														type='file'
														name='tpin_file'
														onBlur={
															this.handlenrcUpload
														}
														onChange={
															this
																.handleTpinUpload
														}
														value={
															this.state.tpin_file
														}
														autoFocus
														id='filled-number'
														InputLabelProps={{
															shrink: true,
														}}
													/>
													{/* <label htmlFor="contained-button-file">
                            <Button
                              style={{
                                textTransform: "none",
                                border: "1px solid #979797",

                                height: "40px",
                                padding: "0px 0px 0px 10px ",
                                background: "none",
                                marginTop: 20,
                                color: "#C0C0C0",

                                fontSize: 13,
                                fontWeight: "normal",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                boxShadow: "none",
                                "&:hover": {
                                  backgroundColor: "#d4f2cc",
                                  border: "1px solid #d4f2cc",
                                  boxShadow: "none",
                                },
                              }}
                              startIcon={
                                <CloudUploadIcon
                                  style={{ fontSize: 18, color: "#C0C0C0" }}
                                />
                              }
                              variant="contained"
                              component="span"
                            >
                              Upload TPIN Document
                            </Button>
                          </label>
                          <InputBase
                            accept="image/*"
                            style={{
                              fontSize: 10,
                              display: "none",
                              textTransform: "none",
                              paddingRight: 20,
                              opacity: 0.3,
                            }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="tpin_file"
                            onBlur={this.handlenrcUpload}
                            onChange={this.handleTpinUpload}
                            value={this.state.tpin_file}
                          /> */}
													<br />
													{this.state
														.tpin_file_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.tpin_file_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.tpin_file}
                          </span> */}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='contact_phone_number'
														error={
															this.state
																.phoneError
														}
														helperText={
															this.state
																.phoneHelperText
														}
														value={
															this.state
																.contact_phone_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Company Phone No.'
													/>

													{this.state
														.contact_phone_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.contact_phone_number_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.contact_phone_number}
                          </span> */}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='business_registeration_number'
														value={
															this.state
																.business_registeration_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Business Registration No.'
													/>

													{this.state
														.business_registeration_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.business_registeration_number_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.business_registeration_number}
                          </span> */}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														label=' Upload PACRA Document'
														type='file'
														name='pacra_document'
														onChange={
															this
																.handlepacraUpload
														}
														onBlur={
															this
																.handlepacraUpload
														}
														value={
															this.state
																.pacra_document
														}
														autoFocus
														id='filled-number'
														InputLabelProps={{
															shrink: true,
														}}
													/>
													{/* <label htmlFor="contained-button-file">
                            <Button
                              style={{
                                textTransform: "none",
                                border: "1px solid #979797",

                                height: "40px",
                                padding: "0px 0px 0px 10px ",
                                background: "none",
                                marginTop: 20,
                                color: "#C0C0C0",

                                fontSize: 13,
                                fontWeight: "normal",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                boxShadow: "none",
                                "&:hover": {
                                  backgroundColor: "#d4f2cc",
                                  border: "1px solid #d4f2cc",
                                  boxShadow: "none",
                                },
                              }}
                              startIcon={
                                <CloudUploadIcon
                                  style={{ fontSize: 18, color: "#C0C0C0" }}
                                />
                              }
                              variant="contained"
                              component="span"
                            >
                              Upload PACRA Document
                            </Button>
                          </label>
                          <InputBase
                            accept="image/*"
                            style={{
                              fontSize: 10,
                              // display: "none",
                              textTransform: "none",
                              paddingRight: 20,
                              opacity: 0.3,
                            }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="pacra_document"
                            onChange={this.handlepacraUpload}
                            onBlur={this.handlepacraUpload}
                            value={this.state.pacra_document}
                          /> */}
													<br />

													{this.state
														.pacra_document_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.pacra_document_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.pacra_document}
                          </span> */}
												</Grid>
											</Grid>
											<br />
										</Card>
										<br />
										<br />
										<Card
											style={{
												background: [
													[
														'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
														'no-repeat',
														'top -0% left -50%',
													],
												],

												padding: 20,
											}}>
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}>
												<div>
													Company Director Information
												</div>
											</Grid>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='first_name'
														value={
															this.state
																.first_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='First Name'
													/>

													{this.state
														.first_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.first_name_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.first_name}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='last_name'
														value={
															this.state.last_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Last Name'
													/>

													{this.state
														.last_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.last_name_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.last_name}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='nrc_number'
														error={
															this.state.nrcError
														}
														helperText={
															this.state
																.nrcHelperText
														}
														value={
															this.state
																.nrc_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Enter NRC NO.'
													/>

													{this.state
														.nrc_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.nrc_number_error
															}
														</span>
													)}

													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.nrc_number}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														label='Upload NRC'
														type='file'
														name='nrc_file'
														onChange={
															this.handlenrcUpload
														}
														onBlur={
															this.handlenrcUpload
														}
														value={
															this.state.nrc_file
														}
														autoFocus
														id='filled-number'
														InputLabelProps={{
															shrink: true,
														}}
													/>
													{/* <label htmlFor="contained-button-file">
                            <Button
                              style={{
                                textTransform: "none",
                                border: "1px solid #979797",

                                height: "40px",
                                padding: "0px 0px 0px 10px ",
                                background: "none",
                                marginTop: 20,
                                color: "#C0C0C0",

                                fontSize: 13,
                                fontWeight: "normal",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                boxShadow: "none",
                                "&:hover": {
                                  backgroundColor: "#d4f2cc",
                                  border: "1px solid #d4f2cc",
                                  boxShadow: "none",
                                },
                              }}
                              startIcon={
                                <CloudUploadIcon
                                  style={{ fontSize: 18, color: "#C0C0C0" }}
                                />
                              }
                              variant="contained"
                              component="span"
                            >
                              Upload NRC
                            </Button>
                          </label>
                          <InputBase
                            accept="image/*"
                            style={{
                              fontSize: 10,
                              // display: "none",
                              textTransform: "none",
                              paddingRight: 20,
                              opacity: 0.3,
                            }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="nrc_file"
                            onChange={this.handlenrcUpload}
                            onBlur={this.handlenrcUpload}
                            value={this.state.nrc_file}
                          /> */}
													<br />
													{this.state
														.nrc_file_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.nrc_file_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.nrc_file}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='phone_number'
														error={
															this.state
																.phone1Error
														}
														helperText={
															this.state
																.phone1HelperText
														}
														value={
															this.state
																.phone_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Enter Phone NO.'
													/>

													{this.state
														.phone_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.phone_number_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.phone_number}
                          </span> */}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														type='email'
														name='email'
														error={
															this.state
																.email1Error
														}
														helperText={
															this.state
																.email1HelperText
														}
														value={this.state.email}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
														label='Email Address'
													/>

													{this.state.email_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.email_error
															}
														</span>
													)}
													{/* <span style={{ fontSize: "small", color: "red" }}>
                            {this.state.email}
                          </span> */}
												</Grid>
											</Grid>
											<Hidden smUp>
												<br />
											</Hidden>
											<TextField
												variant='outlined'
												margin='normal'
												required
												multiline
												rows={4}
												fullWidth
												name='physical_address'
												value={
													this.state.physical_address
												}
												onChange={this.handleChange}
												onBlur={this.handleChange}
												label='Residential Address'
											/>

											{this.state
												.physical_address_error && (
												<span
													style={{
														fontSize: 'small',
														color: 'red',
													}}>
													{
														this.state
															.physical_address_error
													}
												</span>
											)}
											{/* <span style={{ fontSize: "small", color: "red" }}>
                        {this.state.physical_address}
                      </span> */}
											<Hidden smDown>
												<br /> <br />
											</Hidden>
										</Card>
										<span style={{ fontSize: '10px' }}>
											Note: All fields are required
										</span>
										<br />
										<Hidden smDown>
											<br />
										</Hidden>
										<Grid container spacing={0}>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
											<Grid
												item
												xs={12}
												sm={4}
												md={4}
												lg={4}
												xl={4}>
												<Button
													onClick={this.handleSubmit}
													type='submit'
													value='Submit'
													style={{
														color: 'white',
														variant: 'contained',
														size: 'small',
														backgroundColor:
															'#29BD00',
														margin: '0px 1px 0px 0px ',
														fontSize: 15,
														height: '40px',
														fontWeight: '',
														textTransform: 'none',
														textAlign: 'center',
														border: '1px solid #29BD00',
														borderRadius:
															'100px 100px 100px 100px',
														padding: '5px 3px ',
														width: '100%',
														boxShadow:
															'0px 3px 3px #E2E2E2',
														'&:hover': {
															backgroundColor:
																'#29BD00',
														},
													}}>
													{loading && (
														<>
															<Spinner
																as='span'
																animation='grow'
																size='lg'
																role='status'
																aria-hidden='true'
																style={{
																	color: '#fff',
																}}
															/>
														</>
													)}
													{!loading && (
														<span>Submit</span>
													)}
												</Button>
											</Grid>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
										</Grid>
										<br />
										{/* </form> */}
									</Grid>
								</CardContent>
							</Card>
						</Grid>

						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>
					</ThemeProvider>
				</Grid>
				<br /> <br />
			</Fragment>
		);
	}
}
PersonalBusinessRegistered.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
	PersonalBusinessRegistered
);
