import React, { Component, Fragment } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
// import './account.css';
import TextField from '@material-ui/core/TextField';

// import "./PageStyles.css";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axiosInstance from '../axiosinstance/axiosApi';

const styles = (theme) => ({
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'yellow !important',
		color: 'red',
	},
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#29BD00', //your color
		},
	},
});

class Limitedcompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			business_name: '',
			bank_account_number: '',
			email_address: '',
			contact_phone_number: '',
			mobile_money_account_number: '000',
			physical_address: '',
			organization_type: '',
			tpin_number: '',
			tpin_file: null,
			phone_number: '',
			pacra_number: '',
			incoperation_document: null,
			tpinfile: null,

			//   first director
			first_name: '',
			last_name: '',
			nrc_number: '',
			nrc_file: null,
			phone_number: '',
			physical_address1: '',
			email: '',

			//   #Director 2
			first_name2: '',
			last_name2: '',
			nrc_number2: '',
			nrc_file2: null,
			phone_number2: '',
			physical_address2: '',
			email2: '',
			show: false,
			loading: false,
			error: '',
			accessshow: false,
			timeoutshow: false,

			access_error: '',
			// error handling

			business_name_error: '',
			bank_account_number_error: '',
			email_address_error: '',
			contact_phone_number_error: '',
			mobile_money_account_number_error: '',
			physical_address_error: '',
			organization_type_error: '',
			tpin_number_error: '',
			tpin_file_error: null,
			phone_number_error: '',
			pacra_number_error: '',
			incoperation_document_error: null,
			tpinfile_error: null,

			//   first director
			first_name_error: '',
			last_name_error: '',
			nrc_number_error: '',
			nrc_file_error: null,
			phone_number_error: '',
			physical_address1_error: '',
			email_error: '',

			//   #Director 2
			first_name2_error: '',
			last_name2_error: '',
			nrc_number2_error: '',
			nrc_file2_error: null,
			phone_number2_error: '',
			physical_address2_error: '',
			email2_error: '',

			//form validation
			emailHelperText: '',
			emailError: false,

			email1HelperText: '',
			email1Error: false,

			email2HelperText: '',
			email2Error: false,

			phoneHelperText: '',
			phoneError: false,

			phone1HelperText: '',
			phone1Error: false,

			phone2HelperText: '',
			phone2Error: false,

			nrcHelperText: '',
			nrcError: false,

			nrc1HelperText: '',
			nrc1Error: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlelogout = this.handlelogout.bind(this);
	}
	registerarcerificate = null;
	incoperationdocument = null;
	tpinfile = null;
	nrcfile = null;
	nrcfile2 = null;
	// save the picked file in this
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });

		//email validation begin
		if (event.target.name === 'email_address') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						emailHelperText: 'Enter valid email',
						emailError: true,
				  })
				: this.setState({ emailHelperText: '', emailError: false });
		}

		if (event.target.name === 'email') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						email1HelperText: 'Enter valid email',
						email1Error: true,
				  })
				: this.setState({ email1HelperText: '', email1Error: false });
		}

		if (event.target.name === 'email2') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						email2HelperText: 'Enter valid email',
						email2Error: true,
				  })
				: this.setState({ email2HelperText: '', email2Error: false });
		}

		//EMAIL VALIDATION END

		//contact business phonenumber validation
		if (event.target.name === 'contact_phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phoneError: true,
						phoneHelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phoneError: false, phoneHelperText: '' });
		}

		//contact1 validation
		if (event.target.name === 'phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phone1Error: true,
						phone1HelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phone1Error: false, phone1HelperText: '' });
		}

		//contact2 validation
		if (event.target.name === 'phone_number2') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phone2Error: true,
						phone2HelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phone2Error: false, phone2HelperText: '' });
		}

		if (event.target.name === 'nrc_number') {
			!event.target.value.match(/^\d{6}\/\d{2}\/\d{1}$/)
				? this.setState({
						nrcError: true,
						nrcHelperText:
							'Enter valid nrc number with forward slashes',
				  })
				: this.setState({ nrcError: false, nrcHelperText: '' });
		}

		if (event.target.name === 'nrc_number2') {
			!event.target.value.match(/^\d{6}\/\d{2}\/\d{1}$/)
				? this.setState({
						nrc1Error: true,
						nrc1HelperText:
							'Enter valid nrc number with forward slashes',
				  })
				: this.setState({ nrc1Error: false, nrc1HelperText: '' });
		}
	}

	handlenrc2Upload = (event) => {
		event.preventDefault();
		this.nrcfile2 = event.target.files[0];
	};

	handlenrcUpload = (event) => {
		event.preventDefault();
		this.nrcfile = event.target.files[0];
	};

	handletpinUpload = (event) => {
		event.preventDefault();
		this.tpinfile = event.target.files[0];
	};

	handleincoperateUpload = (event) => {
		event.preventDefault();
		this.incoperationdocument = event.target.files[0];
	};

	async handleSubmit(event) {
		event.preventDefault();

		if (this.state.business_names == 0) {
			this.setState({
				business_name_error: ' Business Name is required',
			});
		} else if (this.state.organization_type == 0) {
			this.setState({
				organization_type_error: ' Business Type is Required',
			});
			this.setState({
				business_name_error: '',
			});
		} else if (this.state.bank_account_number == 0) {
			this.setState({
				bank_account_number_error: ' Bank Account Number is required',
			});
			this.setState({
				organization_type_error: '',
				business_name_error: '',
			});
		} else if (this.state.tpin_number == 0) {
			this.setState({
				tpin_number_error: ' TPIN No. is Required',
			});
			this.setState({
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
			});
		} else if (this.state.pacra_number == 0) {
			this.setState({
				pacra_number_error: 'Business Registration No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
			});
		} else if (this.state.contact_phone_number == 0) {
			this.setState({
				contact_phone_number_error: 'Phone No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
			});
		} else if (this.state.email_address == 0) {
			this.setState({
				email_address_error: 'Email is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
			});
		} else if (this.state.physical_address == 0) {
			this.setState({
				physical_address_error: 'Address is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
			});
		} else if (this.state.first_name == 0) {
			this.setState({
				first_name_error: 'First Name is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
			});
		} else if (this.state.last_name == 0) {
			this.setState({
				last_name_error: 'Last Name is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
			});
		} else if (this.state.nrc_number == 0) {
			this.setState({
				nrc_number_error: 'NRC No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
			});
		} else if (this.state.phone_number == 0) {
			this.setState({
				phone_number_error: 'Phone No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
			});
		} else if (this.state.email == 0) {
			this.setState({
				email_error: 'Email is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
			});
		} else if (this.state.physical_address1 == 0) {
			this.setState({
				physical_address1_error: 'Residential Address is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',
			});
		} else if (this.state.first_name2 == 0) {
			this.setState({
				first_name2_error: 'First Name is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',
				physical_address1_error: '',
			});
		} else if (this.state.last_name2 == 0) {
			this.setState({
				last_name2_error: 'Last Name is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
			});
		} else if (this.state.nrc_number2 == 0) {
			this.setState({
				nrc_number2_error: 'NRC No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
				last_name2_error: '',
			});
		} else if (this.state.phone_number2 == 0) {
			this.setState({
				phone_number2_error: 'Phone No. is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
				last_name2_error: '',
				nrc_number2_error: '',
			});
		} else if (this.state.email2 == 0) {
			this.setState({
				email2_error: 'Email is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
				last_name2_error: '',
				nrc_number2_error: '',
				phone_number2_error: '',
			});
		} else if (this.state.physical_address2 == 0) {
			this.setState({
				physical_address2_error: 'Residential Address is Required',
			});
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
				last_name2_error: '',
				nrc_number2_error: '',
				phone_number2_error: '',
			});
		} else {
			this.setState({
				tpin_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				organization_type_error: '',
				pacra_number_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				physical_address_error: '',
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				phone_number_error: '',
				email_error: '',

				physical_address1_error: '',
				first_name2_error: '',
				last_name2_error: '',
				nrc_number2_error: '',
				phone_number2_error: '',
				physical_address2_error: '',
			});

			this.setState({ loading: true });

			let data = new FormData();

			data.append('business_name', this.state.business_name);

			// upload files
			data.append('business_name', this.state.business_name);
			data.append('bank_account_number', this.state.bank_account_number);
			data.append('email_address', this.state.email_address);
			data.append(
				'contact_phone_number',
				this.state.contact_phone_number
			);
			data.append(
				'mobile_money_account_number',
				this.state.mobile_money_account_number
			);

			data.append('organization_type', this.state.organization_type);
			data.append('tpin_number', this.state.tpin_number);
			data.append('pacra_number', this.state.pacra_number);
			data.append('physical_address', this.state.physical_address);

			// first director
			data.append('first_name', this.state.first_name);
			data.append('last_name', this.state.last_name);
			data.append('nrc_number', this.state.nrc_number);
			data.append('phone_number', this.state.phone_number);
			data.append('email', this.state.email);
			data.append('physical_address1', this.state.physical_address1);

			// second director
			data.append('first_name2', this.state.first_name2);
			data.append('last_name2', this.state.last_name2);
			data.append('nrc_number2', this.state.nrc_number2);
			data.append('phone_number2', this.state.phone_number2);
			data.append('email2', this.state.email_address);
			data.append('physical_address2', this.state.physical_address2);

			// fileupload

			data.append('tpin_file', this.tpinfile);
			data.append('nrc_file', this.nrcfile);
			data.append('nrc_file2', this.nrcfile2);
			data.append('account_type', 'limited_company');
			data.append('incoperation_document', this.incoperationdocument);
			try {
				const response = await axiosInstance.post(
					'/account/limited/business/',
					data
				);
				console.log('getting response', response.data);

				if (response.data.success === 'ok') {
					this.onEkamoClick();
					this.setState({ loading: false, timeoutshow: false });

					console.log('account has been created');
				}

				if (!response.data.error == 0) {
					console.log('check the if error is present');
					this.setState({
						loading: false,
						timeoutshow: false,

						business_name_error: response.data.error.business_name,
						bank_account_number_error:
							response.data.error.bank_account_number,
						email_address_error: response.data.error.email_address,
						contact_phone_number_error:
							response.data.error.contact_phone_number,
						mobile_money_account_number_error:
							response.data.error.mobile_money_account_number,
						physical_address_error:
							response.data.error.physical_address,
						organization_type_error:
							response.data.error.organization_type,
						tpin_number_error: response.data.error.tpin_number,
						tpin_file_error: response.data.error.tpin_file,
						phone_number_error: response.data.error.phone_number,
						pacra_number_error: response.data.error.pacra_number,
						incoperation_document_error:
							response.data.error.incoperation_document,
						// tpinfile_error:  response.data.error.business_name,

						//   first director
						first_name_error: response.data.error.first_name,
						last_name_error: response.data.error.last_name,
						nrc_number_error: response.data.error.nrc_number,
						nrc_file_error: response.data.error.nrc_file,
						phone_number_error: response.data.error.phone_number,
						physical_address1_error:
							response.data.error.physical_address1,
						email_error: response.data.error.email,

						//   #Director 2
						first_name2_error: response.data.error.first_name2,
						last_name2_error: response.data.error.last_name2,
						nrc_number2_error: response.data.error.nrc_number2,
						nrc_file2_error: response.data.error.nrc_file2,
						phone_number2_error: response.data.error.phone_number2,
						physical_address2_error:
							response.data.error.physical_address2,
						email2_error: response.data.error.email2,
					});
				}

				if (
					response.data.error ===
					'You can not access this area, you are not a sales agent'
				) {
					this.setState({
						access_error: response.data.error,
						timeoutshow: false,
					});

					this.accesserrorclick();
				}

				if (response.data.error === 'ok') {
					this.setState({
						loading: false,
						timeoutshow: false,

						error: response.data.message,
					});
					this.notify();
				}
			} catch (error) {
				// console.log(error);

				this.setState({ loading: false });
				this.setState({
					errors: error,
					timeoutshow: false,
				});
			}
			setTimeout(() => {
				this.setState({
					loading: false,
					timeoutshow: true,
				});
			}, 60000);
		}
	}

	async handlelogout(event) {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	}

	accesserrorclick = () => {
		this.setState({ accessshow: true });
	};

	onEkamoClick = () => {
		this.setState({ show: true });
	};

	notify = () => {
		toast.error(this.state.error, {
			position: 'top-center',
			autoClose: 400000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	handletimeClose = () => {
		this.setState({ timeoutshow: false });
	};

	render() {
		const { loading } = this.state;
		return (
			<Fragment>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Modal
					scrollable={true}
					show={this.state.timeoutshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<br />
						<h5
							style={{
								fontWeight: 'bold',
								color: 'red',
								textAlign: 'center',
							}}>
							Response taking too long
						</h5>
						<br />
						<ul>
							<li>
								Go through the form and check for error
								messages.
							</li>
							<li>
								Go to accounts created page to see if account
								has been created.
							</li>
							<li>Ensure you have Strong internet Connection.</li>
							<li>
								Make sure you have logged in and are able to
								view your name.
							</li>
							<li>
								Ensure the number you used to login is
								activaterd as Sales Agent.
							</li>
							<li>If issue persists, contact us.</li>
						</ul>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Button
								onClick={this.handletimeClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.accessshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							{this.state.access_error}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />

							<Button
								onClick={this.handlelogout}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							You Have Succesfully Created a Profile account
							called
							{this.state.business_name}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />
							<Link
								to='/'
								color='inherit'
								style={{ fontSize: '12px', color: 'white' }}>
								<Button
									style={{
										color: 'white',
										variant: 'contained',
										size: 'small',
										backgroundColor: '#29BD00',
										margin: '0px 1px 0px 0px ',
										fontSize: 15,
										height: '40px',
										fontWeight: '',
										textTransform: 'none',
										border: '1px solid #29BD00',
										borderRadius: '100px 100px 100px 100px',
										padding: '5px 3px ',
										width: '70%',
										boxShadow: '0px 3px 3px #E2E2E2',
										'&:hover': {
											backgroundColor: '#29BD00',
										},
									}}>
									Close
								</Button>
							</Link>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<br /> <br />
				<Grid item container>
					<ThemeProvider theme={theme}>
						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>

						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<div
								style={{
									margin: 0,
									minHeight: 40,
									textAlign: 'left',
									marginLeft: 20,
									marginTop: '10%',
									marginRight: 20,
									borderRadius: '10px 10px 0px 0px',
									background: [
										// add background image
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/reg_title_icon.svg)',
											'no-repeat',
											'top 56% left 440px',
										],

										['#29BD00'],
									],
									//background:
									color: 'white',
								}}>
								<Typography
									style={{
										padding: 10,
										paddingLeft: 20,
									}}>
									Limited Company
								</Typography>
							</div>
							<Card
								style={{
									minHeight: 0,
									border: '1px solid #E8E8E8',
									textAlign: 'left',
									borderRadius: 0,
									marginLeft: 20,
									marginRight: 20,
									boxShadow: 'none',
								}}>
								<CardContent>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}>
										<form onSubmit={this.handleSubmit}>
											<Card
												style={{
													background: [
														// add background image
														[
															'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
															'no-repeat',
															'top -0% left -50%',
														],
													],

													padding: 20,
												}}>
												<br />
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													xl={12}>
													<div>
														Company Information
													</div>
												</Grid>
												<Grid container spacing={2}>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='business_name'
															value={
																this.state
																	.business_name
															}
															label='Business Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.business_name_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.business_name_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														{/* organization_type strt */}
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='organization_type'
															value={
																this.state
																	.organization_type
															}
															label='Business Category'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
														{/* organization_type end */}

														{this.state
															.organization_type_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.organization_type_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='bank_account_number'
															value={
																this.state
																	.bank_account_number
															}
															label='Bank Account Number'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
														{this.state
															.bank_account_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.bank_account_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='tpin_number'
															value={
																this.state
																	.tpin_number
															}
															label='TPIN No. Number'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
														{this.state
															.tpin_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.tpin_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='tpin_file'
															label=' TPIN Certificate'
															type='file'
															value={
																this.state
																	.tpin_file
															}
															onChange={
																this
																	.handletpinUpload
															}
															onBlur={
																this
																	.handletpinUpload
															}
															autoFocus
															id='filled-number'
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{this.state
															.tpin_file_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.tpin_file_error
																}
															</span>
														)}
														{/* <div
                              style={{
                                border: "1px solid #97979796",
                                // borderRadius: theme.shape.borderRadius,
                                height: "54px",
                                padding: "0px 0px 0px 10px ",
                                backgroundColor: "none",
                                marginTop: 20,
                                fontSize: 14,
                                width: "100%",
                              }}
                            >
                              <span></span>

                              <InputBase
                                accept="image/*"
                                style={{
                                  fontSize: 10,

                                  textTransform: "none",
                                  paddingRight: 20,
                                  opacity: 0.3,
                                }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="tpin_file"
                                onChange={this.handletpinUpload}
                                value={this.state.tpin_file}
                              />
                            </div> */}
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='pacra_number'
															value={
																this.state
																	.pacra_number
															}
															label='Business Registration No.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
														{this.state
															.pacra_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.pacra_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															label=' Document Of Incorporation'
															autoFocus
															type='file'
															name='incoperation_document'
															onChange={
																this
																	.handleincoperateUpload
															}
															value={
																this.state
																	.incoperation_document
															}
															id='filled-number'
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{this.state
															.incoperation_document_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.incoperation_document_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='contact_phone_number'
															error={
																this.state
																	.phoneError
															}
															helperText={
																this.state
																	.phoneHelperText
															}
															value={
																this.state
																	.contact_phone_number
															}
															label='Company Phone No.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.contact_phone_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.contact_phone_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															type='email'
															name='email_address'
															error={
																this.state
																	.emailError
															}
															helperText={
																this.state
																	.emailHelperText
															}
															value={
																this.state
																	.email_address
															}
															label='Company Email Address'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.email_address_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.email_address_error
																}
															</span>
														)}
													</Grid>
												</Grid>
												<br />
												<TextField
													variant='outlined'
													margin='normal'
													multiline
													rows={4}
													fullWidth
													name='physical_address'
													value={
														this.state
															.physical_address
													}
													label='Company Physical Addresss'
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>
												{this.state
													.physical_address_error && (
													<span
														style={{
															fontSize: 'small',
															color: 'red',
														}}>
														{
															this.state
																.physical_address_error
														}
													</span>
												)}
												<br /> <br />
											</Card>
											<br />
											<br />
											<Card
												style={{
													background: [
														// add background image
														[
															'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
															'no-repeat',
															'top -0% left -50%',
														],
													],

													padding: 20,
												}}>
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													xl={12}>
													<div>
														Company Director 1
													</div>
												</Grid>
												<Grid container spacing={2}>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='first_name'
															value={
																this.state
																	.first_name
															}
															label='First Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.first_name_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.first_name_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='last_name'
															value={
																this.state
																	.last_name
															}
															label='Last Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.last_name_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.last_name_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='nrc_number'
															error={
																this.state
																	.nrcError
															}
															helperText={
																this.state
																	.nrcHelperText
															}
															value={
																this.state
																	.nrc_number
															}
															label='Enter NRC NO.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.nrc_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.nrc_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															label='Upload NRC'
															autoFocus
															type='file'
															name='nrc_file'
															onChange={
																this
																	.handlenrcUpload
															}
															value={
																this.state
																	.nrc_file
															}
															id='filled-number'
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{this.state
															.nrc_file_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.nrc_file_error
																}
															</span>
														)}
														{/* <div
                              style={{
                                border: "1px solid #97979796",
                                // borderRadius: theme.shape.borderRadius,
                                height: "54px",
                                padding: "0px 0px 0px 10px ",
                                backgroundColor: "none",
                                marginTop: 20,
                                fontSize: 14,
                                width: "100%",
                              }}
                            >
                              <span> Upload NRC</span>
                              <InputBase
                                accept="image/*"
                                style={{
                                  fontSize: 10,

                                  textTransform: "none",
                                  paddingRight: 20,
                                  opacity: 0.3,
                                }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="nrc_file"
                                onChange={this.handlenrcUpload}
                                value={this.state.nrc_file}
                              />
                            </div> */}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='phone_number'
															error={
																this.state
																	.phone1Error
															}
															helperText={
																this.state
																	.phone1HelperText
															}
															value={
																this.state
																	.phone_number
															}
															label='Phone No. '
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.phone_number_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.phone_number_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															type='email'
															name='email'
															error={
																this.state
																	.email1Error
															}
															helperText={
																this.state
																	.email1HelperText
															}
															value={
																this.state.email
															}
															label='Email Address '
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.email_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.email_error
																}
															</span>
														)}
													</Grid>
												</Grid>
												<Hidden smUp>
													<br />
												</Hidden>
												<TextField
													variant='outlined'
													margin='normal'
													multiline
													rows={4}
													fullWidth
													name='physical_address1'
													value={
														this.state
															.physical_address1
													}
													label='Residential Address '
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>

												{this.state
													.physical_address1_error && (
													<span
														style={{
															fontSize: 'small',
															color: 'red',
														}}>
														{
															this.state
																.physical_address1_error
														}
													</span>
												)}

												<Hidden smDown>
													<br /> <br />
												</Hidden>
											</Card>
											<br />
											<Hidden smDown>
												<br />
											</Hidden>
											<Hidden smUp>
												<br />
											</Hidden>
											<Card
												style={{
													background: [
														// add background image
														[
															'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
															'no-repeat',
															'top -0% left -50%',
														],
													],

													padding: 20,
												}}>
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													xl={12}>
													<div>
														Company Director 2
													</div>
												</Grid>
												<Grid container spacing={2}>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='first_name2'
															value={
																this.state
																	.first_name2
															}
															label='First Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.first_name2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.first_name2_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='last_name2'
															value={
																this.state
																	.last_name2
															}
															label='Last Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.last_name2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.last_name2_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='nrc_number2'
															error={
																this.state
																	.nrc1Error
															}
															helperText={
																this.state
																	.nrc1HelperText
															}
															value={
																this.state
																	.nrc_number2
															}
															label='Enter NRC NO.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.nrc_number2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.nrc_number2_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															label='Upload NRC'
															autoFocus
															type='file'
															name='nrc_file2'
															onChange={
																this
																	.handlenrc2Upload
															}
															value={
																this.state
																	.nrc_file2
															}
															id='filled-number'
															InputLabelProps={{
																shrink: true,
															}}
														/>
														{this.state
															.nrc_file2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.nrc_file2_error
																}
															</span>
														)}
														{/* <div
                              style={{
                                border: "1px solid #97979796",
                                // borderRadius: theme.shape.borderRadius,
                                height: "54px",
                                padding: "0px 0px 0px 10px ",
                                backgroundColor: "none",
                                marginTop: 20,
                                fontSize: 14,
                                width: "100%",
                              }}
                            >
                              <span> Upload NRC</span>
                              <InputBase
                                accept="image/*"
                                style={{
                                  fontSize: 10,

                                  textTransform: "none",
                                  paddingRight: 20,
                                  opacity: 0.3,
                                }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="nrc_file2"
                                onChange={this.handlenrc2Upload}
                                value={this.state.nrc_file2}
                              />
                            </div> */}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='phone_number2'
															error={
																this.state
																	.phone2Error
															}
															helperText={
																this.state
																	.phone2HelperText
															}
															value={
																this.state
																	.phone_number2
															}
															label='Phone No.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.phone_number2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.phone_number2_error
																}
															</span>
														)}
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															type='email'
															name='email2'
															error={
																this.state
																	.email2Error
															}
															helperText={
																this.state
																	.email2HelperText
															}
															value={
																this.state
																	.email2
															}
															label='Email Address'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														{this.state
															.email2_error && (
															<span
																style={{
																	fontSize:
																		'small',
																	color: 'red',
																}}>
																{
																	this.state
																		.email2_error
																}
															</span>
														)}
													</Grid>
												</Grid>
												<Hidden smUp>
													<br />
												</Hidden>
												<TextField
													variant='outlined'
													margin='normal'
													multiline
													rows={4}
													fullWidth
													name='physical_address2'
													value={
														this.state
															.physical_address2
													}
													label='Residential Address'
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>

												{this.state
													.physical_address2_error && (
													<span
														style={{
															fontSize: 'small',
															color: 'red',
														}}>
														{
															this.state
																.physical_address2_error
														}
													</span>
												)}

												<Hidden smDown>
													<br /> <br />
												</Hidden>
											</Card>
											<br />
											<Hidden smDown>
												<br />
											</Hidden>

											<Grid container spacing={0}>
												<Grid
													item
													xs={0}
													sm={4}
													md={4}
													lg={4}
													xl={4}
												/>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<Button
														style={{
															color: 'white',
															variant:
																'contained',
															size: 'small',

															background:
																'linear-gradient(45deg, #29BD00 30%, #25A203 90%)',
															margin: '15px 1px 0px 0px ',
															fontSize: 15,
															height: '54px',
															fontWeight: '',
															textTransform:
																'none',
															border: 0,
															boxShadow:
																'0 3px 5px 2px rgba(41, 189, 0, .3)',
															borderRadius:
																'100px 100px 100px 100px',
															padding: '5px 3px ',
															width: '100%',

															'&:hover': {
																backgroundColor:
																	'#29BD00',
															},
														}}
														type='submit'>
														{loading && (
															<>
																<Spinner
																	as='span'
																	animation='grow'
																	size='lg'
																	role='status'
																	aria-hidden='true'
																	style={{
																		color: '#fff',
																	}}
																/>
															</>
														)}
														{!loading && (
															<span>Submit</span>
														)}
													</Button>
												</Grid>
												<Grid
													item
													xs={0}
													sm={4}
													md={4}
													lg={4}
													xl={4}
												/>
											</Grid>
											<br />
										</form>
									</Grid>
								</CardContent>
							</Card>
						</Grid>

						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>
					</ThemeProvider>
				</Grid>
				<br /> <br />
			</Fragment>
		);
	}
}

Limitedcompany.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Limitedcompany);
