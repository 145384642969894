import React, { Component, useState } from "react";
import axiosInstance from "../axiosinstance/axiosApi";


import Moment from "moment";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Modal,
  Col,
  Row,
  Container,
  Form,
  Carousel,
  Collapse,
} from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Ticketpin from "./ticketpin";
import Selforother from "./selforother";
import "./bus.css";
import Spinner from "react-bootstrap/Spinner";
// import DatePicker from "react-datepicker";
import MainWrapper from "./mainwrapper";
import Cookies from "js-cookie";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
// import Moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers"; //import datepicker provider
import { KeyboardDatePicker } from "@material-ui/pickers"; //import datepicker
import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { GoCalendar } from "react-icons/go";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export class buslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem("access_token") ? true : false,
      tickets: [],
      ticketname: "",
      tickettime: "",
      balance:"",
      errormessage: [],
      isLoading: true,
      errors: null,
      loading: false,

      showHide: false,
      busTicketObject: "",
      bus_name: "",
      bus: null,
      loadingerror: "",

      form: {
        from_location: "",
        to_location: "",
        departure_date: "",
      },
      formErrors: {
        from_location: null,
        to_location: null,
        departure_date: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getbalance = this.getbalance.bind(this);
    this.sendData = this.sendData.bind(this);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
  }

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    // handle change event except language field
    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "to_location":
        if (!value) errorMsg = "Please Enter Location.";
        break;

      case "from_location":
        if (!value) errorMsg = "Please enter Location.";
        break;

      case "departure_date":
        if (!value) errorMsg = "Please enter Date.";
        break;

      default:
        break;
    }
    return errorMsg;
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit() {
    // this.setState({ loading: true });

    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }
    // console.log("check if the date is correct", form.departure_date);
    this.sendData();
  }

  async sendData() {
    this.setState({ loading: true });
    const { form } = this.state;
    Cookies.set(`date`, {
      departure_date: form.departure_date,
    });
    let timeout1 = setTimeout(() => {
      this.setState({
        loading: false,
        // loadingerror: [
        //   "Network Error, Please Select Your Options and Try Again",
        // ],
      });
    }, 8000);

    // console.log("send to data server");
    try {
      const response = await axiosInstance.get(
        `/bus/tickets/list/${form.from_location}/${form.to_location}/${form.departure_date}/`
      );

      this.setState({
        tickets: response.data.results,
        errormessage: response.data.error,
      });

      this.setState({ loading: false });
      // console.log("check data", response.data.results);
      // console.log("check errors", response.data.error);

      if (response.data.success === "ok") {
        this.handleModalShowHide();
        clearTimeout(timeout1);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }

      return response;
    } catch (error) {
      // console.log(error.stack);
      clearTimeout(this.timeout1);
      this.setState({
        errors: error.response,
        loading: false,
        loadingerror: [" Error, Please Select Your Options and Try Again"],
      });
      clearTimeout(this.timeout1);
    }
    // this.gotoDetails();
  }

  pinModalRef = ({ handlePinShow }) => {
    this.showModal = handlePinShow;
  };

  onSuccessClick = () => {
    this.showModal();
  };

  componentDidMount() {
    // this.getBuslist()
    this.handleModalShowHide();
    const refreshToken = localStorage.getItem("refresh_token");
    this.getbalance()
  }

  getbalance(){
    
    const userdetails = JSON.parse(Cookies.get("userdetails"));
    console.log("use this to check the user balance",userdetails)
    this.setState({ balance:userdetails.balance });

  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  onchange = (e) => {
    this.setState({ tickettime: e.target.value });
  };
  ontochange = (e) => {
    this.setState({ ticketname: e.target.value });
  };

  ticketBuy(value, e) {
      let ExpireIn30Minutes = 1 / 48;
      Cookies.set("bus", value, {
        expires: ExpireIn30Minutes,
      });
      this.onSelfOtherClick();
  }

  // gotoOptions() {
  //   window.location.href = "/selforother";
  // }

  gotohome() {
    window.location.href = "/";
  }

  selfotherModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onSelfOtherClick = () => {
    this.showModal();
  };

  render() {
    const momo = require("moment");
    const {
      isLoading,
      tickets,
      tickettime,
      ticketname,
      loading,
      form,
      formErrors,
    } = this.state;
    const arrow = "/images/arrow.svg"; //get  Icon
    const arrowsmall = "/images/arrowSmallest.svg"; //get  Icon

    const calender = <GoCalendar />;

    const filteredBuses = this.state.tickets.filter((busticket) => {
      return (
        busticket.departure_time
          .toLowerCase()
          .indexOf(tickettime.toLowerCase()) !== -1 &&
        busticket.bus_name.toLowerCase().indexOf(ticketname.toLowerCase()) !==
          -1
      );
    });

    return (
      <React.Fragment>
        		<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Selforother ref={this.selfotherModalRef}></Selforother>
        <MainWrapper className="explore-body">
          <Modal
            size="md"
            // size="lg"
            centered
            show={this.state.showHide}
            className="my-modal"
            style={{ background: "#4a4a4a" }}
          >
            <Modal.Header
              style={{
                backgroundColor: "#fff",

                textAlign: "center",
              }}
              // closeButton
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Search Available Bus
              </h4>
            </div>
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container direction="column">
                <Grid item container>
                  <Grid item xs={1} sm={2} md={2} lg={12} xl={4} />
                  <Grid
                    item
                    container
                    spacing={0}
                    xs={10}
                    sm={8}
                    md={8}
                    lg={12}
                    xl={4}
                    style={{
                      position: "relative",
                      width: "",
                      borderRadius: "10px 10px 10px 10px ",
                      //  backgroundColor: theme.palette.background.paper,
                      //border: '2px solid #C8C8C8',
                      //  boxShadow: theme.shadows[5],
                      // padding: theme.spacing(2, 12, 8),
                      textAlign: "center",
                    }}
                  >
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <FormControl style={{ width: "100%" }}>
                        <select
                          name="from_location"
                          onBlur={this.handleChange}
                          onChange={this.handleChange || null}
                          value={form.from_location}
                          style={{
                            border: "2px solid #29BD00",
                            // borderRadius: "12px",
                            height: "40px",
                            padding: "0px 0px 0px 10px ",
                            backgroundColor: "white",
                            margin: "0px 2px 0px 0px ",
                            fontSize: 14,
                            width: "100%",
                          }}
                        >
                          <option value="">From</option>
                          <option value="Lusaka">Lusaka</option>
                        </select>

                        {formErrors.from_location && (
                          <span style={{ fontSize: "small", color: "red" }}>
                            {formErrors.from_location}
                          </span>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div style={{ fontSize: "15px", paddingTop: "8px" }}>
                        -
                      </div>
                    </Grid>

                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <FormControl style={{ width: "100%" }}>
                        <select
                          name="to_location"
                          onBlur={this.handleChange}
                          onChange={this.handleChange || null}
                          value={form.to_location}
                          style={{
                            border: "2px solid #29BD00",
                            // borderRadius: "12px",
                            height: "40px",
                            padding: "0px 0px 0px 10px ",
                            backgroundColor: "white",
                            margin: "0px 2px 0px 0px ",
                            fontSize: 14,
                            width: "100%",
                          }}
                        >
                          <option value="">To</option>
                          {/* <option value="Lusaka">Lusaka</option> */}
                          <option value="Kasama">Kasama</option>
                          <option value="Nakonde">Nakonde</option>
                          <option value="Livingstone">Livingstone </option>
                          <option value="Kitwe">Kitwe</option>
                          <option value="Mbala">Mbala</option>
                          <option value="Kabwe">Kabwe</option>
                          <option value="Mongu">Mongu</option>
                          <option value="Chipata">Chipata</option>

                          <option value="Ndola">Ndola</option>
                          <option value="Solwezi">Solwezi</option>
                          <option value="Mumbwa">Mumbwa</option>
                          <option value="HookBridge">Hook Bridge</option>
                          <option value="TBZ">TBZ</option>
                          <option value="MunkuyeSipopa">Munkuye Sipopa</option>
                          <option value="Longe">Longe</option>
                          <option value="Kaoma">Kaoma</option>
                          <option value="Namasheshe">Namasheshe</option>
                          <option value="Katunda">Katunda</option>
                          <option value="Burton">Burton</option>
                          <option value="Lealui">Lea lui</option>
                          <option value="FishControl">Fish Control</option>
                          <option value="Kaugeta">Kaugeta</option>
                          <option value="Looma">Looma</option>
                          <option value="Sefula">Sefula</option>
                          <option value="Liyangati">Liyangati</option>
                          <option value="Namatombo">Namatombo</option>
                          <option value="Litoya">Litoya</option>
                          <option value="Siyanda">Siyanda</option>
                          <option value="Nande">Nande</option>
                          <option value="Senanga">Senanga</option>
                          <option value="Kalabo">Kalabo</option>
                          <option value="Kaulembe">Kaulembe</option>
                          <option value="Katete">Katete</option>
                          <option value="Sinda">Sinda</option>
                          <option value="Petauke">Petauke</option>
                          <option value="Nyimba">Nyimba</option>
                          <option value="Luangwa">Luangwa</option>
                          <option value="Lilongwe">Lilongwe</option>
                          <option value="Chama">Chama</option>
                          <option value="Muyombe">Muyombe</option>
                          <option value="Kalovya">Kalovya</option>
                          <option value="Eggichiken">Eggichiken</option>
                          <option value="Hoya">Hoya</option>
                          <option value="Lundazi">Lundazi</option>
                          <option value="Lumezi">Lumezi</option>

                          <option value="KapiriMposhi">Kapiri Mposhi</option>

                          <option value="Luanshya">Luanshya</option>
                          <option value="Mpongwe">Mpongwe</option>
                          <option value="Mufulira">Mufulira</option>
                          <option value="Kasumbalesa">Kasumbalesa</option>
                          <option value="Chililabombwe">Chililabombwe</option>
                          <option value="Chingola">Chingola</option>
                          <option value="Mwinilunga">Mwinilunga</option>
                          <option value="LumwanaWest">Lumwana West</option>
                          <option value="Kisasa">Kisasa</option>
                          <option value="Kalumbila">Kalumbila</option>
                          <option value="LumwanaEast">Lumwana East</option>
                          <option value="Manyama">Manyama</option>
                          <option value="R.ONE">R.ONE</option>
                          <option value="Maheba">Maheba</option>
                          <option value="Junction">Junction</option>
                          <option value="Mutanda">Mutanda</option>
                          <option value="Solwezi">Solwezi</option>
                          <option value="Chavuma">Chavuma</option>
                          <option value="Zambezi">Zambezi</option>
                          <option value="Kabompo">Kabompo</option>
                          <option value="Manyinga">Manyinga</option>
                          <option value="Mufumbwe">Mufumbwe</option>
                          <option value="Kasempa">Kasempa</option>
                          <option value="Mpulungu">Mpulungu</option>
                          <option value="Mbala">Mbala</option>
                          <option value="SengaHill">Senga Hill</option>
                          <option value="Nseluka">Nseluka</option>
                          <option value="Kasama">Kasama</option>
                          <option value="Mpika">Mpika</option>
                          <option value="Mununga">Mununga</option>
                          <option value="Mukanda">Mukanda</option>
                          <option value="Serenje">Serenje</option>
                          <option value="Mkushi">Mkushi</option>
                          <option value="Luwingu">Luwingu</option>
                          <option value="Lunte">Lunte</option>
                          <option value="Lubushi">Lubushi</option>
                          <option value="Mumana">Mumana</option>
                          <option value="ChintaShika">Chinta Shika</option>
                          <option value="Chambeshi">Chambeshi</option>
                          <option value="Mpepo">Mpepo</option>
                          <option value="Isoka">Isoka</option>
                          <option value="Chinsali">Chinsali</option>
                          <option value="Shiwangandu">Shiwangandu</option>
                          <option value="Matumbo">Matumbo</option>
                          <option value="Chitambo">Chitambo</option>
                          <option value="Chansa">Chansa</option>
                          <option value="Kashikishi">Kashikishi</option>
                          <option value="Mbeleshi">Mbeleshi</option>
                          <option value="Kazembe">Kazembe</option>
                          <option value="Lukwesa">Lukwesa</option>
                          <option value="Mwense">Mwense</option>
                          <option value="Mulundu">Mulundu</option>
                          <option value="MusondaFalls">Musonda Falls</option>
                          <option value="MansaZNS">Mansa ZNS</option>
                          <option value="Samfya">Samfya</option>
                          <option value="Tutu">Tutu</option>
                          <option value="Mukando">Mukando </option>
                          <option value="Kawambwa">Kawambwa </option>
                          <option value="Mansa">Mansa </option>
                          <option value="Zimba">Zimba </option>
                          <option value="Kalomo">Kalomo </option>
                          <option value="Choma">Choma </option>
                          <option value="Batoka">Batoka </option>
                          <option value="Muzuka">Muzuka </option>
                          <option value="Pemba">Pemba </option>
                          <option value="Chisekesi">Chisekesi </option>
                          <option value="Monze">Monze </option>
                          <option value="Mazabuka">Mazabuka </option>
                          <option value="Sesheke">Sesheke </option>
                          <option value="Kazungula">Kazungula </option>
                          <option value="Mwandi">Mwandi </option>
                        </select>

                        {formErrors.to_location && (
                          <span style={{ fontSize: "small", color: "red" }}>
                            {formErrors.to_location}
                          </span>
                        )}
                      </FormControl>
                    </Grid>

                    {/*Start MUI DatePicker */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      <Card>
                        <CardContent>
                          <input
                            required
                            placeholder="Select Date..."
                            class="textbox-n"
                            type="date"
                            id="date"
                            name="departure_date"
                            onBlur={this.handleChange}
                            onChange={this.handleChange || null}
                            value={form.departure_date}
                            style={{
                              border: "0px solid #32c709",
                              // width: "0px",
                              borderRadius: "12px",
                              // height: "40px",
                              // padding: "0px 0px 0px 10px ",
                              backgroundColor: "white",
                              // margin: "0px 2px 0px 0px ",
                              fontSize: 14,
                            }}
                          />
                        </CardContent>
                      </Card>
                      {formErrors.departure_date && (
                        <span style={{ fontSize: "small", color: "red" }}>
                          {formErrors.departure_date}
                        </span>
                      )}
                    </Grid>
                    {/*End MUI DatePicker */}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        background: [
                          //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                          [
                            "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                            "no-repeat",
                            "top 230% left 100%",
                          ],
                          //['white']
                        ],
                      }}
                    >
                      <br />
                      <span
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        {this.state.loadingerror}
                      </span>

                      {loading && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            style={{ color: "#29BD00" }}
                          />
                          <span style={{ color: "#29BD00" }}>
                            Processing...
                          </span>
                        </div>
                      )}

                      {!loading && (
                        <div>
                          <Button
                            onClick={() => this.handleSubmit()}
                            style={{
                              color: "white",
                              width: "99%",
                              border: "1px solid #29BD00",
                              height: "40px",
                              margin: "0px 1px 0px 0px",
                              padding: "5px 3px",
                              variant: "contained",
                              fontSize: "15px",
                              // boxshadow: "0px 3px 3px #E2E2E2",

                              borderRadius: "100px 100px 100px 100px",
                              display: "block",
                              marginRight: "auto",
                              marginLeft: "auto",
                              background: "#29BD00",
                              // width: "70%",
                            }}
                          >
                            <SearchIcon
                              style={{ marginRight: "10px", fontSize: "15px" }}
                            />
                            Search
                          </Button>
                        </div>
                      )}
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <br />
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "11px",
                          color: "#646464",
                        }}
                      >
                        Please Select Point Of Departure, Final Destination,
                        Departure Date and Tap Search To Check Available Buses
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sm={2} md={2} lg={4} xl={4} />
                </Grid>
              </Grid>
            </Modal.Body>

            <br />
          </Modal>

          <h4 style={{ textAlign: "center", marginTop: "-50px" }}>Filter By</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={1} lg={6} sm={4} md={5}>
              <Grid item xs={4} sm={4} lg={4}>
                <select
                  value={this.state.tickettime || ""}
                  onChange={this.onchange || null}
                  style={{
                    border: "2px solid #29BD00",
                    // borderRadius: "12px",
                    height: "40px",
                    padding: "0px 0px 0px 10px ",
                    backgroundColor: "white",
                    margin: "0px 2px 0px 0px ",
                    borderRadius: "25px",
                    fontSize: 14,
                    width: "100%",
                    color: "#29BD00",
                    textAlign: "center",
                  }}
                >
                  <option isdisabled="true" value="">
                    Departure Time
                  </option>

                  <option value="05:00:00">05:00</option>
                  <option value="06:00:00">06:00</option>
                  <option value="07:00:00">07:00</option>
                  <option value="08:00:00">08:00</option>
                  <option value="09:00:00">09:00</option>
                  <option value="10:00:00">10:00</option>
                  <option value="11:00:00">11:00</option>
                  <option value="12:00:00">12:00</option>
                  <option value="13:00:00">13:00</option>
                  <option value="14:00:00">14:00</option>
                  <option value="">Reset Time</option>
                </select>
              </Grid>

              <Grid item xs={4} sm={4} lg={4}>
                <input
                  id="inputID"
                  value={this.state.ticketname || ""}
                  onChange={this.ontochange || null}
                  type="text"
                  placeholder="Bus Name"
                  style={{
                    border: "2px solid #29BD00",
                    // borderRadius: "12px",
                    height: "40px",
                    padding: "0px 0px 0px 10px ",
                    backgroundColor: "white",
                    margin: "0px 2px 0px 0px ",
                    fontSize: 14,
                    width: "100%",
                    borderRadius: "25px",
                    color: "#29BD00",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={4}>
                <Button
                  style={{
                    border: "2px solid #29BD00",
                    // borderRadius: "12px",
                    height: "40px",
                    padding: "0px 0px 0px 10px ",
                    backgroundColor: "white",
                    margin: "0px 2px 0px 0px ",
                    fontSize: 14,
                    width: "100%",
                    borderRadius: "25px",
                  }}
                  onClick={this.handleModalShowHide}
                >
                  <span
                    style={{
                      color: "#29BD00",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    Search Again
                  </span>
                </Button>
              </Grid>
            </Grid>
          </div>

          <br />

          {/* <h6
            style={{
              fontWeight: "bolder",

              textAlign: "center",
            }}
          >
            Available Buses
          </h6> */}
          {this.state.errormessage && (
            <div>
              <h3 style={{ textAlign: "center" }}>
                Click Below To Search Again
              </h3>
              <br />
              <h3 style={{ textAlign: "center" }}>
                {this.state.errormessage}
                <br />
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    background: "#32c709",
                    height: "32px",
                  }}
                  onClick={this.handleModalShowHide}
                >
                  <span
                    style={{
                      fontWeight: "bolder",
                      color: "white",
                      fontSize: "11px",
                      textAlignL: "center",
                    }}
                  >
                    Search Bus
                  </span>
                </Button>
              </div>
            </div>
          )}
          <div
            className="explore-card-columns  container-fluid"
            style={{ marginBottom: "100px" }}
          >
            {filteredBuses.map((busticket, i) => (
              <div>
                <div className="card" key={busticket.id}>
                  <div
                    style={{
                      margin: "0px",

                      textAlign: "left",

                      marginRight: "10px",

                      borderRadius: "10px 10px 0px 0px",
                      background: [["#29BD00"]],
                      color: "white",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "20px",
                        padding: "0px",
                        marginLeft: "12px",
                      }}
                    >
                      {busticket.bus_name}
                      <span style={{ marginLeft: "15%" }}>
                        {busticket.bus_position_order}
                      </span>
                    </Typography>
                  </div>
                  <Card
                    style={{
                      border: "1px solid #29BD00",
                      borderRadius: "0px 0px 10px 10px",
                      marginRight: "10px",
                      textAlign: "left",
                      wordWrap: "break-word",

                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "16px",
                            paddingBottom: "10px",

                            paddingLeft: "20px",
                          }}
                        >
                          {busticket.from_location}
                        </Grid>
                        <Grid>
                          <img alt="arrow" src={arrow} />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "16px",
                            paddingBottom: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          {busticket.to_location}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          item
                          style={{
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          Boarding Time:
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                        <Grid
                          style={{
                            fontWeight: "bolder",
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          {busticket.boarding_time}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{
                            // fontWeight: "bolder",
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          Departure Time:
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          {busticket.departure_time}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{
                            // fontWeight: "bolder",
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          Price:
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "12px",
                            paddingBottom: "10px",
                          }}
                        >
                          K{busticket.price}.00
                        </Grid>
                      </Grid>

                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ fontSize: "11px" }}
                            >
                              Departure Date
                            </Grid>

                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "22px",
                                }}
                              >
                                {form.departure_date}
                              </span>
                              {/* Sep 2020 */}
                              <br />
                              {/* <span style={{ fontSize: "11px" }}>
                                Wednesday
                              </span> */}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              style={{
                                fontSize: "22px",

                                color: "#373435",
                              }}
                            >
                              {calender}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      {this.state.logged_in ? (
                        <div>
                          {/* <Link
                            to="/selforother"
                            type="submit"
                            value="submit"
                            style={{
                              textDecoration: "none",
                              borderColor: "red",
                            }}
                          > */}
                          <Button
                            onClick={this.gotoOptions}
                            style={{
                              color: "white",
                              variant: "contained",
                              size: "small",
                              backgroundColor: "#29BD00",
                              margin: "0px 1px 0px 0px ",
                              marginLeft: "12%",
                              fontSize: "22px",
                              height: "50px",
                              fontWeight: "bolder",
                              textTransform: "none",
                              border: "1px solid #29BD00",
                              borderRadius: "100px 100px 100px 100px",
                              padding: "5px 3px ",
                              width: "200px",
                              boxShadow: "0px 3px 3px #E2E2E2",
                              "&:hover": {
                                backgroundColor: "#29BD00",
                              },

                              marginTop: 25,
                            }}
                            key={i}
                            ref="itemValue"
                            value={busticket}
                            onClick={this.ticketBuy.bind(this, busticket)}
                          >
                            Buy Ticket
                          </Button>
                          {/* </Link> */}
                        </div>
                      ) : (
                        <div>
                          <Notloggedin />
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </div>
              </div>
            ))}
          </div>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

export default buslist;

function Notloggedin() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="primary"
        style={{
          color: "white",
          variant: "contained",
          size: "small",
          backgroundColor: "#29BD00",
          margin: "0px 1px 0px 0px ",
          marginLeft: "22%",
          fontSize: "22px",
          height: "50px",
          fontWeight: "bolder",
          textTransform: "none",
          border: "1px solid #29BD00",
          borderRadius: "100px 100px 100px 100px",
          padding: "5px 3px ",
          width: "200px",
          boxShadow: "0px 3px 3px #E2E2E2",
          "&:hover": {
            backgroundColor: "#29BD00",
          },

          marginTop: 25,
        }}
        onClick={handleShow}
      >
        Buy Ticket
      </Button>

      <Modal size="sm" centered show={show} onHide={handleClose}>
        <Modal.Body>
          <span
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              marginLeft: "20%",
            }}
          >
            Please Log In To Proceed
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#29BD00", border: "1px solid #29BD00" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
