import axios from 'axios';
const baseURL = 'https://prod.ekamo.co.zm/api/v1/';
// const baseURL = 'https://test.ekamo.co.zm/api/v1/';



const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		// Prevent infinite loops early
		if (
			error &&
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'token/refresh/'
		) {
			this.props.history.push('/login/');
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem(
								'access_token',
								response.data.access
							);
							localStorage.setItem(
								'refresh_token',
								response.data.refresh
							);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log(
						'Refresh token is expired',
						tokenParts.exp,
						now
					);
					this.props.history.push('/login/');
				}
			} else {
				const response = axiosInstance.post('/blacklist/', {
					refresh_token: localStorage.getItem(' '),
				});
				localStorage.removeItem('access_token');
				localStorage.removeItem('refresh_token');
				axiosInstance.defaults.headers['Authorization'] = null;
				window.location.href = '/';
				console.log('you have been terminated');
				return response;
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;
