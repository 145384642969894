import React, { Component } from "react";
// import "./home.css";
import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
// import axiosInstance from "../../axiosApi";
import axiosInstance from "../../axiosinstance/axiosApi";
import Avatar from "@material-ui/core/Avatar";
import { Grid, InputBase } from "@material-ui/core";
import Spinner from "react-bootstrap/Spinner";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Cookies from "js-cookie";
import { Popover, Tooltip, Modal } from "react-bootstrap";
// import axiosInstance from "./components/axiosApi";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#29BD00",
  },
});
class Forgotpasswordform extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { phone_number: "", loading: false };

    // this.handleClose = this.handleClose.bind(this);

    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async onSubmitForm() {
    // this.handleClose();
    this.setState({ loading: true });
    let ExpireIn30Minutes = 1 / 48;
    Cookies.set("username", this.state.phone_number, {
      expires: ExpireIn30Minutes,
    });
    try {
      const response = await axiosInstance.get("/user/request/password/", {
        phone_number: this.state.phone_number,
      });

      console.log("password RESPONSE", response.data);
      // this.setState(response.data);

      //Faking API call here

      // if (response.data.otp_code === "success") {
      //   // console.log("get data to server");
      //   this.handleClose();
      //   this.setState({ loading: false });
      // }

      return response;
    } catch (error) {
      throw error;
    }
  }

  render() {
    const { items, loading } = this.state;
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <br />
          <br />
          <br />
          <br />
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            Reset Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="OTP Code"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="New Password"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Confirm Pin"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Typography color="inherit">Bilfold Zambia Limited</Typography>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
      // <div>
      //   <br />
      //   <br />
      //   <br />
      //   <br />
      //   <h3 style={{ textAlign: "center", marginTop: "2%" }}>
      //     Ekamo Password Recovery
      //   </h3>
      //   <div
      //     style={{
      //       marginTop: "10%",
      //       display: "flex",
      //       flexDirection: "column",
      //       alignItems: "center",
      //     }}
      //   >
      //     <InputBase
      //       placeholder="Enter Mobile Number"
      //       name="phone_number"
      //       type="number"
      //       value={this.state.phone_number}
      //       onChange={this.onInputchange}
      //     />
      //     <br />
      //     <Button onClick={this.onSubmitForm} disabled={loading}>
      //       Next
      //     </Button>
      //   </div>
      //   <br />
      // </div>
    );
  }
}

Forgotpasswordform.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Forgotpasswordform);
