import React, { useEffect, useState } from 'react';
import {
	Layout,
	Form,
	Input,
	Button,
	Spin,
	DatePicker,
	InputNumber,
	Popconfirm,
	notification,
	message,
	Result,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import styles from './styles.module.css';
import { APIService } from '../api/APIService';
import Modal from 'antd/lib/modal/Modal';

export const CreatePin = ({ title }) => {
	const [userPin, setUserPin] = useState({
		pin: '',
		confirm_pin: '',
	});
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState('');
	const [otpmodal, setOtpmodal] = useState(false);
	const [phone, setPhone] = useState('');
	const [otpCounter, setOtpCounter] = useState(false);
	const [optLoading, setOtpLoading] = useState(false);
	const [loadinMessage, setLoadingMessage] = useState('');
	const [otpError, setOtpError] = useState('');
	const [success, setSuccess] = useState();

	const handleOtpChange = (digit) => {
		return setOtp(digit);
	};

	const onFinish = async (values) => {
		console.log('Success:', values);

		try {
			setLoading(true);
			const pinDetails = {
				...userPin,
			};
			console.log(pinDetails);
			const response = await APIService.createPin(values);
			setOtpmodal(false);

			if (response.success) {
				setSuccess(true);
				setUserPin({
					old_pin: '',
					pin: '',
					confirm_pin: '',
				});
			}
			setOtpError('');
			setOtp('');
			console.log(response);
			setLoading(false);
		} catch (error) {
			setOtpError(error.message);
			setOtpmodal(false);
			console.log(error.message);
			setLoading(false);
			setSuccess(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	// const confirm = async () => {
	// 	try {
	// 		setLoading(true);
	// 		const pinDetails = {
	// 			...userPin,
	// 		};
	// 		console.log(pinDetails);
	// 		const response = await APIService.updatePin(pinDetails);
	// 		setOtpmodal(false);

	// 		if (response.success) {
	// 			setSuccess(true);
	// 			setUserPin({
	// 				old_pin: '',
	// 				pin: '',
	// 				confirm_pin: '',
	// 			});
	// 		}
	// 		setOtpError('');
	// 		setOtp('');
	// 		console.log(response);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		setOtpError(error.message);
	// 		setOtpmodal(false);
	// 		console.log(error.message);
	// 		setLoading(false);
	// 		setSuccess(false);
	// 	}
	// };

	const onCloseModal = () => {
		setOtpmodal(false);
		setOtpLoading(false);
	};

	return (
		<Form
			name='basic'
			labelCol={{
				span: 8,
			}}
			wrapperCol={{
				span: 16,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: '',
				alignContent: 'center',
				paddingLeft: '10%',
			}}>
			{otpError ? <small>{otpError}</small> : ''}
			{loading ? (
				<Spin />
			) : (
				<>
					<Form.Item
						className={styles.form_item}
						style={{ textAlign: 'center', color: '#29bd00' }}>
						<b>
							{success ? (
								<Result
									status='success'
									title='Successfully changed your pin!'
								/>
							) : success === false ? (
								<Result status='error' title={otpError} />
							) : null}
						</b>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						name='pin'
						rules={[
							{
								required: true,
								message: 'Please confirm your old pin',
							},
							{
								max: 4,
								message: 'Maximum 4 digits',
							},
							{
								min: 4,
								message: 'Minimum 4 digits',
							},
						]}>
						<Input
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='password'
							placeholder='Enter new pin'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='confirm_pin'
						rules={[
							{
								required: true,
								message: 'Please enter your new 4 digit pin',
							},
							{
								max: 4,
								message: 'Maximum 4 digits',
							},
							{
								min: 4,
								message: 'Minimum 4 digits',
							},
						]}>
						<Input
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='password'
							placeholder='Confirm New Pin'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item className={styles.form_item}>
						{optLoading ? (
							<small>{loadinMessage}</small>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}>
								<Button
									shape='round'
									style={{
										borderColor: '#29bd00',
										color: 'white',
										background: '#29bd00',
										width: '100px',
									}}
									htmlType='submit'>
									Save
								</Button>
							</div>
						)}
					</Form.Item>
				</>
			)}
		</Form>
	);
};
