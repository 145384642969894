import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Card, CardActions, CardContent } from "@material-ui/core";
// import Cashout from "./Atmcashout";
import { Link } from "react-router-dom";
import Ekamoselfcashout from "./Ekamoselfcashout";
import Ekamoothercashout from "./Ekamoothercashout";
import { Grid } from "@material-ui/core";
import axiosInstance from "../axiosinstance/axiosApi";
import Paper from "@material-ui/core/Paper";

// import Agentconfirm from "./Agentconfirm.js";
import { Popover, Tooltip, Modal } from "react-bootstrap";

class Ekamocashout extends Component {
  constructor(props, context) {
    super(props, context);
    this.gotoCashoutForOther = this.gotoCashoutForOther.bind(this);
    this.gotoCashoutForSelf = this.gotoCashoutForSelf.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // code for cashout for other details
  cashoutforotherdetailsModalRef = ({ cashoutforotherShow }) => {
    this.showcashoutforotherModal = cashoutforotherShow;
  };

  cashoutforotherdetails = () => {
    this.showcashoutforotherModal();
  };

  gotoCashoutForOther() {
    this.setState({ show: false });
    this.cashoutforotherdetails();
  }

  // code for cashout for other ends here
  cashoutforselfdetailsModalRef = ({ cashoutforselfShow }) => {
    this.showcashoutforselfModal = cashoutforselfShow;
  };

  cashoutforselfdetails = () => {
    this.showcashoutforselfModal();
  };

  gotoCashoutForSelf() {
    this.setState({ show: false });
    this.cashoutforselfdetails();
  }

  // code cashout for self

  render() {
    return (
      <div>
        <Ekamoselfcashout
          ref={this.cashoutforselfdetailsModalRef}
        ></Ekamoselfcashout>

        <Ekamoothercashout
          ref={this.cashoutforotherdetailsModalRef}
        ></Ekamoothercashout>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Cash Out
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Withdraw money from your ekamo wallet
              </div>
              <div>
                <Paper
                  elevation={3}
                  onClick={this.gotoCashoutForSelf}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",

                    display: "flex",
                    height: "66px",
                    marginBottom: "10px",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span style={{ fontWeight: "bolder", marginLeft: "74px" }}>
                      Cash Out For Self
                    </span>
                  </div>
                </Paper>

                <br />

                <Paper
                  elevation={3}
                  onClick={this.gotoCashoutForOther}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",

                    display: "flex",
                    height: "66px",
                    marginBottom: "10px",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span style={{ fontWeight: "bolder", marginLeft: "74px" }}>
                      Cash Out For Other
                    </span>
                  </div>
                </Paper>
              </div>
              <br />
              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Ekamocashout;
