import React, { Component, Fragment } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
// import './account.css';
import axiosInstance from '../axiosinstance/axiosApi';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Header from '../../components/header';
import Spinner from 'react-bootstrap/Spinner';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import axiosInstance from "../../axiosinstance/axiosApi";

const styles = (theme) => ({
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#29BD00 !important',
	},
	cssLabel: {
		color: 'black !important',
	},
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#29BD00', //your color
		},
	},
});

class RetailBusinessForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			business_name: '',
			bank_account_number: '',
			email_address: '',
			contact_phone_number: '',
			mobile_money_account_number: '000',
			physical_address: '',
			organization_type: '',
			tpin_number: '',
			tpin_file: null,
			phone_number: '',
			pacra_number: '',
			incoperation_document: null,
			tpinfile: null,

			//   first director
			first_name: '',
			last_name: '',
			nrc_number: '',
			nrc_file: null,
			phone_number: '',
			physical_address1: '',
			email: '',

			//   #Director 2
			first_name2: '',
			last_name2: '',
			nrc_number2: '',
			nrc_file2: null,
			phone_number2: '',
			physical_address2: '',
			email2: '',
			show: false,
			loading: false,
			error: '',
			accessshow: false,
			timeoutshow: false,

			access_error: '',
			// error handling

			business_name_error: '',
			bank_account_number_error: '',
			email_address_error: '',
			contact_phone_number_error: '',
			mobile_money_account_number_error: '',
			physical_address_error: '',
			organization_type_error: '',
			tpin_number_error: '',
			tpin_file_error: null,
			phone_number_error: '',
			pacra_number_error: '',
			incoperation_document_error: null,
			tpinfile_error: null,

			//   first director
			first_name_error: '',
			last_name_error: '',
			nrc_number_error: '',
			nrc_file_error: null,
			phone_number_error: '',
			physical_address1_error: '',
			email_error: '',

			//   #Director 2
			first_name2_error: '',
			last_name2_error: '',
			nrc_number2_error: '',
			nrc_file2_error: null,
			phone_number2_error: '',
			physical_address2_error: '',
			email2_error: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlelogout = this.handlelogout.bind(this);
	}
	registerarcerificate = null;
	incoperationdocument = null;
	tpinfile = null;
	nrcfile = null;
	nrcfile2 = null;
	// save the picked file in this
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handlenrc2Upload = (event) => {
		event.preventDefault();
		this.nrcfile2 = event.target.files[0];
	};

	handlenrcUpload = (event) => {
		event.preventDefault();
		this.nrcfile = event.target.files[0];
	};

	handletpinUpload = (event) => {
		event.preventDefault();
		this.tpinfile = event.target.files[0];
	};

	handleincoperateUpload = (event) => {
		event.preventDefault();
		this.incoperationdocument = event.target.files[0];
	};

	async handleSubmit(event) {
		event.preventDefault();

		
        this.onEkamoClick();
			// setTimeout(() => {
			// 	this.setState({
			// 		loading: false,
			// 		timeoutshow: true,
			// 	});
			// }, 60000);
		
	}

	async handlelogout(event) {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	}

	accesserrorclick = () => {
		this.setState({ accessshow: true });
	};

	onEkamoClick = () => {
		this.setState({ show: true });
	};

	notify = () => {
		// toast.error(this.state.error, {
		// 	position: 'top-center',
		// 	autoClose: 400000,
		// 	hideProgressBar: true,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// });
	};

	handletimeClose = () => {
		this.setState({ timeoutshow: false });
	};

	render() {
		const { loading } = this.state;
    const { classes } = this.props;

		return (
			<Fragment>
				{/* <ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/> */}
				<Modal
					scrollable={true}
					show={this.state.timeoutshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<br />
						<h5
							style={{
								fontWeight: 'bold',
								color: 'red',
								textAlign: 'center',
							}}>
							Response taking too long
						</h5>
						<br />
						<ul>
							<li>
								Go through the form and check for error
								messages.
							</li>
							<li>
								Go to accounts created page to see if account
								has been created.
							</li>
							<li>Ensure you have Strong internet Connection.</li>
							<li>
								Make sure you have logged in and are able to
								view your name.
							</li>
						
							<li>If issue persists, contact us.</li>
						</ul>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Button
								onClick={this.handletimeClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.accessshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							{this.state.access_error}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />

							<Button
								onClick={this.handlelogout}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
						 Retail Business Form Successfully Submitted, You will be Notified once It has been Approved
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />
							<Link
								to='/'
								color='inherit'
								style={{ fontSize: '12px', color: 'white' }}>
								<Button
									style={{
										color: 'white',
										variant: 'contained',
										size: 'small',
										backgroundColor: '#29BD00',
										margin: '0px 1px 0px 0px ',
										fontSize: 15,
										height: '40px',
										fontWeight: '',
										textTransform: 'none',
										border: '1px solid #29BD00',
										borderRadius: '100px 100px 100px 100px',
										padding: '5px 3px ',
										width: '70%',
										boxShadow: '0px 3px 3px #E2E2E2',
										'&:hover': {
											backgroundColor: '#29BD00',
										},
									}}>
									Close
								</Button>
							</Link>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
			
				<br /> <br />
				<Grid item container>
					<ThemeProvider theme={theme}>
						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>

						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<div
								style={{
									margin: 0,
									minHeight: 40,
									textAlign: 'left',
									marginLeft: 20,
									marginTop: '10%',
									marginRight: 20,
									borderRadius: '10px 10px 0px 0px',
									background: [
										// add background image
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/reg_title_icon.svg)',
											'no-repeat',
											'top 56% left 440px',
										],

										['#29BD00'],
									],
									//background:
									color: 'white',
								}}>
								<Typography
									style={{
										padding: 10,
										paddingLeft: 20,
									}}>
								RETAIL BUSINESS LEVY FORM(Form BL05)
								</Typography>

                                
							</div>
							<Card
								style={{
									minHeight: 0,
									border: '1px solid #E8E8E8',
									textAlign: 'left',
									borderRadius: 0,
									marginLeft: 20,
									marginRight: 20,
									boxShadow: 'none',
								}}>
								<CardContent>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}>
										<form onSubmit={this.handleSubmit}>
											<Card
												style={{
													background: [
														// add background image
														[
															'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
															'no-repeat',
															'top -0% left -50%',
														],
													],

													padding: 20,
												}}>
												<br />
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													xl={12}>
													<div>
                                                    <span style={{fontWeight:"bold",fontSize:"20px"}}>		Enter Information</span>

													</div>
												</Grid>
												<Grid container spacing={2}>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='account_number'
															value={
																this.state
																	.account_number
															}
															label='A/C No.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

													
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='applicant_name'
															value={
																this.state
																	.applicant_name
															}
															label='Full Name of Applicant'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='postal_address'
															value={
																this.state
																	.postal_address
															}
															label='Postal Address'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
													
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='stand_number'
															value={
																this.state
																	.stand_number
															}
															label='Stand Number'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
												
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='suburb'
															label=' Surburb'
														
															value={
																this.state
																	.suburb
															}
															onChange={
																this
																	.handletpinUpload
															}
														
														/>
													
														
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='street_name'
															value={
																this.state
																	.street_name
															}
															label='Business Registration No.'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>
												
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															label=' Telephone'
															autoFocus
														
															name='telephone'
															onChange={
																this
																	.handleChange
															}
															value={
																this.state
																	.telephone
															}
														
														/>
													
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='business_name'
															value={
																this.state
																	.business_name
															}
															label='Business Name'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

													
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															type='email'
															name='email_address'
															value={
																this.state
																	.email_address
															}
															label='Email Address'
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														
													</Grid>



                                                    <Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
													margin='normal'
													// multiline
													// rows={4}
													fullWidth
													name='number_of_employees'
													value={
														this.state
															.number_of_employees
													}
													label='Number of Employees'
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>


</Grid>



<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
													margin='normal'
													// multiline
													// rows={4}
													fullWidth
													name='feature_near_place'
													value={
														this.state
															.feature_near_place
													}
													label='Prominent feature near your place'
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>


</Grid>

<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
													margin='normal'
													// multiline
													// rows={4}
													fullWidth
													name='description'
													value={
														this.state
															.description
													}
													label='Description of services/goods to be sold'
													onChange={this.handleChange}
													onBlur={this.handleChange}
												/>


</Grid>

<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
                                                    margin='normal'
                                                    fullWidth
                                                    label='Upload NRC'
                                                    autoFocus
                                                    type='file'
                                                    name='nrc_file'
                                                    onChange={
                                                        this
                                                            .handlenrcUpload
                                                    }
                                                    value={
                                                        this.state
                                                            .nrc_file
                                                    }
                                                    id='filled-number'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
												/>


</Grid>




                                           



<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
                                                    margin='normal'
                                                    fullWidth
                                                    label='Certificate of Incoporation '
                                                    autoFocus
                                                    type='file'
                                                    name='incoperation_document'
                                                    // onChange={
                                                    //     this
                                                    //         .handlenrcUpload
                                                    // }
                                                    value={
                                                        this.state
                                                            .incoperation_document
                                                    }
                                                    id='filled-number'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
												/>


</Grid>





<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
													variant='outlined'
                                                    margin='normal'
                                                    fullWidth
                                                    label='ZRA TAX CERTIFICATE '
                                                    autoFocus
                                                    type='file'
                                                    name='zra_tax_certificate'
                                                    // onChange={
                                                    //     this
                                                    //         .handlenrcUpload
                                                    // }
                                                    value={
                                                        this.state
                                                            .zra_tax_certificate
                                                    }
                                                    id='filled-number'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
												/>


</Grid>







<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
							variant='outlined'
							margin='normal'
						
							fullWidth
							label='Date Deposited'
							type='date'
							name='deposit_date'
							onBlur={this.handleChange}
							onChange={this.handleChange || null}
							value={this.state.deposit_date}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
</Grid>


<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>

<TextField
								variant='outlined'
                                margin='normal'
                                fullWidth
                                label='SIGNATURE OF APPLICANT '
                                autoFocus
                                type='file'
                                name='signature'
                                // onChange={
                                //     this
                                //         .handlenrcUpload
                                // }
                                value={
                                    this.state
                                        .signature
                                }
                                id='filled-number'
                            
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
</Grid>



												</Grid>
												<br />
											
											
												<br /> <br />
											</Card>
											<br />
											<br />







































                                            <br />
											<br />
											<Card
												style={{
													background: [
														// add background image
														[
															'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
															'no-repeat',
															'top -0% left -50%',
														],
													],

													padding: 20,
												}}>
												<Grid
													xs={12}
													sm={12}
													md={12}
													lg={12}
													xl={12}>
													<div>
													<span style={{fontWeight:"bold",fontSize:"20px"}}>	Fees</span>
													</div>
                                                    (i) Consumables
                                                    <ul>
                                                        <li>
                                                        CBD - K999.90
                                                        </li>
                                                        <li>
                                                        Medium CLASS - K666.60
                                                        </li>
                                                        <li>
                                                        BUSINESS AREA - K333.30
                                                        </li>
                                                    </ul>

                                                    (ii) Merchants (Non Consumables) –
                                                    <ul>
                                                        <li>
                                                        CBD -K1,666.60
                                                        </li>
                                                        <li>
                                                        Medium Class - K1,333.50
                                                        </li>
                                                        <li>
                                                        Business Area - K666,60
                                                        </li>
                                                        <li>
                                                        Lower class - K501.00
                                                        </li>
                                                    </ul>

                                                    (ii) PENALTY FOR LATE RENEWAL IS K750.00
												</Grid>
												<Grid container spacing={2}>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='personal_levy'
															value={
																this.state
																	.personal_levy
															}
															label=' Personal Levy Fee  (No. of employees X K15.00 '
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

														
													</Grid>
													<Grid
														item
														xs={12}
														sm={4}
														md={4}
														lg={4}
														xl={4}>
														<TextField
															variant='outlined'
															margin='normal'
															fullWidth
															name='year'
															value={
																this.state
																	.renewal_penalty
															}
															label='For The Year '
															onChange={
																this
																	.handleChange
															}
															onBlur={
																this
																	.handleChange
															}
														/>

													</Grid>
												
										
											
												</Grid>
												<Hidden smUp>
													<br />
												</Hidden>
									

												<Hidden smDown>
													<br /> <br />
												</Hidden>
											</Card>
											<br />
											<Hidden smDown>
												<br />
											</Hidden>
											<Hidden smUp>
												<br />
											</Hidden>
											




















                                            <span style={{fontWeight:"bold"}}>
                                                        NB: Any act or omission done by the applicant herein or any council employee acting as agent which shall result into loss of Council monies will attract a prescribed penalty fee or possible legal prosecution.
                                                        </span>

											<Grid container spacing={0}>
												<Grid
													item
													xs={0}
													sm={4}
													md={4}
													lg={4}
													xl={4}
												/>
                                                
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
                                                  
													<Button
														style={{
															color: 'white',
															variant:
																'contained',
															size: 'small',

															background:
																'linear-gradient(45deg, #29BD00 30%, #25A203 90%)',
															margin: '15px 1px 0px 0px ',
															fontSize: 15,
															height: '54px',
															fontWeight: '',
															textTransform:
																'none',
															border: 0,
															boxShadow:
																'0 3px 5px 2px rgba(41, 189, 0, .3)',
															borderRadius:
																'100px 100px 100px 100px',
															padding: '5px 3px ',
															width: '100%',

															'&:hover': {
																backgroundColor:
																	'#29BD00',
															},
														}}
														type='submit'>
														{loading && (
															<>
																<Spinner
																	as='span'
																	animation='grow'
																	size='lg'
																	role='status'
																	aria-hidden='true'
																	style={{
																		color: '#fff',
																	}}
																/>
															</>
														)}
														{!loading && (
															<span>Submit</span>
														)}
													</Button>
												</Grid>
												<Grid
													item
													xs={0}
													sm={4}
													md={4}
													lg={4}
													xl={4}
												/>
											</Grid>
											<br />
										</form>
									</Grid>
								</CardContent>
							</Card>
						</Grid>

						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>
					</ThemeProvider>
				</Grid>
				<br /> <br />
			</Fragment>
		);
	}
}

RetailBusinessForm.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RetailBusinessForm);
