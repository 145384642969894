import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Cookies from "js-cookie";
import { IconButton, Avatar } from "@material-ui/core";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import axiosInstance from "../../axiosinstance/axiosApi";

import Successmodal from "../success";
class Confirmtopster extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleConfirmShow = this.handleConfirmShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.senddata = this.senddata.bind(this);
    this.laststep = this.laststep.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      loading: false,
    };
  }
  getmtnLoadfunds() {
    const topsterdetails = JSON.parse(Cookies.get("topstercookie"));
    let response = topsterdetails;
    this.setState({
      confirmation_number: response.confirmation_number,
      customer_surname: response.customer_surname,
      customer_name: response.customer_name,
      data_amount: response.data_amount,
      icu_number: response.icu_number,
    });
    console.log("topster confirm details", topsterdetails);
  }
  handleConfirmShow() {
    console.log(this.state);
    this.setState({ show: true });
    this.getmtnLoadfunds();
    this.setState({ loading: false });

  }
  handleClose() {
    this.setState({ show: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );

      console.log(response.data);
      // this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("pin success");

        // window.location.href = "./cashoutSuccess";
        this.senddata();
        // this.handleClose();
      }
      return response;
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  async senddata() {
    this.gotosucess();

    const topsterdetails = JSON.parse(Cookies.get("topstercookie"));
    const confirmation_number = topsterdetails.confirmation_number;

    const data = { confirmation_number };
    console.log("sending topster third step data", data);
    try {
      const response = await axiosInstance.post(
        "/topstar/customer/confirm/",
        data
      );
      this.setState({
        finalconfirmation_number: response.data.confirmation_number,
      });
      console.log("this is the response", response);
      // this.setState(response.data);
      if (response.data.success === "ok") {
        this.laststep();
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  async laststep() {
    console.log("Data number pa last  ", this.state.finalconfirmation_number);
    const data = {
      confirmation_number: this.state.finalconfirmation_number,
      data_amount: this.state.data_amount,
    };
    console.log("laststep", data);
    try {
      const response = await axiosInstance.post(
        "/topstar/payment/confirm/",
        data
      );
      console.log("this is the response", response);
      // this.setState(response.data);
      if (response.data.success === "ok") {
        this.gotosucess();
        this.setState({ loading: false });
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  gotosucess = () => {
    this.onLoginClick();
    this.setState({ show: false });
  };

  loginModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    const airtel = "/images/airtel_momo.svg"; //get  Icon
    const mtn = "/images/mtn_momo.svg"; //get  Icon
    const zamtel = "/images/zamtel_momo.svg"; //get  Icon
    const { items, loading } = this.state;
    return (
      <div>
        <Successmodal ref={this.loginModalRef}></Successmodal>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Bill Payment
            </h4>
          </div>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              Pay
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                ZMW 908
              </span>
              <br />
              for Topster account
              <span style={{ marginLeft: "5px" }}>
                belonging to
                <span
                  style={{
                    fontWeight: "bold",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  mwale {this.state.customer_name}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  andrew{this.state.customer_surname}
                </span>
              </span>
            </div>
            <br />
            <br />
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>

            <br />
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  placeholder="Enter Pin"
                  name="pin"
                  className="form-control"
                  type="password"
                  value={this.state.pin}
                  onChange={this.handleChange}
                  style={{
                    border: "1px solid #29BD00",

                    height: "40px",
                    padding: "0px 0px 0px 10px ",
                    backgroundColor: "none",
                    margin: "0px 2px 0px 0% ",
                    fontSize: 14,
                    width: "70%",
                  }}
                />
              </div>
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Proceed"
                    className="btn btn-primary"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 1px 0px 0% ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "50%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}

              <br />
              <h5
                style={{
                  fontWeight: "bold",
                  color: "red",
                  textAlign: "center",
                }}
              >
                {this.state.error}
              </h5>
            </div>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Confirmtopster;
