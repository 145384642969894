import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import DSTV from "./dstv/dstv";
import GOTV from "./gotv/gotv";
import Topster from "./topster/topster";
import Paper from "@material-ui/core/Paper";

class TV extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handletvShow = this.handletvShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.ondstvClick = this.ondstvClick.bind(this);
    this.ontopsterClick = this.ontopsterClick.bind(this);
    this.ongotvClick = this.ongotvClick.bind(this);
    // this.gotodstv = this.gotodstv.bind(this);
    this.state = {
      show: false,
    };
  }

  handletvShow() {
    console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // dstv modal here
  dstvModalRef = ({ handledstvShow }) => {
    this.showModal = handledstvShow;
  };

  gotodstv = () => {
    this.showModal();
    this.setState({ show: false });
  };
  ondstvClick() {
    this.setState({ show: false });
    this.gotodstv();
  }

  // gotv modal here
  gotvModalRef = ({ handlegotvShow }) => {
    this.showgotvModal = handlegotvShow;
  };

  gotogotv = () => {
    this.showgotvModal();
  };
  ongotvClick() {
    this.setState({ show: false });
    this.gotogotv();
  }

  // topster modal here
  topsterModalRef = ({ handledtopsterShow }) => {
    this.showtopsterModal = handledtopsterShow;
  };
  ontopsterClick() {
    this.setState({ show: false });
    this.gototopster();
  }

  gototopster = () => {
    this.showtopsterModal();
  };
  render() {
    return (
      <div>
        <DSTV ref={this.dstvModalRef}></DSTV>
        <GOTV ref={this.gotvModalRef}></GOTV>
        <Topster ref={this.topsterModalRef}></Topster>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              // backgroundColor: theme.palette.background.paper,
              // border: "2px solid #C8C8C8",
              // boxShadow: theme.shadows[5],
              // padding: theme.spacing(2, 4, 4),
              // textAlign: "center",
              background: [
                //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                [
                  "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                  "no-repeat",
                  "top 230% left 100%",
                ],
                //['white']
              ],
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Bill Payment
              </h4>
            </div>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Select your TV option
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.gotodstv}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      DSTV
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.ongotvClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      GOTV
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.ontopsterClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Topster
                    </span>
                  </div>
                </Paper>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default TV;
