import React, { useEffect, useState } from 'react';
import {
	Layout,
	Form,
	Input,
	Button,
	Spin,
	DatePicker,
	InputNumber,
	Popconfirm,
	notification,
	message,
	Result,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import styles from './styles.module.css';
import { APIService } from '../api/APIService';
import Modal from 'antd/lib/modal/Modal';

export const PinChangeForm = ({ title }) => {
	const [userPin, setUserPin] = useState({
		old_pin: '',
		pin: '',
		confirm_pin: '',
	});
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState('');
	const [otpmodal, setOtpmodal] = useState(false);
	const [phone, setPhone] = useState('');
	const [otpCounter, setOtpCounter] = useState(false);
	const [optLoading, setOtpLoading] = useState(false);
	const [loadinMessage, setLoadingMessage] = useState('');
	const [otpError, setOtpError] = useState('');
	const [success, setSuccess] = useState();
	const [err, setErr] = useState(false);
	const [errMessage, setErrMessage] = useState('');

	const handleOtpChange = (digit) => {
		return setOtp(digit);
	};

	const onFinish = async (values) => {
		console.log('Success:', values);

		try {
			setUserPin(values);
			setOtpLoading(true);
			setLoadingMessage(`Sending OTP to ${phone}`);
			const response = await APIService.sendOtp({
				phone_number: `${phone}`,
			});
			if (response.otp_code === 'success') {
				setOtpLoading(false);
				return setOtpmodal(true);
			} else {
				setOtpLoading(false);
				setOtpmodal(false);
			}

			console.log(response);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const confirm = async () => {
		try {
			setLoading(true);
			const pinDetails = {
				...userPin,
				phone_number: phone,
				otp_code: otp,
			};
			console.log(pinDetails);
			const response = await APIService.updatePin(pinDetails);
			setOtpmodal(false);

			if (response.success) {
				setSuccess(true);
				setUserPin({
					old_pin: '',
					pin: '',
					confirm_pin: '',
				});
			}
			if (response.error) {
				setErr(true);
				setErrMessage(response.error);
			}
			setOtpError('');
			setOtp('');
			console.log(response);
			setLoading(false);
		} catch (error) {
			setOtpError(error.message);
			setOtpmodal(false);
			console.log(error.message);
			setLoading(false);
			setSuccess(false);
		}
	};

	const onCloseModal = () => {
		setOtpmodal(false);
		setOtpLoading(false);
	};

	useEffect(async () => {
		try {
			const response = await APIService.getUserProfile();
			console.log('response here: ', response);
			setPhone(response.phone_number);
		} catch (err) {
			console.log(err);
		}
	}, []);

	return (
		<Form
			name='basic'
			labelCol={{
				span: 8,
			}}
			wrapperCol={{
				span: 16,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			style={{
				display: 'flex',
				flexDirection: 'column',

				alignContent: 'center',
				paddingLeft: '10%',
			}}>
			{otpError ? <small>{otpError}</small> : ''}
			{loading ? (
				<Spin />
			) : (
				<>
					<Form.Item
						className={styles.form_item}
						style={{ textAlign: 'center', color: '#29bd00' }}>
						{success ? (
							<Result
								status='success'
								// title='Successfully changed your pin!'
								subTitle='Successfully changed your pin!'
							/>
						) : success === false || err ? (
							<Result status='error' title={errMessage} />
						) : null}
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						name='old_pin'
						rules={[
							{
								required: true,
								message: 'Please confirm your old pin',
							},
							{
								max: 4,
								message: 'Maximum 4 digits',
							},
							{
								min: 4,
								message: 'Minimum 4 digits',
							},
						]}>
						<Input
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='number'
							placeholder='Enter old pin'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='pin'
						rules={[
							{
								required: true,
								message: 'Please enter your new 4 digit pin',
							},
							{
								max: 4,
								message: 'Maximum 4 digits',
							},
							{
								min: 4,
								message: 'Minimum 4 digits',
							},
						]}>
						<Input
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='number'
							placeholder='Create New Pin'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='confirm_pin'
						rules={[
							{
								required: true,
								message: 'Please confirm your new pin',
							},
							{
								max: 4,
								message: 'Maximum 4 digits',
							},
							{
								min: 4,
								message: 'Minimum 4 digits',
							},
						]}>
						<Input
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='number'
							placeholder='Confirm Pin'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>
					<Modal
						title={
							<div style={{ textAlign: 'center' }}>Enter OTP</div>
						}
						destroyOnClose={true}
						width={300}
						visible={otpmodal}
						onCancel={onCloseModal}
						footer={null}>
						<Form
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Form.Item>
								<OtpInput
									value={otp}
									onChange={handleOtpChange}
									numInputs={4}
									separator={<span> &nbsp;- </span>}
									containerStyle={{
										width: '100%',
										justifyContent: 'center',
									}}
									inputStyle={{
										width: '30px',
										height: '30px',
										color: '#29bd00',
										fontSize: '16px',
										fontWeight: 'bolder',
										bolderColor: '#8A2BE2',
										borderWidth: '1px',
									}}
									isInputNum={true}
								/>
							</Form.Item>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}>
								<Form.Item style={{ width: '100px' }}>
									<Button
										shape='round'
										style={{
											width: '100px',
											borderColor: '#29bd00',
											color: 'white',
											background: '#29bd00',
										}}
										onClick={confirm}>
										Submit
									</Button>
								</Form.Item>
							</div>
						</Form>
					</Modal>
					<Form.Item className={styles.form_item}>
						{optLoading ? (
							<small>{loadinMessage}</small>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}>
								<Button
									shape='round'
									style={{
										borderColor: '#29bd00',
										color: 'white',
										background: '#29bd00',
										width: '100px',
									}}
									htmlType='submit'>
									change
								</Button>
							</div>
						)}
					</Form.Item>
				</>
			)}
		</Form>
	);
};
