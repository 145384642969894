import axiosInstance from '../axiosinstance/axiosApi';

import Box from '@material-ui/core/Box';

import DepositFloat from '../floatdeposit/create';

import CreateBranch from './branches/branch';
import DeleteBranch from './branches/deletebranch';
import EditBranch from './branches/editbranch';
import Media from 'react-media';
import { Button } from 'antd';

import React, { Fragment, Component } from 'react';

import PropTypes from 'prop-types';
import Depositoptions from '../businessdeposit/depositoptions';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';

import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
	},
	secondaryHeading: {
		fontWeight: 'bold',
		fontSize: '20px',
		textAlign: 'center',
		marginLeft: '10%',
	},
	icon: {
		verticalAlign: 'bottom',
		height: 20,
		width: 20,
	},
	details: {
		alignItems: 'center',
	},
	transferdetails: {
		alignItems: 'center',
		display: 'grid',
	},
	column: {
		// flexBasis: '33.33%',
	},
	helper: {
		borderLeft: `2px solid ${theme.palette.divider}`,
		padding: theme.spacing(1, 2),
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},

	paper: {
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],

		background: [
			// ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
			[
				// "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
				'no-repeat',
				'top 100% left 100%',
			],
			//['white']
		],
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#29BD00 !important',
	},
	cssLabel: {
		color: 'black !important',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#4a4a4a',
		height: '110px',
		width: '110px',
	},
	//define style for country code on mobile screens
});

class Registeredbusinessprofile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			branches: [],
			username: '',
			balance: '',
			showedit: '',
			business_name: '',
			businessAccountID:'',
			registered_business: '',
			bank_account_number: '',
			mobile_money_account_number: '',
			account_type: '',
			email_address: '',
			tpin_number: '',
			branch_name: '',
			bank_name: '',
			business_type: 'org_branch',
			contact_phone_number: '',
			profilebalance: '',
			valuebranch: '',
			editvalue: '',
			merchant_code: '',
			logged_in: localStorage.getItem('access_token') ? true : false,
		};
		// this.handleLogout = this.handleLogout.bind(this);
		// this.getUser = this.getUser.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.getbranches = this.getbranches.bind(this);
	}
	componentDidMount() {
		const refreshToken = localStorage.getItem('refresh_token');
		// this.setState({
		//   branches: "",

		// });
		this.getProfile();
		// this.getbranches()
	}

	async getProfile() {
		// console.log("Does this even work");
		try {
			let response = await axiosInstance.get(
				'/user/profile/business/'
			);
			// let branch_res = await axiosInstance.get("/account/branch/businesses/list");
			console.log('get business company data ', response);





			this.setState({
				business_name: response.data.profile.business_name,
				account_type: response.data.profile.account_type,
				merchant_code: response.data.profile.merchant_code,
				business_registration_number: response.data.profile.business_registration_number,
				company_physical_address: response.data.profile.company_physical_address,
				contact_phone_number: response.data.profile.contact_phone_number,
				

				
				email: response.data.profile.email,
				email_address: response.data.profile.email_address,
				first_director: response.data.profile.first_director,
				first_name: response.data.profile.first_name,
				last_name: response.data.profile.last_name,
				last_name: response.data.profile.last_name,
				nrc_file: response.data.profile.nrc_file,
				nrc_number: response.data.profile.nrc_number,
				phone_number: response.data.profile.phone_number,
				physical_address: response.data.profile.physical_address,
				pacra_document: response.data.profile.pacra_document,
				tpin_file: response.data.profile.tpin_file,
				tpin_number: response.data.profile.tpin_number,
				user: response.data.profile.user,


				businessAccountID:response.data.businessAccountID,
				bank_account_number: response.data.profile.bank_account_number,
				
				profilebalance: response.data.balance,
			});

			this.setState({ valuebranch: this.state });

			console.log('get church data ', response);
			if (this.getbranches()) return response;
		} catch (error) {
			// console.log("Error error: ", JSON.stringify(error, null, 4));
			// throw error; todo
		}
	}

	async getbranches() {
		let merchant_code = this.state.merchant_code;

		console.log('pass merchant code to branch get', merchant_code);
		try {
			const response = await axiosInstance.get(
				`/account/branch/businesses/list/${merchant_code}`
			);
			// let branch_res = await axiosInstance.get("/account/branch/businesses/list");
			console.log('get list of branches ', response);
			this.setState({
				branches: response.data,
			});
		} catch (error) {}
	}

	editModalRef = ({ handleEditShow }) => {
		this.showeditModal = handleEditShow;
	};

	onEditClick = () => {
		this.showeditModal();
	};

	getBranchValue = (value) => {
		this.setState({ editvalue: value });

		console.log('check the values', this.state.editvalue);
		Cookies.set(`branch_data`, {
			branch_data: value,
		});
		this.onEditClick();
	};

	deleteModalRef = ({ handleDeleteShow }) => {
		this.showdeleteModal = handleDeleteShow;
	};

	onDeleteClick = () => {
		this.showdeleteModal();
	};

	DeleteBranchButton = (value) => {
		this.setState({ editvalue: value });

		console.log('check the values', this.state.editvalue);
		Cookies.set(`branch_data`, {
			branch_data: value,
		});
		this.onDeleteClick();
	};

	gotobusinesshistory = (value) => {
		
		console.log("check state before procceeding",this.state.businessAccountID)


		window.location.href = '/BusinessTransactionHistory';
		Cookies.set(`view_business_history`, {
			business_name: this.state.business_name,
			businessAccountID: this.state.businessAccountID

		});
		console.log("testing")
	};


			// Edeposit
			depositModalRef = ({ handledepositShow }) => {
				this.showdepositModal = handledepositShow;
			};
			onDepositClick = () => {
				this.showdepositModal();
				Cookies.set(`get_merchant_code`, {
					merchant_code: this.state.merchant_code,
				
		
				});
			};
		

	render() {
		const error = '/images/error.jpg';
		const { values, handleChange, classes } = this.props;
		const { branches } = this.state;

		return (
			<div>
				<EditBranch ref={this.editModalRef}></EditBranch>
				<DeleteBranch ref={this.deleteModalRef}></DeleteBranch>
				<Depositoptions ref={this.depositModalRef}></Depositoptions>

				<br />
				<br />
				<br />
				<br />

				<Media
					queries={{
						small: '(max-width: 599px)',
						medium: '(min-width: 600px) and (max-width: 1199px)',
						large: '(min-width: 1200px)',
					}}>
					{(matches) => (
						<Fragment>
							{matches.large && (
								<>
									<Grid container spacing={3}>
										<Grid
											item
											xs={6}
											style={{ paddingLeft: '70px' }}>
											{/* <Paper className={classes.paper}> */}

											<Box
												style={{ width: '410px' }}
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<Avatar
														className={
															classes.avatar
														}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'14px',
															}}>
															{' '}
															ZMW{' '}
															<span
																style={{
																	fontSize:
																		'17px',
																}}>
																{
																	this.state
																		.profilebalance
																}
															</span>
														</span>
														<br />
													</Avatar>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<span
														style={{
															fontSize: '14px',
														}}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'17px',
															}}>
															{' '}
															{
																this.state
																	.business_name
															}
														</span>
													</span>
													<br />
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<span
														style={{
															fontSize: '14px',
														}}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'17px',
															}}>
															{' '}
															{
																this.state
																	.contact_phone_number
															}
														</span>
													</span>
													<br />
												</div>

												<DepositFloat />
											</Box>

											<Box
												style={{ width: '410px' }}
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												{/* <button onClick={() => this.setState({showedit:true})}>Open Modal</button> */}

												<span
													style={{
														fontWeight: 'bold',
														fontSize: '20px',
														textAlign: 'center',
														marginLeft: '10%',
													}}>
													Branches
												</span>
												<br />
												<br />
												{/* {branches.map((branch, i) => (
													<>
														<Box
															display='flex'
															bgcolor='background.paper'>
															<Box
																p={1}
																flexGrow={1}
																style={{
																	borderRadius:
																		'12px',
																	cursor: 'pointer',

																	display:
																		'flex',
																	fontWeight:
																		'bold',
																	marginBottom:
																		'10px',
																}}>
																{
																	branch.branch_name
																}
															</Box>
															<Box
																p={1}
																style={{
																	cursor: 'pointer',
																	color: 'red',
																}}
																ref='itemValue'
																value={branch}
																onClick={this.DeleteBranchButton.bind(
																	this,
																	branch
																)}>
																Delete
															</Box>
															<Box
																p={1}
																style={{
																	cursor: 'pointer',
																	color: '#29BD00',
																}}
																ref='itemValue'
																value={branch}
																onClick={this.getBranchValue.bind(
																	this,
																	branch
																)}>
																Edit
															</Box>
														</Box>
													</>
												))} */}
											</Box>

											<Box
												style={{ width: '410px' }}
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												<span
													style={{
														fontWeight: 'bold',
														fontSize: '20px',
														textAlign: 'center',
														marginLeft: '10%',
													}}>
													Transaction History
												</span>
												<br />
												<br />
							
												<span
												onClick={this.gotobusinesshistory}
												style={{
											
													cursor: "pointer",
													color: '#29BD00',
													fontWeight: 'bold',
													textAlign:"center"
												  }}
														>
														Click to View History
													</span>
											</Box>

											{/* </Paper> */}
										</Grid>
										

										<Grid
											item
											xs={6}
											style={{ marginLeft: '-80px' }}>
											<Paper className={classes.paper}>
												<Accordion defaultExpanded>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon />
														}
														aria-controls='panel1c-content'
														id='panel1c-header'>
														<div
															className={
																classes.column
															}
														/>

														<div
															className={
																classes.column
															}>
															<Typography
																className={
																	classes.secondaryHeading
																}>
																Create Branch
															</Typography>
														</div>
													</AccordionSummary>
													<AccordionDetails
														className={
															classes.details
														}>
														<div
															className={
																classes.column
															}
														/>

														<CreateBranch
															valuebranch={
																this.state
																	.valuebranch
															}
														/>
														{/* <CreateBranch business_type='limited' /> */}
													</AccordionDetails>
													<Divider />
												</Accordion>
											</Paper>
											<br />
											<Paper className={classes.paper}>
												<Accordion defaultExpanded>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon />
														}
														aria-controls='panel1c-content'
														id='panel1c-header'>
														<div
															className={
																classes.column
															}
														/>

														<div
															className={
																classes.column
															}>
															<Typography
																className={
																	classes.secondaryHeading
																}>
																Transfer Funds
															</Typography>
														</div>
													</AccordionSummary>
													<AccordionDetails
														className={
															classes.transferdetails
														}>
														<div
															className={
																classes.column
															}
														/>
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'center',
																alignItems:
																	'center',
															}}>
															Select where you are
															sending to ?
														</div>
														<br />

														<div
															style={{
																display: 'flex',
																justifyContent:
																	'center',
																alignItems:
																	'center',
															}}>
													
															{/* <BusinessPerson
																businessBalance={
																	this.state
																		.profilebalance
																}
																sender_merchant_code={
																	this.state
																		.merchant_code
																}
															/> */}
														

															{/* <BusinessBusiness
																businessBalance={
																	this.state
																		.profilebalance
																}
																sender_merchant_code={
																	this.state
																		.merchant_code
																}
															/> */}
															

															<Button
										onClick={this.onDepositClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Transfer Funds
									</Button>
														</div>
													</AccordionDetails>
													<Divider />
												</Accordion>
											</Paper>
										</Grid>
									</Grid>
								</>
							)}

							{matches.small && (
								<>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Box
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<Avatar
														className={
															classes.avatar
														}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'14px',
															}}>
															{' '}
															ZMW{' '}
															<span
																style={{
																	fontSize:
																		'17px',
																}}>
																{
																	this.state
																		.profilebalance
																}
															</span>
														</span>
														<br />
													</Avatar>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<span
														style={{
															fontSize: '14px',
														}}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'17px',
															}}>
															{' '}
															{
																this.state
																	.business_name
															}
														</span>
													</span>
													<br />
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<span
														style={{
															fontSize: '14px',
														}}>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'17px',
															}}>
															{' '}
															{
																this.state
																	.contact_phone_number
															}
														</span>
													</span>
													<br />
												</div>

												<div
													style={{
														marginLeft: '-30px',
													}}>
													<DepositFloat />
												</div>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Box
												//  style={{ width: "410px" }}
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												<span
													style={{
														fontWeight: 'bold',
														fontSize: '20px',
														textAlign: 'center',
														marginLeft: '10%',
													}}>
													Branches small
												</span>
												<br />
												<br />

												{branches.map((branch, i) => (
													<>
														<Box
															display='flex'
															bgcolor='background.paper'>
															<Box
																p={1}
																flexGrow={1}
																style={{
																	borderRadius:
																		'12px',
																	cursor: 'pointer',

																	display:
																		'flex',
																	fontWeight:
																		'bold',
																	marginBottom:
																		'10px',
																}}>
																{
																	branch.branch_name
																}
															</Box>
															<Box
																p={1}
																style={{
																	cursor: 'pointer',
																	color: 'red',
																}}
																ref='itemValue'
																value={branch}
																onClick={this.DeleteBranchButton.bind(
																	this,
																	branch
																)}>
																Delete
															</Box>
															<Box
																p={1}
																style={{
																	cursor: 'pointer',
																	color: '#29BD00',
																}}
																ref='itemValue'
																value={branch}
																onClick={this.getBranchValue.bind(
																	this,
																	branch
																)}>
																Edit
															</Box>
														</Box>
													</>
												))}
											</Box>

											<Box
												style={{ width: '410px' }}
												boxShadow={3}
												bgcolor='background.paper'
												m={1}
												p={1}>
												<span
													style={{
														fontWeight: 'bold',
														fontSize: '20px',
														textAlign: 'center',
														marginLeft: '10%',
													}}>
													Transaction History
												</span>
												<br />
												<br />
							
												<span
												onClick={this.gotobusinesshistory}
												style={{
											
													cursor: "pointer",
													color: '#29BD00',
													fontWeight: 'bold',
													textAlign:"center"
												  }}
														>
														Click to View History
													</span>
											</Box>
										</Grid>

										

										<Grid item xs={11}>
											<Accordion defaultExpanded>
												<AccordionSummary
													expandIcon={
														<ExpandMoreIcon />
													}
													aria-controls='panel1c-content'
													id='panel1c-header'>
													<div />

													<div>
														<Typography
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'20px',
																marginLeft:
																	'12px',
															}}>
															Create Branch
														</Typography>
													</div>
												</AccordionSummary>
												<AccordionDetails
													className={classes.details}>
													<div />

													<CreateBranch
															valuebranch={
																this.state
																	.valuebranch
															}
														/>
												</AccordionDetails>
												<Divider />
											</Accordion>
										</Grid>

										<Grid item xs={11}>
										<Accordion defaultExpanded>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon />
														}
														aria-controls='panel1c-content'
														id='panel1c-header'>
														<div
															className={
																classes.column
															}
														/>

														<div
															className={
																classes.column
															}>
															<Typography
																className={
																	classes.secondaryHeading
																}>
																Transfer Funds
															</Typography>
														</div>
													</AccordionSummary>
													<AccordionDetails
														className={
															classes.transferdetails
														}>
														<div
															className={
																classes.column
															}
														/>
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'center',
																alignItems:
																	'center',
															}}>
															Select where you are
															sending to ?
														</div>
														<br />

														<div
															style={{
																display: 'flex',
																justifyContent:
																	'center',
																alignItems:
																	'center',
															}}>
													
															<Button
										onClick={this.onDepositClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Transfer Funds
									</Button>
														</div>
													</AccordionDetails>
													<Divider />
												</Accordion>




										</Grid>
									</Grid>
								</>
							)}
						</Fragment>
					)}
				</Media>
			</div>
		);
	}
}

Registeredbusinessprofile.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Registeredbusinessprofile);
