import {
  Button,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { InputBase } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { FaRegPaperPlane } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  Footer: {
    backgroundColor: "#F9F8F9",
    color: "#5B5757",
  },

  MiniFooter: {
    backgroundColor: "red",
    color: "#5B5757",
  },
  //define style for input field
  formInput: {
    border: "1px solid #32C709",
    borderRadius: "100px 0px 0px 100px",
    color: "#FFFFFF",
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "#32C709",
    marginTop: 20,
    fontSize: 12,
    width: "200px",
    paddingLeft: 20,
  },

  SubscribeButton: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29AC05",
    margin: "20px 0px 0px 0px ",
    fontSize: 12,
    height: "40px",
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #29AC05",
    borderRadius: "0px 100px 100px 0px",
    //padding: '5px 3px ',
    width: "",
    // boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29AC05",
    },
  },

  FooterContentSection: {
    //minWidth: 275,
    textAlign: "left",
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#F9F8F9",
  },

  MiniFooterCard: {
    boxShadow: "none",
    backgroundColor: "#F1F0F2",
    height: 50,
  },

  CopyrightSection: {
    fontSize: 11,
    textAlign: "left",
    color: "#5B5757",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  FooterSocialIconsSection: {
    fontSize: 11,
    textAlign: "right",
    color: "#5B5757",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },

  SectionTitle: {
    fontWeight: "bolder",
    color: "#5B5757",
  },

  SectionList: {
    fontSize: 12,
    color: "#5B5757",
    marginTop: 4,
  },

  address: {
    fontSize: 12,
    color: "#5B5757",
    marginTop: 4,
    marginLeft: 16,
  },

  SectionListItem: {
    paddingTop: 20,
  },

  //Fine tune the appearance of the icon next to Text in footer
  SectionIcon: {
    fontSize: 16,
    position: "relative",
    top: "4px",
    right: "10px",
  },

  FooterSocialIcon: {
    fontSize: 16,
    position: "relative",
    top: "4px",
    right: "0px",
    color: "#5B5757",
    paddingRight: 6,

    [theme.breakpoints.down("xs")]: {
      right: "0px",
    },
  },

  SectionTitleFirstItem: {
    fontWeight: "bolder",
    color: "#5B5757",
    position: "relative",
    right: "10px",
  },

  Logo: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    align: "right",
  },
}));

const logo = "/images/logo_grey.svg";

const Footer = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container className={classes.Footer}>
        <Grid item xs={0} sm={0} md={0} lg={0} xl={0} />
        <Hidden smDown>
          <Grid item container xs={12} sm={3} md={3} lg={3} xl={3}>
            {/* <Card className={classes.FooterContentSection}>
                <CardContent>
                  <Typography className={classes.SectionTitle}>
                    <img alt="Ekamo Logo" src={logo} className={classes.Logo} />
                  </Typography>
                </CardContent>
              </Card> */}
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
          <Card className={classes.FooterContentSection}>
            <CardContent>
              <Typography className={classes.SectionTitleFirstItem}>
                Contacts Us
              </Typography>
              <Typography className={classes.SectionList}>
                <PhoneIcon className={classes.SectionIcon} />
                (+260) 776 788 751
              </Typography>
              <Typography className={classes.SectionList}>
                <MailOutlinedIcon className={classes.SectionIcon} />
                <Link color="inherit">Info@ekamo.co.zm</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
          <Card className={classes.FooterContentSection}>
            <CardContent>
              <Typography className={classes.SectionTitle}>
                Policies {"&"} Info:
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  Terms and Conditions
                {/* </Link> */}
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  Privacy Policy
                {/* </Link> */}
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  Ekamowallet
                {/* </Link> */}
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  FAQs
                {/* </Link> */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
          <Card className={classes.FooterContentSection}>
            <CardContent>
              <Typography className={classes.SectionTitle}>
                Quick Links
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  Home
                {/* </Link> */}
              </Typography>
              <Typography className={classes.SectionList}>
                {/* <Link color="inherit" href="ww"> */}
                  About Us
                {/* </Link> */}
              </Typography>
              {/* <Typography className={classes.SectionList}>
                  <Link color="inherit" href="ww">
                    Load Fund
                  </Link>
                </Typography>
                <Typography className={classes.SectionList}>
                  <Link color="inherit" href="ww">
                    Pay
                  </Link>
                </Typography> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Card className={classes.FooterContentSection}>
            <CardContent>
              <Typography className={classes.SectionTitle}>
                Subscribe Now
              </Typography>
              <Typography className={classes.SectionList}>
                Subscribe now to get information about Intercitybusticket
                Products, Promotions and More...
              </Typography>
              <form>
                <Typography style={{ display: "inline-flex" }}>
                  <InputBase
                    id="username"
                    type="text"
                    placeholder="Enter Your E-mail Address..."
                    variant="outlined"
                    className={classes.formInput}
                  />
                  <Button
                    className={classes.SubscribeButton}
                    startIcon={<FaRegPaperPlane />}
                  />
                </Typography>
              </form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card className={classes.MiniFooterCard}>
            <CardContent>
              <Typography className={classes.CopyrightSection}>
                Copyright © 2020 Billfold Zambia Limited
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card className={classes.MiniFooterCard}>
            <CardContent>
              <Typography className={classes.FooterSocialIconsSection}>
                <Link>
                  <FacebookIcon className={classes.FooterSocialIcon} />
                </Link>
                <Link>
                  <LinkedInIcon className={classes.FooterSocialIcon} />
                </Link>
                <Link>
                  <TwitterIcon className={classes.FooterSocialIcon} />
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Footer;
