import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Cookies from "js-cookie";
import { IconButton, Avatar } from "@material-ui/core";
import { Popover, Tooltip, Modal } from "react-bootstrap";

import axiosInstance from "../../axiosinstance/axiosApi";
import Spinner from "react-bootstrap/Spinner";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Successmodal from "../success";
class Confirmairtel extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleConfirmairtelShow = this.handleConfirmairtelShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.senddata = this.senddata.bind(this);
    // this.laststep = this.laststep.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      loading: false,
    };
  }
  getmtnLoadfunds() {
    const mtndetails = JSON.parse(Cookies.get("mtndetails"));
    let response = mtndetails;
    this.setState({
      phone_number: mtndetails.phone_number,
      balance: mtndetails.balance,
    });
    console.log("mtn confirm details", mtndetails);
  }
  handleConfirmairtelShow() {
    console.log(this.state);
    this.setState({ show: true,  loading: false,pin:"" });
    this.getmtnLoadfunds();
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );

      console.log("check pin response",response);
      // this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("pin success");

        // window.location.href = "./cashoutSuccess";
        this.senddata();
        // this.handleClose();
      }
      if(response.status === 500){
        toast.error('Internal Server Error, try again later', {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
      }


      if(response.data.error){
      this.setState({ loading: false,pin:"" });

        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      
     
    
    }
  }

  async senddata() {

    const mtndetails = JSON.parse(Cookies.get("mtndetails"));
    const amount = Number(mtndetails.balance) 
    const phone_number = mtndetails.phone_number

    const data = {
      phone_number,
      amount,
    };

    console.log("check the mtn airtime data",data)
    try {
      const response = await axiosInstance.post(
        "/airtel/buy/airtime",
        data
      );
    
      console.log("this is the response from mtn" , response);
   
if(response.data.success){
  this.gotosucess()
}

if(response.data.error){
  this.setState({ loading: false });

  toast.error(response.data.error, {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}


    } catch (error) {
     
      
      this.setState({ loading: false });

      console.log("check the airtel airtime data err",error.response)

      if(error.response.data.error){

      toast.error(error.response.data.error, {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

        if(error.response.status === 500){
          toast.error('Internal Server Error, Try again later', {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
        }

      
    }
  }

  gotosucess = () => {
    this.onLoginClick();
    this.setState({ show: false });
  };

  loginModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    const airtel = "/images/airtel_momo.svg"; //get  Icon
    const mtn = "/images/mtn_momo.svg"; //get  Icon
    const zamtel = "/images/zamtel_momo.svg"; //get  Icon
    const { items, loading } = this.state;
    return (
      <div>
           		<ToastContainer
					position='top-center'
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Successmodal ref={this.loginModalRef}></Successmodal>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
               Airtel Talktime
            </h4>
          </div>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              You want to buy 
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                ZMW {this.state.balance}
              </span>
              <br />
              Airtel airtime for
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {this.state.phone_number}
              </span>
            </div>

            <br />
            <br />
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
              autocomplete='false'
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                value={this.state.pin}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 0% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
            </div>

            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Proceed"
                    className="btn btn-primary"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 1px 0px 0% ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "50%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}

              <br />
              <h5
                style={{
                  fontWeight: "bold",
                  color: "red",
                  textAlign: "center",
                }}
              >
                {this.state.error}
              </h5>
            </div>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Confirmairtel;
