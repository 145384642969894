// import React, { Component } from "react";
import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../axiosinstance/axiosApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputBase } from "@material-ui/core";
import Mtnsuccess from "./mtnsuccess";
import Spinner from "react-bootstrap/Spinner";
// import Paysuccess from "./paysuccess.js";
import "./style.css";

class Depositmtnpin extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCashout = this.sendCashout.bind(this);
    this.handlemtndepositShow = this.handlemtndepositShow.bind(this);

    this.gotosuccess = this.gotosuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      show: false,
      phone_number: "",
      pin: "",
      loading: false,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getmtnDeposit() {
    const mtndeposit = JSON.parse(Cookies.get("mtndeposit"));
    let response = mtndeposit;
    this.setState({
      loading:false,
      amount: mtndeposit.amount,
      phone_number: mtndeposit.phone_number,
    });
    // console.log("Mtn Deposit details", response);
  }

  async handleSubmit() {
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );

      console.log(response.data);
      // this.setState(response.data);

      if (response.data.pin === "success") {
        this.sendCashout();

      }


      if(response.data.error){
        this.setState({ loading: false,pin:"" });
  
          toast.error(response.data.error, {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      return response;
    } catch (error) {
      this.setState({ loading: false });
      
  
    
    }
  }

  async sendCashout() {
    console.log("start the send" )
    this.gotosuccess()
    this.setState({ loading: true });
    // this.gotosuccess();
    // event.preventDefault();
    const mtndeposit = JSON.parse(Cookies.get("mtndeposit"));
    this.setState({ phone_number: mtndeposit.phone_number });

    const data = {
      phone_number: mtndeposit.phone_number,
      amount: mtndeposit.amount,
    };

    // console.log("check for data going tomtn momo server", data);
    try {
      const response = await axiosInstance.post("/mtn/transferfund/", data);

      console.log("check mtn e-deposit response",response )

      // if (response.data.success) {
      //   // console.log("funds loaded");
      //   this.gotosuccess();
      // }
      /* window.location.href = "/"; */
      return response;
    } catch (error) {
      // throw error;
      // console.log("error here",error.response)
    }
  }
 
  handlePinShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({ loading: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  gotoDetails() {
    this.setState({ show: false });
    this.onLoginClick();
  }

  // code for success
  handlemtndepositShow() {
    // console.log(this.state);
    this.setState({ show: true,loading:false });
    this.getmtnDeposit();
  }
  gotosuccess() {
    this.setState({ show: false });
    this.showSuccessModal();
  }

  showSuccessModal = () => {
    this.showModal();
  };

  successModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  render() {
    const { items, loading } = this.state;
    return (
      <div>
                		<ToastContainer
					position='top-center'
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Mtnsuccess ref={this.successModalRef}></Mtnsuccess>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Send Money
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              Deposit
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                K{this.state.amount}
              </span>
              to
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {this.state.phone_number}
              </span>
              from your Ekamo Wallet Account
              <br />
              <br />
              <br />
            </div>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                value={this.state.pin}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />

              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Deposit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <br />
            <h5>{this.state.error}</h5>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Depositmtnpin;
