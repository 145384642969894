import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode';
import './style.css';
import { Spinner } from 'react-bootstrap';
import { cleanup } from '@testing-library/react';
import { Space } from 'antd';

export const ProtectRoutes = (props) => {
	const history = useHistory();

	const [state, setState] = useState({
		token: undefined,
		message: null,
	});

	useEffect(() => {
		try {
			const token = localStorage.getItem('access_token');
			let decoded;

			if (token) {
				decoded = jwt_decode(token);
				console.log('Decoded token is: ', decoded);
			}

			if (decoded.exp < Date.now() / 1000) {
				window.location.reload();
				history.push({
					pathname: '/',
					errorMessage: 'Kindly sign in again.',
				});
			}

			if (!token) {
				window.location.reload();
				return history.push({
					pathname: '/',
					errorMessage: 'Kindly login',
				});
			}

			if (decoded.exp && decoded.exp > Date.now() / 1000) {
				return setState({ token });
			}
			setState({ token });
		} catch (error) {
			window.location.reload();
			return history.push({
				pathname: '/',
				errorMessage: 'kindly signup or login',
			});
		}
		return () => {
			setState((preValues) => {
				return { ...preValues, token: undefined };
			});
		};
	}, []);

	if (state.token === undefined)
		return (
			<div className='spinner-container'>
				<div className='spinners'>
					<Space size='middle'>
						<Spinner />
					</Space>
				</div>
			</div>
		);

	return <div>{props.children}</div>;
};
