/*
  ==========================================================================
  Copyright (C) 2020 Billfold Zambia Limited. All rights reserved.
  
  Object Name  : Contact
  
  Purpose      : To Get Visitor Contact Details
  
  Author       : Bernard Kapinda
  
  Date         : 04-10-2020
  
  Parameters   : None

  Version      : 0.90

  Depends on   : Specify Django API Here!
  ==========================================================================
  */
import React, { Fragment } from "react";
import { Button, Grid, Hidden } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contactFirstColumn: {
    minHeight: 500,
    textAlign: "left",
    borderRadius: 0,
    boxShadow: "none",

    //change style on screens of 600px and below
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      minHeight: 100,
    },
  },

  contactSecondColumn: {
    minHeight: 500,
    textAlign: "left",
    borderRadius: 0,
    // boxShadow: "none"
  },

  contactLocationCard: {
    minHeight: 100,
    textAlign: "left",
    borderRadius: 0,
    // boxShadow: "none"
  },

  contactFormInputCard: {
    minHeight: 200,
    textAlign: "left",
    borderRadius: 0,
    // boxShadow: "none"
  },

  contactTitle: {
    fontWeight: "bolder",
    color: "#000000",

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 20,
    },
  },

  QueriesCard: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 12,
    },
  },

  contactFormTitle: {
    fontWeight: "bolder",
    color: "#000000",
  },

  //define style for input field
  formInput: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    marginTop: 20,
    fontSize: 14,
    width: "100%",
  },

  formInputSubject: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    height: "40px",
    padding: "0px 0px 0px 10px ",
    backgroundColor: "none",
    marginTop: 20,
    fontSize: 14,
    width: "100%",
  },

  formInputMessage: {
    border: "1px solid #979797",
    borderRadius: theme.shape.borderRadius,
    padding: "10px 0px 0px 10px ",
    backgroundColor: "none",
    marginTop: 20,
    width: "100%",
  },

  //define style for Contact button
  ContactButton: {
    color: "white",
    variant: "contained",
    size: "small",
    backgroundColor: "#29BD00",
    margin: "0px 1px 0px 0px ",
    fontSize: 15,
    height: "40px",
    fontWeight: "",
    textTransform: "none",
    border: "1px solid #29BD00",
    borderRadius: "100px 100px 100px 100px",
    padding: "5px 3px ",
    width: "100%",
    boxShadow: "0px 3px 3px #E2E2E2",
    "&:hover": {
      backgroundColor: "#29BD00",
    },
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item container>
        <Hidden mdDown>
          <Grid item xs={12} sm={1} md={1} lg={1} xl={1} />{" "}
        </Hidden>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Card className={classes.contactFirstColumn}>
            <CardContent>
              <Typography variant="h4" className={classes.contactTitle}>
                Contact Us:
              </Typography>
              <Typography className={classes.QueriesCard}>
                For any Queries you may have, fill up the form and our Ekamo
                representative will get in touch with you
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Card className={classes.contactLocationCard}>
                <CardContent>
                  <Typography variant="h5" className={classes.contactFormTitle}>
                    Location:
                  </Typography>
                  <Typography className={classes.contactDescription}>
                   Kabulonga Shopping Centre, Plot No.497, Office Number 13,Chindo Road,Lusaka 10101
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Card className={classes.contactLocationCard}>
                <CardContent>
                  <Typography variant="h5" className={classes.contactFormTitle}>
                    Email:
                  </Typography>
                  <Typography className={classes.contactDescription}>
                    Info@ekamowallet.com
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Card className={classes.contactLocationCard}>
                <CardContent>
                  <Typography variant="h5" className={classes.contactFormTitle}>
                    Call:
                  </Typography>
                  <Typography className={classes.contactDescription}>
                    +260 976 775492
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Card className={classes.contactFormInputCard}>
                <CardContent>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <InputBase
                            id="username"
                            type="text"
                            placeholder="Enter Your Name"
                            variant="outlined"
                            className={classes.formInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <InputBase
                            id="username"
                            type="text"
                            placeholder="Email Address"
                            variant="outlined"
                            className={classes.formInput}
                          />{" "}
                        </Grid>
                      </Grid>
                      <InputBase
                        id="username"
                        type="text"
                        placeholder="Subject"
                        variant="outlined"
                        className={classes.formInputSubject}
                      />
                      <InputBase
                        id="username"
                        multiline
                        rows={4}
                        placeholder="Message"
                        variant="outlined"
                        className={classes.formInputMessage}
                      />
                      <br />
                      <br />
                      <Button className={classes.ContactButton} type="button">
                        Submit
                      </Button>
                      <br />
                      <br />
                    </form>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Contact;
