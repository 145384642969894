import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../axiosinstance/axiosApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, InputBase } from "@material-ui/core";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Depositmtnpin from "./depositmtnpin";
export class Mtndeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_number: "",
      amount: "",
      phone_number_error: "",
			amount_error: "",
      account_type: "mtnmomo",
      state: false,
    };
    this.handlemtnShow = this.handlemtnShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // continue = (e) => {
  //   e.preventDefault();
  //   this.props.nextStep();
  // };

  handlemtnShow() {
    // console.log(this.state);
    this.setState({
       show: true,
      phone_number_error: "",
			amount_error: "",
      phone_number:"",
      amount:""
    });
  }
  handleClose() {
    this.setState({ show: false });
  }

  gotoDetails() {
    this.setState({ show: false });
    this.onmtnClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();


    const maximumamount = "5001"

    if (this.state.phone_number == 0) {
			this.setState({
				phone_number_error: 'Phone Number is Required',
			});
		} else if (this.state.amount == 0) {
			this.setState({
				amount_error: 'Amount is Required',
				phone_number_error: '',
			});
		

		}

    // else if (this.state.amount >= maximumamount) {
		// 	this.setState({
    //     amount_error: "",
		// 		phone_number_error: "",
			
		// 	});
		
    //   toast.error('Sorry, you can not transfer more than k5000', {
    //     position: 'top-center',
    //     autoClose: 4000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });


		// }
    else{
      Cookies.set(`mtndeposit`, {
        phone_number: this.state.phone_number,
        amount: this.state.amount,
        account_type: this.state.account_type,
      });
  
      this.gotoDetails();
    }



 
  }

  mtndepositModalRef = ({ handlemtndepositShow }) => {
    this.showModal = handlemtndepositShow;
  };

  onmtnClick = () => {
    this.showModal();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        	<ToastContainer
					position='top-center'
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Depositmtnpin ref={this.mtndepositModalRef}></Depositmtnpin>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Send Money
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}
            <div style={{ fontSize: "9", textAlign: "center" }}>
              Send Money to your MTN Money account
            </div>
            <br />
            <form autoComplete='off'>
            <InputBase
            	autocomplete='false'
              placeholder="Enter Phone Number"
              name="phone_number"
              className="form-control"
              type="number"
              value={this.state.phone_number}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            <br />
            <br />
            <InputBase
            	autocomplete='false'
              placeholder="Enter Deposit Amount"
              name="amount"
              className="form-control"
              type="number"
              value={this.state.amount}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            </form>
            <br />
            <br />
            <input
              onClick={this.handleSubmit}
              type="submit"
              value="Submit"
              style={{
                color: "white",
                variant: "contained",
                size: "small",
                backgroundColor: "#29BD00",
                margin: "0px 2px 0px 65px ",
                fontSize: 15,
                height: "40px",
                fontWeight: "",
                textTransform: "none",
                textAlign: "center",
                border: "1px solid #29BD00",
                borderRadius: "100px 100px 100px 100px",
                padding: "5px 3px ",
                width: "50%",
                boxShadow: "0px 3px 3px #E2E2E2",
                "&:hover": {
                  backgroundColor: "#29BD00",
                },
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Mtndeposit;
