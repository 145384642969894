import React, { Component } from "react";
import axiosInstance from "../../axiosinstance/axiosApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid, InputBase } from "@material-ui/core";
// import PayBusinessList from "./paybusiness.js";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    // border: "2px solid #C8C8C8",
    // boxShadow: theme.shadows[5],

    background: [
      // ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
      [
        // "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
        "no-repeat",
        "top 100% left 100%",
      ],
      //['white']
    ],
  },

  //define style for country code on mobile screens
});

class EditBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_name: "",
      business_type:"",
      branch_phone_number: "",
      notification_phone_number1:"",
      show: false,
      close: false,
      branch_address:"",
      editvalue:"",
      loading: false,
     
    };
    // this.state = { phone_number: "", amount: "", business_name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.setcookie = this.setcookie.bind(this);
    this.handleEditShow = this.handleEditShow.bind(this);
    // this.cashoutforotherShow = this.cashoutforotherShow.bind(this);

    this.handleClose = this.handleClose.bind(this); //   this closes the modal
  }

  // this code is for the modal
  handleEditShow() {
    const branch_data = JSON.parse(Cookies.get("branch_data"));
    console.log("this is branch edit", branch_data.branch_data);
this.setState({
    branch_name:  branch_data.branch_data.branch_name,
    business_type: branch_data.branch_data.business_type,
    branch_phone_number: branch_data.branch_data.branch_phone_number,
    notification_phone_number1:branch_data.branch_data.notification_phone_number1,
    branch_address:branch_data.branch_data.branch_address,
})
    this.setState({ show: true });
    this.setState({
      loading: false,

     
      phone_number_error: "",
      amount_error: "",
      amount: "",
    });
  }



  handleClose() {
    this.setState({ show: false });
  }

  gotoDetails() {
    this.setState({ show: false });
    this.setState({ loading: true });

    this.onSuccessClick();
  }
  // the modal code ends here

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }



  async handleSubmit() {
    const branch_data = JSON.parse(Cookies.get("branch_data"));
// console.log("check id",branch_data.branch_data.id)
const id = branch_data.branch_data.id
const branch_name = this.state.branch_name;
const branch_phone_number = this.state.branch_phone_number;
const notification_phone_number1 = this.state.notification_phone_number1;
const branch_address = this.state.branch_address;

const data = {branch_name,branch_phone_number,notification_phone_number1,branch_address}
  console.log("check the details on submit",data
  )
  try {
    const response = await axiosInstance.put(`/account/branch/businesses/${id}`, data);
    if(response.data.success){
        console.log("branch create response", response.data.success);
        this.handleClose()
        this.notify();
    }
  }
  catch (error) {
    console.log("check for pay errors", error.response);
  }
}


notify = () => {
    toast.success('Branch has been edited', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
};

  businesslistModalRef = ({ handlePinBusinessShow }) => {
    this.showModal = handlePinBusinessShow;
  };

  onSuccessClick = () => {
    this.showModal();
  };

  render() {
    const { form, formErrors, loading } = this.state;
    const { classes } = this.props;

    return (
      <div>
          <ToastContainer
position="top-center"
autoClose={300000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
        <Modal
          show={this.state.show}
          animation={true}
          centered
          onHide={this.handleClose}
          backdrop='static'
          size="sm" shadow-lg >
      
      <Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Edit Branch
						</h5>
					</div>
            {/* <Fragment> */}
         
      
              <InputBase
                placeholder="Branch Name"
                name="branch_name"
                className="form-control"
                type="text"
                value={this.state.branch_name}
              
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                //   height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />
              <InputBase
                placeholder="Contact No."
                name="branch_phone_number"
                className="form-control"
                type="number"
                value={this.state.branch_phone_number}
              
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                //   height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
<br />
              <InputBase
                placeholder="Notification Number"
                name="notification_phone_number1"
                className="form-control"
                type="number"
                value={this.state.notification_phone_number1}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                //   height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
<br/>
<InputBase
                placeholder="Branch Address"
                name="branch_address"
                className="form-control"
                type="text"
                value={this.state.branch_address}
              
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                //   height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}
              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
<br/>
</Modal>
            </div>

   
    );
  }
}
EditBranch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditBranch);
