import { Card, Col, Collapse, Divider, Image, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { UserDetailsForm } from './userDetailsForm';
import { APIService } from '../api/APIService';
import { set } from 'js-cookie';
import Avatar from 'antd/lib/avatar/avatar';
import { AntDesignOutlined } from '@ant-design/icons';
import { PinChangeForm } from './pinForm';
import { PasswordForm } from './passwordForm';
import { UploadKyc } from './uploadKyc';
import Modal from 'antd/lib/modal/Modal';
import { DetailsDisplay } from './detailsDisplay';
import profileStyles from './profile.module.css';
import { CreatePin } from './createPin';

const { Content } = Layout;

export const ProfilePage = () => {
	const [visible, setVisible] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [userDetails, setUserDetails] = useState({
		balance: 0,
		date_of_birth: '',
		email: '',
		first_name: '',
		last_name: '',
		nrc_number: '',
		phone_number: '',
		my_pin: '',
	});
	const [loading, setLoading] = useState(false);

	useEffect(async () => {
		try {
			setLoading(true);
			const response = await APIService.getUserProfile();
			console.log(response);
			setUserDetails({
				balance: response.balance,
				date_of_birth: Response.date_of_birth,
				email: response.email,
				first_name: response.first_name,
				last_name: response.last_name,
				nrc_number: response.nrc_number,
				phone_number: response.phone_number,
				my_pin: response.my_pin,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	}, []);

	console.log(userDetails);
	return (
		<Layout
			style={{
				height: '100vh',
				padding: '20px',
				paddingTop: '5px',
			}}>
			<Content
				className='site-layout'
				style={{
					marginTop: 64,
					width: '100%',
					paddingRight: '30px',
				}}>
				<div className={profileStyles.main_content_div}>
					<div style={{ width: '100%' }}>
						<div className={profileStyles.details_display_div}>
							<DetailsDisplay details={userDetails} />
						</div>
					</div>
					<div className={profileStyles.left_side_Card_parent_div}>
						<Collapse
							defaultActiveKey={['1']}
							onChange={() => console.log('change')}>
							{/* <Collapse.Panel
								header={<b>Edit Personal Details</b>}
								key='1'
								style={{
									borderColor: '#29bd00',
									borderWidth: '1px',
								}}>
								<UserDetailsForm
									title={<b>Personal Details</b>}
									data={userDetails}
								/>
							</Collapse.Panel> */}

							<Collapse.Panel
								header={
									<b>
										{userDetails.my_pin === true
											? 'Change Pin'
											: 'Create Pin'}
									</b>
								}
								key='2'
								style={{
									borderColor: '#29bd00',
									borderWidth: '1px',
								}}>
								{userDetails.my_pin ? (
									<PinChangeForm title='Change Pin' />
								) : (
									<CreatePin />
								)}
							</Collapse.Panel>

							<Collapse.Panel
								header={<b>Change Password</b>}
								key='3'
								style={{
									borderColor: '#29bd00',
									borderWidth: '1px',
								}}>
								<PasswordForm title='Change Password' />
							</Collapse.Panel>
							<UploadKyc title='Upload Back Of NRC' />
						</Collapse>
					</div>
				</div>
			</Content>
		</Layout>
	);
};

export default ProfilePage;
