import React, { Component } from "react";
import axiosInstance from "../axiosinstance/axiosApi";
import { Button, Modal, Tab, Tabs, Table } from 'react-bootstrap';
import Moment from "moment";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "./myticket.css";
import QRCode from "react-weblineindia-qrcode-generator";
// import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { GoCalendar } from "react-icons/go";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Cookies from "js-cookie";
import Ticketdetails from "./ticketdetails";
import Agentprofile from "./agentprofile";

export class Myticket extends Component {
  state = {
    tickets: [],
    reserved: [],
    usedtickets: [],
    isLoading: true,
    is_valid: "",
    errors: null,
    from: "",
    to: "",
    departure_date: Moment("").format("DD-MM-YYYY"),
    logged_in: localStorage.getItem("access_token") ? true : false,
    showHide: false,
    busTicketObject: "",
    bus_name: "",
    show: false,
			details_state: '',
    bus: null,
    vData: "https://www.weblineindia.com/",
  };

  componentDidMount() {
    this.getBuslist();
    this.getUsedList();
    this.getReservedlist();
    this.handleModalShowHide();
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  onchange = (e) => {
    this.setState({ from: e.target.value });
  };
  ontochange = (e) => {
    this.setState({ to: e.target.value });
  };

  // ticketBuy(value) {
  //   let busTicketObject = value;
  //   this.setState({ busTicketObject: busTicketObject });
  //   console.log("clicked tcket data", busTicketObject);
  // }

  async getBuslist() {
    let response = await axiosInstance.get("/bus/my-tickets/list/");

    this.setState({
      tickets: response.data.data,

      isLoading: false,
    });

    // How Do i Show this grouped Data in my render?

    console.log("tickets i have bought", response.data.data);
    return response;
  }

  async getReservedlist() {
    let response = await axiosInstance.get("/bus/my-tickets/list/");

    this.setState({
      tickets: response.data.data,

      isLoading: false,
    });

    // How Do i Show this grouped Data in my render?

    console.log("tickets i have bought", response.data.data);
    return response;
  }

  async getUsedList() {
    let response = await axiosInstance.get("/bus/used-tickets/list/");

    this.setState({
      usedtickets: response.data.data,

      isLoading: false,
    });

    // How Do i Show this grouped Data in my render?

    console.log("tickets i have used", response.data.data);
    return response;
  }

  // ticketdata(value, e) {
  //   console.log("check ticket details",value)
  //   // let ExpireIn30Minutes = 1 / 48;
  //   // Cookies.set("ticketdetails", value, {
  //   //   expires: ExpireIn30Minutes,
  //   // });
  // }

  ticketdata = (value) => {
		console.log('check the details', value);
		this.setState({ show: true, details_state: value });
	};

  handleClose = () => {
		this.setState({ show: false });
	};
  render() {
    const momo = require("moment");
    const { isLoading, tickets, reserved, from, to, is_valid, usedtickets } =
      this.state;
    const arrow = "/images/arrow.svg"; //get  Icon
    const arrowsmall = "/images/arrowSmallest.svg"; //get  Icon

    const calender = <GoCalendar />;
    const error = "/images/error.jpg";

      return (
        <div>
          <br />
          <br />
          <br />
    
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
						Ticket Details
						</h4>
						<br />
            <Agentprofile/>
						<div
						// style={{
						//   marginLeft: "20%",
						//   justifyContent: "center",
						//   alignItems: "center",
						// }}
						>
							<Typography gutterBottom>
								Bus Name: 
							<span style={{fontWeight:"bold"}}> 
               {this.state.details_state.bus_name}
                </span>	
							</Typography>
              <Typography gutterBottom>
							Refference ID: 
              <span style={{fontWeight:"bold"}}> 
								{this.state.details_state.refference_id}
                </span>
							</Typography>
              <Typography gutterBottom>
							Ticket Number: 
              <span style={{fontWeight:"bold"}}>
								{this.state.details_state.ticket_number}
                </span>
							</Typography>
              
              <Typography gutterBottom>
							From: 
              <span style={{fontWeight:"bold"}}>
								{this.state.details_state.from_location}
                </span>
							</Typography>
              <Typography gutterBottom>
							To: 
              <span style={{fontWeight:"bold"}}>
								{this.state.details_state.to_location}
                </span>
							</Typography>
							<Typography gutterBottom>
						Bus Terminal: 
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.bus_terminal}
                </span>
							</Typography>
							<Typography gutterBottom>
								Departure Date: 
                <span style={{fontWeight:"bold"}}>
								{this.state.details_state.departure_date}
							</span>
							</Typography>
							<Typography gutterBottom>
						Departure Time:
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.departure_time}
                </span>
							</Typography>

              <Typography gutterBottom>
						Ticket Price:
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.price}
                </span>
							</Typography>
					
					
              {this.state.details_state.first_name?
            		<Typography gutterBottom>
							First Name:
              <span style={{fontWeight:"bold"}}>
								{this.state.details_state.first_name}
                </span>
							</Typography>
            : 
           <div/>
            }
							
              {this.state.details_state.last_name?
            		<Typography gutterBottom>
							Last Name:
              <span style={{fontWeight:"bold"}}>
								{this.state.details_state.last_name}
                </span>
							</Typography>
            : 
           <div/>
            }

{this.state.details_state.phone_number?
            		<Typography gutterBottom>
						Phone Number:
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.phone_number}
                </span>
							</Typography>
            : 
           <div/>
            }
					<br />
{this.state.details_state.first_name2?
            		<h5 style={{fontWeight:"bold"}}>Next Of Kin</h5>
                : 
                <div/>
                 }
		

                 {this.state.details_state.first_name2?
            		<Typography gutterBottom>
						First Name:
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.first_name2}
                </span>
							</Typography>
            : 
           <div/>
            }
     {this.state.details_state.last_name2?
            		<Typography gutterBottom>
						Last Name:
            <span style={{fontWeight:"bold"}}>
								{this.state.details_state.last_name2}
                </span>
							</Typography>
            : 
           <div/>
            }
              {this.state.details_state.phone_number2?
            		<Typography gutterBottom>
					Phone Number:
          <span style={{fontWeight:"bold"}}>
								{this.state.details_state.phone_number2}
                </span>
							</Typography>
            : 
           <div/>
            }

{this.state.details_state.relation?
            		<Typography gutterBottom>
				relation:
        <span style={{fontWeight:"bold"}}>
								{this.state.details_state.relation}
                </span>
							</Typography>
            : 
           <div/>
            }
							<br />
						

							<Button
								onClick={this.handleClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
			
					</Modal.Body>
				</Modal>
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              color: "#29BD00",
            }}
          >
            My Tickets
          </h3>
        
        
<br/>
          <Tabs defaultActiveKey="purchased" id="uncontrolled-tab-example">
            <Tab eventKey="purchased" title="Purchased">
              <div style={{ marginTop: "18px" }}>
                <div
                  className="explore-card-columns  container-fluid"
                  style={{ marginBottom: "100px" }}
                >
                  {tickets.map((busticket, i) => (
                    <div>
                      <div className="card" key={busticket.id}>
                        <div
                          style={{
                            margin: "0px",

                            textAlign: "left",

                            marginRight: "10px",

                            borderRadius: "10px 10px 0px 0px",
                            background: [["#29BD00"]],
                            color: "white",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              textAlign: "left",
                              fontSize: "20px",
                              padding: "0px",
                              marginLeft: "12px",
                            }}
                          >
            
                		<Typography gutterBottom>
				 <span style={{ marginLeft: "4px" }}>
                              {busticket.first_name}
                            </span>

                            <span style={{ marginLeft: "4px" }}>
                              {busticket.last_name}
                            </span>
							</Typography>
          
                           
                          </Typography>
                        </div>
                        <Card
                          style={{
                            border: "1px solid #29BD00",
                            borderRadius: "0px 0px 10px 10px",
                            marginRight: "10px",
                            textAlign: "left",
                            wordWrap: "break-word",

                            boxShadow: "none",
                          }}
                        >
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                item
                                style={{
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Bus:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.bus_name}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                item
                                style={{
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Boarding Time:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.boarding_time}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{
                                  // fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Departure Time:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.departure_time}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{
                                  // fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Price:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                K{busticket.price}.00
                              </Grid>
                            </Grid>
                            <Card>
                              <CardContent>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    style={{ fontSize: "11px" }}
                                  >
                                    Departure Date
                                  </Grid>

                                  <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                    <span
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "22px",
                                      }}
                                    >
                                      {busticket.departure_date}
                                    </span>
                                    {/* Sep 2020 */}
                                    <br />
                                    {/* <span style={{ fontSize: "11px" }}>
                                         Wednesday
                                       </span> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    style={{
                                      fontSize: "22px",

                                      color: "#373435",
                                    }}
                                  >
                                    {calender}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                            {/* <Link
                              to="/ticketdetails"
                              type="submit"
                              value="submit"
                              style={{
                                textDecoration: "none",
                                borderColor: "red",
                              }}
                            > */}
                              <Button
                                   style={{
                                    color: "white",
                                    variant: "contained",
                                    size: "small",
                                    backgroundColor: "#29BD00",
                                    margin: "0px 1px 0px 0px ",
                                    marginLeft: "10%",
                                    fontSize: "15px",
                                    height: "30px",
                                    fontWeight: "bolder",
                                    textTransform: "none",
                                    border: "1px solid #29BD00",
                                    borderRadius: "100px 100px 100px 100px",
                                    padding: "5px 3px ",
                                    width: "200px",
                                    boxShadow: "0px 3px 3px #E2E2E2",
                                    "&:hover": {
                                      backgroundColor: "#29BD00",
                                    },
  
                                    marginTop: 25,
                                  }}
                                key={i}
                                ref="itemValue"
                                value={busticket}
                                onClick={this.ticketdata.bind(this, busticket)}
                              >
                                View Details
                              </Button>
                            {/* </Link> */}
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </Tab>

            <Tab eventKey="used" title="Used">
              <div style={{ marginTop: "18px" }}>
                <div
                  className="explore-card-columns  container-fluid"
                  style={{ marginBottom: "100px" }}
                >
                  {usedtickets.map((busticket, i) => (
                    <div>
                      <div className="card">
                        <div
                          style={{
                            margin: "0px",

                            textAlign: "left",

                            marginRight: "10px",

                            borderRadius: "10px 10px 0px 0px",
                            background: [["#29BD00"]],
                            color: "white",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              textAlign: "left",
                              fontSize: "20px",
                              padding: "0px",
                              marginLeft: "12px",
                            }}
                          >
                            <span style={{ marginLeft: "4px" }}>
                              {busticket.first_name}
                            </span>

                            <span style={{ marginLeft: "4px" }}>
                              {busticket.last_name}
                            </span>
                          </Typography>
                        </div>
                        <Card
                          style={{
                            border: "1px solid #29BD00",
                            borderRadius: "0px 0px 10px 10px",
                            marginRight: "10px",
                            textAlign: "left",
                            wordWrap: "break-word",

                            boxShadow: "none",
                          }}
                        >
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                item
                                style={{
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Bus:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.bus_name}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                item
                                style={{
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Boarding Time:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.boarding_time}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{
                                  // fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Departure Time:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {busticket.departure_time}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{
                                  // fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                Price:
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} />
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                K{busticket.price}.00
                              </Grid>
                            </Grid>
                            <Card>
                              <CardContent>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    style={{ fontSize: "11px" }}
                                  >
                                    Departure Date
                                  </Grid>

                                  <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                    <span
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "22px",
                                      }}
                                    >
                                      {busticket.departure_date}
                                    </span>
                                    {/* Sep 2020 */}
                                    <br />
                                    {/* <span style={{ fontSize: "11px" }}>
                                         Wednesday
                                       </span> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    style={{
                                      fontSize: "22px",

                                      color: "#373435",
                                    }}
                                  >
                                    {calender}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                            <Link
                              to="/ticketdetails"
                              type="submit"
                              value="submit"
                              style={{
                                textDecoration: "none",
                                borderColor: "red",
                              }}
                            >
                              <Button
                                style={{
                                  color: "white",
                                  variant: "contained",
                                  size: "small",
                                  backgroundColor: "#29BD00",
                                  margin: "0px 1px 0px 0px ",
                                  marginLeft: "27%",
                                  fontSize: "15px",
                                  height: "30px",
                                  fontWeight: "bolder",
                                  textTransform: "none",
                                  border: "1px solid #29BD00",
                                  borderRadius: "100px 100px 100px 100px",
                                  padding: "5px 3px ",
                                  width: "200px",
                                  boxShadow: "0px 3px 3px #E2E2E2",
                                  "&:hover": {
                                    backgroundColor: "#29BD00",
                                  },

                                  marginTop: 25,
                                }}
                                key={i}
                                ref="itemValue"
                                value={busticket}
                                onClick={this.ticketdata.bind(this, busticket)}
                              >
                                View Details
                              </Button>
                            </Link>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </Tab>
          </Tabs>
        </div>
      );
   
  }
}

export default Myticket;
