import React, { useState, useEffect, Fragment } from 'react';
import {
	AppBar,
	Button,
	IconButton,
	makeStyles,
	InputBase,
	Toolbar,
	Typography,
	Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import '../../App.css';
import Cookies from 'js-cookie';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { FaChurch, FaUserTie, FaUserAlt } from 'react-icons/fa';

import { GiOrganigram } from 'react-icons/gi';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import MobileLogin from "../MobileAccRegistration/MobileLogin";
// import MobileSignUp from "../MobileAccRegistration/MobileSignUp";
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Headerbuttons from './headerbutton';
import HeaderName from './headername';
import axiosInstance from '../../axiosinstance/axiosApi';
import GoToProfile from '../../accounts/gotobusinessprofile';
import CreateBusiness from '../../accounts/createbusiness';
import Council from '../../council/council';

import PermContactCalendarSharpIcon from '@material-ui/icons/PermContactCalendarSharp';
import ShoppingBasketSharpIcon from '@material-ui/icons/ShoppingBasketSharp';
import ShoppingCartSharpIcon from '@material-ui/icons/ShoppingCartSharp';
import EventNoteSharpIcon from '@material-ui/icons/EventNoteSharp';
import LoyaltySharpIcon from '@material-ui/icons/LoyaltySharp';
import EventAvailableSharpIcon from '@material-ui/icons/EventAvailableSharp';
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import ReceiptSharpIcon from '@material-ui/icons/ReceiptSharp';
import TransferWithinAStationSharpIcon from '@material-ui/icons/TransferWithinAStationSharp';
import PersonAddSharpIcon from '@material-ui/icons/PersonAddSharp';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import AccountTreeSharpIcon from '@material-ui/icons/AccountTreeSharp';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import BusinessIcon from '@material-ui/icons/Business';
import { withRouter } from 'react-router-dom';

//=====================================
//Define Styling For The Header
//=====================================
const useStyles = makeStyles((theme) => ({
	header: {
		backgroundColor: '#4a4a4a',
	},

	//define style for Drawer List Content
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},

	drawerText: {
		fontSize: 12,
		fontWeight: 'bold',
	},

	//Define style for Drawer
	paper: {
		padding: '0px 0px 0px 0px',
		background: [
			// Numbers can become default unit automatically.
			[
				'url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)',
				'no-repeat',
				'top -0% left 1%',
			],
			['white'],
			//['linear-gradient(to right, white 98%, #29BD00 1%)']
		],
	},

	loggedinUser: {
		padding: '20px 0px 0px 20px',
		display: 'flex',
	},

	//############
	logolarge: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},

	//define style for header buttons on desktop screens
	HeaderButton: {
		color: 'inherit',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#25A203',
		margin: '3px 3px 2px 0px ',
		fontSize: 11,
		textTransform: 'none',
		fontWeight: 'bold',
		padding: '5px 0px',
		'&:hover': {
			backgroundColor: '#25A203',
		},
	},

	//define style for header buttons on mobile screens
	HeaderButtonMobile: {
		color: 'inherit',
		variant: 'contained',
		size: 'small',
		backgroundColor: '#25A203',
		margin: '0px 1px 0px 0px ',
		fontSize: 13,
		fontWeight: '',
		textTransform: 'none',
		border: '1px solid #25A203',
		borderRadius: theme.shape.borderRadius,
		padding: '5px 3px ',
		'&:hover': {
			backgroundColor: '#25A203',
		},
	},

	//Push Header Items To Right Of Screen
	HeaderAlignRight: {
		flexGrow: 1,
	},

	//define style for password on desktop screens
	HeaderInputPassword: {
		border: '1px solid white',
		borderRadius: theme.shape.borderRadius,
		height: '',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'white',
		margin: '3px 8px 0px 0px ',
		fontSize: 12,
	},

	//define style for country code on desktop screens
	HeaderInputCountryCode: {
		border: '1px solid #25A203',
		borderRadius: '5px 0px 0px 5px ',
		height: '',
		padding: '0px 0px 0px 10px ',
		backgroundColor: '#25A203',
		color: 'inherit',

		margin: '0px 0px 0px 0px ',
		fontSize: 12,
		width: '40px',
	},

	//define style for username on desktop screens
	HeaderInputUsername: {
		border: '1px solid white',
		borderRadius: '0px 4px 4px 0px ',
		height: '',
		padding: '0px 0px 0px 10px ',
		backgroundColor: 'white',
		margin: '0px 4px 0px 0px ',
		fontSize: 12,
	},

	nested: {
		paddingLeft: theme.spacing(4),
		fontSize: 12,
	},

	DrawerIcon: {
		fontSize: 16,
	},
}));

const Header = (props) => {
	const classes = useStyles(); //get all styles defined
	const imgUrl =
		'https://liftandshifttrucking.co.zm/email_signature/ekamo_logo_mobile.svg'; //get Ekamo logo
	const NotMobile = useMediaQuery('(min-width:700px)'); //CHECK IF SCREEN SIZE IS 649PX OR ABOVE
	const isMobile = useMediaQuery('(max-width:699px)'); //CHECK IF SCREEN SIZE IS 648PX OR BELOW
	const [logged_in, setlogged_in] = useState(
		localStorage.getItem('access_token') ? true : false
	);
	let username, password, countrycode, headerbutton;

	if (NotMobile) {
		username = classes.HeaderInputUsername;
		password = classes.HeaderInputPassword;
		headerbutton = classes.HeaderButton;
		countrycode = classes.HeaderInputCountryCode;
	} else {
	}

	// fetchdata(async () => {
	// const res = axiosInstance.get("/user/details/");
	// console.log("check the api for name in use bro", res.data);
	// setData(res.data);
	// });

	// async function fetchData() {
	//   const res = await await axiosInstance.get("/user/details/");
	//   res
	//     .json()
	//     .then((res) => setname(res.data))
	//     .catch(console.log("error"));
	// }

	//==========================================
	//Set Default Drawer anchor to false Onload
	//==========================================
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	//============================================
	//Define Toggle Behavior For Left Drawer Menu
	//============================================
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	//==================================
	//Start Drawer Menu Items
	//==================================
	const { history } = props;
	const itemsList = [
		// {
		//   text: "Create Grocery Budget",
		//   icon: <ShoppingBasketSharpIcon />,
		//   onClick: () => history.push("/CreateGroceryBudget"),
		// },
		// {
		//   text: "My Saved Grocery Budget",
		//   icon: <ShoppingCartSharpIcon />,
		//   onClick: () => history.push("/MySavedGroceryBudget"),
		// },
		// {
		//   text: "Create Event Ticket",
		//   icon: <EventNoteSharpIcon />,
		//   onClick: () => history.push("/CreateEventTicket"),
		// },
		{
			text: 'My Tickets',
			icon: <LoyaltySharpIcon />,
			onClick: () => history.push('/MyTickets'),
		},
		// {
		//   text: "My Booked Events",
		//   icon: <EventAvailableSharpIcon />,
		//   onClick: () => history.push("/MyBookedEvents"),
		// },
		// {
		//   text: "My Savings",
		//   icon: <AccountBalanceWalletSharpIcon />,
		//   onClick: () => history.push("/MySavings"),
		// },
		// {
		//   text: "Incoming Bills",
		//   icon: <ReceiptSharpIcon />,
		//   onClick: () => history.push("/IncomingBills"),
		// },
		// {
		//   text: "Buy & Sell",
		//   icon: <TransferWithinAStationSharpIcon />,
		//   onClick: () => history.push("/BuynSell"),
		// },
		// {
		//   text: "OnBoard Service Agent",
		//   icon: <PersonAddSharpIcon />,
		//   onClick: () => history.push("/OnBoardServiceAgent"),
		// },
		{
			text: 'Transaction Summary',
			icon: <AssessmentSharpIcon />,
			onClick: () => history.push('/TransactionSummary'),
		},
		// {
		//   text: "Switch Account",
		//   icon: <AccountTreeSharpIcon />,
		//   onClick: () => history.push("/SwitchAccount"),
		// },
		// {
		//   text: "Loggout",
		//   icon: <ExitToAppSharpIcon />,
		//   onClick: { handlelogout },
		// },
	];

	//Define collapse or expand behavior of Menu item
	const [open, setOpen] = React.useState(false);
	const [openaccounts, setOpenAccounts] = React.useState(false);
	const [showprofile, setShowProfile] = useState(false);
	const [showcreate, setShowCreate] = useState(false);
	const [showcouncil, setShowCouncil] = useState(false);

	// console.log("check the damn names", userdetails);
	const handleClick = () => {
		setOpen(!open);
	};

	useEffect(async () => {
	
		try {
		} catch (err) {
			console.log('error here', err);
		}
		// try {
		// 	const response = await APIService.getUserProfile();
		// 	console.log('response here: ', response);
		// 	setPhone(response.phone_number);
		// } catch (err) {
		// 	console.log(err);
		// }
	}, []);

	const handleAccountsClick = () => {
		setOpenAccounts(!openaccounts);
	};

	const gotoprofile = () => {
		window.location.href = '/Profile';
	};

	const gotoTransferHistory = () => {
		window.location.href = '/transfer-history';
	};

	const gototickets = () => {
		window.location.href = '/MyTickets';
	};

	const gotohome = () => {
		window.location.href = '/';
	};

	const gotoAgentProfile = () => {
		window.location.href = '/LimitedcompanyProfile';
	};

	const gotoLimited = () => {
		window.location.href = '/OrganisationBusinessProfile';
	};

	const gotoorganisation = () => {
		window.location.href = '/OrganisationBusinessProfile';
	};

	const gotoBusinessProfiles = (anchor) => {
		setShowProfile(true);

		// toggleDrawer( false)
	};

	const gotoCreateBusiness = (anchor) => {
		setShowCreate(true);

		// toggleDrawer( false)
	};

	const gotocouncil = (anchor) => {
		setShowCouncil(true);
		// toggleDrawer( false)
	};

	const handlelogout = async (event) => {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	};

	//Create a collapsible List
	const collapsibleList = (anchor) => {
		return (
			<List>
				<div
					onClick={toggleDrawer(anchor, false)}
					onKeyDown={toggleDrawer(anchor, false)}>
					<ListItem button onClick={gotohome}>
						<ListItemIcon>
							<HomeSharpIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='Home'
						/>
					</ListItem>

					<ListItem button onClick={gotoprofile}>
						<ListItemIcon>
							<FaUserAlt className={classes.DrawerIcon} />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='Profile'
						/>
					</ListItem>

					<ListItem button onClick={gotoTransferHistory}>
						<ListItemIcon>
							<HomeSharpIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='Transaction History'
						/>
					</ListItem>

					<ListItem button onClick={gotoBusinessProfiles}>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='View Business Profile'
						/>
					</ListItem>

					<ListItem button onClick={gotoCreateBusiness}>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='Create Business Account'
						/>
						{/* {open ? <ExpandLess /> : <ExpandMore />} */}
					</ListItem>

					<ListItem button onClick={gotocouncil}>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.drawerText }}
							primary='Council Services'
						/>
						{/* {open ? <ExpandLess /> : <ExpandMore />} */}
					</ListItem>
				</div>

				<Collapse in={open} timeout='auto' unmountOnExit>
					<List
						component='div'
						disablePadding
						onClick={toggleDrawer(anchor, false)}
						onKeyDown={toggleDrawer(anchor, false)}>
						<ListItem
							className={classes.nested}
							button
							onClick={() =>
								history.push('/personalbusinessregistered')
							}>
							<ListItemIcon>
								<FaUserTie className={classes.DrawerIcon} />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.drawerText }}
								primary='Registered Business Profile'
							/>
						</ListItem>
						<ListItem
							className={classes.nested}
							button
							onClick={() =>
								history.push('/businessnotregisteredprofile')
							}>
							<ListItemIcon>
								<FaUserTie className={classes.DrawerIcon} />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.drawerText }}
								primary='Unregistered Business Profile'
							/>
						</ListItem>
						<ListItem
							className={classes.nested}
							button
							onClick={gotoLimited}>
							<ListItemIcon>
								<GiOrganigram className={classes.DrawerIcon} />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.drawerText }}
								primary='Limited Company Profile'
							/>
						</ListItem>
						<ListItem
							className={classes.nested}
							button
							onClick={gotoorganisation}>
							<ListItemIcon>
								<FaChurch className={classes.DrawerIcon} />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.drawerText }}
								primary='Church / Organisation Profile'
							/>
						</ListItem>

						<ListItem
							className={classes.nested}
							button
							onClick={gotoAgentProfile}>
							<ListItemIcon>
								<PermContactCalendarSharpIcon
									className={classes.DrawerIcon}
								/>
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.drawerText }}
								primary='Agent Profile'
							/>
						</ListItem>
					</List>
				</Collapse>

				<div
					onClick={toggleDrawer(anchor, false)}
					onKeyDown={toggleDrawer(anchor, false)}>
					{/* <ListItem button onClick={() => history.push("/agent")}>
            <ListItemIcon>
              <PermContactCalendarSharpIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.drawerText }}
              primary="Create Agent Account"
            />
          </ListItem> */}
				</div>

				{/* <ListItem button onClick={handleAccountsClick}>
          <ListItemIcon>
            <AccountTreeSharpIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.drawerText }}
            primary="Switch Account"
          />
          {openaccounts ? <ExpandLess /> : <ExpandMore />}
        </ListItem> */}
				{/* <Collapse in={openaccounts} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <ListItem
              className={classes.nested}
              button
              onClick={() => history.push("/personalbusinessregistered")}
            >
              <ListItemIcon>
                <FaUserTie className={classes.DrawerIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary="Personal Business With Pacra"
              />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => history.push("/businessnotregisteredprofile")}
            >
              <ListItemIcon>
                <FaUserTie className={classes.DrawerIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary="Personal Business Without Pacra"
              />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => history.push("/limitedcompany")}
            >
              <ListItemIcon>
                <GiOrganigram className={classes.DrawerIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary="Company Business"
              />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => history.push("/church")}
            >
              <ListItemIcon>
                <FaChurch className={classes.DrawerIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary="Church / Organisation"
              />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => history.push("/AgentBusinessProfile")}
            >
              <ListItemIcon>
                <PermContactCalendarSharpIcon className={classes.DrawerIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary="Agent"
              />
            </ListItem>
          </List>
        </Collapse> */}
			</List>
		);
	};

	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			role='presentation'
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}>
			<List>
				{/*Loop Through List Menu Items */}
				{/* {itemsList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} onClick={onClick}>
              {icon && (
                <ListItemIcon className={classes.drawerText}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.drawerText }}
                primary={text}
              />
            </ListItem>
          );
        })} */}

				<ListItem onClick={gototickets}>
					<ListItemIcon>
						<LoyaltySharpIcon />
					</ListItemIcon>
					<span
						style={{
							fontSize: '12px',
							fontWeight: 'bold',
							cursor: 'pointer',
						}}>
						My Tickets
					</span>
				</ListItem>

				<ListItem onClick={handlelogout}>
					<ListItemIcon>
						<ExitToAppSharpIcon />
					</ListItemIcon>
					<span
						style={{
							fontSize: '12px',
							fontWeight: 'bold',
							cursor: 'pointer',
						}}>
						Log out
					</span>
				</ListItem>
			</List>
		</div>
	);
	//==============================
	//End Drawer Menu Items
	//==============================

	//==================================
	//Start AppBar Top Menu
	//==================================
	return (
		<AppBar position='fixed' elevation={0} className={classes.header}>
			<GoToProfile
				show={showprofile}
				close={() => setShowProfile(false)}
			/>
			<CreateBusiness
				show={showcreate}
				close={() => setShowCreate(false)}
			/>
			<Council show={showcouncil} close={() => setShowCouncil(false)} />

			<Toolbar>
				{/*====================================
                                Trigger Drawer On IconButton Click
                               ====================================*/}
				{['left'].map((anchor) => (
					<Fragment key={anchor}>
						{logged_in ? (
							<IconButton
								edge='start'
								color='inherit'
								aria-label='menu'
								onClick={toggleDrawer(anchor, true)}>
								{<MenuIcon />}
							</IconButton>
						) : (
							<></>
						)}

						<Drawer
							classes={{ paper: classes.paper }}
							anchor={anchor}
							open={state[anchor]}
							onClose={toggleDrawer(anchor, false)}>
							{/* <div item className={classes.loggedinUser}> */}
							<HeaderName />
							{/* </div> */}
							{collapsibleList(anchor)}
							<Divider />
							{list(anchor)}
						</Drawer>
					</Fragment>
				))}

				<Typography>
					{/*Show Header Logo On smallest screens and show Buttons*/}
					{isMobile ? (
						''
					) : (
						<Avatar
							alt='Ekamo'
							src={imgUrl}
							className={classes.logolarge}
						/>
					)}
				</Typography>
				<Typography className={classes.HeaderAlignRight}>
					{/*Hide Header Logo On small screens only show textfields & Buttons*/}
					{isMobile && (
						<Avatar
							alt='Ekamo'
							src={imgUrl}
							className={classes.logolarge}
						/>
					)}
				</Typography>

				<Fragment>
					<Headerbuttons />
				</Fragment>
			</Toolbar>
		</AppBar>
	);

	//End AppBar Top Menu
};

export default withRouter(Header);
