// import React, { Component } from "react";
import React, { Component } from 'react';
import { Popover, Tooltip, Modal, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axiosInstance from '../axiosinstance/axiosApi';
import { Grid, InputBase } from '@material-ui/core';

import Paysuccess from './paysuccess.js';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';

class Paypin extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.verifypin = this.verifypin.bind(this);

		this.makepayment = this.makepayment.bind(this);
		this.handlePinShow = this.handlePinShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			show: false,
			max_chars: "4",
			loading: false,
			merchant_code: '',
			business_name: '',
			charge: null,
			balance_error: '',
			form: {
				pin: '',
			},
			formErrors: {
				pin: null,
			},
		};
	}

	// this function makes sure only numbers are entered
	validateNumber = (evt) => {
		var theEvent = evt || window.event;

		// Handle paste
		if (theEvent.type === 'paste') {
			key = theEvent.clipboardData.getData('text/plain');
		} else {
			// Handle key press
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	};

	// this function is for the changes being put in the form, it sets the new value to the form object
	handleChange = (e) => {
		const { name, value, checked } = e.target;
		const { form, formErrors } = this.state;
		let formObj = {};

		// handle change event except language field
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({ form: formObj }, () => {
			if (!Object.keys(formErrors).includes(name)) return;
			let formErrorsObj = {};

			this.setState({ formErrors: formErrorsObj });
		});
	};

	// this function validates the input field
	validateField = (name, value, refValue) => {
		let errorMsg = null;
		switch (name) {
			case 'pin':
				if (!value) errorMsg = 'Pin is required.';
				break;

			default:
				break;
		}
		return errorMsg;
	};

	validateForm = (form, formErrors, validateFunc) => {
		const errorObj = {};
		Object.keys(formErrors).map((x) => {
			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});
		return errorObj;
	};

	handleSubmit = () => {
		const { form, formErrors } = this.state;
		this.setState({ loading: true });
		const errorObj = this.validateForm(
			form,
			formErrors,
			this.validateField
		);
		if (Object.keys(errorObj).length !== 0) {
			this.setState({ formErrors: { ...formErrors, ...errorObj } });
			return false;
		}
		// console.log("Data: ", form);
		this.verifypin();
	};

	async verifypin() {
		const { form } = this.state;
		try {
			const response = await axiosInstance.get(
				`pin/verification/${form.pin}`
			);

			if (response.data.error) {
				this.setState({ loading: false });
				return toast.error('Wrong pin', {
					position: 'top-center',
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}

			if (response.data.pin === 'success') {
				this.makepayment();
				// this.gottosuccess();
				// this.handleClose();
			}

			return response;
		} catch (error) {
			throw error;
		}
	}

	getMerchant() {
		const pay = JSON.parse(Cookies.get('pay'));
		const paybusiness = JSON.parse(Cookies.get('paybusiness'));
		let response = pay;
		this.setState({
			amount: pay.amount,
		});
		this.setState(
			{
				business_name: paybusiness.business_name,
				merchant_code: paybusiness.merchant_code,
				charge: pay.charge,
			},
			() => console.log('cookies details: ', pay)
		);
		// console.log("Get Merchant details", response);
	}

	async makepayment() {
		// this.gottosuccess();
		this.setState({ loading: true });
		// event.preventDefault();
		const pay = JSON.parse(Cookies.get('pay'));
		const paybusiness = JSON.parse(Cookies.get('paybusiness'));
		// this.setState({ merchant_code: pay.merchant_code });

		const data = {
			merchant_code: paybusiness.merchant_code,
			amount: pay.amount,
		};

		try {
			const response = await axiosInstance.put(`/payments/pay/`, data);
			console.log('pay response', response.data);

			if (response.data.success === 'ok') {
				// console.log("pay success");
				this.setState({ loading: false });
				this.gottosuccess();
			} else if (response.data.low_balance === 'Insufient funds') {
				this.setState({
					loading: false,
					balance_error: response.data.low_balance,
				});
				console.log('balance response', response.data.low_balance);
			}

			return response;
		} catch (error) {
			console.log('check for pay errors', error.response.data);
		}
	}

	handlePinShow() {
		// console.log("show this", this.state);
		this.setState({ show: true, balance_error: '', pin: '' });

		this.setState({ loading: false });
		this.getMerchant();
	}

	gottosuccess() {
		this.setState({ show: false });
		this.showSuccessModal();
	}

	handleClose() {
		this.setState({ show: false });
	}

	// gotoDetails() {
	//   this.setState({ show: false });
	//   this.onLoginClick();
	// }

	// code for success
	successModalRef = ({ handleSuccessShow }) => {
		this.showModal = handleSuccessShow;
	};

	showSuccessModal = () => {
		this.showModal();
	};

	// onLoginClick = () => {
	//   this.showModal();
	// };

	render() {
		const { form, formErrors, loading } = this.state;

		return (
			<div>
				<Paysuccess ref={this.successModalRef}></Paysuccess>
				<Modal
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Confirm Details
						</h4>
					</div>
					<Modal.Body>
						<div
							style={{
								fontSize: 12,
								textAlign: 'center',
								marginTop: '-14px',
							}}>
							You want to pay
							<span
								style={{
									fontWeight: 'bolder',
									marginLeft: '5px',
									marginRight: '5px',
								}}>
								ZMW {this.state.amount}
							</span>
							to
							<span
								style={{
									fontWeight: 'bolder',
									marginLeft: '4px',
									marginRight: '4px',
								}}>
								{this.state.business_name}.
							</span>
							You will be charged ZMW{' '}
							<span
								style={{
									fontWeight: 'bolder',
								}}>
								ZMW {this.state.charge}
							</span>{' '}
							for this transaction
							<br />
							<br />
							<br />
						</div>
						<div
							style={{
								fontSize: 12,
								textAlign: 'center',
								marginTop: '-14px',
							}}>
							<span style={{ marginBottom: '9px' }}>
								Enter Pin To Confirm Payment
							</span>
						</div>
						<br />

						<div
							style={{
								// display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<InputBase
							autocomplete="off"
								placeholder='Enter Pin'
								name='pin'
								className='form-control'
								type='password'
								maxLength={this.state.max_chars}
								value={form.pin}
								onChange={this.handleChange}
								value={form.pin}
								onChange={this.handleChange}
								onBlur={this.handleChange}
								onKeyPress={this.validateNumber}
								style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
							/>

							{this.state.balance_error && (
								<>
									<br />
									<span
										style={{
											fontSize: '18px',
											color: 'red',
											textAlign: 'center',
											marginLeft: '55px',
										}}>
										{this.state.balance_error}
									</span>
								</>
							)}
							<br />
							{loading && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<br />
									<br />
									<Spinner
										as='span'
										animation='grow'
										size='lg'
										role='status'
										aria-hidden='true'
										style={{ color: '#29BD00' }}
									/>
									<span style={{ color: '#29BD00' }}>
										Loading...
									</span>
								</div>
							)}

							{!loading && (
								<div>
									<br />

									<input
										onClick={this.handleSubmit}
										type='submit'
										value='Pay'
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 2px 0px 55px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											textAlign: 'center',
											border: '1px solid #29BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '60%',
											boxShadow: '0px 3px 3px #E2E2E2',
											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}
									/>
								</div>
							)}
						</div>
						<br />
						<h5>{this.state.error}</h5>

						<br />
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Paypin;
