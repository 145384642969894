import React, { Component } from "react";
import axiosInstance from "../axiosinstance/axiosApi";
import Spinner from "react-bootstrap/Spinner";

import { Grid, InputBase } from "@material-ui/core";
import Successother from "./successother.js";
import "./cashout.css";

import { Popover, Tooltip, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
class Ekamoothercashout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: "",
      balance: "",
      phone_number_error: "",
      loading: false,
      balance_error: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cashoutforotherShow = this.cashoutforotherShow.bind(this);

    this.handleClose = this.handleClose.bind(this); //   this closes the modal
  }

  // this code is for the modal
  cashoutforotherShow() {
    // console.log(this.state);
    this.setState({
      show: true,
      phone_number: "",
      phone_number_error: "",
      balance_error: "",
      balance: "",
    });
    this.setState({ loading: false });
  }

  handleClose() {
    this.setState({ show: false });
  }

  gotoSuccess() {
    this.setState({ show: false });
    this.onSuccessClick();
  }
  // the modal code ends here

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    if (this.state.phone_number == 0) {
      this.setState({
        phone_number_error: "Phone Number is Required",
      });
    } else if (this.state.balance == 0) {
      this.setState({
        balance_error: "Amount is Required",
        phone_number_error: "",
      });
    } else {
      this.setState({
        loading: true,
        phone_number_error: "",
        balance_error: "",
      });
      Cookies.set(`cashoutother`, {
        phone_number: this.state.phone_number,
        balance: this.state.balance,
      });
      this.gotoSuccess();
    }
  }

  // code for success modal
  successotherModalRef = ({ handlesuccessShow }) => {
    this.showsuccessModal = handlesuccessShow;
  };

  onSuccessClick = () => {
    this.showsuccessModal();
  };

  render() {
    const { items, loading } = this.state;

    return (
      <div>
        <Successother ref={this.successotherModalRef}></Successother>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Cash Out
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              {/* <Fragment> */}

              <div style={{ fontSize: "9", textAlign: "center" }}>
                Cashout For Someone From Your Ekamo Wallet Through
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  Ekamo Agent
                </span>
              </div>

              <br />
              <form autoComplete='off'>
              <InputBase
              autocomplete="off"
                placeholder="Enter Phone Number"
                name="phone_number"
                className="form-control"
                type="number"
                value={this.state.phone_number}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />

              <br />
              {this.state.phone_number_error && (
                <>
                  <span
                    style={{
                      fontSize: "small",
                      color: "red",
                      textAlign: "center",
                      marginLeft: "12%",
                    }}
                  >
                    {this.state.phone_number_error}
                  </span>
                  <br />
                </>
              )}
              <br />
              <InputBase
              autocomplete="off"
                placeholder="Enter Cashout Amount"
                name="balance"
                className="form-control"
                type="number"
                value={this.state.balance}
                onChange={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              </form>
              <br />
              {this.state.balance_error && (
                <>
                  <span
                    style={{
                      fontSize: "small",
                      color: "red",
                      textAlign: "center",
                      marginLeft: "12%",
                    }}
                  >
                    {this.state.balance_error}
                  </span>
                </>
              )}
              <br />

              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                  {/* <span style={{ color: "#29BD00" }}>Loading...</span> */}
                </div>
              )}

              {!loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 0% ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "50%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}

              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Ekamoothercashout;
