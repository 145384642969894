import { Upload, Modal, Divider, Button, message } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../axiosinstance/axiosApi';
import { APIService } from '../api/APIService';
import KYC from '../accounts/kyc';

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export const UploadKyc = () => {
	const [state, setState] = useState({
		previewVisible: false,
		previewImage: '',
		previewTitle: '',
		fileList: [],
		uploading: false,
		visible: false,
	});

	const [nrc_photo_frontface, setNrc_photo_frontface] = useState();
	const [nrc_photo_backface, setNrc_photo_backface] = useState();
	const [passport_photo, setPassport_photo] = useState();

	const handleCancel = () => setState({ previewVisible: false });

	let nrc_frontface = null;
	const handlenrcUpload = (event) => {
		setNrc_photo_frontface(event.file);
		nrc_frontface = event.file;
	};

	let nrc_backface = null;
	const handlenrcbackfaceUpload = (event) => {
		setNrc_photo_backface(event.file);
		nrc_backface = event.file;
	};

	let passport = null;
	const handlepassportUpload = (event) => {
		setPassport_photo(event.file);
		passport = event.file;
	};
	console.log(passport_photo);
	const onSubmitForm = async (e) => {
		e.preventDefault();
		APIService.updateKyc({
			passport_photo,
			nrc_photo_backface,
			nrc_photo_frontface,
		});
		// try {
		// 	let data = new FormData();
		// 	console.log(passport);
		// 	// // setState({ loading: true });
		// 	data.append('passport_photo', passport_photo);
		// 	data.append('nrc_photo_frontface', nrc_photo_frontface);
		// 	data.append('nrc_photo_backface', nrc_photo_backface);
		// 	console.log('check the number');

		// 	// const response = await axiosInstance.put(
		// 	// 	'/user/profile/update/',
		// 	// 	data
		// 	// );
		// 	// console.log(response);
		// } catch (error) {
		// 	console.log('check for errors', error);
		// }
	};

	// const { uploading, fileList } = state;
	// const props = {
	// 	onRemove: (file) => {
	// 		setState((state) => {
	// 			const index = state.fileList.indexOf(file);
	// 			const newFileList = state.fileList.slice();
	// 			newFileList.splice(index, 1);
	// 			return {
	// 				fileList: newFileList,
	// 			};
	// 		});
	// 	},

	// 	beforeUpload: (file) => {
	// 		setState((state) => ({
	// 			fileList: [...state.fileList, file],
	// 		}));
	// 		return false;
	// 	},
	// };

	return (
		<div style={{ marginBottom: '10px' }}>
			<Modal
				closable={true}
				destroyOnClose={true}
				onCancel={() => setState({ visible: false })}
				width='350px'
				footer={[
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}>
						<Button
							shape='round'
							style={{
								width: '200px',
								borderColor: '#29bd00',
								color: 'white',
								background: '#29bd00',
								fontWeight: 'bold',
							}}
							onClick={onSubmitForm}
							// disabled={fileList.length > 3}
							loading={state.uploading}>
							{state.uploading ? 'Uploading' : 'Start Upload'}
						</Button>
					</div>,
				]}
				visible={state.visible}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						fontWeight: 'bold',
						borderWidth: '2px',
						borderColor: 'black',
					}}>
					{/* <hr /> */}
					{/* {props.title} */}

					<hr style={{ borderColor: 'white' }} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						<b> Upload a clear Potrait</b>
						<hr style={{ borderColor: 'white' }} />
						<Upload onChange={handlepassportUpload}>
							<Button
								shape='round'
								style={{
									width: '200px',
									background: '#29bd00',
								}}
								icon={<UploadOutlined />}>
								Select Photo
							</Button>
						</Upload>
						<Divider style={{ background: '#29bd00' }} />
						<b> Upload Front Of NRC</b>
						<small>The front is where your face is showing</small>
						<hr style={{ borderColor: 'white' }} />
						<Upload onChange={handlenrcbackfaceUpload}>
							<Button
								shape='round'
								style={{
									width: '200px',
									background: '#29bd00',
								}}
								icon={<UploadOutlined />}>
								Select Photo
							</Button>
						</Upload>

						<Divider style={{ background: '#29bd00' }} />
						<b>Upload Back Of NRC</b>
						<small>
							The back part is the part without your potrait
						</small>
						<hr style={{ borderColor: 'white' }} />
						<Upload onChange={handlenrcbackfaceUpload}>
							<Button
								shape='round'
								style={{
									width: '200px',
									background: '#29bd00',
								}}
								icon={<UploadOutlined />}>
								Select Photo
							</Button>
						</Upload>
					</div>
				</div>
			</Modal>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '5px',
				}}>
				{/* <Button
					shape='round'
					style={{ color: 'white', background: '#29bd00' }}
					onClick={() => setState({ visible: true })}>
					Update Account Documents
				</Button> */}
				<KYC />
			</div>
		</div>
	);
};
