import React, { useEffect, useState } from 'react';
import {
	Layout,
	Form,
	Input,
	Button,
	Spassword,
	DatePicker,
	InputNumber,
	Spin,
	Divider,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { APIService } from '../api/APIService';

export const PasswordForm = ({ title }) => {
	const [userPassword, setUserPassword] = useState({
		old_password: '',
		password: '',
		confirm_pasword: '',
	});
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		const response = await APIService.changePassword(values);
		console.log(response);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			name='basic'
			labelCol={{
				span: 8,
			}}
			wrapperCol={{
				span: 16,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignContent: 'center',
				paddingLeft: '10%',
			}}>
			{' '}
			{loading ? (
				<Spin />
			) : (
				<>
					<Form.Item
						className={styles.form_item}
						style={{ textAlign: 'center', color: '#29bd00' }}>
						<b>{title}</b>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='old_password'
						rules={[
							{
								required: true,
								message: 'Please enter your old password',
							},
							{
								min: 6,
								message: 'Minimum of 6 characters',
							},
						]}>
						<Input
							placeholder='Enter old password'
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							type='password'
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='new_password'
						rules={[
							{
								required: true,
								message: 'Please enter your new  password',
							},
							{
								min: 6,
								message: 'Minimum of 6 characters',
							},
						]}>
						<Input
							type='password'
							placeholder='Create new password'
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item
						className={styles.form_item}
						// initialValue={userDetails.first_name}
						name='confirm_password'
						rules={[
							{
								required: true,
								message: 'Please confirm your new password',
							},
							{
								min: 6,
								message: 'Minimum of 6 characters',
							},
						]}>
						<Input
							type='password'
							placeholder='Confirm password'
							style={{
								borderColor: '#29bd00',
								borderRadius: '5px',
								height: '45px',
							}}
							prefix={
								<LockOutlined className='site-form-item-icon' />
							}
						/>
					</Form.Item>

					<Form.Item className={styles.form_item}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}>
							<Button
								shape='round'
								style={{
									color: 'white',
									background: '#29bd00',
								}}
								htmlType='submit'>
								Change Password
							</Button>
						</div>
					</Form.Item>
				</>
			)}
		</Form>
	);
};
