import React, { Fragment, Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Hidden, IconButton, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Media from 'react-media';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Partners from '../components/partners/Partners';
import ListGroup from 'react-bootstrap/ListGroup';
// import Hidden from "@material-ui/core/Hidden";
// import Contact from "./Contact/Contact";
// import Footer from '../Footer/Footer';
// import LoadFundOption from '../LoadFund/LoadFundOption';
// import CashoutOption from '../Cashout/CashoutOption';
// import SearchAvailableBus from '../BusTicket/SearchAvailableBus';
// import Zesco from '../BillPayment/ZescoUnits';
import './home.css';
import Autherror from './autherror';
import axiosInstance from '../axiosinstance/axiosApi';
import CountUp from 'react-countup';
// import PayGotv from "../BillPayment/PayGotv";
// import PayDstv from "../BillPayment/PayDstv";
// import PayTopstar from "../BillPayment/PayTopstar";
// import AirtelAirtime from "../BillPayment/AirtelAirtime";
// import MTNAirtime from "../BillPayment/MTNAirtime";
// import ZamtelAirtime from "../BillPayment/ZamtelAirtime";
import Footer from '../components/footer/Footer';
import Contact from '../components/contact/Contact';

import Ekamocashout from '../cashout/Ekamocashout';
import Mobilemoney from '../loadfunds/mobilemoney';
import Bills from '../bills/bills';
import { Link } from 'react-router-dom';
import Depositoptions from '../deposit/Depositoptions';
import Pay from '../pay/pay';

const styles = (theme) => ({
	card: {
		minWidth: 275,
		minHeight: 330,

		margin: 20,
		textAlign: 'center',
		background: [
			// Set Background Image .
			[
				'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
				'no-repeat',
				'top 100% left 50%',
			],
			['white'],
		],
	},

	longTextCard: {
		minWidth: 275,
		minHeight: 250,
		margin: 20,

		textAlign: 'center',

		//change style on screens of 600px and below
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},

		background: [
			// Set Background Image .
			[
				'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
				'no-repeat',
				'top 100% left 50%',
			],
			['white'],
		],
	},

	textOnlyCardLeft: {
		minWidth: 275,
		textAlign: 'left',
		borderRadius: 0,
		boxShadow: 'none',
	},

	textOnlyCardRight: {
		minWidth: 275,
		textAlign: 'left',
		borderRadius: 0,
		boxShadow: 'none',
	},
	servicesSection: {
		// minWidth: 275,
	
		// minHeight: 20,
		margin: 20,
		textAlign: 'center',
		// borderRadius: '100px 100px 100px 100px ',
		// background: [['#29BD00']],
	
	},

	firstColumn: {
		//minWidth: 275,
		//minHeight: 680,
		// marginTop: 120,
		marginTop: 20,
		paddingLeft: 35,
		//paddingRight:30,
		boxShadow: 'none',

		//On Mobile Screens
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			marginTop: 5,
		},
	},

	title: {
		fontSize: 14,
	},

	cardTitle: {
		fontWeight: 'bolder',
		color: '#000000',
		marginTop: 8,
	},

	longCardTitle: {
		fontWeight: 'bolder',
		color: '#000000',
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: 25,
		},
	},

	cardTitleTextOnly: {
		fontWeight: 'bolder',
		color: '#000000',
	},

	cardDescription: {
		marginTop: 6,
		fontSize: '12px',
	},

	longCardDescription: {
		padding: 40,
		[theme.breakpoints.down('sm')]: {
			marginTop: 13,
			padding: 0,
			textAlign: 'left',
		},
	},

	serviceTitle: {
		marginTop: 4,
		color: '#000',
		fontSize: 14,
	},

	serviceNumber: {
		fontWeight: 'bolder',
		color: '#29BD00',
		marginLeft:"5px",
		marginRight:"5px",
	},
	cardIcon: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		align: 'center',
	},

	spaceBelowHeader: {
		marginBottom: 50,
	},

	tiledcard: {
		fontWeight: 'bolder',
		color: 'white',
		minHeight: 100,
		// minWidth: 100,
		boxShadow: 'none',
		backgroundColor: '#29BD00',
		//border: '1px solid #ffffff',
		borderRadius: 0,
		'&:hover': {
			backgroundColor: '#4dcf29',
		},
	},

	resultsIconSection: {
		marginTop: 0,
		paddingLeft: 55,
		//On Mobile
		[theme.breakpoints.down('sm')]: {
			paddingTop: 13,
			textAlign: 'left',
			paddingLeft: 5,
		},
	},

	resultsText: {
		color: '#979797',
		fontSize: 12,
		paddingTop: 1,
		textAlign: 'left',

		//On Mobile
		[theme.breakpoints.down('sm')]: {
			fontSize: 8,
			paddingTop: 15,
			paddingLeft: 0,
		},
	},

	resultsIcon: {
		paddingBottom: 13,
		//On Mobile
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(5),
			height: theme.spacing(5),
		},
	},

	cardIconContainer: {
		paddingTop: 25,
		'&:hover': {
			backgroundColor: '#4dcf29',
		},
	},

	tiledButton: {
		fontWeight: 'bolder',

		boxShadow: 'none',
		backgroundColor: '#29BD00',
		border: '1px solid #ffffff',
		borderRadius: 0,
		'&:hover': {
			backgroundColor: '#4dcf29',
		},
	},

	tiledText: {
		fontSize: 10,

		//On Mobile
		[theme.breakpoints.down('sm')]: {
			fontSize: 8,
		},
	},

	tiledIcon: {
		width: '100%',
		height: '100%',
		align: 'center',
		//marginTop: 25,
	},

	sectionSpace: {
		marginBottom: 30,
	},

	demo: {
		background: [['#29BD00']],

		minHeight: 200,
		fontSize: 20,
		color: 'white',
		fontWeight: 'bolder',
		textAlign: 'center',
	},
});

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logged_in: localStorage.getItem('access_token') ? true : false,
			totals:''
		};
		this.gettotal = this.gettotal.bind(this);

	}

	componentDidMount() {
		const refreshToken = localStorage.getItem('refresh_token');
	
		this.gettotal();
		this.setState();
	}


	async gettotal() {
	let response = await axiosInstance.get('/onboarding/totals');
console.log("get the total counter",response.data)
this.setState({ totals: response.data });

	}
	// Cashout modal
	cashModalRef = ({ handleShow }) => {
		this.showModal = handleShow;
	};

	onCashoutClick = () => {
		this.showModal();
	};

	// loadfunds code
	loadModalRef = ({ handleloadShow }) => {
		this.showloadModal = handleloadShow;
	};
	onloadClick = () => {
		this.showloadModal();
	};

	// bill payment
	onbillClick = () => {
		this.showbillModal();
	};

	billModalRef = ({ handlebillShow }) => {
		this.showbillModal = handlebillShow;
	};

	// make payment
	payModalRef = ({ handlePayShow }) => {
		this.showpayModal = handlePayShow;
	};

	onPayClick = () => {
		this.showpayModal();
	};

	onTicketClick = () => {
		window.location.href = '/Busticket';
	};

	onBookingClick = () => {
		window.location.href = '/booking/';
	};
	// Edeposit
	depositModalRef = ({ handledepositShow }) => {
		this.showdepositModal = handledepositShow;
	};
	onDepositClick = () => {
		this.showdepositModal();
	};

	successModalRef = ({ handleSuccessShow }) => {
		this.showerrorModal = handleSuccessShow;
	};

	showSuccessModal = () => {
		this.showerrorModal();
	};
	render() {
		const { classes } = this.props;
		//======================
		//Fetch Images
		//======================
		const loadFundIcon =
			'https://liftandshifttrucking.co.zm/email_signature/loadfundCardIcon.svg'; //get Load Fund Icon
		const shopNPayIcon =
			'https://liftandshifttrucking.co.zm/email_signature/shopandpay.svg'; //get Shop N Pay Icon
		const buyTicketIcon =
			'https://liftandshifttrucking.co.zm/email_signature/buyticket.svg'; //get Ticket Icon
		const edepositIcon =
			'https://liftandshifttrucking.co.zm/email_signature/edepositCard.svg'; //get E-Deposit Icon
		const gridIcon = '/images/results_icon.svg';

		// Fetch Billpayment icon
		const loadfund = '/images/LOADFUND.png';
		const tickethome = '/images/EBUSTICKET.png';
		const cahsouthome = '/images/CASHOUT.jpg';
		const payhome = '/images/PAYINSTORE.jpg';
		const totals = '/images/counterpic.jpg';
		const billhome = '/images/BILLPAYMENT.jpg';
		const deposithome = '/images/EDEPOSIT.png';

		//const mobileflipped = useMediaQuery(theme.breakpoints.down('sm'));//on screens with 960px -> 1280px

		return (
			<Fragment>
				<Autherror ref={this.successModalRef}></Autherror>
				<Mobilemoney ref={this.loadModalRef}></Mobilemoney>
				<Pay ref={this.payModalRef}></Pay>
				<Bills ref={this.billModalRef}></Bills>
				<Ekamocashout ref={this.cashModalRef}></Ekamocashout>
				<Depositoptions ref={this.depositModalRef}></Depositoptions>
				<Grid container spacing={0} style={{ marginTop: '4%' }}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						className={classes.spaceBelowHeader}
					/>
					{
						//====================
						//1st Column [Menu Items]
						//====================
					}
					{
						//===============================
						//Start Ekamo Features Card Section
						//================================
					}
					{
						//===================
						//2nd Column Cards
						//====================
					}
					<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
						<Card className={classes.card}>
							<img
								alt='not logged in'
								src={loadfund}
								style={{
									width: '76%',
									height: '165px',
									objectFit: 'cover',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									Load Funds
								</Typography>
								<Typography className={classes.cardDescription}>
									Put your money into Ekamo Wallet and
									Experience the financial freedom of
									transferring your money to any mobile money
									for free or pay for goods and services
									regardless of the network you are using.
								</Typography>
								<br />
								{this.state.logged_in ? (
									<Button
										onClick={this.onloadClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Add Money
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Add Money
									</Button>
								)}
							</CardContent>
						</Card>

						<Card className={classes.card}>
							<img
								alt='not logged in'
								src={deposithome}
								style={{
									width: '76%',
									height: '165px',
									objectFit: 'cover',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									E-Deposit
								</Typography>
								<Typography className={classes.cardDescription}>
									You do not need to withdraw the money from
									this wallet and put in the other wallet.
									With Ekamo system you can deposit/Send money
									to any bank account or mobile money account
									on real-time anywhere in Zambia
								</Typography>
								<br />
								{this.state.logged_in ? (
									<Button
										onClick={this.onDepositClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Send Money
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Send Money
									</Button>
								)}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
						<Card className={classes.card}>
						
							<img
								alt='not logged in'
								src={payhome}
								style={{
									width: '76%',
									height: '165px',
									objectFit: 'cover',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									Pay In-Store
								</Typography>
								<Typography className={classes.cardDescription}>
									This is a 3 step smart payment process that
									allows Ekamo customers to make payments to
									any business registered on Ekamo.
								</Typography>
								<br />

								{this.state.logged_in ? (
									<Button
										onClick={this.onPayClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Pay Now
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Pay Now
									</Button>
								)}
							</CardContent>
						</Card>

						<Card className={classes.card}>
							<img
								alt='not logged in'
								src={billhome}
								style={{
									width: '76%',
									height: '165px',
									objectFit: 'cover',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									Bill Payment
								</Typography>
								<Typography className={classes.cardDescription}>
									Ekamo takes the dread out of waiting in long
									lines at payment centers. With a simple tap
									on your screen, you’re finished paying off
									your Bills.
								</Typography>
								<br />

								{this.state.logged_in ? (
									<Button
										onClick={this.onbillClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Pay Bills
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '40%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Pay Bills
									</Button>
								)}
							</CardContent>
						</Card>
					</Grid>
					{
						//===================
						//3rd Column Cards
						//===================
					}
					<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
						<Card className={classes.card}>
							<img
								alt='cashout image'
								src={cahsouthome}
								style={{
									width: '76%',
									height: '165px',
									objectFit: 'fill',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									Cash Out
								</Typography>
								<Typography className={classes.cardDescription}>
									You can withdraw your money using Ekamo
									Agent, ATM or Zanaco express at the lowest
									service charge ever in Zambia.
								</Typography>
								<br />

								{this.state.logged_in ? (
									<Button
										onClick={this.onCashoutClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '50%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Withdraw Cash
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '50%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Withdraw Cash
									</Button>
								)}
							</CardContent>
						</Card>
						<Card className={classes.card}>
							<img
								alt='buy ticket'
								src={tickethome}
								style={{
									width: '76%',
									height: '165px',
								}}
							/>
							<CardContent>
								<Typography
									variant='h5'
									className={classes.cardTitle}>
									E-Bus Ticket
								</Typography>
								<Typography className={classes.cardDescription}>
									Take more control of your leisure time and
									book your preferred bus seat as early or as
									late as you want with our partners. Skip the
									queue and go straight to the seat.
								</Typography>
								<br />

								{this.state.logged_in ? (
									<Button
										onClick={this.onTicketClick}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '50%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Buy Ticket
									</Button>
								) : (
									<Button
										onClick={this.showSuccessModal}
										style={{
											color: 'white',
											variant: 'contained',
											size: 'small',
											backgroundColor: '#29BD00',
											margin: '0px 1px 0px 0px ',
											fontSize: 15,
											height: '40px',
											fontWeight: '',
											textTransform: 'none',
											border: '1px solid #229BD00',
											borderRadius:
												'100px 100px 100px 100px',
											padding: '5px 3px ',
											width: '50%',
											boxShadow: '0px 3px 3px #E2E2E2',

											'&:hover': {
												backgroundColor: '#29BD00',
											},
										}}>
										Buy Ticket
									</Button>
								)}
							</CardContent>
						</Card>
					</Grid>
				
						<Grid item xs={2} sm={2}>
							{' '}
						</Grid>
				
					{
						//==============================
						//End Ekamo Service Card Section
						//===============================
					}{' '}
				
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className={classes.sectionSpace}
						/>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className={classes.sectionSpace}
						/>
				
					{
						//==============================
						//Start Services Accessed Section
						//================================
					}
					{
						//create space of 2 columns on the left side
					}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
				
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{height:"300px",		backgroundImage: `url(${totals})`,
										// backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover',
										}}>
							<br/>
							<br/>
							<br/>
					




							<Media
					queries={{
						small: '(max-width: 599px)',
						medium: '(min-width: 600px) and (max-width: 1199px)',
						large: '(min-width: 1200px)',
					}}>
					{(matches) => (
						<Fragment>
							{matches.small && (
								<>
										<div
					>
							<Card className={classes.servicesSection}>
								<CardContent>
									<Grid item container>
										<Grid item sm={4} md={4} lg={4} xl={4} style={{marginLeft:"19px",}}>
											
											
											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
												{this.state.totals.total_users}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Personal <br/>
												Accounts
											</Typography>
							
										</Grid>

										<Grid item sm={4} md={4} lg={4} xl={4} style={{marginLeft:"19px"}}>
									
											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
											{this.state.totals.total_agent_accounts}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Agent <br/>
												Accounts
											</Typography>
										

										</Grid>

										<Grid item sm={4} md={4} lg={4} xl={4} style={{marginLeft:"19px"}}>

							


											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
												{this.state.totals.total_business_accounts}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Business <br/>
												Accounts
											</Typography>
	

										</Grid>
									</Grid>
								</CardContent>
							</Card>
							</div>

								</>
							)}


{matches.large && (
	<>
		<Card className={classes.servicesSection}>
								<CardContent>
									<Grid item container>
										<Grid item sm={4} md={4} lg={4} xl={4} >
											
											
											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
												{this.state.totals.total_users}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Personal Accounts
											</Typography>
							
										</Grid>

										<Grid item sm={4} md={4} lg={4} xl={4}>
									
											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
											{this.state.totals.total_agent_accounts}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Agent Accounts
											</Typography>
										

										</Grid>

										<Grid item sm={4} md={4} lg={4} xl={4} >

							


											<Typography
												variant='h4'
												className={
													classes.serviceNumber
												}>
												{this.state.totals.total_business_accounts}
											</Typography>
											<Typography
												variant='h4'
												className={
													classes.serviceTitle
												}>
												Business Accounts
											</Typography>
	

										</Grid>
									</Grid>
								</CardContent>
							</Card>
	</>
)}
						</Fragment>
					)}
				</Media>



							
						</Grid>
					
					{
						//create space of 2 columns on the right side
					}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//==============================
						//End Services Accessed Section
						//===============================
					}
					{
						//=================================================
						//Start What is Ekamo? Text only Explanation Section
						//==================================================
					}
					{
						//create space Above The Section
					}{' '}
					<Hidden smDown>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className={classes.sectionSpace}
						/>
					</Hidden>
					{
						//create space of 2 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
						<Card className={classes.textOnlyCardLeft}>
							<CardContent>
								<Typography
									variant='h4'
									className={classes.cardTitleTextOnly}>
									What is Ekamo?
								</Typography>
								<Typography>
									Ekamo is a digital wallet integrated on a
									digital shared market platform. It is a
									product of Billfold Zambia Limited.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
						<Card className={classes.textOnlyCardRight}>
							<CardContent>
								<Typography>
									Ekamo takes convenience to an entirely
									different level. You can do so much with
									Ekamo. It is designed to effectively take
									away the struggles of braving heat—or
									rain—be it in traffic or long distances in
									rural areas, as well as long lines and
									wasted time. With the hours you save, you
									can invest more in the things that
									infinitely matter more: family, friends,
									passions and health.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//create space of 2 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
						<Card className={classes.textOnlyCardLeft}>
							<CardContent>
								<Typography
									variant='h4'
									className={classes.cardTitleTextOnly}>
									Why Introduce Ekamo?
								</Typography>
								<Typography>
									We believe Ekamo will make financial
									transactions easier for all registered
									users.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
						<Card classes={{ root: classes.textOnlyCardRight }}>
							<CardContent>
								<Typography>
									We target to integrate our system with all
									the banks in Zambia and the 3 network
									providers running the mobile money in the
									country in order to truly help revolutionize
									the way Zambians transact.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					{
						//create space of 2 columns on the right side
					}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//=================================================
						//End What is Ekamo? Text only Explanation Section
						//==================================================
					}
					{
						//============================================================
						//Start What Can You Do With Ekamo Long Card Explanation Section
						//=============================================================
					}
					{
						//create space Above The Section
					}
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						className={classes.sectionSpace}
					/>
					{
						//create space of 4 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<Card className={classes.longTextCard}>
							<CardContent>
								<Typography
									variant='h4'
									classes={{ root: classes.longCardTitle }}>
									Mobile Facilities and Online Banking
									Partners
								</Typography>
								<Typography
									className={classes.longCardDescription}>
									With Ekamo, you never have to worry about
									urgent money matters. You can send money to
									your loved ones or make transactions with
									other users through our partner mobile
									facilities and online banking partners
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					{
						//create space of 2 columns on the right side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//create space Above The Section
					}
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						className={classes.sectionSpace}
					/>
					{
						//create space of 4 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<Card className={classes.longTextCard}>
							<CardContent>
								<Typography
									variant='h4'
									className={classes.longCardTitle}>
									Ekamo Bridging The Gap In Financial
									Institutions
								</Typography>
								<Typography
									className={classes.longCardDescription}>
									Ekamo bridges the gap between the banked and
									unbanked sectors by allowing anyone to
									deposit money to any bank account or mobile
									money account and pay for anything from
									bills to online and offline stores
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					{
						//create space of 2 columns on the right side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//create space of 4 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<Card className={classes.longTextCard}>
							<CardContent>
								<Typography
									variant='h4'
									className={classes.longCardTitle}>
									How Else Can You Maximize Ekamo?
								</Typography>
								<Typography
									className={classes.longCardDescription}>
									You can also use our platform to carry out a
									community fundraising campaign for the
									church, needy, wedding, church groups, sick
									and other Donations
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					{
						//create space of 2 columns on the left side
					}{' '}
					<Hidden smDown>
						<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
							{' '}
						</Grid>
					</Hidden>
					{
						//============================================================
						//End What Can You Do With Ekamo Long Card Explanation Section
						//=============================================================
					}
				</Grid>
				{
					//create space Above The Section
				}
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					className={classes.sectionSpace}
				/>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					className={classes.sectionSpace}
				/>
				<br />
				{
					//Show Ekamo Partners
				}
				<Partners />
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					className={classes.sectionSpace}
				/>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					className={classes.sectionSpace}
				/>
				<br /> <br />
				{/*Show Contact Us*/}
				<Contact />
				<br />
				<br /> <br />
				{/*Show Footer*/}
				<Media
					queries={{
						small: '(max-width: 599px)',
						medium: '(min-width: 600px) and (max-width: 1199px)',
						large: '(min-width: 1200px)',
					}}>
					{(matches) => (
						<Fragment>
							{matches.small && (
								<>
									{this.state.logged_in ? (
										<Fab
											onClick={this.onPayClick}
											style={{
												margin: 0,
												top: 'auto',
												right: 20,
												bottom: 20,
												left: 'auto',
												position: 'fixed',
												background: '#29BD00',
											}}
											aria-label='add'>
											<span
												style={{
													color: 'white',
													fontWeight: 'bold',
												}}>
												Pay
											</span>
										</Fab>
									) : (
										<Fab
											onClick={this.showSuccessModal}
											style={{
												margin: 0,
												top: 'auto',
												right: 20,
												bottom: 20,
												left: 'auto',
												position: 'fixed',
												background: '#29BD00',
											}}
											aria-label='add'>
											<span
												style={{
													color: 'white',
													fontWeight: 'bold',
												}}>
												Pay
											</span>
										</Fab>
									)}
								</>
							)}
						</Fragment>
					)}
				</Media>
				<Footer />
			</Fragment>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Home);
