import React, { Component } from "react";
// import { Text, View } from "react-native";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Nextofkin from "./nextofkin";
import Ticketother from "./ticketother";

export default class Selforother extends Component {
  constructor(props, context) {
    super(props, context);
    // this.gotoCashoutForOther = this.gotoCashoutForOther.bind(this);
    // this.gotoSelfTicket = this.gotoSelfTicket.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.gotoshowselfticket = this.gotoshowselfticket.bind(this);
    this.gotootherticket = this.gotootherticket.bind(this);
    this.state = {
      show: false,
    };
  }

  handleShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  // gotoself() {
  //   window.location.href = "/nextofkin";
  // }

  // gotoother() {
  //   window.location.href = "/ticketother";
  // }

  // code for cashout for other ends here
  selfticketModalRef = ({ selfticketShow }) => {
    this.showselfticketModal = selfticketShow;
  };

  showselfticketdetails = () => {
    this.showselfticketModal();
  };

  gotoshowselfticket() {
    this.setState({ show: false });
    this.showselfticketdetails();
  }

  // code for cashout for other ends here
  otherticketModalRef = ({ otherticketShow }) => {
    this.otherticketModal = otherticketShow;
  };

  otherticketdetails = () => {
    this.otherticketModal();
  };

  gotootherticket() {
    this.setState({ show: false });
    this.otherticketdetails();
  }

  render() {
    return (
      <div>
        <Nextofkin ref={this.selfticketModalRef}></Nextofkin>
        <Ticketother ref={this.otherticketModalRef}></Ticketother>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              E-Bus Ticket
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Who Are You Buying The Ticket For?
              </div>
              <div>
                <Paper
                  elevation={3}
                  onClick={this.gotoshowselfticket}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",

                    display: "flex",
                    height: "66px",
                    marginBottom: "10px",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span style={{ fontWeight: "bolder", marginLeft: "74px" }}>
                      Buy Ticket For Self
                    </span>
                  </div>
                </Paper>

                <br />

                <Paper
                  elevation={3}
                  onClick={this.gotootherticket}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",

                    display: "flex",
                    height: "66px",
                    marginBottom: "10px",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span style={{ fontWeight: "bolder", marginLeft: "74px" }}>
                      Buy Ticket For Other
                    </span>
                  </div>
                </Paper>
              </div>
              <br />
              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
