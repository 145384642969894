import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#80808014",
    height: "190px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Partner() {
  const classes = useStyles();
  const bongohive = "/images/bongohive.svg"; //get  Icon
  const zicta = "/images/zicta.svg"; //get  Icon
  const kazang = "/images/kazang_momo.svg"; //get  Icon
  const boz = "/images/bank-of-zambia.svg"; //get  Icon
  const zamtel = "/images/zamtel.svg"; //get  Icon
  const uncdf = "/images/uncdf.svg"; //get  Icon
  const zanaco = "/images/zanaco.svg"; //get  Icon
  const isMobile = useMediaQuery("(max-width:699px)");

  return (
    <div>
      {isMobile ? (
        <div style={{ flexGrow: 1, background: "#80808014", height: "130px" }}>
          <h4 style={{ textAlign: "center", fontWeight: "bolder" }}>
            Our Partners:
          </h4>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={bongohive}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={zicta}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={kazang}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={boz}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={uncdf}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={zanaco}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.root}>
          <h4 style={{ textAlign: "center", fontWeight: "bolder" }}>
            Our Partners:
          </h4>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={bongohive}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={zicta}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={kazang}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={boz}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={uncdf}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="not logged in"
                  src={zanaco}
                  style={{
                    width: "82px",
                    height: "82px",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
