import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";

class Successmodal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleShow() {
    console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  color: "#4fe422",
                }}
              >
                You will receive a prompt on your phone
              </span>
            </div>
            <br />
            <Button
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "#29BD00",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "40%",
              }}
              onClick={this.handleClose}
            >
              Finish
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Successmodal;
