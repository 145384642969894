import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Timer from '../timer/Timer';
export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {
    
    const hoursMinSecs = { seconds: 31}
  
    return (
        <Modal 
        show={showModal}
         onHide={handleClose}     
           centered
           
        backdrop="static">
            <Modal.Header closeButton>
            <Modal.Title style={{fontWeight:"bold"}}>Session Timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div
        
          >  
            	<div style={{ fontSize: '9', textAlign: 'center' }}>
                Your online session will expire in
						</div>

<div style={{ fontSize: '9', textAlign: 'center' }}>
    <span style={{fontWeight:"bold",fontSize:"26px"}}>
<Timer hoursMinSecs={hoursMinSecs} />
</span>
Please click "Continue" to keep stay logged in:
<br/>
Or click "Logout" to end your session now.
</div>
</div>
<br/>
<div

            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button  style={{ backgroundColor: "red",color:"white", border: "1px solid red",marginLeft:"2px",marginRight:"2px"}} onClick={handleLogout}>
                Logout
            </Button>
            <Button style={{ backgroundColor: "#29BD00",color:"white", border: "1px solid #29BD00",marginLeft:"2px",marginRight:"2px"}} onClick={handleClose}>
                Continue
            </Button>
            </div>
            </Modal.Body>
            
        </Modal>
    )
}