import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";

class Depositsuccess extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
       
          backdrop="static"
          size="sm"
        >
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    color: "#4fe422",
                  }}
                >
                  Transaction Successful
                </span>
              </div>
              <br />
              <br />
              <Button
                style={{
                  background: "#29BD00",
                  border: "1px solid #29BD00",
                  borderRadius: "100px 100px 100px 100px",
                }}
                onClick={this.handleClose}
              >
                Finish
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Depositsuccess;
