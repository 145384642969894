import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CamelCase = (str) => {
	try {
		const arr = str.split('_');

		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join('');
		return str2;
	} catch (err) {
		return err.message;
	}
};

export const notify = (message, type) => {
	if ((type != 'success') | (type != 'error')) {
		return 'toast type must be either success or error';
	}
	toast.type(message, {
		position: 'top-center',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
