import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../axiosinstance/axiosApi";

import Spinner from "react-bootstrap/Spinner";
import { Grid, InputBase } from "@material-ui/core";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import DepositToPersonPin from "./deposittopersonpin";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Depositekamopin from "./Depositekamopin";
export class DepositToPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_number: "",
      amount: "",
      balance: "",
      loading: false,
      account_type: "ekamo",
      phone_number_error: "",
      amount_error: "",
      error_message:"",
      state: false,
    };
	

    this.handlepersonShow = this.handlepersonShow.bind(this);
    this.gottonext = this.gottonext.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlepersonShow() {
    this.setState({ show: true });
    const userdetails = JSON.parse(Cookies.get("userdetails"));
 
    this.setState({
      balance: userdetails.balance,
    });
    this.setState({
      phone_number: "",
      phone_number_error: "",
      amount_error: "",
      amount: "",
    });
  }
  gottonext() {
    this.setState({ show: false });
    this.onmtnClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();

    if (this.state.phone_number == 0) {
      this.setState({
        phone_number_error: "Phone Number is Required",
      });
    } else if (this.state.amount == 0) {
      this.setState({
        amount_error: "Amount is Required",
        phone_number_error: "",
      });
    }
    
    
    // else if (this.state.balance <= this.state.amount) {
     
    //   toast.error("you do not have enough funds", {
		// 		position: 'top-center',
		// 		autoClose: 4000,
		// 		hideProgressBar: true,
		// 		closeOnClick: true,
		// 		pauseOnHover: true,
		// 		draggable: true,
		// 		progress: undefined,
		// 	  });
    // } 
    



    else {
      this.setState({
        loading: true,
        phone_number_error: "",
        amount_error: "",
      });

   

      try {
        const response = await axiosInstance.get(
          `/money/receivers/${this.state.phone_number}`
        );
        console.log("check the response",response)
   
        if(response.statusText == "OK"){
          Cookies.set(`businesstoperson`, {
            phone_number: this.state.phone_number,
            amount: this.state.amount,
            account_type: this.state.account_type,
            first_name:response.data.first_name,
            last_name:response.data.last_name

          });

          this.gottonext();

        }

     
       

        return response;
      } catch (error) {
    
    console.log("check the error",error.response)
        // if(error.response.data.detail == "Not found."){
        //   this.setState({
        //     error_message: "Recipient Number Not Found",
           
        //   });
        // }
      }
    }

  }

  ekamodepositModalRef = ({ handleekamodepositShow }) => {
    this.showModal = handleekamodepositShow;
  };

  onmtnClick = () => {
    this.showModal();
  };


  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        	<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
      <DepositToPersonPin ref={this.ekamodepositModalRef}></DepositToPersonPin>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Send Money
            </h4>
          </div>
          <Modal.Body>
            {/* <Fragment> */}
            <div style={{ fontSize: "9", textAlign: "center" }}>
              Enter receiver's Phone Number
            </div>
            <br />
            <br />
            	{/* <Fragment> */}
				<form autoComplete='off'>
            <InputBase
              placeholder="Enter Phone Number"
              name="phone_number"
              className="form-control"
              type="number"
              value={this.state.phone_number}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />

            <br />
            {this.state.phone_number_error && (
              <>
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "12%",
                  }}
                >
                  {this.state.phone_number_error}
                </span>
                <br />
              </>
            )}
            <br />

            <InputBase
              placeholder="Enter Deposit Amount"
              name="amount"
              className="form-control"
              type="number"
              value={this.state.amount}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />
            </form>
            <br />
            {this.state.amount_error && (
              <>
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "12%",
                  }}
                >
                  {this.state.amount_error}
                </span>
              </>
            )}
            <br />

            <input
              onClick={this.handleSubmit}
              type="submit"
              value="Submit"
              style={{
                color: "white",
                variant: "contained",
                size: "small",
                backgroundColor: "#29BD00",
                margin: "12px 2px 0px 65px ",
                fontSize: 15,
                height: "40px",
                fontWeight: "",
                textTransform: "none",
                textAlign: "center",
                border: "1px solid #29BD00",
                borderRadius: "100px 100px 100px 100px",
                padding: "5px 3px ",
                width: "50%",
                boxShadow: "0px 3px 3px #E2E2E2",
                "&:hover": {
                  backgroundColor: "#29BD00",
                },
              }}
            />
<br/>
<h6 style={{color:"red",fontWeight:"bold",textAlign:"center"}}>{this.state.error_message}</h6>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DepositToPerson;
