import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';
import axiosInstance from '../axiosinstance/axiosApi';
import Spinner from 'react-bootstrap/Spinner';

const GoToProfile = (props) => {
	// const [loginModalShow, setLoginModalShow] = useState(props.showModal);

	const [data, setData] = useState([]);
	const [details, setBusinessDetails] = useState({});
	const [isLoading, setLoading] = useState(false);

	useEffect(async () => {
		setLoading(false);

		const usernumber = JSON.parse(Cookies.get('userdetails'));
		const phone_number = usernumber.phone_number;

		const response = await axiosInstance.get(
			`/business/details/${phone_number}`
		);

		console.log('check my accounts', response);
		if(response.data.results){
			setLoading(false);

			setData(response.data.results);
		}
		else{
			setLoading(true);

		}
	}, {});

	const gotoAgentProfile = () => {
		window.location.href = '/AgentBusinessProfile';
	};

	const gotoLimited = () => {
		window.location.href = '/LimitedcompanyProfile';
	};

	const gotoorganisation = () => {
		window.location.href = '/OrganisationBusinessProfile';
	};

	const gotoUnregisteredProfile = () => {
		window.location.href = '/PersonalBusinessNotRegistered';
	};

	const gotoRegistered = () => {
		window.location.href = '/BusinessRegistered';
	};

	const onBusinessClick = (item) => {
		if (item.account_type == 'organization') {
			console.log('check the value', item.account_type);

			gotoorganisation();
		}

		if (item.account_type == 'agent') {
			console.log('check the value', item.account_type);

			gotoAgentProfile();
		}

		if (
			item.account_type == 'unregistered' ||
			item.account_type == 'unregisterd' ||
			item.account_type == 'unregistered_business' ||
			item.account_type == 'unregisterd_business'
		) {
			console.log('check the value', item.account_type);

			gotoUnregisteredProfile();
		}

		if (item.account_type == 'limited') {
			console.log('check the value', item.account_type);

			gotoLimited();
		}

		if (
			item.account_type == 'personal' ||
			item.account_type == 'business' ||
			item.account_type == 'registered_business' ||
			item.account_type == 'registerd_business'
		) {
			console.log('check the value', item.account_type);

			gotoRegistered();
		}
		// event.preventDefault();
		// const pay = JSON.parse(Cookies.get("pay"));
		// this.setState({ merchant_code: pay.merchant_code });
		// this.gottopin();
	};

	// console.log("PROPS in MODAL", props);
	return (
		<>
			<Modal
				show={props.show}
				onHide={props.close}
				size='sm'
				aria-labelledby='contained-modal-title-vcenter'
				centered>
				<Modal.Header
					style={{
						backgroundColor: '#fff',

						textAlign: 'center',
					}}
					closeButton
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>
						Select Business To View
					</h5>
				</div>
				<Modal.Body>
					<div
						style={{
							// backgroundColor: theme.palette.background.paper,
							// border: "2px solid #C8C8C8",
							// boxShadow: theme.shadows[5],
							// padding: theme.spacing(2, 4, 4),
							// textAlign: "center",
							background: [
								//['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
								[
									'url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)',
									'no-repeat',
									'top 230% left 100%',
								],
								//['white']
							],
						}}></div>
					<div>
						<div>

						{isLoading && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<Spinner
										as='span'
										animation='grow'
										size='lg'
										role='status'
										aria-hidden='true'
										style={{ color: '#29BD00' }}
									/>
									<span style={{ color: '#29BD00' }}>
										Loading Your Accounts
									</span>
								</div>
							)}

{!isLoading && (
	<>
{data.map((item) => (
								<Paper
									elevation={3}
									onClick={() => {
										onBusinessClick(item);
									}}
									style={{
										borderRadius: '12px',
										cursor: 'pointer',

										display: 'flex',
										height: '50px',
										marginBottom: '10px',
									}}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<span
											style={{
												fontWeight: 'bolder',
												marginLeft: '25px',
											}}>
											{item.business_name}
										</span>
									</div>
								</Paper>
							))}
	</>
)}
							
						</div>
					</div>
					<br />
					<br />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default GoToProfile;