import React, { Component } from "react";
import Button from "@material-ui/core/Button";
// import Mobilemoney from "./mobilemoney";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import axiosInstance from "../axiosinstance/axiosApi";

// import Ekamocashout from "./Ekamocashout";
import { Link } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import Zesco from "./zesco";
import TV from "./tv";
import Water from "./water";
import TT from "./tt";
import { Popover, Tooltip, Modal } from "react-bootstrap";

class Bills extends Component {
  constructor(props, context) {
    super(props, context);
    this.handlebillShow = this.handlebillShow.bind(this);
    // this.gottoATM = this.gottoATM.bind(this);
    this.onZescoClick = this.onZescoClick.bind(this);
    this.ontvClick = this.ontvClick.bind(this);
    this.onwaterClick = this.onwaterClick.bind(this);
    this.onttClick = this.onttClick.bind(this);
    

    this.gotowater = this.gotowater.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      showtv: false,
    };
  }

  handlebillShow() {
    // console.log(this.state);
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  //   Pay Zesco bills
  ZescoModalRef = ({ handlezescodetailsShow }) => {
    this.showzescodetailsModal = handlezescodetailsShow;
  };

  gotozesco = () => {
    this.showzescodetailsModal();
  };
  onZescoClick() {
    this.setState({ show: false });
    this.gotozesco();
  }

  // pay tv

  tvModalRef = ({ handletvShow }) => {
    this.showtvModal = handletvShow;
  };

  gototv = () => {
    this.showtvModal();
  };

  ontvClick() {
    this.setState({ show: false });
    this.gototv();
  }

  // pay water
  waterModalRef = ({ handlewaterShow }) => {
    this.showwaterModal = handlewaterShow;
  };

  gotowater = () => {
    this.showwaterModal();
  };

  onwaterClick() {
    this.setState({ show: false });
    this.gotowater();
  }

  // buy airtime
  ttModalRef = ({ handlettShow }) => {
    this.showttModal = handlettShow;
  };

  gotott = () => {
    this.showttModal();
  };

  onttClick() {
    this.setState({ show: false });
    this.gotott();
  }


  
  render() {
    return (
      <div>
        <Zesco ref={this.ZescoModalRef}></Zesco>
        <TV ref={this.tvModalRef}></TV>
        <Water ref={this.waterModalRef}></Water>
        <TT ref={this.ttModalRef}></TT>
      

        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              // backgroundColor: theme.palette.background.paper,
              // border: "2px solid #C8C8C8",
              // boxShadow: theme.shadows[5],
              // padding: theme.spacing(2, 4, 4),
              // textAlign: "center",
              background: [
                //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                [
                  "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                  "no-repeat",
                  "top 230% left 100%",
                ],
                //['white']
              ],
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Bill Payment
              </h4>
            </div>
          </div>
          <Modal.Body>
            <div
              style={{
              
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                 
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Select your Bill option
              </div>
            </div>
            <div>

           
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onZescoClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Pay Zesco
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.ontvClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Pay TV
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onwaterClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Pay Water
                    </span>
                  </div>
                </Paper>
              </div>

           
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onttClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                     Buy Airtime
                    </span>
                  </div>
                </Paper>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Bills;
