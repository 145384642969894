import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Pinsuccess from './pinsuccess';
import axiosInstance from '../axiosinstance/axiosApi';
import TextField from '@material-ui/core/TextField';
import { Navbar, NavItem, Nav } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import moment from 'moment';
import { message } from 'antd';

const styles = (theme) => ({
	paper: {
		// border: "2px solid #C8C8C8",
		// boxShadow: theme.shadows[5],

		background: [
			// ["url(./card_bg.png)", "no-repeat", "top -30% left 1%"],
			[
				// "url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)",
				'no-repeat',
				'top 100% left 100%',
			],
			//['white']
		],
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#29BD00 !important',
	},
	cssLabel: {
		color: 'black !important',
	},

	//define style for country code on mobile screens
});

class DepositFloat extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			phone_number: '',
			business_name: '',
			bank_name: '',
			merchant_code: '',
			amount: '',
			deposit_slip: null,

			refrerence_number: '',
			deposit_date: '',
			initialamount: '',
			loading: false,

			isOpen: false,
		};

		// this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);

		// this.onInputchange = this.onInputchange.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);
		// this.handlekyc = this.handlekyc.bind(this);
		// this.handleClose = this.handleClose.bind(this);
		// this.Close = this.Close.bind(this);

		this.state = {
			show: false,
			error: '',
		};
	}

	componentDidMount() {
		this.setState({
			merchant_code: '',
			business_name: '',
		});
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	deposit_slip = null;

	handleDepositcUpload = (event) => {
		event.preventDefault();
		this.deposit_slip = event.target.files[0];
	};

	async onSubmitForm() {
		// this.handleClose();
		let data = new FormData();

		let initialamount = this.state.initialamount;
		const amount = Number(initialamount);

		// this.setState({ loading: true });

		data.append('deposit_slip', this.deposit_slip);
		data.append('bank_name', this.state.bank_name);
		data.append('merchant_code', this.state.merchant_code);
		data.append(
			'deposit_date',
			moment(this.state.deposit_date).format('YYYY-MM-DD')
		);
		data.append('amount', amount);
		data.append('business_name', this.state.business_name);
		data.append('refrerence_number', this.state.refrerence_number);
console.log("check the data being sent",this.state)
		try {
			const response = await axiosInstance.post(
				'/agents/upload-float',
				data
			);

			console.log('check for server errors', response);


			if (response.data.success){
				toast('Float successfully deposited, you will be notified once its approved', {
					position: 'top-center',
					autoClose: 400000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				this.closeModal()
			}

			// if (response.data.amount) {
			// 	message.success({
			// 		content: 'Float Successfuly Added',
			// 		style: {
			// 			marginTop: '20vh',
			// 		},
			// 	});
			// 	this.setState({ isOpen: false });
			// }
		} catch (error) {
			console.log('check for errors', error.response);
		}
	}

	handlekyc() {
		// console.log(this.state);
		this.setState({ show: true });
	}

	openModal = () => this.setState({ isOpen: true });
	closeModal = () => this.setState({ isOpen: false });

	render() {
		const { classes } = this.props;
		const { items, loading } = this.state;
		return (
			<>
			<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Button
						onClick={this.openModal}
						style={{
							color: '#29BD00',
							variant: 'contained',
							size: 'small',
							width: 'min-content',
							backgroundColor: '#fff',
							margin: '10px 1px 0px 0px ',
							fontSize: 15,
							height: '30px',
							fontWeight: '',
							textTransform: 'none',
							border: '1px solid #29BD00',
							borderRadius: '100px 100px 100px 100px',
							padding: '5px 3px ',
							width: '30%',
							boxShadow: '0px 3px 3px #E2E2E2',
							'&:hover': {
								backgroundColor: '#29BD00',
							},
						}}>
						Load Money
					</Button>
				</div>
				<Modal
					scrollable={true}
					show={this.state.isOpen}
					onHide={this.closeModal}
					centered
					backdrop='static'
					size='sm'>
					<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Bank Deposit: Upload Proof of Payment
						</h6>
					</div>
					<Modal.Body>
						<TextField
							variant='outlined'
							margin='normal'
							required
							size='medium'
							fullWidth
							label='How much did you deposit?'
							type='text'
							name='initialamount'
							value={this.state.initialamount}
							onChange={this.handleChange}
							id='filled-number'
							InputLabelProps={{
								// shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<FormControl style={{ width: '100%' }}>
							<select
								name='bank_name'
								value={this.state.bank_name}
								onBlur={this.handleChange || null}
								onChange={this.handleChange || null}
								style={{
									border: '1px solid #29BD00',
									// borderRadius: "12px",
									borderRadius: '4px',
									height: '50px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'white',
									margin: '0px 2px 0px 0px ',
									fontSize: 14,
									width: '100%',
								}}>
								<option value=''>
									Which bank did you use to deposit ?
								</option>
								<option value='StanBic'>Stanbic Bank</option>
								<option value='Zanaco'>Zanaco Bank</option>
							</select>
						</FormControl>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='What Refference did  you use?'
							type='text'
							name='refrerence_number'
							onBlur={this.handleChange}
							onChange={this.handleChange || null}
							value={this.state.refrerence_number}
							// autoFocus
							id='filled-number'
							InputLabelProps={{
								// shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Date Deposited'
							type='date'
							name='deposit_date'
							onBlur={this.handleChange}
							onChange={this.handleChange || null}
							value={this.state.deposit_date}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							label='Upload Deposit Slip or PoP'
							type='file'
							name='deposit_slip'
							value={this.state.deposit_slip}
							onChange={this.handleDepositcUpload}
							onBlur={this.handleDepositcUpload}
							autoFocus
							id='filled-number'
							InputLabelProps={{
								shrink: true,

								classes: {
									root: classes.cssLabel,
									focused: classes.cssLabel,
								},
							}}
							InputProps={{
								classes: {
									root: classes.notchedOutline,
									focused: classes.notchedOutline,
									notchedOutline: classes.notchedOutline,
								},
							}}
						/>
						<br />

						<span style={{ color: 'red' }}>{this.state.error}</span>
						<br />
						{loading && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Spinner
									as='span'
									animation='grow'
									size='lg'
									role='status'
									aria-hidden='true'
									style={{ color: '#29BD00' }}
								/>
								<span style={{ color: '#29BD00' }}>
									Updating Profile
								</span>
							</div>
						)}

						{!loading && (
							<Button
								onClick={this.onSubmitForm}
								style={{
									color: 'white',
									width: '100%',
									border: '1px solid #29BD00',
									height: '40px',
									margin: '0px 1px 0px 0px',
									padding: '5px 3px',
									variant: 'contained',
									fontSize: '15px',
									// boxshadow: "0px 3px 3px #E2E2E2",

									borderRadius: '100px 100px 100px 100px',
									display: 'block',
									marginRight: 'auto',
									marginLeft: 'auto',
									background: '#29BD00',
									width: '70%',
								}}>
								Proceed
							</Button>
						)}
						<div
							style={{
								background: [
									// add background image
									[
										'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
										'no-repeat',
										'top 10% left 15%',
									],
								],

								padding: 20,
							}}></div>
					</Modal.Body>
				</Modal>
			</>
		);
	}
}

DepositFloat.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DepositFloat);
