import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axiosInstance from "../axiosinstance/axiosApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confirmzesco from "./confirmzesco";
import Spinner from "react-bootstrap/Spinner";
import { Grid, InputBase } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";

export class Zesco extends Component {
  constructor(props) {
    super(props);
    this.state = { initialmeteter_number: "", initial_amount: "", show: false, loading: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlezescodetailsShow = this.handlezescodetailsShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlezescodetailsShow() {
    // console.log(this.state);
    this.setState({ show: true });
    this.setState({ loading: false });
    this.setState({ initialmeteter_number: "" });
    this.setState({ initial_amount: "" });
    this.setState({ loading: false });

  }

  handleClose() {
    this.setState({ show: false });
  }
  gotoDetails() {
    this.setState({ show: false });
    this.onLoginClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    // this.gotoDetails();
    this.setState({ loading: true });
    // const meter_number = this.state.meter_number;
    // const amount = this.state.initial_amount;


   let meter_number = this.state.initialmeteter_number
  //  const meter_number = Number(initialmeteter_number) 


   let initial_amount = this.state.initial_amount
   const amount = Number(initial_amount) 



    const data = {
      meter_number,
      amount,
    };
    console.log("check zesco details", data);
    try {
      const response = await axiosInstance.post(
        "/zesco/customer/details/",
        data
      );
      console.log("this is the zesco response", response);
      // this.setState(response.data);

      Cookies.set(`zescocookie`, {
        confirmation_number: response.data.confirmation_number,
        customer_address: response.data.customer_address,
        customer_name: response.data.customer_name,
        amount: amount,
        meter_number: this.state.initialmeteter_number,
      });

      if (response.data.success === "ok") {
        this.gotoDetails();
      }



      return response;
    } catch (error) {



      // if(error.response.status == 500) {
      
      // this.setState({ loading: false });
      // toast.error('Internal Server Error, try again later', {
      //   position: 'top-center',
      //   autoClose: 4000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      
      
      // }



      this.setState({ loading: false });
if(error.response.data.error){
  toast.error(error.response.data.error, {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
  
      console.log("check if the user in logged in",error.response);
      throw error;
    }
  }
  // 30733736737
  // code for confirm modal
  confirmModalRef = ({ handleConfirmShow }) => {
    this.showModal = handleConfirmShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    const { values, handleChange } = this.props;
    const { form, formErrors, loading } = this.state;
    return (
      <div>
        		<ToastContainer
					position='top-center'
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Confirmzesco ref={this.confirmModalRef}></Confirmzesco>
        <Modal
      scrollable={true}
      show={this.state.show}
      onHide={this.handleClose}
      centered
      backdrop='static'
      size='sm'
        >
        	<Modal.Header
						style={{
							backgroundColor: '#fff',

							textAlign: 'center',
						}}
						closeButton
					/>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Bill Payment
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div style={{ fontSize: "9", textAlign: "center" }}>
                Enter Zesco Meter Number to make payment
              </div>
            </div>
            <br />
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
                placeholder="Enter Zesco Meter No."
                name="initialmeteter_number"
                className="form-control"
                type="number"
                value={this.state.initialmeteter_number}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                onKeyPress={this.validateNumber}
            style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
              />
              <br />

              <br />
              <InputBase
                placeholder="Enter Amount"
                name="initial_amount"
                className="form-control"
                type="number"
                value={this.state.initial_amount}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                onKeyPress={this.validateNumber}
                style={{
									border: '1px solid #29BD00',

									height: '40px',
									padding: '0px 0px 0px 10px ',
									backgroundColor: 'none',
									margin: '0px 2px 0px 12% ',
									fontSize: 14,
									width: '70%',
								}}
              />
              <br />

              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Submit"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Zesco;
