import React, { Component } from "react";
import axiosInstance from "../../axiosinstance/axiosApi";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Login from "../../auth/login";
import Sendotp from "../../auth/register/sendotp";

import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { InputBase } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
class Headerbuttons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      first_name: "",
      is_kyc_approved:"",
      pin:"",
      last_name: "",
      balance: "",
      phone_number: "",
      password: "",
      isLoading: true,
      logged_in: localStorage.getItem("access_token") ? true : false,
      matches: window.matchMedia("(max-width: 699px)").matches,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.getUser = this.getUser.bind(this);
    this.setcookie = this.setcookie.bind(this);
    // this.handleSubmitWThen = this.handleSubmitWThen.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  async handleLogout(event) {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/blacklist/", {
        refresh_token: localStorage.getItem(" "),
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;
      window.location.href = "/";
    
      return response;
    } catch (e) {
  
    }
  }

  async getUser() {
    if (this.state.logged_in) {
      try {
        let response = await axiosInstance.get("/user/details/");
        this.setState({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          balance: response.data.balance,
          phone_number: response.data.phone_number,
					is_kyc_approved: response.data.is_kyc_approved,

          pin: response.data.pin,

        });

        this.setcookie();
        return response;
      } catch (error) {
       
      }
    }
  }

  async setcookie() {
    Cookies.set("userdetails", this.state);
   
  }

  render() {
    return (
      <div>
        {this.state.logged_in ? (
          <div style={{ display: "flex" }}>
            <span
              style={{
                color: "#29BD00",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              ZMW <span style={{ fontSize: "14px" }}>{this.state.balance}</span>
            </span>

            <span
              style={{
                color: "#29BD00",
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {this.state.first_name}
            </span>
          </div>
        ) : (
          <div>
            <div style={{ display: "flex" }}>
              <Loginbutton />

              <Signup />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Headerbuttons;

class Signup extends Component {
  otpModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    return (
      <div>
        <Sendotp ref={this.otpModalRef}></Sendotp>

        <div>
          <Button
            size="small"
            type="submit"
            onClick={this.onLoginClick}
            style={{
              color: "#29BD00",
              variant: "contained",
              size: "small",
              backgroundColor: "#4a4a4a",
              margin: "3px 3px 2px 0px ",
              fontSize: 16,
              textTransform: "none",
              borderColor: "#4a4a4a",
              fontWeight: "bold",
              padding: "5px 0px",
              "&:hover": {
                backgroundColor: "#4a4a4a",
              },
            }}
          >
            Register
          </Button>
          {/* </Link> */}
        </div>
      </div>
    );
  }
}

class Loginbutton extends Component {
  loginModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    return (
      <div>
        <Login ref={this.loginModalRef}></Login>
        <div>
          <Button
            size="small"
            type="submit"
            onClick={this.onLoginClick}
            style={{
              color: "#29BD00",
              variant: "contained",
              size: "small",
              backgroundColor: "#4a4a4a",
              margin: "3px 3px 2px 0px ",
              fontSize: 16,
              textTransform: "none",
              borderColor: "#4a4a4a",
              fontWeight: "bold",
              padding: "5px 0px",
              "&:hover": {
                backgroundColor: "#4a4a4a",
              },
            }}
          >
            Login
          </Button>
        </div>
      </div>
    );
  }
}
