import React, { Component, Fragment } from 'react';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import Verifyotp from './verifyotp';
import Card from '@material-ui/core/Card';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import Pinsuccess from './pinsuccess';
import axiosInstance from '../axiosinstance/axiosApi';

import { Navbar, NavItem, Nav } from 'react-bootstrap';
class Pin extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = { phone_number: '', isLoading: true };
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.Close = this.Close.bind(this);

		this.onInputchange = this.onInputchange.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);
		this.state = {
			show: false,
			error: '',
		};
	}

	onInputchange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	async onSubmitForm() {
		// this.handleClose();

		try {
			const response = await axiosInstance.post('/user/pin/create/', {
				pin: this.state.pin,
			});

			// console.log("servcer response", response.data);
			this.setState(response.data);

			if (response.data.success === 'ok') {
				// console.log("sussess pin");
				this.handleClose();
			} else {
				this.setState({ error: response.data.error });
			}

			return response;
		} catch (error) {
			throw error;
		}
	}

	handleShow() {
		// console.log(this.state);
		this.setState({ show: true });
	}
	handleClose() {
		this.setState({ show: false });
		this.onTokenClick();
	}

	tokenModalRef = ({ handleSuccessShow }) => {
		this.showModal = handleSuccessShow;
	};

	onTokenClick = () => {
		this.showModal();
	};

	Close() {
		this.setState({ show: false });
	}

	render() {
		const { items } = this.state;
		return (
			<div>
				<Pinsuccess ref={this.tokenModalRef}></Pinsuccess>
				<Modal
					show={this.state.show}
					centered
					backdrop='static'
					onHide={this.Close}>
					<Modal.Header closeButton>
						<Modal.Title
							style={{
								textAlign: 'center',
								display: 'block',
								fontSize: '1.17em',
								marginBlockStart: '1em',
								marginBlockEnd: '1em',
								marginInlineStart: '0px',
								marginInlineEnd: '0px',
								fontWeight: 'bold',
							}}>
							Create Pin
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<input
							placeholder='Enter Pin'
							name='pin'
							type='password'
							value={this.state.pin}
							onChange={this.onInputchange}
							style={{
								width: '70%',
								border: '1px solid #979797',
								height: '40px',
								margin: '0px 2px 0px 0px',
								padding: '0px 0px 0px 10px',
								fontSize: '14px',
								borderRadius: '4px',
								display: 'block',
								marginRight: 'auto',
								marginLeft: 'auto',
							}}
						/>
						<br />
						<br />
						<span style={{ color: 'red' }}>{this.state.error}</span>
						<br />
						<Button
							onClick={this.onSubmitForm}
							style={{
								color: 'white',
								width: '100%',
								border: '1px solid #29BD00',
								height: '40px',
								margin: '0px 1px 0px 0px',
								padding: '5px 3px',
								variant: 'contained',
								fontSize: '15px',
								// boxshadow: "0px 3px 3px #E2E2E2",

								borderRadius: '100px 100px 100px 100px',
								display: 'block',
								marginRight: 'auto',
								marginLeft: 'auto',
								background: '#29BD00',
								width: '70%',
							}}>
							Proceed
						</Button>
						<div
							style={{
								background: [
									// add background image
									[
										'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
										'no-repeat',
										'top 10% left 15%',
									],
								],

								padding: 20,
							}}></div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Pin;
