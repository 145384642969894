import React, { Component } from "react";

import Login from "../login";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";

class Accountsuccess extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlesuccessShow = this.handlesuccessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handlesuccessShow() {
 
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  loginModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
    this.handleClose();
  };

  render() {
    const error = "/images/success.jpg";
    return (
      <div>
        <Login ref={this.loginModalRef}></Login>
        <Modal show={this.state.show} centered onHide={this.handleClose}>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="not logged in"
                src={error}
                style={{
                  width: "135px",
                  height: "135px",
                }}
              />
            </div>
            <h5 style={{ textAlign: "center" }}>
              Account Successfully Created, click buttton below to login
            </h5>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                type="submit"
                onClick={this.onLoginClick}
                style={{
                  color: "white",
                  variant: "contained",
                  size: "small",
                  backgroundColor: "#29BD00",
                  margin: "3px 3px 2px 0px ",

                  textTransform: "none",
                  borderColor: "#29BD00",
                  fontWeight: "bold",
                  padding: "5px 0px",
                  "&:hover": {
                    backgroundColor: "#29BD00",
                  },
                }}
              >
                Login here
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Accountsuccess;
