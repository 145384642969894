import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import LWSC from "./lwsc/lwsc";

import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import MWS from "./mws/mws";
import NWS from "./nws/nws";
import NWWS from "./nwws/nwws";
class Water extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlewaterShow = this.handlewaterShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onlwscClick = this.onlwscClick.bind(this);
    this.onmwsClick = this.onmwsClick.bind(this);
    this.onnwsclick = this.onnwsclick.bind(this);
    this.onnwwsClick = this.onnwwsClick.bind(this);
    // this.gotodstv = this.gotodstv.bind(this);
    this.state = {
      show: false,
    };
  }

  handlewaterShow() {
    console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  // lwsc modal here
  lwscModalRef = ({ handlelwscShow }) => {
    this.showModal = handlelwscShow;
  };

  gotolwsc = () => {
    this.showModal();
  };

  onlwscClick() {
    this.setState({ show: false });
    this.gotolwsc();
  }

  // mws modal here
  mwsModalRef = ({ handlemwsShow }) => {
    this.showmwsModal = handlemwsShow;
  };

  gotomws = () => {
    this.showmwsModal();
  };

  onmwsClick() {
    this.setState({ show: false });
    this.gotomws();
  }

  // nws modal here
  nwsModalRef = ({ handlenwsShow }) => {
    this.shownwsModal = handlenwsShow;
  };

  gotonws = () => {
    this.shownwsModal();
  };

  onnwsclick() {
    this.setState({ show: false });
    this.gotonws();
  }

  // nwws modal here
  nwwsModalRef = ({ handlenwwsShow }) => {
    this.shownwwsModal = handlenwwsShow;
  };

  gotonwws = () => {
    this.shownwwsModal();
  };

  onnwwsClick() {
    this.setState({ show: false });
    this.gotonwws();
  }

  render() {
    const nkana = "/images/nkana.png"; //get  Icon
    const mulonga = "/images/mulonga.png"; //get  Icon
    const north = "/images/north.png"; //get  Icon
    const lusaka = "/images/lusaka.png"; //get  Icon

    return (
      <div>
        <LWSC ref={this.lwscModalRef}></LWSC>
        <MWS ref={this.mwsModalRef}></MWS>
        <NWS ref={this.nwsModalRef}></NWS>
        <NWWS ref={this.nwwsModalRef}></NWWS>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />
          <div
            style={{
              // backgroundColor: theme.palette.background.paper,
              // border: "2px solid #C8C8C8",
              // boxShadow: theme.shadows[5],
              // padding: theme.spacing(2, 4, 4),
              // textAlign: "center",
              background: [
                //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                [
                  "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                  "no-repeat",
                  "top 230% left 100%",
                ],
                //['white']
              ],
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Bill Payment
              </h4>
            </div>
          </div>
          <Modal.Body>
            <div
              style={{
                // backgroundColor: theme.palette.background.paper,
                // border: "2px solid #C8C8C8",
                // boxShadow: theme.shadows[5],
                // padding: theme.spacing(2, 4, 4),
                // textAlign: "center",
                background: [
                  //['url(https://liftandshifttrucking.co.zm/email_signature/drawer-bg.png)', 'no-repeat', 'top -0% left 1%'],
                  [
                    "url(https://liftandshifttrucking.co.zm/email_signature/card_bg.png)",
                    "no-repeat",
                    "top 230% left 100%",
                  ],
                  //['white']
                ],
              }}
            >
              <div
                style={{
                  fontSize: "9",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                Select your Water option
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onlwscClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Lusaka Water
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onmwsClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Mulonga
                    </span>
                  </div>
                </Paper>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onnwsclick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      Nkana Water
                    </span>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  onClick={this.onnwwsClick}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "75%",
                    display: "flex",
                    height: "50px",
                    marginBottom: "10px",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <br />
                  <div style={{ marginTop: "18px" }}>
                    <span
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "0%",
                        fontSize: "12px",
                      }}
                    >
                      North Western Water
                    </span>
                  </div>
                </Paper>
              </div>
            </div>
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Water;
