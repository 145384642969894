import React, { Component } from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../axiosinstance/axiosApi";

import Spinner from "react-bootstrap/Spinner";
import "./cashout.css";

import { Grid } from "@material-ui/core";
import Cashoutsuccess from "./cashoutSuccess";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles'
class Selfcashout extends Component {
  constructor(props, context) {
    super(props, context);
    this.handlesuccessShow = this.handlesuccessShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCashout = this.sendCashout.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.gotosuccess = this.gotosuccess.bind(this);

    this.state = {
      show: false,
      merchant_code: "",
      business_name: "",
      phone_number: "",
      low_balance: "",

      balance: "",
      loading: false,
      pin: "",
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    this.setState({ loading: true, low_balance: "" });
    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );

      console.log("check pin response", response.data);
      // this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("pin success");
        // window.location.href = "./cashoutSuccess";
        this.sendCashout();
        // this.handleClose();
      }

    

      if(response.data.error){
    this.setState({ loading: false });

        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  getDetails() {
    const cashoutself = JSON.parse(Cookies.get("cashoutself"));
    let response = cashoutself;
    this.setState({
      balance: cashoutself.balance,
      business_name: response.business_name,
      phone_number: response.phone_number,
    });
    // console.log("merchant details", response);
  }

  async sendCashout() {
    this.setState({ loading: true });
    // event.preventDefault();
    const cashoutself = JSON.parse(Cookies.get("cashoutself"));
    this.setState({ merchant_code: cashoutself.merchant_code });
    // console.log("check for business name", cashoutself);
    const data = {
      merchant_code: cashoutself.merchant_code,

      balance: cashoutself.balance,
      business_name: cashoutself.business_name,
    };

    console.log("cookie data", data);
    // event.preventDefault();
    this.setState({ merchant_code: cashoutself.merchant_code });
    try {
      const response = await axiosInstance.put(
        `/money/withdraw/${this.state.phone_number}/`,
        data
      );

      if (response.data.success === "ok") {
        this.gotosuccess();
      }

      console.log("check self cashout response", response.data);
     if(response.data.low_balance){
        this.setState({ loading: false });

        toast.error(response.data.low_balance, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (error) {
      throw error;

      this.setState({ loading: false });
    }
  }

  handlesuccessShow() {
    this.setState({ show: true });
    this.setState({ loading: false });

    this.setState({ pin: "", low_balance: "" });
    this.getDetails();
  }

  handleClose() {
    this.setState({ show: false });
    // this.showSuccessModal();
  }

  gotosuccess() {
    this.setState({ show: false });
    this.showSuccessModal();
  }

  showSuccessModal = () => {
    this.showModal();
  };

  successModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  render() {
    const { items, loading } = this.state;

    return (
      <div>
        		<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
        <Cashoutsuccess ref={this.successModalRef}></Cashoutsuccess>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              Cash Out
            </h4>
          </div>
          <Modal.Body>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              You want to withdraw
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                ZMW{this.state.balance}
              </span>
              from
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                {this.state.business_name}
              </span>
              <br />
              <br />
              <br />
            </div>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Withdraw
              </span>
            </div>
            <br />

            <input
              placeholder="Enter Pin"
              name="pin"
              className="form-control"
              type="password"
              value={this.state.pin}
              onChange={this.handleChange}
              style={{
                border: "1px solid #29BD00",

                height: "40px",
                padding: "0px 0px 0px 10px ",
                backgroundColor: "none",
                margin: "0px 2px 0px 12% ",
                fontSize: 14,
                width: "70%",
              }}
            />

            <br />
            {this.state.low_balance && (
              <>
                <br />
                <span
                  style={{
                    fontSize: "small",
                    color: "red",
                    textAlign: "center",
                    marginLeft: "15%",
                  }}
                >
                  {this.state.low_balance}
                </span>
                <br />
              </>
            )}

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#29BD00" }}
                />
                <span style={{ color: "#29BD00" }}>Loading...</span>
              </div>
            )}

            {!loading && (
              <div>
                <input
                  onClick={this.handleSubmit}
                  type="submit"
                  value="Withdraw"
                  style={{
                    color: "white",
                    variant: "contained",
                    size: "small",
                    backgroundColor: "#29BD00",
                    margin: "0px 2px 0px 55px ",
                    fontSize: 15,
                    height: "40px",
                    fontWeight: "",
                    textTransform: "none",
                    textAlign: "center",
                    border: "1px solid #29BD00",
                    borderRadius: "100px 100px 100px 100px",
                    padding: "5px 3px ",
                    width: "60%",
                    boxShadow: "0px 3px 3px #E2E2E2",
                    "&:hover": {
                      backgroundColor: "#29BD00",
                    },
                  }}
                />
              </div>
            )}
            <br />
            <h5 style={{ textAlign: "center", color: "red" }}>
              {this.state.error}
            </h5>

            <br />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Selfcashout;
