import React, { Component, Fragment } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
// import './account.css';
import TextField from '@material-ui/core/TextField';

// import "./PageStyles.css";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from 'react-router-dom';
import { Popover, Tooltip, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PaymentIcon from '@material-ui/icons/Payment';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axiosInstance from '../axiosinstance/axiosApi';
import { APIService } from '../api/APIService';

const styles = (theme) => ({
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'yellow !important',
		color: 'red',
	},
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#29BD00', //your color
		},
	},
});

class Agent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			business_name: '',
			first_name: '',
			last_name: '',
			nrc_number: '',
			bank_account_number: '',
			email_address: '',
			contact_phone_number: '',
			mobile_money_account_number: '01',
			physical_address: '',
			kin_first_name: '',
			kin_last_name: '',
			kin_phone_number: '',
			kin_relation: '',
			merchant_code: '000',

			business_name_error: null,
			first_name_error: null,
			last_name_error: null,
			nrc_number_error: null,
			bank_account_number_error: null,
			email_address_error: null,
			contact_phone_number_error: null,
			mobile_money_account_number_error: null,
			physical_address_error: null,
			kin_first_name_error: null,
			kin_last_name_error: null,
			kin_phone_number_error: null,
			kin_relation_error: null,
			nrc_error: null,

			nrc: null,
			show: false,
			loading: false,
			error: '',
			accessshow: false,
			timeoutshow: false,

			access_error: '',

			//form validation
			emailHelperText: '',
			emailError: false,

			phoneHelperText: '',
			phoneError: false,

			phone1HelperText: '',
			phone1Error: false,

			nrcHelperText: '',
			nrcError: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendData = this.sendData.bind(this);
		this.handlelogout = this.handlelogout.bind(this);
	}
	pickedFile = null; // save the picked file in this
	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });

		//email validation begin
		if (event.target.name === 'email_address') {
			!event.target.value.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? this.setState({
						emailHelperText: 'Enter valid email',
						emailError: true,
				  })
				: this.setState({ emailHelperText: '', emailError: false });
		}

		//EMAIL VALIDATION END

		//contact business phonenumber validation
		if (event.target.name === 'contact_phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phoneError: true,
						phoneHelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phoneError: false, phoneHelperText: '' });
		}

		//contact1 validation
		if (event.target.name === 'kin_phone_number') {
			!event.target.value.match(
				/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
			)
				? this.setState({
						phone1Error: true,
						phone1HelperText: 'Enter valid 10 digit phone number',
				  })
				: this.setState({ phone1Error: false, phone1HelperText: '' });
		}

		if (event.target.name === 'nrc_number') {
			!event.target.value.match(/^\d{6}\/\d{2}\/\d{1}$/)
				? this.setState({
						nrcError: true,
						nrcHelperText:
							'Enter valid nrc number with forward slashes',
				  })
				: this.setState({ nrcError: false, nrcHelperText: '' });
		}
	}
	handleUpload = (event) => {
		event.preventDefault();
		this.pickedFile = event.target.files[0];
	};

	handleSubmit = () => {
		if (this.state.first_name == 0) {
			this.setState({
				first_name_error: 'First Name is Required',
			});
		} else if (this.state.last_name == 0) {
			this.setState({
				last_name_error: 'Last Name is Required',
			});
			this.setState({
				first_name_error: '',
			});
		} else if (this.state.nrc_number == 0) {
			this.setState({
				nrc_number_error: 'NRC is Required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
			});
		} else if (this.state.business_name == 0) {
			this.setState({
				business_name_error: ' Business Name is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
			});
		} else if (this.state.bank_account_number == 0) {
			this.setState({
				bank_account_number_error: ' Bank Account Number is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
			});
		} else if (this.state.physical_address == 0) {
			this.setState({
				physical_address_error: ' Physical Address is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
			});
		} else if (this.state.contact_phone_number == 0) {
			this.setState({
				contact_phone_number_error: 'Phone No. is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
			});
		} else if (this.state.email_address == 0) {
			this.setState({
				email_address_error: 'Email Address is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
			});
		} else if (this.state.kin_first_name == 0) {
			this.setState({
				kin_first_name_error: 'First Name is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
			});
		} else if (this.state.kin_last_name == 0) {
			this.setState({
				kin_last_name_error: 'Last Name is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				kin_first_name_error: '',
			});
		} else if (this.state.kin_phone_number == 0) {
			this.setState({
				kin_phone_number_error: 'Phone No. is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				kin_first_name_error: '',
				kin_last_name_error: '',
			});
		} else if (this.state.kin_relation == 0) {
			this.setState({
				kin_relation_error: 'Relation is required',
			});
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				kin_first_name_error: '',
				kin_last_name_error: '',
				kin_phone_number_error: '',
			});
		} else {
			this.sendData();
			this.setState({
				first_name_error: '',
				last_name_error: '',
				nrc_number_error: '',
				business_name_error: '',
				bank_account_number_error: '',
				physical_address_error: '',
				contact_phone_number_error: '',
				email_address_error: '',
				kin_first_name_error: '',
				kin_last_name_error: '',
				kin_phone_number_error: '',
				kin_relation_error: '',
				loading: true,
			});
		}

		// this.setState({ loading: true });

		// console.log("Data: ", form);
	};

	async sendData() {
		// const { form, formErrors } = this.state;

		let data = new FormData();
		data.append('first_name', this.state.first_name);
		data.append('last_name', this.state.last_name);
		data.append('business_name', this.state.business_name);
		data.append('nrc_number', this.state.nrc_number);
		data.append('bank_account_number', this.state.bank_account_number);
		data.append('email_address', this.state.email_address);
		data.append('contact_phone_number', this.state.contact_phone_number);
		data.append(
			'mobile_money_account_number',
			this.state.mobile_money_account_number
		);
		data.append('physical_address', this.state.physical_address);
		data.append('nrc', this.pickedFile);
		data.append('kin_first_name', this.state.kin_first_name);
		data.append('kin_last_name', this.state.kin_last_name);
		data.append('kin_phone_number', this.state.kin_phone_number);
		data.append('kin_relation', this.state.kin_relation);
		data.append('account_type', 'agent');

		try {
			const response = await axiosInstance.post(
				'/account/agent/business/',
				data
			);
			console.log('Checking: ', response);

			if (response.data.success) {
				this.onEkamoClick();
				toast(response.data.success, {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				return this.setState({ loading: false, timeoutshow: false });
			}

			if (
				response.data.error ===
				'You can not access this area, you are not a sales agent'
			) {
				this.setState({
					access_error: response.data.error,
					timeoutshow: false,
					loading: false,
				});

				return this.accesserrorclick();
			}

			if (!response.data.error == 0) {
				console.log('check the if error is present');
				this.setState({
					loading: false,
					timeoutshow: false,
					business_name_error: response.data.error.business_name,
					first_name_error: response.data.error.first_name,
					last_name_error: response.data.error.last_name,
					nrc_number_error: response.data.error.nrc_number,
					bank_account_number_error:
						response.data.error.bank_account_number,
					email_address_error: response.data.error.email_address,
					contact_phone_number_error:
						response.data.error.contact_phone_number,
					mobile_money_account_number_error:
						response.data.error.mobile_money_account_number,
					physical_address_error:
						response.data.error.physical_address,
					kin_first_name_error: response.data.error.kin_first_name,
					kin_last_name_error: response.data.error.kin_last_name,
					kin_phone_number_error:
						response.data.error.kin_phone_number,
					kin_relation_error: response.data.error.kin_relation,
					nrc_error: response.data.error.nrc,
				});
			}

			if (response.data.error) {
				console.log('this is the error log', response.data);
				this.setState({
					loading: false,
					error: response.data.message,
					timeoutshow: false,
				});
				this.notify();
			}
		} catch (error) {
			console.log(error);

			this.setState({ loading: false, timeoutshow: false });
			this.setState({
				errors: error,
			});
		}

		setTimeout(() => {
			this.setState({
				loading: false,
				timeoutshow: true,
			});
		}, 60000);
	}

	async handlelogout(event) {
		event.preventDefault();
		try {
			const response = await axiosInstance.post('/blacklist/', {
				refresh_token: localStorage.getItem(' '),
			});
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			axiosInstance.defaults.headers['Authorization'] = null;
			window.location.href = '/';
			// console.log("you have been terminated");
			return response;
		} catch (e) {
			// console.log(e);
		}
	}

	accesserrorclick = () => {
		this.setState({ accessshow: true });
	};

	onEkamoClick = () => {
		this.setState({ show: true });
	};

	notify = () => {
		toast.error(this.state.error, {
			position: 'top-center',
			autoClose: 400000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	handletimeClose = () => {
		this.setState({ timeoutshow: false });
	};
	render() {
		const { loading } = this.state;

		return (
			<div>
				<ToastContainer
					position='top-center'
					autoClose={400000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Modal
					scrollable={true}
					show={this.state.timeoutshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<br />
						<h5
							style={{
								fontWeight: 'bold',
								color: 'red',
								textAlign: 'center',
							}}>
							Response taking too long
						</h5>
						<br />
						<ul>
							<li>
								Go through the form and check for error
								messages.
							</li>
							<li>
								Go to accounts created page to see if account
								has been created.
							</li>
							<li>Ensure you have Strong internet Connection.</li>
							<li>
								Make sure you have logged in and are able to
								view your name.
							</li>
							<li>
								Ensure the number you used to login is
								activaterd as Sales Agent.
							</li>
							<li>If issue persists, contact us.</li>
						</ul>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Button
								onClick={this.handletimeClose}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.accessshow}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							{this.state.access_error}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />

							<Button
								onClick={this.handlelogout}
								style={{
									color: 'white',
									variant: 'contained',
									size: 'small',
									backgroundColor: '#29BD00',
									margin: '0px 1px 0px 0px ',
									fontSize: 15,
									height: '40px',
									fontWeight: '',
									textTransform: 'none',
									border: '1px solid #29BD00',
									borderRadius: '100px 100px 100px 100px',
									padding: '5px 3px ',
									width: '70%',
									boxShadow: '0px 3px 3px #E2E2E2',
									'&:hover': {
										backgroundColor: '#29BD00',
									},
								}}>
								Close
							</Button>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Modal
					scrollable={true}
					show={this.state.show}
					onHide={this.handleClose}
					centered
					backdrop='static'>
					<Modal.Header />

					<Modal.Body>
						<h4 style={{ textAlign: 'center' }}>
							You Have Succesfully Created a Profile account
							called {this.state.business_name}
						</h4>
						<div
							style={{
								marginLeft: '20%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<br />
							<br />
							<Link
								to='/home'
								color='inherit'
								style={{ fontSize: '12px', color: 'white' }}>
								<Button
									style={{
										color: 'white',
										variant: 'contained',
										size: 'small',
										backgroundColor: '#29BD00',
										margin: '0px 1px 0px 0px ',
										fontSize: 15,
										height: '40px',
										fontWeight: '',
										textTransform: 'none',
										border: '1px solid #29BD00',
										borderRadius: '100px 100px 100px 100px',
										padding: '5px 3px ',
										width: '70%',
										boxShadow: '0px 3px 3px #E2E2E2',
										'&:hover': {
											backgroundColor: '#29BD00',
										},
									}}>
									Close
								</Button>
							</Link>
						</div>
						<br />
						<br />
					</Modal.Body>
				</Modal>
				<Grid item container>
					<ThemeProvider theme={theme}>
						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>

						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<div
								style={{
									overflowY: 'scroll',
									margin: 0,
									minHeight: 40,
									textAlign: 'left',
									marginLeft: 20,
									marginTop: '10%',
									marginRight: 20,
									borderRadius: '10px 10px 0px 0px',
									background: [
										[
											'url(https://liftandshifttrucking.co.zm/email_signature/reg_title_icon.svg)',
											'no-repeat',
											'top 56% left 440px',
										],

										['#29BD00'],
									],
									color: 'white',
								}}>
								<Typography
									style={{ padding: 10, paddingLeft: 20 }}>
									Create Ekamo Agent Account
								</Typography>
							</div>
							<Card
								style={{
									minHeight: 0,
									border: '1px solid #E8E8E8',
									textAlign: 'left',
									borderRadius: 0,
									marginLeft: 20,
									marginRight: 20,
									boxShadow: 'none',
								}}>
								<CardContent>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}>
										<Card
											style={{
												background: [
													[
														'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
														'no-repeat',
														'top -0% left -50%',
													],
												],

												padding: 20,
											}}>
											<br />
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}>
												<div>
													Basic Agent Information
												</div>
											</Grid>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='first_name'
														label='First Name'
														type='text'
														value={
															this.state
																.first_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.first_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.first_name_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='last_name'
														label='Last Name'
														type='text'
														value={
															this.state.last_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.last_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.last_name_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														error={
															this.state.nrcError
														}
														helperText={
															this.state
																.nrcHelperText
														}
														name='nrc_number'
														label='Enter NRC NO.'
														type='text'
														value={
															this.state
																.nrc_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.nrc_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.nrc_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='nrc'
														label='Upload NRC'
														type='file'
														value={this.state.nrc}
														onChange={
															this.handleUpload
														}
														onBlur={
															this.handleUpload
														}
														autoFocus
														id='filled-number'
														InputLabelProps={{
															shrink: true,
														}}
													/>

													{/* <label htmlFor="contained-button-file">
                            <Button
                              style={{
                                textTransform: "none",
                                border: "1px solid #979797",

                                height: "40px",
                                padding: "0px 0px 0px 10px ",
                                background: "none",
                                marginTop: 20,
                                color: "#C0C0C0",

                                fontSize: 13,
                                fontWeight: "normal",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                boxShadow: "none",
                                "&:hover": {
                                  backgroundColor: "#d4f2cc",
                                  border: "1px solid #d4f2cc",
                                  boxShadow: "none",
                                },
                              }}
                              startIcon={
                                <CloudUploadIcon
                                  style={{ fontSize: 18, color: "#C0C0C0" }}
                                />
                              }
                              variant="contained"
                              component="span"
                            >
                              Upload NRC
                            </Button>
                          </label>
                          <InputBase
                            accept="image/*"
                            style={{
                              fontSize: 10,
                              textTransform: "none",
                              paddingRight: 20,
                              opacity: 0.3,
                            }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            name="nrc"
                            value={this.state.nrc}
                            onChange={this.handleUpload}
                            onBlur={this.handleUpload}
                          /> */}

													<br />
													{this.state.nrc_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.nrc_error
															}
														</span>
													)}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='business_name'
														label='Business Name'
														type='text'
														value={
															this.state
																.business_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.business_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.business_name_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='bank_account_number'
														label='Bank Account Number'
														value={
															this.state
																.bank_account_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.bank_account_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.bank_account_number_error
															}
														</span>
													)}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='physical_address'
														label='Physical Address'
														type='text'
														value={
															this.state
																.physical_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.physical_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.physical_address_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														error={
															this.state
																.phoneError
														}
														helperText={
															this.state
																.phoneHelperText
														}
														name='contact_phone_number'
														label='Phone No.'
														type='number'
														value={
															this.state
																.contact_phone_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.contact_phone_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.contact_phone_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														error={
															this.state
																.emailError
														}
														helperText={
															this.state
																.emailHelperText
														}
														name='email_address'
														label='Email Address'
														type='text'
														value={
															this.state
																.email_address
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.email_address_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.email_address_error
															}
														</span>
													)}
												</Grid>
											</Grid>
											<br />
										</Card>

										<br />
										<Card
											style={{
												background: [
													[
														'url(https://liftandshifttrucking.co.zm/email_signature/ba_1.jpg)',
														'no-repeat',
														'top -0% left -50%',
													],
												],

												padding: 20,
											}}>
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}>
												<div>Next Of Kin</div>
											</Grid>
											<Grid container spacing={2}>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='kin_first_name'
														label='First Name'
														type='text'
														value={
															this.state
																.kin_first_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.kin_first_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.kin_first_name_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='kin_last_name'
														label='Last Name'
														type='text'
														value={
															this.state
																.kin_last_name
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.kin_last_name_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.kin_last_name_error
															}
														</span>
													)}
												</Grid>

												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														error={
															this.state
																.phone1Error
														}
														helperText={
															this.state
																.phone1HelperText
														}
														name='kin_phone_number'
														label='Phone No.'
														type='text'
														value={
															this.state
																.kin_phone_number
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>
													{this.state
														.kin_phone_number_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.kin_phone_number_error
															}
														</span>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
													lg={4}
													xl={4}>
													<TextField
														variant='outlined'
														margin='normal'
														required
														fullWidth
														name='kin_relation'
														label='Relation'
														type='text'
														value={
															this.state
																.kin_relation
														}
														onChange={
															this.handleChange
														}
														onBlur={
															this.handleChange
														}
													/>

													{this.state
														.kin_relation_error && (
														<span
															style={{
																fontSize:
																	'small',
																color: 'red',
															}}>
															{
																this.state
																	.kin_relation_error
															}
														</span>
													)}
												</Grid>
											</Grid>
										</Card>
										<span style={{ fontSize: '10px' }}>
											Note: All fields are required
										</span>

										<br />
										<Hidden smDown>
											<br />
										</Hidden>
										<Grid container spacing={0}>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
											<Grid
												item
												xs={12}
												sm={4}
												md={4}
												lg={4}
												xl={4}>
												<Button
													onClick={this.handleSubmit}
													type='submit'
													value='Submit'
													style={{
														color: 'white',
														variant: 'contained',
														size: 'small',
														backgroundColor:
															'#29BD00',
														margin: '0px 1px 0px 0px ',
														fontSize: 15,
														height: '40px',
														fontWeight: '',
														textTransform: 'none',
														textAlign: 'center',
														border: '1px solid #29BD00',
														borderRadius:
															'100px 100px 100px 100px',
														padding: '5px 3px ',
														width: '100%',
														boxShadow:
															'0px 3px 3px #E2E2E2',
														'&:hover': {
															backgroundColor:
																'#29BD00',
														},
													}}>
													{loading && (
														<>
															<Spinner
																as='span'
																animation='grow'
																size='lg'
																role='status'
																aria-hidden='true'
																style={{
																	color: '#fff',
																}}
															/>
														</>
													)}
													{!loading && (
														<span>Submit</span>
													)}
												</Button>
											</Grid>
											<Grid
												item
												xs={0}
												sm={4}
												md={4}
												lg={4}
												xl={4}
											/>
										</Grid>
										<br />
									</Grid>
								</CardContent>
							</Card>
						</Grid>

						<Hidden mdDown>
							<Grid item xs={12} sm={0} md={2} lg={2} xl={2} />
						</Hidden>
					</ThemeProvider>
				</Grid>
			</div>
		);
	}
}

Agent.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Agent);
