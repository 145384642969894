import React, { Component } from "react";
import axiosInstance from "../../axiosinstance/axiosApi";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Login from "../../auth/login";
import Sendotp from "../../auth/register/sendotp";

import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { InputBase } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
class Headername extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      first_name: "",
      last_name: "",
      balance: "",
      username: "",
      password: "",
      isLoading: true,
      logged_in: localStorage.getItem("access_token") ? true : false,
      matches: window.matchMedia("(max-width: 699px)").matches,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.getUser = this.getUser.bind(this);
    // this.handleSubmitWThen = this.handleSubmitWThen.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  async handleLogout(event) {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/blacklist/", {
        refresh_token: localStorage.getItem(" "),
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;
      window.location.href = "/";
  
      return response;
    } catch (e) {
     
    }
  }

  async getUser() {
    if (this.state.logged_in) {
      try {
        let response = await axiosInstance.get("/user/details/");
        this.setState({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          balance: response.data.balance,
        });
        Cookies.set(`names`, {
          first_name: response.data.first_name,
          last_name: response.data.last_name,
        });
    
        return response;
      } catch (error) {

      }
    }
  }

 

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#000",
              marginLeft: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {this.state.first_name}
          </span>
          <span
            style={{
              color: "#000",
              marginLeft: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {this.state.last_name}
          </span>
        </div>
      </div>
    );
  }
}
export default Headername;
