import React, { Component } from "react";
import { Popover, Tooltip, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import axiosInstance from "../../axiosinstance/axiosApi";
import {  InputBase } from "@material-ui/core";

// import Success from "./success";
import Spinner from "react-bootstrap/Spinner";
import { Grid } from "@material-ui/core";
import Successmodal from "./success";
import "../load.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Confirmmtn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleConfirmShow = this.handleConfirmShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      show: false,
      loading: false,
      error: "",
      errorpin: "",
      loadingerror: "",
      error_message:""
    };

    // this.state = {
    //   show: false,
    // };
  }

  getmtnLoadfunds() {
    const airtelmomo = JSON.parse(Cookies.get("mtnmomo"));
    let response = airtelmomo;
    this.setState({
      price: airtelmomo.amount,
      phone_number: airtelmomo.phone_number,
    });
    // console.log("Mtn Load fund details", response);
  }

  handleConfirmShow() {
    // console.log(this.state);
    this.setState({ show: true, loading: false });
    this.getmtnLoadfunds();
  }
  handleClose() {
    this.setState({ show: true, loading: false })
    this.showSuccessModal();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }


  async handleSubmit() {
    // this.gotosuccess();
    this.setState({ loading: true });

    try {
      const response = await axiosInstance.get(
        `pin/verification/${this.state.pin}`
      );
console.log("check pin response",response.data.error)
      if(response.data.error){


        toast.error(response.data.error, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });


        this.setState({
          error_message:response.data.error,
          loading:false
        })
      }
      // this.setState(response.data);

      if (response.data.pin === "success") {
        // console.log("pin success");
        this.onLoginClick();
       console.log("pin successfully tested")
        // this.handleClose();
      }

      return response;
    } catch (error) {
      throw error;
    }
  }
  promptModalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  async onLoginClick() {
 
    // this.setState({ show: false });

    // this.showModal();
    // this.gotoprompt();

    const mtnmomo = JSON.parse(
      Cookies.get("mtnmomo", {
        // expires: ExpireIn30Minutes,
      })
    );

    const data = mtnmomo;

    // const data = { phone_number };

    // const ExpireIn30Minutes = 1 / 48;

    //  const airtel = airtel

    // this.setState({ show: false });
    console.log("send prompt to mtn money", data);
    try {
      const response = await axiosInstance.post("/mtn/loadfund/", data);

      // Cookies.set(`mtnfund`, {
      //   supplier_transaction_id: response.data.supplier_transaction_id,
      //   wallet_msisdn: response.data.wallet_msisdn,
      // });
      console.log("get mtn server response", response);
    } catch (error) {
      // console.log("ticket server response", error);
    }
  }

  gotoprompt = () => {
    this.setState({ show: false });

    this.showModal();
  };

  handleClose() {
    this.setState({ show: false });
    // this.showSuccessModal();
  }
  render() {
    const { values, handleChange } = this.props;
    const { items, loading } = this.state;
    return (
      <div>
        <Successmodal ref={this.promptModalRef}></Successmodal>
        <Modal
          scrollable={true}
          show={this.state.show}
          onHide={this.handleClose}
          centered
          backdrop="static"
          size="sm"
        >
          <Modal.Header
            style={{
              backgroundColor: "#fff",

              textAlign: "center",
            }}
            closeButton
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
              LoadFunds
            </h4>
          </div>
          <Modal.Body>
          <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              Load
              <span
                style={{
                  fontWeight: "bolder",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                ZMW {this.state.price}
              </span>
              to
            

               your Ekamo Wallet Account using MTN Money
              <br />
              <br />
              <br />
            </div>
            <div
              style={{ fontSize: 12, textAlign: "center", marginTop: "-14px" }}
            >
              <span style={{ marginBottom: "9px" }}>
                Enter Pin To Confirm Payment
              </span>
            </div>
            <br />
            <div
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputBase
              autocomplete="off"
                placeholder="Enter Pin"
                name="pin"
                className="form-control"
                type="password"
                value={this.state.pin}
                onChange={this.handleChange}
                value={this.state.pin}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                style={{
                  border: "1px solid #29BD00",

                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "none",
                  margin: "0px 2px 0px 12% ",
                  fontSize: 14,
                  width: "70%",
                }}
              />
              <br />
              {/* {formErrors.amount && (
                <span style={{ fontSize: "small", color: "red" }}>
                  {formErrors.amount}
                </span>
              )} */}
              <br />
              <br />
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#29BD00" }}
                  />
                  <span style={{ color: "#29BD00" }}>Loading...</span>
                </div>
              )}

              {!loading && (
                <div>
                  <input
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Send"
                    style={{
                      color: "white",
                      variant: "contained",
                      size: "small",
                      backgroundColor: "#29BD00",
                      margin: "0px 2px 0px 55px ",
                      fontSize: 15,
                      height: "40px",
                      fontWeight: "",
                      textTransform: "none",
                      textAlign: "center",
                      border: "1px solid #29BD00",
                      borderRadius: "100px 100px 100px 100px",
                      padding: "5px 3px ",
                      width: "60%",
                      boxShadow: "0px 3px 3px #E2E2E2",
                      "&:hover": {
                        backgroundColor: "#29BD00",
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Confirmmtn;
